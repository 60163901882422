import React, { useEffect, useState } from "react";
import Modal from "../clientes/ClienteModal";
import {EFECTIVO, MASTER, AMERICAN, VISA, YAPE, PLIN, RAPPI, TUNKI, calcularTotales, decimalAdjust} from "../../Global";
import {defaultPostHeaders} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import { getPreventaFromDB, getDetallesPreventaFromDB } from './shared';
import moment from "moment";
import printTicket from "../../pages/RegistroVentas/Ticket";


const defaultPago = {
    date: moment(new Date()).format("YYYY-MM-DD"),
    monto: 0
};

export default function (props) {
    const [pedido, setPedido] = useState(undefined);
    const [pagos, setPagos] = useState([]);
    const [preventa, setPreventa] = useState(undefined);
    const [detallesPreventa, setDetallesPreventa] = useState([]);
    const [isAddingPago, setIsAddingPago] = useState(false);
    const [currentPago, setCurrentPago] = useState({...defaultPago});
    const [totalPagado, setTotalPagado] = useState(0);
    const [totales, setTotales] = useState(undefined);
    const [montoRestante, setMontoRestante] = useState(0);

    const [vale, setVale] = useState(undefined);
    const [detallesVale, setDetallesVale] = useState([]);
    const [tipoPago, setTipoPago] = useState(EFECTIVO);
    const [reference, setReference] = useState("");

    const [isSavingPayment, setIsSavingPayment] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const pedido = await getPedidoById();
            const preventa = await getPreventaFromDB(pedido.IdPreventa);
            const detallesPreventa = await getDetallesPreventaFromDB(pedido.IdPreventa);

            const _totales = calcularTotales(detallesPreventa);
            setTotales(_totales);

            const pagos = await getPagos(pedido.IdVenta);
            setPagos(pagos)
            setTotalPagado(calculateMontoPagado(pagos, _totales));
            const vale = await getVale(pedido.IdVenta);
            const detalles = await getDetallesVale(pedido.IdVenta);

            const restante = calculateMontoRestante(pagos, _totales);
            setMontoRestante(restante);

            setPedido(pedido);
            setPreventa(preventa);
            setDetallesPreventa(detallesPreventa);

            setVale(vale);
            setDetallesVale(detalles);
        }
        fetchData();
    }, [])

    useEffect(() => {
        if (totales && pagos) {
            setTotalPagado(calculateMontoPagado(pagos, totales));
            setMontoRestante(calculateMontoRestante(pagos, totales));
        }
    }, [pagos, totales])

    const onChange = () => {
        if (props.onChange && typeof props.onChange === "function") {
            props.onChange();
        }
    }

    const getPedidoById = async () => {
        try {
            const req = await fetch(`/api/pedidos/${props.pedidoId}`);
            if (!req.ok) {
                throw new Error("Ocurrió un error al obtener datos del pedido.")
            }
    
            return await req.json();
        } catch (e) {
            notificarError(e.message)
        }
    }

    const getPagos = async (idVenta) => {
        try {
            const req = await fetch(`/api/pagos/vale/get/${idVenta}`);
            if (!req.ok) {
                throw new Error("Ocurrió un error al obtener datos del pedido.")
            }

            const { respuesta } = await req.json();

            return respuesta;
        } catch (e) {
            notificarError(e.message)
        }
    }

    const getVale = async (idVenta) => {
        try {
            const req = await fetch(`/api/vales/get/${idVenta}`);
            if (!req.ok) {
                throw new Error("Ocurrió un error al obtener datos del pedido.")
            }

            const { respuesta } = await req.json();
            return respuesta[0];
        } catch (e) {
            notificarError(e.message)
        }
    }

    const getDetallesVale = async (idVenta) => {
        try {
            const req = await fetch(`/api/vales/detalles/${idVenta}`);
            if (!req.ok) {
                throw new Error("Ocurrió un error al obtener datos del pedido.")
            }

            const { respuesta } = await req.json();

            return respuesta;
        } catch (e) {
            notificarError(e.message)
        }
    }

    const calculateMontoPagado = (pagos, totales) => {
        const totalPagado = pagos.reduce((acc, curr) => Number(curr.Monto) + acc, 0);
        return decimalAdjust('round', totalPagado, -1);
    }

    const calculateMontoRestante = (pagos, totales) => {
        const totalPagado = pagos.reduce((acc, curr) => Number(curr.Monto) + acc, 0);
        return decimalAdjust('round', totales.total - totalPagado, -1);
    }

    const onWantAddPago = () => {
        setIsAddingPago(false);
        setIsAddingPago(true);
        setCurrentPago({...defaultPago, monto: +montoRestante })
    }

    const guardarPago = async () => {
        if (isNaN(currentPago.monto) || Number(currentPago.monto) <= 0) {
            notificarError("El monto ingresado no es válido.");
            return;
        }

        const date = moment(currentPago.date);
        if (!date.isValid() || date.isAfter(moment())) {
            notificarError("La fecha ingresada no es válida.");
            return;
        }

        setIsSavingPayment(true);

        if (isSavingPayment) {
            return;
        }

        const req = await fetch(`/api/pedidos/${props.pedidoId}/pagos`, {
            body: JSON.stringify({
                "DetallesPagos": [
                    [
                        pedido.IdVenta,
                        tipoPago,
                        Number(currentPago.monto),
                        "PAGADO",
                        reference,
                        true,
                        null
                    ]
                ]
            }),
            method: "POST",
            headers: {...defaultPostHeaders()},
        });

        const pagos = await getPagos(pedido.IdVenta);

        setIsSavingPayment(false);

        if (!req.ok) {
            notificarError("No se pudo registrar el pago. Intentelo otra vez.");
            return;
        }

        setPagos(pagos)

        setTipoPago(EFECTIVO);
        setReference("");
        setCurrentPago({...defaultPago});

        notificarMsg("Se registro el pago de manera exitosa");
        setIsAddingPago(false);
    }

    const onPrint = async () => {
        printTicket(detallesVale, vale, 'png', {
            montoPagado: totalPagado,
            total: totales.total,
            restante: montoRestante,
            fechaEntrega: moment(preventa.FechaEntrega).format("DD-MM-YYYY"),
        });
    };

    const onComplete = async () => {
        const req = await fetch(`/api/pedidos/${props.pedidoId}/completar`);

        if (!req.ok) {
            notificarError("No se pudo completar el pedido. Intentelo otra vez.");
            return;
        }

        setPedido({...pedido, Estado: "COMPLETADO"});
        onChange();
        
        notificarMsg("Se completó el pedido de manera exitosa");
    }

    const onTryProcess = () => {
        window.location.href = `/vales/procesar/${pedido.IdVenta}?cliente=${preventa.NroTipoDocumento}&isPedido=`;
    }

    if (!preventa || !pedido || !totales) {
        return null;
    }
    
    return <>
        <Modal isOpen={true} title="Gestionar pedido" onClose={() => props.onClose()}>
            <hr width="1080px" className="border-0" />
            <div className="row">
                <div className="col-7">
                    <div className="form-row">
                        <div className="form-group col-12">
                            <label>Cliente</label>
                            <input type="text" className="form-control" value={`${preventa.NroTipoDocumento} - ${preventa.RazonSocial}`} disabled/>
                        </div>

                        <div className="form-group col-12">
                            <label>Observación:</label>
                            <textarea className="form-control" cols="30" rows="3" value={pedido.Observacion} disabled></textarea>
                        </div>

                        <div className="form-group col-4">
                            <label>Fecha de entrega:</label>
                            <input type="text" className="form-control" value={moment(preventa.FechaEntrega).format("DD-MM-YYYY")} disabled/>
                        </div>

                        <div className="form-group col-3">
                            <label>Total S/:</label>
                            <input type="text" className="form-control" value={decimalAdjust('round', totales.total, -1)} disabled/>
                        </div>

                        <div className="form-group col-3">
                            <label>Pagado S/:</label>
                            <input type="text" className="form-control" value={totalPagado} disabled/>
                        </div>

                        <div className="form-group col-2">
                            <label>Estado:</label>
                            <input type="text" className="form-control font-weight-bold" value={pedido.Estado} disabled/>
                        </div>
                    </div>

                    <h5 className="font-weight-bold">Resumen</h5>
                   
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th style={{ color: "white", background: "#01A59C", width: '40%' }}>
                                    CANTIDAD
                                </th>
                                <th style={{ color: "white", background: "#01A59C", width: '40%' }}>
                                    PRECIO
                                </th>
                                <th style={{ color: "white", background: "#01A59C", width: '40%' }}>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detallesPreventa.map((detalle, index) => <>
                                <tr key={`description-${index}`}>
                                    <td colSpan={3}>{detalle.descripcion} {detalle.unidadMedida}</td>
                                </tr>
                                <tr key={`detail-${index}`}>
                                    <td>{decimalAdjust("round", detalle.Cantidad, -2)}</td>
                                    <td>{decimalAdjust("round", detalle.PrecioVenta, -2)}</td>
                                    <td>{decimalAdjust("round", detalle.Total, -2)}</td>
                                </tr>
                            </>)}
                        </tbody>
                    </table>
                </div>
                
                <div className="col-5">
                    <div className="d-flex">
                        <div className="p-2 flex-grow-1">
                            <h5 className="font-weight-bold mt-2">Pagos</h5>
                        </div>
                        <div className="p-2">
                            {(!isAddingPago && montoRestante > 0) && <button type="button" className="btn btn-success btn-verde-mfp-letra-normal" onClick={onWantAddPago}>Agregar</button>}
                        </div>
                    </div>
                    {montoRestante > 0 && <div className="alert alert-danger" role="alert">
                        <strong>Aviso:</strong> Aún queda un monto pendiente de S/ {montoRestante}
                    </div>}
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th style={{ width: '25%' }}>Monto</th>
                                <th style={{ width: '25%' }}>Fecha</th>
                                <th style={{ width: '50%' }}>T. Pago</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pagos.map((p, index) => <tr key={`payment-${index}`}>
                                <td>{p.Monto}</td>
                                <td>{p.FechaPago}</td>
                                <td>{p.TipoPago} {p.Referencia && p.Referencia !== "" ? `- ${p.Referencia}` : "" }</td>
                            </tr>)}
                            {isAddingPago && <>
                                <tr>
                                    <td>
                                        <input type="number" className="form-control" defaultValue={currentPago.monto}  value={Number(currentPago.monto).toString()} onChange={e => setCurrentPago({...currentPago, monto: e.target.value})}/>
                                    </td>
                                    <td>
                                        <input type="date" className="form-control" value={moment(currentPago.date).format("YYYY-MM-DD")} onChange={e => setCurrentPago({...currentPago, date: e.target.value})}/>
                                    </td>
                                    <td>
                                        <div className="d-flex">
                                            <div className="flex-grow-1 mr-2">
                                                <select name="tipoPago" id="tipoPago" value={tipoPago} onChange={e => setTipoPago(e.target.value)} className="form-control mr-2">
                                                    <option value={EFECTIVO}>Efectivo</option>
                                                    <option value={MASTER}>Master</option>
                                                    <option value={AMERICAN}>American</option>
                                                    <option value={VISA}>Visa</option>
                                                    <option value={YAPE}>Yape</option>
                                                    <option value={PLIN}>Plin</option>
                                                    <option value={RAPPI}>Rappi</option>
                                                    <option value={TUNKI}>Tunki</option>
                                                </select>
                                            </div>
                                            <div className="">
                                                <button type="button" className="btn btn-success btn-verde-mfp-letra-normal" onClick={guardarPago}>Registrar</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <div className="form-group row">
                                            <label for="reference" className="col-sm-2 col-form-label">Referencia</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="reference" placeholder="Ref. num" value={reference} onChange={e => setReference(e.target.value)}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </>}
                        </tbody>
                    </table>
                </div>
            </div>

            <button type="button" className="btn btn-outline-primary btn-verde-mfp btn-block" onClick={onPrint}>Imprimir</button>

            {pedido.Estado === "PENDIENTE" && montoRestante <= 0 && <button type="button" className="btn btn-outline-primary btn-verde-mfp btn-block" onClick={onComplete}>Completar</button>}
            
            {(pedido.Estado === "COMPLETADO" && pedido.EstadoVale === 'PENDIENTE') && <button type="button" className="btn btn-outline-primary btn-verde-mfp btn-block" onClick={onTryProcess}>Procesar</button>}
        </Modal>
    </>
}
