import React from 'react';
import {Link} from 'react-router-dom';
import PageLoading from '../PageLoading';
import ClienteForm from "./ClientesForm";
import {async} from 'q';
import swal from "sweetalert";

class ClientesEditar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            form: {
                IdCliente: "",
                IdTipoCliente: "",
                IdTipoPersona: "",
                IdTipoDocumento: "",
                NroTipoDocumento: "",
                RazonSocial: "",
                NombreComercial: "",
                ApellidoPaterno: "",
                ApellidoMaterno: "",
                PrimerNombre: "",
                SegundoNombre: "",
                Direccion: "",
                Telefono: "",
                Celular: "",
                CorreoElectronico: "",
                PersonaContacto: "",
                BuenContribuyente: false,
                AgentePercepcion: false,
                AgenteRetencion: false,
                Prico: false,
                DAOT: false,
                CIIU: null,
                IdTipoMoneda: null,
                LineaCredito: null,
                FormaPago: null,
                EstadoSunat: null,
                Situacion: null,
                Ubigeo: "",
                Region: "",
                Provincia: "",
                Distrito: "",
                IdEmpresa: "",
                Estado: "",
                Zona:"",
                Descuento:"0"
            },
            editarDescuento:false,
            data: {
                results: []
            },
            dataMoneda: {
                results: []
            },
            datareg: [],
            dataprov: [],
            datadist: [],
        };
    }

    async componentDidMount() {
        await this.TraerClientePorId();
        await this.listaMonedas();
        await this.verificarEditarDescuento();
    };

    async verificarEditarDescuento() {
        try {
            const responseValidarMontos = await fetch(`/api/clientes/editarDescuento/validar`);
            const data = await responseValidarMontos.json();
            this.setState({editarDescuento: true})
        } catch (e) {
            this.setState({editarDescuento: false})
        }
    }


    CargarProvincias = async (codreg) => {
        const dataP = await this.state.data.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprov: dataP,
            form: {
                ...this.state.form,
                Region: codreg,
                Provincia: null
            },
            datadist: [],
        });
        //console.log(this.state);
    };

    CargarDistritos = async (codprov) => {
        const dataD = await this.state.data.results.filter((item) => {
            return item.CodRegion === this.state.form.Region && item.CodProvincia === codprov && item.CodDistrito != "00"
        });
        this.setState({
            datadist: dataD,
            form: {
                ...this.state.form,
                Provincia: codprov,
                Distrito: null
            },

        });
    };

    /* ******************************************************************
            FUNCION: TRAE UN CLIENTE POR ID
    * ******************************************************************/
    TraerClientePorId = async () => {
        this.setState({loading: true, error: null});
        try {
            // const response = await fetch(`/api/clientes/item/${this.props.match.params.idcliente}`);
            const response = await fetch(`/api/clientes/item/${this.props.idcliente}`);
            const regcli = await response.json();
            console.log(regcli);
            this.setState({
                loading: false,
                form: {
                    IdCliente: regcli[0][0].IdCliente,
                    IdTipoCliente: regcli[0][0].IdTipoCliente,
                    IdTipoPersona: regcli[0][0].IdTipoPersona,
                    IdTipoDocumento: regcli[0][0].IdTipoDocumento,
                    NroTipoDocumento: regcli[0][0].NroTipoDocumento,
                    RazonSocial: regcli[0][0].RazonSocial ? regcli[0][0].RazonSocial : "",
                    NombreComercial: regcli[0][0].NombreComercial,
                    ApellidoPaterno: regcli[0][0].ApellidoPaterno,
                    ApellidoMaterno: regcli[0][0].ApellidoMaterno,
                    PrimerNombre: regcli[0][0].PrimerNombre ? regcli[0][0].PrimerNombre : "",
                    SegundoNombre: regcli[0][0].SegundoNombre ? regcli[0][0].SegundoNombre : "",
                    Direccion: regcli[0][0].Direccion,
                    Telefono: regcli[0][0].Telefono,
                    Celular: regcli[0][0].Celular,
                    CorreoElectronico: regcli[0][0].CorreoElectronico,
                    PersonaContacto: regcli[0][0].PersonaContacto,
                    BuenContribuyente: regcli[0][0].BuenContribuyente === "Si" ? true : false,
                    AgentePercepcion: regcli[0][0].AgentePercepcion === "Si" ? true : false,
                    AgenteRetencion: regcli[0][0].AgenteRetencion === "Si" ? true : false,
                    Prico: regcli[0][0].Prico === "Si" ? true : false,
                    DAOT: regcli[0][0].DAOT === "Si" ? true : false,
                    CIIU: regcli[0][0].CIIU,
                    IdTipoMoneda: regcli[0][0].IdTipoMoneda,
                    LineaCredito: regcli[0][0].LineaCredito,
                    FormaPago: regcli[0][0].FormaPago,
                    EstadoSunat: regcli[0][0].EstadoSunat,
                    Situacion: regcli[0][0].Situacion,
                    Ubigeo: regcli[0][0].Ubigeo ? regcli[0][0].Ubigeo : "",
                    Region: regcli[0][0].Ubigeo ? regcli[0][0].Ubigeo.substr(0, 2) : "",
                    Provincia: regcli[0][0].Ubigeo ? regcli[0][0].Ubigeo.substr(2, 2) : "",
                    Distrito: regcli[0][0].Ubigeo ? regcli[0][0].Ubigeo.substr(4, 2) : "",
                    IdEmpresa: regcli[0][0].Idempresa,
                    Estado: regcli[0][0].Estado,
                    Zona:regcli[0][0].Zona,
                    Descuento:regcli[0][0].DescuentoPersonal
                }
            });
            await this.listaUbigeos();
            await this.CargarProvincias(regcli[0][0].Ubigeo.substr(0, 2));
            await this.CargarDistritos(regcli[0][0].Ubigeo.substr(2, 2));
            this.setState({
                form: {
                    ...this.state.form,
                    Distrito: regcli[0][0].Ubigeo.substr(4, 2)
                },

            });
            //console.log(regcli.results[0]);

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    /* ******************************************************************
            FUNCION: OBTIENE LA LISTA DE MONEDAS
    * ******************************************************************/
    listaMonedas = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/clientes/monedas/`);
            const dataMoneda = await response.json();
            this.setState({
                loading: false,
                dataMoneda: dataMoneda,

            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    /* ******************************************************************
            FUNCION: TRAE LA LISTA DE UBIGEOS
    * ******************************************************************/
    listaUbigeos = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/clientes/ubigeos/`);
            const data = await response.json();
            this.setState({
                loading: false,
                data: data,
            });

            const dataR = await this.state.data.results.filter((item) => {
                return item.CodProvincia === "00"
            });
            this.setState({
                datareg: dataR,
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    /* ******************************************************************
            FUNCION: ACTUALIZA DATOS DE CLIENTE
    * ******************************************************************/
    actualizarCliente = async () => {
        this.setState({loading: true, error: null});
        try {
            const config = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdTipoCliente: this.state.form.IdTipoCliente,
                    IdTipoPersona: this.state.form.IdTipoPersona,
                    IdTipoDocumento: this.state.form.IdTipoDocumento,
                    NroTipoDocumento: this.state.form.NroTipoDocumento,
                    RazonSocial: this.state.form.RazonSocial,
                    NombreComercial: this.state.form.NombreComercial,
                    ApellidoPaterno: this.state.form.ApellidoPaterno,
                    ApellidoMaterno: this.state.form.ApellidoMaterno,
                    PrimerNombre: this.state.form.PrimerNombre,
                    SegundoNombre: this.state.form.SegundoNombre,
                    Ubigeo: this.state.form.Ubigeo,
                    Direccion: this.state.form.Direccion,
                    Telefono: this.state.form.Telefono,
                    Celular: this.state.form.Celular,
                    CorreoElectronico: this.state.form.CorreoElectronico,
                    PersonaContacto: this.state.form.PersonaContacto,
                    BuenContribuyente: this.state.form.BuenContribuyente ? 'Si' : 'No',
                    AgentePercepcion: this.state.form.AgentePercepcion ? 'Si' : 'No',
                    AgenteRetencion: this.state.form.AgenteRetencion ? 'Si' : 'No',
                    Prico: this.state.form.Prico ? 'Si' : 'No',
                    DAOT: this.state.form.DAOT ? 'Si' : 'No',
                    CIIU: this.state.form.CIIU,
                    IdTipoMoneda: this.state.form.IdTipoMoneda,
                    LineaCredito: this.state.form.LineaCredito,
                    FormaPago: this.state.form.FormaPago,
                    EstadoSunat: this.state.form.EstadoSunat,
                    Situacion: this.state.form.Situacion,
                    IdEmpresa: 1,
                    Estado: this.state.form.Estado,
                    Zona: this.state.form.Zona,
                    Descuento: this.state.form.Descuento,

                })
            };
            // const response = await fetch(`/api/clientes/actualizar/${this.props.match.params.idcliente}`, config) ;
            await fetch(`/api/clientes/actualizar/${this.props.idcliente}`, config);
            this.setState({loading: false});
            console.log(config);

            /* LUEGO DE REALIZAR LA ACTULIZACION REGRESAMOS A LA PAGINA DONDE LISTA LOS CLIENTES */
            /* ================================================================================= */
            // this.props.history.push('/listaclientes');
            this.props.listaClientes();
            this.props.onClose();

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    handleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

            },
        });
    }

    handleChangeCheckBox = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.checked

            },
        });
    }

    handleChangeRegion = (e) => {
        const dataP = this.state.data.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprov: dataP,
            form: {
                ...this.state.form,
                Region: e.target.value,
                Provincia: null,
                Ubigeo: e.target.value,
            },
            datadist: [],
        });
        //console.log(this.state);
    };

    handleChangeProvincia = (e) => {
        const dataD = this.state.data.results.filter((item) => {
            return item.CodRegion === this.state.form.Region && item.CodProvincia === e.target.value && item.CodDistrito != "00"
        });
        this.setState({
            datadist: dataD,
            form: {
                ...this.state.form,
                Provincia: e.target.value,
                Distrito: null,
                Ubigeo: `${this.state.form.Region}${e.target.value}`,
            },

        });
    };

    handleChangeDistrito = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                Ubigeo: `${this.state.form.Region}${this.state.form.Provincia}${e.target.value}`,

            },
        });

    };


    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.state.form.NroTipoDocumento === "" || !this.state.form.NroTipoDocumento) {
            swal({
                title: 'Atención',
                text: `El cliente debe tener nro de documento`,
                icon: 'info'
            })
        } else {
            this.actualizarCliente();
        }
    };

    handleSubmitVolver = async (e) => {
        e.preventDefault();
        this.props.history.push('/listaclientes');
    };


    render() {

        /* SI HUBO ERROR AL CARGAR LA DATA MOSTRAMOS EL ERROR */
        /* ================================================== */
        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }

        /* MOSTRAMOS UN PRE-LOAD AL CARGAR LA DATA */
        /* ================================================== */
        if (this.state.loading === true && (this.state.data.results === undefined || this.state.data.results.length === 0)) {
            return <PageLoading/>;
        }

        // const listaregiones= this.state.data.results.filter((item)=>{
        //     return item.CodProvincia==="00"
        // });

        return (
            // <div className="container">
            // <div className="card">
            //     <div className="card-header bg-dark text-white text-center p-100">Modificar Cliente</div>
            //     <div className="card-body">
            <ClienteForm

                onClose={this.props.onClose}
                onChange={this.handleChange}
                onChangeCheckBox={this.handleChangeCheckBox}
                onChangeRegion={this.handleChangeRegion}
                onChangeProvincia={this.handleChangeProvincia}
                onChangeDistrito={this.handleChangeDistrito}
                onSubmit={this.handleSubmit}
                onSubmitVolver={this.handleSubmitVolver}
                formValues={this.state.form}
                error={this.state.error}
                datareg={this.state.datareg}
                dataprov={this.state.dataprov}
                datadist={this.state.datadist}
                dataMoneda={this.state.dataMoneda}
                accion="Actualizar Cliente"
                editarDescuento={this.state.editarDescuento}
            />
            //     </div>
            // </div>
            // </div>
        )
    }
}

export default ClientesEditar;
