import React from 'react';

function PuntoVentaForm(props){
    return(
            <form>
                <div className="form-group">
                    <label  for = "Nombre_PuntoVenta">Nombre / Alias</label>
                    <input  type="text" className="form-control" name="Nombre" 
                            onChange={props.handleChange} id="Nombre_PuntoVenta" placeholder=""
                            value={props.form.Nombre}/>
                </div>
                <div className="form-group">
                    <label  for = "MAC_PuntoVenta">MAC / Dirección Física</label>
                    <input  type="text" className="form-control" name="MAC" 
                            onChange={props.handleChange} id="MAC_PuntoVenta" placeholder=""
                            value={props.form.MAC}/>
                    {/* <button className="" onClick={props.onClickMAC}>MAC</button> */}
                </div>
                <div className="form-group">
                    <label for="Funcionalidad_PuntoVenta">Funcionalidad</label>
                    <select className="form-control" id="Funcionalidad_PuntoVenta" name="Funcion"
                            onChange={props.handleChange}
                            value={props.form.Funcion}>
                        <option value="Punto Venta">Punto Venta</option>
                        <option value="Caja">Caja</option>
                        <option value="Mixto">Mixto</option>
                    </select>
                </div>
                <div className="form-group">
                    <label for="Estado_PuntoVenta">Estado</label>
                    <select className="form-control" id="Estado_PuntoVenta" name="Estado"
                            onChange={props.handleChange}
                            value={props.form.Estado}>
                        <option value="Activo">Activo</option>
                        <option value="Inactivo">Inactivo</option>
                    </select>
                </div>
                <div className="form.row" align="center">
                    <button type="submit" onClick={props.onClose} className="btn btn-secondary mr-2" style={{backgroundColor: "#dc3545", border: "none"}}>Cancelar</button>
                    <button type="submit" onClick={props.handleSubmit} className="btn btn-primary" style={{backgroundColor: "#01A59C", border: "none"}}>{props.accion}</button>
                </div>
            </form>
    )
}
export default PuntoVentaForm