import React, { useState, useEffect } from 'react';
import SelectSearch from "react-select-search";
import "../pages/styles/SelectSearch.css";
import useEmpresas from '../helpers/useEmpresas';

function SelectEmpresa({ Empresa, setEmpresa }) {

    const empresas = useEmpresas()
    const [empresasOptions, setEmpresasOptions] = useState(null);

    useEffect(()=>{
        if (!empresas) return
        const empresasList = empresas.map( empresa => {
            return {
                name: `${empresa.RazonSocial} - ${empresa.Ruc}`,
                value: empresa.ID
            }
        })
        setEmpresasOptions(empresasList)
    }, empresas)

    const handleSelectEmpresa = event => {
        const empresa = empresas.find(empresa => (empresa.ID===event.value));
        setEmpresa(empresa);
    }

    if (empresasOptions)
        return  (
            <section className=''>
            <div className='container pt-5'>
            <SelectSearch
                    value={Empresa && Empresa.ID}
                    onChange={handleSelectEmpresa}
                    options={empresasOptions}
                    placeholder="Seleccionar Empresa"
                    autofocus={!Empresa}
                />
            </div>
            </section>
        );

    return null;
}

export default SelectEmpresa;