import React from 'react';
import '../styles/tabla.css';
import JsBarcode from 'jsbarcode';
import QRCode from "qrcode";
import {Style, Section} from "../../pages/RegistroVentas/ClasesImpresion";
import swal from "sweetalert";
import PageLoading from '../PageLoading.js';
import jsPDF from "jspdf";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import ReactExport from "react-data-export";
import "react-moment";
import moment from "moment";
import {
    BotonPDFpequeno,
    BotonExcel,
    BotonAdd,
    BotonPDF,
    BotonDelete
} from "../Plantillas/Botones";
import Notify from '../Notify';
import {getLoadImage, loadLogoByRUC} from '../../helpers/funcionesGlobales';
import {convertStrToJson, doDownload, GUIA_REMISION} from "../../Global";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Empresas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {
                GuiasRemision: []
            },
            tamañoOpciones: 250,
            dataGuia: {
                GuiaRemision: []
            },
            NombresUbig: [],
            NombresUbigSalida: [],
            NombresUbigLlegada: [],
            IdSucursal: '',
            dataSucursal: [],
            Ruc: '',
            RazonSocial: '',
            Sucursal: '',
            Direccion: '',
            IdEmpresa: '',
            loading: true,
            error: null,
            statusModal: false,
            extImg: 'png',
            isLoading: false
        }
        this.obtenerGuiasRemision = this.obtenerGuiasRemision.bind(this)
        this.handleSavePDF = this.handleSavePDF.bind(this)
        this.getInfoSucursal = this.getInfoSucursal.bind(this)
        this.sendGuiaSunat = this.sendGuiaSunat.bind(this)
        this.sendGuiaSunatVerifica = this.sendGuiaSunatVerifica.bind(this)
        this.sendGuiaDarBaja = this.sendGuiaDarBaja.bind(this)
    }

    componentDidMount() {
        this.getInfoSucursal()
        this.obtenerGuiasRemision()
    }

    componentWillUnmount() {
    }

    obtenerGuiasRemision = async () => {
        try {
            const response = await fetch('/api/guiaRemision/List');
            const data = await response.json();
            let cdr = false
            let ticket = false
            let xml = false
            data.GuiasRemision.forEach(x => {
                try {
                    if (x.Cdr) {
                        cdr = true
                    }
                } catch (e) {
                    console.log({e})
                    console.log("error:" + e)
                }
                try {
                    if (x.SunatTicket) {
                        ticket = true
                    }
                } catch (e) {
                    console.log({e})
                    console.log("error:" + e)
                }
                try {
                    let resEnvioOp = convertStrToJson(x.resOperador);
                    let xmlBool = resEnvioOp.xmlFirmado ? resEnvioOp.xmlFirmado : null;
                    if (xmlBool) {
                        xml = true
                    }
                } catch (e) {
                    console.log({e})
                    console.log("error:" + e)
                }
            });
            let adicional = 0;
            adicional = cdr ? adicional + 150 : adicional;
            adicional = xml ? adicional + 50 : adicional;
            adicional = ticket ? adicional + 50 : adicional;

            this.setState({
                loading: true,
                data: data,
                tamañoOpciones: this.state.tamañoOpciones + adicional
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    //Cambiar acá si es necesario mostrar más datos en el pdf
    handleSavePDF(e) {
        e.preventDefault();
        let doc = new jsPDF({
            orientation: "landscape"
        });

        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(12);
        doc.text("RELACION DE GUIAS DE REMISION", 110, 10);
        // console.log(this.state.data.GuiasRemision)
        doc.autoTable({
            body: this.state.data.GuiasRemision,
            columns: [
                {header: 'Guia', dataKey: 'SerieNumeroComprobante'},
                {header: 'Motivo De Traslado', dataKey: 'MotivoDeTraslado'},
                {header: 'Fecha de Inicio de Traslado', dataKey: 'FechaInicioTraslado'},
                {header: 'Ubigeo Salida', dataKey: 'UbigeoSalida'},
                {header: 'Ubigeo Llegada', dataKey: 'UbigeoLlegada'},
                {header: 'Direccion Llegada', dataKey: 'DireccionLlegada'},
                {header: 'Peso(Kg)', dataKey: 'Peso'}
            ],
            startY: 20,
            margin: 5,
            styles: {
                overflow: "linebreak",
                lineWidth: 0.1,
                tableWidth: "auto",
                columnWidth: "auto",
                halign: "center"
            },
            theme: "plain"
        }).setFontSize(11);
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("relación_GuiasRemision.pdf");
    }

    getDataExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: `RELACION DE GUIAS DE REMISIÓN `,
                        width: {wpx: 80}
                    }
                ],
                data: []
            },
            {
                columns: [
                    {
                        title: `GUIA`,
                        width: {wpx: 150}
                    },
                    {
                        title: `MOTIVO DE TRASLADO`,
                        width: {wpx: 150}
                    },
                    {
                        title: `FECHA DE INICIO DE TRASLADO `,
                        width: {wpx: 200}
                    },
                    {
                        title: `UBIGEO DE SALIDA`,
                        width: {wpx: 150}
                    },
                    {
                        title: `UBIGEO DE LLEGADA `,
                        width: {wpx: 80}
                    },
                    {
                        title: `DIRECCION DE LLEGADA  `,
                        width: {wpx: 150}
                    },
                    {
                        title: `PESO(Kg)`,
                        width: {wpx: 150}
                    }
                ],
                data: dataSet.map(row => {
                    return [
                        {
                            value: row.SerieNumeroComprobante,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.MotivoDeTraslado,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.FechaInicioTraslado,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.UbigeoSalida,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.UbigeoLlegada,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.DireccionLlegada,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Peso,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ];
    }

    handleSavePDFitem = async (IdGuiaRemision) => {
        Notify("Creando Documento, por favor espere", "success", 1500);
        // debugger
        //Traer Guia de Remision por Id y Traer Detalle de la guia de remision por Id
        let x = 7
        let y = 32

        await this.obtenerGuiaRemisionPorId(IdGuiaRemision)
        let logoP = await loadLogoByRUC(this.state.dataSucursal[0].Ruc, "logo", this.state.extImg);
        let logo = await logoP;

        const guia = this.state.dataGuia.guia;
        const nombreArchivo = guia.SerieGR + "-" + guia.NumeroComprobanteGR;
        let seNum = nombreArchivo.slice(1, -8)

        // JsBarcode("#barcode", `${seNum + guia.NumeroComprobanteGR}`, {
        //     format: "itf",
        // });
        // const codBar = document.querySelector('img#barcode');
        // console.log(codBar)
        // Inicia el documento
        let doc = new jsPDF({});

        doc.addImage(logo, "JPEG", 5, 5, 60, 15);
        // doc.addImage(codBar.src, "JPEG", 78, 5, 40, 11);

        const pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        const pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        // doc.rect(140, 4, 56, 30)

        doc.setFontSize(13);
        doc.setFontType("normal");
        doc.text(`    R.U.C. ${this.state.dataSucursal[0].Ruc}`, 141, 12);

        doc.setFontSize(13);
        doc.setFontType("bold");
        doc.text("    GUÍA DE REMISION", 141, 17);
        doc.text("   REMITENTE", 151, 22);

        doc.setFontType("bold");
        doc.setFontSize(14);
        doc.text(
            `     ${guia.SerieGR.toUpperCase() + " - Nº " + guia.NumeroComprobanteGR}`,
            141,
            27
        );

        doc.setFontSize(7);
        doc.setFontType("normal");
        let EmpresaTittleStyle = new Style(7, "", 1.5);
        let EmpresaTittleSection = new Section(
            doc,
            x,
            23,
            EmpresaTittleStyle,
            130
        );
        EmpresaTittleSection.write(`${this.state.dataSucursal[0].Nombre}: ${this.state.dataSucursal[0].Direccion} - ${this.state.NombresUbig[0].NombreRegion.toUpperCase()} - ${this.state.NombresUbig[0].Provincia.toUpperCase()} - ${this.state.NombresUbig[0].Distrito.toUpperCase()}`, EmpresaTittleStyle);
        y = y + 3
        //Empiezo a manejar el style y section
        EmpresaTittleStyle = new Style(8, "normal", 1.5);
        EmpresaTittleSection = new Section(
            doc,
            x,
            y,
            EmpresaTittleStyle,
            0
        );
        // doc.rect(x-1, y-3, 41, 9)
        // doc.rect(x+45 , y-3, 55, 9)
        EmpresaTittleSection.write("FECHA DE                                               FECHA DE INICIO ", EmpresaTittleStyle);
        EmpresaTittleSection.write("EMISION                                                  DE TRASLADO", EmpresaTittleStyle);

        EmpresaTittleStyle = new Style(11, "", 1.5);
        EmpresaTittleSection = new Section(
            doc,
            x + 17,
            y + 3,
            EmpresaTittleStyle,
            0
        );
        EmpresaTittleSection.write(`${moment(guia.FechaEmision).format("DD/MM/YYYY")}                                        ${moment(guia.FechaInicioTraslado).format("DD/MM/YYYY")}`, EmpresaTittleStyle);
        //eMPIEZAN LOS DATOS DOMICILIO PARTIDA LLEGADA DESTINATARIO Y UNIDAD DE TRANSPORTE
        x = x - 1
        y = y + 9
        //Cuadrados con una linea separadora -> domicilio de partida, llegada
        doc.rect(x, y, 93, 27)
        doc.line(x, y + 6, x + 93, y + 6)
        doc.rect(x + 99, y, 93, 27)
        doc.line(x + 99, y + 6, x + 99 + 93, y + 6)
        y = y - 3
        let DomicilioStyle = new Style(11, "bold", 1.5);
        let DomicilioSection = new Section(
            doc,
            x + 20,
            y + 4,
            DomicilioStyle,
            0
        );
        DomicilioSection.write("   DOMICILIO DE PARTIDA                                                    DOMICILIO DE LLEGADA", DomicilioStyle)
        DomicilioStyle = new Style(9, "", 1.5);
        DomicilioSection = new Section(
            doc,
            x + 2,
            y + 10,
            DomicilioStyle,
            80
        );

        const TIPO_ORDEN_COMPRA = 'Orden de compra';

        if (guia.tipo === TIPO_ORDEN_COMPRA) {
            DomicilioSection.write(`DIRECCION: ${guia.DireccionLlegada.toUpperCase()}`, DomicilioStyle)
            DomicilioSection.write(`DISTRITO: ${this.state.NombresUbigLlegada[0].Distrito.toUpperCase()} PROV.: ${this.state.NombresUbigLlegada[0].Provincia.toUpperCase()} DEP.: ${this.state.NombresUbigLlegada[0].NombreRegion.toUpperCase()}`, DomicilioStyle)
        } else {
            DomicilioSection.write(`DIRECCION: ${guia.DireccionSalida}`, DomicilioStyle)
            DomicilioSection.write(`DISTRITO: ${this.state.NombresUbigSalida[0].Distrito.toUpperCase()} PROV.: ${this.state.NombresUbigSalida[0].Provincia.toUpperCase()} DEP.: ${this.state.NombresUbigSalida[0].NombreRegion.toUpperCase()}`, DomicilioStyle)
        }

        DomicilioStyle = new Style(9, "", 1.5);
        DomicilioSection = new Section(
            doc,
            x + 101,
            y + 10,
            DomicilioStyle,
            80
        );

        if (guia.tipo === TIPO_ORDEN_COMPRA) {
            DomicilioSection.write(`DIRECCION: ${guia.DireccionSalida}`, DomicilioStyle)
            DomicilioSection.write(`DISTRITO: ${this.state.NombresUbigSalida[0].Distrito.toUpperCase()} PROV.: ${this.state.NombresUbigSalida[0].Provincia.toUpperCase()} DEP.: ${this.state.NombresUbigSalida[0].NombreRegion.toUpperCase()}`, DomicilioStyle)
        } else {
            DomicilioSection.write(`DIRECCION: ${guia.DireccionLlegada.toUpperCase()}`, DomicilioStyle)
            DomicilioSection.write(`DISTRITO: ${this.state.NombresUbigLlegada[0].Distrito.toUpperCase()} PROV.: ${this.state.NombresUbigLlegada[0].Provincia.toUpperCase()} DEP.: ${this.state.NombresUbigLlegada[0].NombreRegion.toUpperCase()}`, DomicilioStyle)
        }

        y = y + 33
        //Cuadrados con una linea separadora -> domicilio de partida, llegada
        doc.rect(x, y, 93, 30)
        doc.line(x, y + 6, x + 93, y + 6)
        doc.rect(x + 99, y, 93, 30)
        doc.line(x + 99, y + 6, x + 99 + 93, y + 6)
        y = y - 3
        let destinatarioTransporteStyle = new Style(11, "bold", 1.5);
        let destinatarioTransporteSection = new Section(
            doc,
            x + 20,
            y + 4,
            destinatarioTransporteStyle,
            0
        );
        destinatarioTransporteSection.write("            DESTINATARIO                                              UNIDAD DE TRANSPORTE Y CONDUCTOR", destinatarioTransporteStyle)

        destinatarioTransporteStyle = new Style(9, "", 1.5);
        destinatarioTransporteSection = new Section(
            doc,
            x + 2,
            y + 10,
            destinatarioTransporteStyle,
            90
        );
        destinatarioTransporteSection.write(`APELLIDOS Y NOMBRES O RAZON SOCIAL: `, destinatarioTransporteStyle)
        destinatarioTransporteSection.write(` ${guia.Cliente.toUpperCase()}`, destinatarioTransporteStyle)
        destinatarioTransporteSection.write(`RUC: ${guia.NroTipoDocumento.length == 8 ? "-" : guia.NroTipoDocumento} `, destinatarioTransporteStyle)
        destinatarioTransporteSection.write(`TIPO DOCUMENTO IDENTIDAD:  ${guia.TipoDocumentoCliente.toUpperCase()}    Nº ${guia.NroTipoDocumento.toUpperCase()}`, destinatarioTransporteStyle)

        destinatarioTransporteStyle = new Style(9, "", 1.5);
        destinatarioTransporteSection = new Section(
            doc,
            x + 101,
            y + 10,
            destinatarioTransporteStyle,
            80
        );

        destinatarioTransporteSection.write(`VEHICULO MARCA Y PLACA Nº: ${guia.Vehiculo ? guia.Vehiculo.toUpperCase() : "-"} `, destinatarioTransporteStyle)
        destinatarioTransporteSection.write(`CERTIFICADO DE INSCRIPCION: ${guia.certificadoInscripcion ? guia.certificadoInscripcion.toUpperCase() : "-"}`, destinatarioTransporteStyle)
        destinatarioTransporteSection.write(`LICENCIA CONDUCTOR 1 Nº ${guia.NumeroLicencia1 ? guia.NumeroLicencia1.toUpperCase() : "-"}`, destinatarioTransporteStyle)
        destinatarioTransporteSection.write(`LICENCIA CONDUCTOR 2 Nº ${guia.NumeroLicencia2 ? guia.NumeroLicencia2.toUpperCase() : "-"}`, destinatarioTransporteStyle)

        y = y + 37
        // doc.rect(x, y, 192, 127)
        // doc.line(x, y+8, x+192,y+8)
        // doc.line(x, y+115, x+192,y+115)

        let TransporteStyle = new Style(9, "bold", 1.5);
        let TransporteSection = new Section(
            doc,
            x + 2,
            y + 5,
            TransporteStyle,
            0
        );

        // TransporteSection.write(`      CODIGO                                            DESCRIPCION                                                   CANTIDAD            UNID. DE MED.        PESO`, TransporteStyle)
        //AQUI VAN LOS PRODUCTOS
        const startYTabla = Math.max(TransporteSection.y, TransporteSection.y);
        doc.setFontSize(8);
        doc.autoTable({
            body: this.state.dataGuia.dets,
            bodyStyles: {fontSize: 7},
            headStyles: {fontSize: 8},
            startY: y,
            margin: {
                right: 12,
                left: 6,
                bottom: 10
            },
            columnStyles: {
                NombreProducto: {columnWidth: 60},
                PrecioVenta: {halign: 'right'},
                Importe: {halign: 'right'},
            },
            styles: {
                overflow: "linebreak",
                lineWidth: 0.1,
                tableWidth: "auto",
                columnWidth: "auto",
                halign: "center"
            },
            PageBreak: "avoid",
            columns: [
                {header: "ITEM", dataKey: "Indice"},
                {header: "DESCRIPCIÓN", dataKey: "NombreProducto"},
                {header: "CANTIDAD", dataKey: "Cantidad"},
                {header: "MEDIDA", dataKey: "Unidad"},
                {header: "PESO", dataKey: "Peso"},
            ],
            theme: "plain"
        });

        //DESDE ACA EMPIEZA A CORRER EN BASE AL TAMAÑO DE LA TABLA

        let finalTable = doc.autoTable.previous.finalY;
        let PiePagStyle = new Style(9, "bold", 1.5);
        let PiePagSection = new Section(
            doc,
            134,
            finalTable + 4,
            PiePagStyle,
            60
        );
        PiePagSection.write(`TOTAL ITEMS: ${this.state.dataGuia.dets.length}     PESO: ${guia.PesoTotal} Kg`, PiePagStyle)
        if (pageHeight - finalTable < 54) { //50 es el tamaño del pie de pagina
            // console.log(pageHeight, " - ",finalTable)
            doc.addPage();
        } else {
            // console.log("menor")
        }
        //Aqui la parte del final

        const facturadorFileUrl = await ((async () => {
            const req = await fetch('/api/guiaRemision/facturador-file-url', {
                method: 'POST',
                body: JSON.stringify({
                    tipoDoc: `${GUIA_REMISION}`.padStart(2, '0'),
                    serie: guia.SerieGR,
                    correlativo: +guia.NumeroComprobanteGR
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (!req.ok) {
                return null;
            }

            const data = await req.json();

            if (!('url' in data)) {
                return null;
            }

            return data['url'];
        })())

        if (!!facturadorFileUrl) {
            const qr = await QRCode.toDataURL(facturadorFileUrl);
            const imgQR = await getLoadImage(qr);
            const imgQRmmHeight = imgQR.height / 3.779528 + 13;
            doc.addImage(imgQR, "PNG", 6, pageHeight - 32 - imgQRmmHeight, 35, 35);
        }

        y = finalTable + 2
        doc.rect(x, pageHeight - 56, 60, 25)
        doc.line(x, pageHeight - 50, x + 60, pageHeight - 50)
        doc.rect(x + 63, pageHeight - 56, 80, 25)
        doc.line(x + 63, pageHeight - 50, x + 63 + 80, pageHeight - 50)
        doc.rect(x + 63 + 83, pageHeight - 56, 46, 48)

        PiePagStyle = new Style(9, "bold", 1.5);
        PiePagSection = new Section(
            doc,
            x + 2,
            pageHeight - 53,
            PiePagStyle,
            0
        );
        PiePagSection.write(`                 TRANSPORTISTA                                                   MOTIVO DE TRASLADO                                           RECIBI CONFORME`, PiePagStyle)

        PiePagStyle = new Style(7, "", 1.5);
        PiePagSection = new Section(
            doc,
            x + 2,
            pageHeight - 47,
            PiePagStyle,
            60
        );
        PiePagSection.write(`NOMBRE: ${guia.EmpresaTransportista || ""}`.toUpperCase(), PiePagStyle)

        PiePagSection = new Section(
            doc,
            x + 2,
            pageHeight - 42,
            PiePagStyle,
            70
        );

        PiePagSection.write(`RUC: ${guia.NroDocEmpresaTransportista || ""}`, PiePagStyle)
        PiePagStyle = new Style(8, "", 1.5);
        PiePagSection = new Section(
            doc,
            x + 65,
            pageHeight - 47,
            PiePagStyle,
            60
        );
        PiePagSection.write(`${guia.IdMotivoDeTraslado} - ${guia.NombreMotivoTraslado}`, PiePagStyle)
        PiePagSection.write(`${guia.DescripcionMotivoTraslado}`, PiePagStyle)

        doc.line(x + 5, pageHeight - 36, x + 55, pageHeight - 36)
        PiePagSection = new Section(
            doc,
            x + 2,
            pageHeight - 34,
            PiePagStyle,
            60
        );
        PiePagSection.write(`                                 FIRMA`, PiePagStyle)
        //Comprobante de pago
        doc.rect(x, pageHeight - 28, 60, 20)
        doc.line(x, pageHeight - 22, x + 60, pageHeight - 22)
        doc.rect(x + 63, pageHeight - 28, 80, 20)
        doc.line(x + 63, pageHeight - 22, x + 63 + 80, pageHeight - 22)
        // y = y+34
        PiePagStyle = new Style(9, "bold", 1.5);
        PiePagSection = new Section(
            doc,
            x + 2,
            pageHeight - 25,
            PiePagStyle,
            0
        );

        PiePagSection.write(`                 DOC. REFERENCIA                                                          COMENTARIOS `, PiePagStyle)

        PiePagStyle = new Style(8, "", 1.5);
        PiePagSection = new Section(
            doc,
            x + 2,
            pageHeight - 19,
            PiePagStyle,
            0
        );

        PiePagSection.write(`TIPO: ${guia.tipo}`, PiePagStyle)

        PiePagSection.write(`Nº: ${guia.numYCorrelativo}`, PiePagStyle)

        //Motivos de Traslado contenido

        //RECIBI CONFORME
        doc.line(x + 150, pageHeight - 13, x + 189, pageHeight - 13)
        PiePagSection.write(`                                                                                                                                                                                                                       FIRMA, SELLO Y FECHA `, PiePagStyle)
        PiePagSection = new Section(
            doc,
            x + 65,
            pageHeight - 18,
            PiePagStyle,
            60
        );
        // console.log(guia)
        PiePagSection.write(`${guia.ComentarioAdicional}`, PiePagStyle)


        // doc.autoP brint();
        // window.open(doc.output("bloburl"), "_blank");
        
        const blob = doc.output('blob');

        const formData = new FormData();
        formData.append('pdf', blob);
        formData.append('tipoDoc', `${GUIA_REMISION}`.padStart(2, '0'));
        formData.append('serie', guia.SerieGR);
        formData.append('correlativo', +guia.NumeroComprobanteGR);

        await fetch('/api/guiaRemision/subir-pdf', {
            method: 'POST',
            body: formData
        })

        doc.save(nombreArchivo + ".pdf");

    }

    obtenerGuiaRemisionPorId = async (IdGuiaRemision) => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch(`/api/guiaRemision/guiaPorId/${IdGuiaRemision}`);
            const data = await response.json();
            this.setState({
                loading: false,
                dataGuia: data,
            })
            await this.obtenerNombreUbigeoSalida(data.guia.IdUbigeoSalida);
            await this.obtenerNombreUbigeoLlegada(data.guia.IdUbigeoLlegada)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getInfoSucursal = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/guiaRemision/InfoEmpresa`);
            const data = await response.json();
            this.setState({
                loading: false,
                IdSucursal: data[0].IdSucursal,
                dataSucursal: data,
                Ruc: data[0].Ruc,
                RazonSocial: data[0].RazonSocial,
                Sucursal: data[0].Nombre,
                Direccion: data[0].Direccion,
                IdEmpresa: data[0].IdEmpresa,
                extImg: data[1]
            });
            await this.obtenerNombreUbigeo(data[0].IdUbigeo);
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    obtenerNombreUbigeo = async (IdUbigeo) => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/ubigeos/InfoUbigeoEmpresa/${IdUbigeo}`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                NombresUbig: data
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    obtenerNombreUbigeoSalida = async (IdUbigeo) => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/ubigeos/InfoUbigeoEmpresa/${IdUbigeo}`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                NombresUbigSalida: data
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    obtenerNombreUbigeoLlegada = async (IdUbigeo) => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/ubigeos/InfoUbigeoEmpresa/${IdUbigeo}`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                NombresUbigLlegada: data
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    deleteGuia(IdGuiaRemision) {
        swal({
            title: "¿Está seguro?",
            text: "¿Borrar Guía de Remisión?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            closeOnClickOutside: false
        }).then(async (isConfirm) => {
            if (isConfirm)
                await this.EliminarGuia(IdGuiaRemision)
        });
    }

    async EliminarGuia(IdGuiaRemision) {
        // console.log(IdGuiaRemision)
        try {
            await fetch(`/api/guiaRemision/delete/${IdGuiaRemision}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            await this.obtenerGuiasRemision()
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    verificarSerie = async () => {
        //Valido
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/guiaRemision/verificarNumSerie`);
            const data = await response.json();
            // console.log(data.ExisteGR)
            if (data.ExisteGR == 0)
                return Notify("Configurar 'Guia de Remisión' para esta caja en Puntos de Venta.", "warning", 2000);
            else
                this.props.history.push('/GuiaRemision')
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
        //Redirijo a add guia remision
        // this.props.history.push('/GuiaRemision')
    }

    async sendGuiaSunat(guia) {
        const willDelete = await swal({
            title: "Enviar a operador",
            text: "¿Está seguro de realizar el envío?",
            icon: "warning",
            buttons: true,
            closeOnClickOutside: false
        });
        if (willDelete) {
            this.setState({isLoading: true})
            let res = await fetch(`/api/guiaRemision/send-guia?idGuia=${guia.IdGuiaRemision}`)
            if (res.ok) {
                let data = await res.json();
                let a = data.ok ? notificarMsg("Se ha enviado con éxito la guía de remisión.") :
                    notificarError("No se puedo enviar la guía de remisión");
                await this.obtenerGuiasRemision();
            } else
                notificarError("No se pudo enviar la guía de remisión. Ah ocurrido un error.")
            this.setState({isLoading: false})
        }
    }

    async sendGuiaSunatVerifica(guia) {
        const {SunatTicket, SerieNumeroComprobante} = guia
        this.setState({isLoading: true})
        const arraySerieCorrelativo = SerieNumeroComprobante.split("-")
        const serieCorrelativo = arraySerieCorrelativo[0] + "-" + parseInt(arraySerieCorrelativo[1])
        let res = await fetch(`/api/guiaRemision/check-guia/${SunatTicket}/${serieCorrelativo}?idGuia=${guia.IdGuiaRemision}`)
        if (res.ok) {
            notificarMsg("Se ha verificado con éxito la guía de remisión.")
            await this.obtenerGuiasRemision();
        } else
            notificarError("Hubo un error al verificar la guia, , asegurese que el envio es correcto")
        this.setState({isLoading: false})
    }

    async sendGuiaDarBaja(guia) {
        const {IdGuiaRemision, SerieNumeroComprobante} = guia
        const arraySerieCorrelativo = SerieNumeroComprobante.split("-")
        const serie = arraySerieCorrelativo[0]
        const correlativo = parseInt(arraySerieCorrelativo[1])

        let res = await fetch(`/api/guiaRemision/dar-baja-guia/${serie}/${correlativo}?idGuia=${IdGuiaRemision}`)
        if (res.ok) {
            notificarMsg("Se ha dado de baja con éxito la guía de remisión.")
            await this.obtenerGuiasRemision();
        } else
            notificarError("Hubo un error al dar la baja de la guia, , asegurese que el envio es correcto")
        this.setState({isLoading: false})
    }

    render() {
        if (this.state.loading === false && this.state.dataGuia.length === 0)
            return <PageLoading/>;

        const dataExport = this.getDataExportExcel(this.state.data.GuiasRemision)
        return (
            <React.Fragment>
                {/* <img hidden id='barcode'/> */}
                <section className='ventas-fpay-section-vh' style={{height: '100vh'}}>
                    <div id="productosContainer">
                        <div className="container pt-5">
                            <div className="row mb-3">
                                <div className="container mb-3 seccion-btn-verde-mfp">
                                    <button
                                        // to= "/GuiaRemision"
                                        onClick={this.verificarSerie}
                                        title="Nueva Guía de Remisión"
                                        className="btn btn-outline-primary btn-verde-mfp mr-3"
                                    >
                                        <span>Nueva Guía</span>
                                    </button>
                                    <BotonPDF
                                        title="Guardar PDF"
                                        onClick={this.handleSavePDF}
                                        className="mr-3"
                                    />
                                    <ExcelFile
                                        element={
                                            <BotonExcel type="button" title="Guardar Excel"/>
                                        }
                                        filename={`relación_Guias`}
                                    >
                                        <ExcelSheet
                                            dataSet={dataExport}
                                            name="GuiasFile"
                                        >
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>

                                <div className='container'>
                                    <ReactTable
                                        data={this.state.data.GuiasRemision}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        columns={[
                                            {
                                                columns: [
                                                    {
                                                        Header: "Acciones",
                                                        accessor: "Editar",
                                                        maxWidth: 450,
                                                        minWidth: 150,
                                                        width: this.state.tamañoOpciones,
                                                        wrap: true,
                                                        filterable: false,
                                                        Cell: (f) => {
                                                            let resEnvioOp = convertStrToJson(f.original.resOperador);
                                                            let xml = resEnvioOp.xmlFirmado ? resEnvioOp.xmlFirmado : null;
                                                            let cdrZip = resEnvioOp.cdrZip ? resEnvioOp.cdrZip : null;

                                                            return (
                                                                <React.Fragment>
                                                                    <div align="center">
                                                                        {/*
                                                                        <BotonPDFpequeno
                                                                            title="Guardar PDF"
                                                                            onClick={() => this.handleSavePDFitem(f.original.IdGuiaRemision)}
                                                                            className="mr-2"
                                                                        />
                                                                        <BotonDelete
                                                                            title="Eliminar Guia de Remisión"
                                                                            onClick={() => this.deleteGuia(f.original.IdGuiaRemision)}
                                                                            className="mr-2"

                                                                        />
                                                                        */}
                                                                        <button
                                                                            title="Guardar PDF"
                                                                            className="btn btn-danger btn-sm ml-1"
                                                                            onClick={() => this.handleSavePDFitem(f.original.IdGuiaRemision)}
                                                                        >
                                                                            PDF
                                                                        </button>

                                                                        <button
                                                                            title="Eliminar Guia de Remisión"
                                                                            onClick={() => this.deleteGuia(f.original.IdGuiaRemision)}
                                                                            disabled={resEnvioOp.ok || this.state.isLoading}
                                                                            className="btn btn-danger btn-sm ml-1"
                                                                        >
                                                                            ELIMINAR
                                                                        </button>

                                                                        <button
                                                                            className="btn btn-primary btn-sm ml-1"
                                                                            title="Enviar a Operador"
                                                                            disabled={resEnvioOp.ok || this.state.isLoading}
                                                                            onClick={() => this.sendGuiaSunat(f.original)}
                                                                        >
                                                                            ENVIAR
                                                                        </button>
                                                                        {/*):null}*/}
                                                                        {f.original.SunatTicket ? (
                                                                            <button
                                                                                className="btn btn-info btn-sm ml-1"
                                                                                title="Verificar"
                                                                                disabled={f.original.Cdr ? true : false}
                                                                                onClick={() => this.sendGuiaSunatVerifica(f.original)}
                                                                            >
                                                                                VERIFICAR
                                                                            </button>
                                                                        ) : null}
                                                                        {f.original.Cdr ? (
                                                                            <a
                                                                                className="btn btn-info btn-sm  ml-1 "
                                                                                target="blank"
                                                                                href={f.original.Cdr}
                                                                                title={`Descargar CDR`}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    doDownload(f.original.Cdr, "CDR_GUIA")
                                                                                }}
                                                                            >
                                                                                CDR
                                                                            </a>
                                                                        ) : null}

                                                                        {xml ? (
                                                                            <a
                                                                                className="btn btn-success btn-sm ml-1 "
                                                                                target="blank"
                                                                                href={xml}
                                                                                title={`Descargar XML`}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    doDownload(xml, xml.substring(xml.lastIndexOf("/") + 1, xml.length))
                                                                                }}
                                                                            >
                                                                                XML
                                                                            </a>
                                                                        ) : null}

                                                                        {f.original.Cdr ? (
                                                                            <button
                                                                                className="btn btn-danger btn-sm ml-1"
                                                                                title="Dar de baja"
                                                                                onClick={() => this.sendGuiaDarBaja(f.original)}
                                                                            >
                                                                                ANULAR
                                                                            </button>
                                                                        ) : null}

                                                                        {/*{cdrZip ? (*/}
                                                                        {/*    <a*/}
                                                                        {/*        className="btn btn-outline-success fas fa-file-archive ml-1"*/}
                                                                        {/*        target="blank"*/}
                                                                        {/*        href={cdrZip}*/}
                                                                        {/*        title="Descargar CDR"*/}
                                                                        {/*    />*/}
                                                                        {/*) : null}*/}


                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    },
                                                    {
                                                        Header: "Guía",
                                                        id: "SerieNumeroComprobante",
                                                        width: 150,
                                                        accessor: d => d.SerieNumeroComprobante,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {keys: ["SerieNumeroComprobante"]}),
                                                        filterAll: true
                                                    },
                                                    {
                                                        Header: "Motivo",
                                                        id: "MotivoDeTraslado",
                                                        width: 200,
                                                        accessor: d => d.MotivoDeTraslado,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {keys: ["MotivoDeTraslado"]}),
                                                        filterAll: true
                                                    },
                                                    {
                                                        Header: "Inicio de traslado",
                                                        id: "FechaInicioTraslado",
                                                        width: 150,
                                                        accessor: d => d.FechaInicioTraslado,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {keys: ["FechaInicioTraslado"]}),
                                                        filterAll: true
                                                    },
                                                    {
                                                        Header: "Vehiculo",
                                                        id: "Vehiculo",
                                                        width: 100,
                                                        accessor: d => d.Vehiculo,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {keys: ["Vehiculo"]}),
                                                        filterAll: true
                                                    },
                                                    {
                                                        Header: "Transportista",
                                                        id: "Transportista",
                                                        width: 100,
                                                        accessor: d => d.Transportista,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {keys: ["Transportista"]}),
                                                        filterAll: true
                                                    },
                                                    {
                                                        Header: "Direccion de Llegada",
                                                        id: "DireccionLlegada",
                                                        width: 200,
                                                        accessor: d => d.DireccionLlegada,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {keys: ["DireccionLlegada"]}),
                                                        filterAll: true
                                                    }
                                                    ,
                                                ]
                                            },
                                        ]}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                        previousText='Anterior'
                                        nextText='Siguiente'
                                        loadingText='Cargando...'
                                        noDataText='No se encontraron registros'
                                        pageText='Página'
                                        ofText='de'
                                        rowsText='filas'
                                        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                                        SubComponent={row => {
                                            let resEnvioOp = convertStrToJson(row.original.resOperador);
                                            if (Object.keys(resEnvioOp).length) {
                                                if (resEnvioOp.ok)
                                                    return (
                                                        <div className="alert alert-success p-3" width="70%">
                                                            {`Descripción:  ${resEnvioOp.status ? resEnvioOp.status : ""}`}
                                                        </div>
                                                    )
                                                else
                                                    return (
                                                        <div className="alert alert-danger p-3" width="70%">
                                                            {resEnvioOp.error ? `Código: ${resEnvioOp.error.codigo} :::::: Descripción: ${resEnvioOp.error.mensaje} ` : ``}
                                                        </div>
                                                    )

                                            } else
                                                return null

                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

}

export default Empresas;
