import React, { useState, useEffect } from "react";
import moment from "moment";
import { Registrar } from "./Registrar";
import {notificarMsg} from "../Almacenes/AlmacenNotify";
import GestionarPedido from "./GestionarPedido";
import ReactTable from "react-table";
import Modal from "../Modal";

const CALENDAR_STYLE = {
  border: "0.5px solid #acacac",
  borderRadius: "25px",
  outline: "none",
  flexBasis: "50%",
  alignSelf: "center",
};

export default function () {
  const [desde, setDesde] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [hasta, setHasta] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [filtrarPor, setFiltrarPor] = useState("FechaRegistro");
  const [pedidos, setPedidos] = useState([]);
  const [currentPedidoId, setCurrentPedidoId] = useState(undefined);
  const [turnoCaja, setTurnoCaja] = useState(null);

  const [user, setUser] = useState(undefined);
  const [sucursales, setSucursales] = useState([]);
  const [sucursalInfo, setSucursalInfo] = useState({
    Nombre: "Sucursal del usuario en sesión"
  });
  const [currentSucursalId, setCurrentSucursalId] = useState(undefined);
  const [currentAlmacenId, setCurrentAlmacenId] = useState(undefined);
  const [canChangeSucursal, setCanChangeSucursal] = useState(false);

  const [aperturarCaja, setAperturarCaja] = useState(false);
  const [puedeRegistrar, setPuedeRegistrar] = useState(false);
  const [alertaApertura, setAlertaApertura] = useState(false);

  useEffect(() => {
    fetchUserData();
    getAperturarCaja();
    const url = new URL(window.location.href);
    const start = url.searchParams.get("start");
    const end = url.searchParams.get("end");

    if (start && end) {
      setDesde(start);
      setHasta(end);
    }
  }, [])

  useEffect(() => {
    if (canChangeSucursal) {
      fetchSucursales();
    }
  }, [canChangeSucursal]);

  useEffect(() => {
    if (!currentSucursalId) {
      return;
    }

    fetchAlmacen(currentAlmacenId);
  }, [currentSucursalId])

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set("start", desde);
    url.searchParams.set("end", hasta);
    window.history.pushState({}, "", url);

    getPedidos();
  }, [desde, hasta, filtrarPor, currentSucursalId]);

  const getPedidos = async () => {
    const response = await fetch(`/api/pedidos?by=${filtrarPor}&start=${desde}&end=${hasta}&sucursal=${currentSucursalId || ""}`);
    const data = await response.json();
    setPedidos(data);
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(`/api/configFactElectronica/User`);
      const data = await response.json();

      const {objuser: {sucursal, ...user}, extImg } = data;

      setUser({...user});
      setSucursalInfo(sucursal);
      setCurrentSucursalId(user.IdSucursal);
      fetchRoleValidation();
    } catch (e) {
      console.error(e)
    }
  }

  const fetchRoleValidation = async () => {
    const response = await fetch(`/api/configFactElectronica/ChangeSucursal`);
    setCanChangeSucursal(response.ok);
  }

  const fetchSucursales = async () => {
    const response = await fetch("/api/configFactElectronica/SucursalEmpresa");
    const sucursales = await response.json();
    setSucursales([...sucursales]);
  }

  const fetchAlmacen = async () => {
    const response = await fetch(`/api/almacenes/AlmacenBySucursal?idSucursal=${currentSucursalId}`);
    const {results, } = await response.json();
    const { IdAlmacen } = results[0];
    setCurrentAlmacenId(IdAlmacen);
  }

  const getTurno = async () => {
    try {
        const response = await fetch(`/api/cajas/verificar/Turno`);
        const dataTurno = await response.json();
        return  dataTurno
    } catch (e) {
        return { Turno: null}
    }
}

  const getAperturarCaja = async ()  => {
    let permiso = false;

    try {
        const response = await fetch(`/api/cajas/user/validar`);
        const data = await response.json();
        permiso = Boolean(data.ValidarUser)
    } catch (e) {
        console.error(e)
    }

    setAperturarCaja(permiso);

    if (!permiso) {
      setPuedeRegistrar(false);
      return;
    }

    const turnoInfo = await getTurno();

    if (!turnoInfo.Turno) {
        setPuedeRegistrar(false);
        notificarMsg("Se ha detectado que la caja se encuentra cerrada", "warning")
        return
    }

    const turno = turnoInfo.Turno[0][0];

    const fechaInicio = moment(turno.FechaInicio).add(-5, 'hours').format("YYYY-MM-DD")
    const now = moment().format("YYYY-MM-DD");

    setTurnoCaja(turno);
    setPuedeRegistrar((fechaInicio == now))
    setAlertaApertura(!(fechaInicio == now))
}

  return (
    <>
      <div className="ventas-fpay-section">
        <div className="caja-fondo-blanco-m-2 mt-3" style={{ width: '40%', marginLeft: '30%' }}>
          <div className="justify-content-center d-flex">
            <span className="align-self-center letra-fecha-fpay">Desde: </span>
            <input
              type="date"
              onChange={(e) => setDesde(e.target.value)}
              value={desde}
              className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
              style={CALENDAR_STYLE}
            />
            <span className="align-self-center letra-fecha-fpay">Hasta: </span>
            <input
              type="date"
              onChange={(e) => setHasta(e.target.value)}
              defaultValue={hasta}
              className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
              style={CALENDAR_STYLE}
            />
          </div>
          <div className="justify-content-center d-flex">
            <span className="letra-fecha-fpay">Filtrar por: </span>
            <select className="form-control w-50 ml-3" value={filtrarPor} onChange={e => setFiltrarPor(e.target.value)}>
              <option value="FechaRegistro">Fecha de registro</option>
              <option value="FechaEntrega">Fecha de entrega</option>
            </select>
          </div>
        </div>

        <div className="pt-3 container">
          <div className={`col-12 no-pad ${canChangeSucursal ? '' : 'caja-fondo-blanco-m-2'}`}>
              {canChangeSucursal ?
                <div className="caja-fondo-blanco-m-2">
                    <label className="sucursal-text-color" htmlFor="sucursales">Seleccione una sucursal</label>
                    <select value={currentSucursalId} name="sucursales" id="sucursales"
                            className="w-100 select-search-box__search"
                            onChange={e => setCurrentSucursalId(e.target.value)}>
                      {sucursales.map((s, i) => <option key={i} value={s.IdSucursal}>{s.Nombre}</option>)}
                    </select>
                </div> : <label className="sucursal-text-color container">{sucursalInfo.Nombre}</label>}
          </div>
        </div>

        <div className="container mt-5">
          <div className="d-flex justify-content-end seccion-btn-generar">
            <Registrar currentSucursalId={currentSucursalId} canRegistrar={puedeRegistrar} onPedidoRegistered={(e) => setCurrentPedidoId(e)} />
          </div>
          <div className="mt-3">
            <ReactTable
              data={pedidos}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  columns: [
                    {
                      Header: "Fecha de registro",
                      id: "FechaEmision",
                      headerClassName: "",
                      width: 125,
                      filterAll: true,
                      accessor: (d) => moment(d.FechaEmision).format("DD/MM/YYYY"),
                    },
                    {
                      Header: "Caja",
                      id: "Caja",
                      headerClassName: "",
                      width: 175,
                      filterAll: true,
                      accessor: (d) => d.NombreCaja,
                    },
                    {
                      Header: "Estado",
                      id: "Estado",
                      accessor: (d) => d.Estado,
                      headerClassName: "",
                      width: 150,
                      filterAll: true,
                      Cell: (row) => (
                        <span className={`badge ${row.original.Estado === 'COMPLETADO'? 'badge-success' : 'badge-danger' }`}>{ row.original.Estado }</span>
                      ),
                    },
                    {
                      Header: "Fecha de entrega",
                      id: "FechaEntrega",
                      headerClassName: "",
                      width: 200,
                      filterAll: true,
                      accessor: (d) => {
                        const fecha = moment(d.FechaEntrega);

                        let label = fecha.fromNow();

                        if (fecha.isSame(new Date(), "day")) {
                          label = "Hoy";
                        }

                        if (fecha.isSame(moment().subtract(1, "day"), "day")) {
                          label = "Ayer";
                        }

                        if (fecha.isSame(moment().add(1, "day"), "day")) {
                          label = "Mañana";
                        }

                        return `${fecha.format("DD/MM/YYYY")} (${label})`;
                      },
                    },
                    {
                      Header: "Cliente",
                      id: "Cliente",
                      headerClassName: "",
                      width: 340,
                      filterAll: true,
                      accessor: (d) => d.RazonSocial,
                    },
                    {
                      Header: "Acciones",
                      headerClassName: "",
                      width: 100,
                      filterAll: true,
                      Cell: (row) => {
                        if (!!turnoCaja && row.original.IdCaja === turnoCaja.IdCaja) {
                          return <div>
                            <button
                              className="btn btn-success btn-verde-mfp-letra-normal"
                              onClick={() => setCurrentPedidoId(row.original.IdPedido)}
                            >
                              Gestionar
                            </button>
                          </div>
                        }

                        return <></>
                      },
                    },
                  ],
                },
              ]}
              defaultPageSize={5}
              //pageSize={10}
              className="-striped -highlight"
              // Text de paginación
              previousText="Anterior"
              nextText="Siguiente"
              loadingText="Cargando..."
              noDataText="No se encontraron registros"
              pageText="Página"
              ofText="de"
              rowsText="filas"
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            />
          </div>
          { !!currentPedidoId && <GestionarPedido onChange={getPedidos} pedidoId={currentPedidoId} onClose={() => {
            getPedidos()
            setCurrentPedidoId(undefined)
          }}/>}
        </div>
      </div>

      <Modal
        isOpen={alertaApertura}
        onClose={() => setAlertaApertura(false)}
        title={"Aviso de apertura de caja"}
      >

        <div className="alert alert-warning mt-3 mx-3" role="alert">
          <h4 className="alert-heading">Aviso!</h4>
            <p>
              Queremos informarle que hemos detectado que la tarea de cierre de caja no ha sido
              completada.
            </p>
        </div>

        <div className="d-flex justify-content-center my-2">
          <a href="/AperturarTurnosMovcaja" className="btn btn-verde-mfp">
            Ir a caja
          </a>
        </div>
      </Modal>
    </>
  );
}
