import React, { useState, useMemo, useRef, useEffect} from 'react';
import {
    GetPrecioCosto,
  } from "../Preventas/PreciosPreventa";

export default function (props) {
    const escalaGanancia = useMemo(() => props.escalaGanancia, [props.escalaGanancia]);
    const items = useMemo(() => props.items, [props.items]);
    const [ganancia, setGanancia] = useState(0);
    const circle = useRef(null)

    useEffect(() => {
        const gananciaAcc = items.reduce((acc, item) => {
            const costo = GetPrecioCosto([], item);
            return acc + ((item.PrecioVenta - costo) / costo * 100);
        }, 0);

        setGanancia(gananciaAcc / items.length);
    }, [items]);

    useEffect(() => {
        circle.current.classList = '';
        if (ganancia < escalaGanancia.Amarillo) {
            circle.current.classList.add('bg-danger');
        } else if (ganancia < escalaGanancia.Verde) {
            circle.current.classList.add('bg-warning');
        } else {
            circle.current.classList.add('bg-success');
        }
    }, [ganancia])

    return <>
        <div ref={circle} style={{ width: '16px', height: '16px', borderRadius: '50%', display: 'inline-block', margin: '0 10px', marginTop: '3px' }}></div>
    </>
}
