import {cfetch} from "../helpers/wrappers";
import {convertObjToQueryParams} from "../helpers/utils";

export default class ProductoService {

    async filtrarProds(bodyParams, queryParams = {}) {
        const config = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            url: `/api/productos/filter?${convertObjToQueryParams(queryParams)}`,
            body: JSON.stringify(bodyParams),
            method: "POST"
        }
        return await cfetch(config)
    }


    async filtrarSucursalesProds(bodyParams) {
        const sucursalesReq= await fetch('/api/configFactElectronica/SucursalEmpresa');

        if (!sucursalesReq.ok) {
            return [];
        }

        const sucursales = await sucursalesReq.json();

        const tasks = sucursales.map(async sucursal => this.filtrarProds(bodyParams, {idSucursal: sucursal.IdSucursal}))

        const sucursalesProds = await Promise.all(tasks);

        const productos = {};

        let pages = 0;

        sucursalesProds.forEach((x, index) => {

            if (x.pages > pages) {
                pages = x.pages
            }

            x.productos.forEach(p => {
                if (!(p.nombre in productos)) {
                    productos[p.nombre] = {
                        nombre: p.nombre,
                        codigo: p.codigo,
                        CodigoBarra: p.CodigoBarra
                    }

                    sucursales.forEach(sucursal => {
                        productos[p.nombre][`Sucursal${sucursal.IdSucursal}`] = 0;
                    })
                }

                productos[p.nombre][`Sucursal${sucursales[index].IdSucursal}`] = p.sdcc
            })
        })

        return {
            productos: Object.values(productos),
            pages
        }
    }
}

