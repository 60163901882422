import React, { useState, useEffect, useRef } from 'react';
import MagellanListener from "../../../helpers/MagellanListener";
import { notificarMsg } from '../../Almacenes/AlmacenNotify';


export function MagellanStatus(props) {
    const [isRunning, setIsRunning] = useState(MagellanListener.sourceIsRunning());
    const [enabled, setEnabled] = useState(MagellanListener.scaleEnabled());
    const [status, setStatus] = useState(MagellanListener.currentStatus());
    const [statusBg, setStatusBg] = useState('bg-secondary')

    useEffect(() => {
        const interval = setInterval(() => {
            setIsRunning(MagellanListener.sourceIsRunning());
            setEnabled(MagellanListener.scaleEnabled());
            setStatus(MagellanListener.currentStatus());
        }, 500);

        if (isRunning) {
            notificarMsg('Balanza Magellan reconocida')
        }

        return () => clearInterval(interval);
    }, [isRunning]);

    useEffect(() => {
        const possibleStatus = {
            'NOT_READY': 'bg-danger',
            'TRANSIENT_WEIGHT': 'bg-warning',
            'TOO_HEAVY': 'bg-warning',
            'NON_ZERO': 'bg-warning',
            'ZERO': 'bg-success'
        }
        
        setStatusBg(possibleStatus[status] || 'bg-danger');
    }, [status])

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }

    const dotStyle = {
        width: '15px',
        height: '15px',
        backgroundColor: '#cccccc',
        borderRadius: '50%',
        margin: '5px 0'
    }

    if (!isRunning) {
        return null;
    }

    return <div style={containerStyle} alt="Magellan 8xxx">
        <div style={dotStyle} className='bg-success'></div>
        <div style={dotStyle} className={enabled ? 'bg-success' : 'bg-danger'}></div>
        <div style={dotStyle} className={statusBg}></div>
    </div>
}
