import React from "react";
import EmpresaPicker from "./EmpresaPicker";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import AsyncSucursalPicker from "../Sucursales/AsyncSucursalPicker";

export default class ImportProds extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            isLoading: false,
            msg: ""
        }
        this.onSendImport = this.onSendImport.bind(this)
    }

    onSendImport(e) {
        const payload = this.state.payload
        const data = new FormData()
        data.append('idEmpresa', payload.idEmpresa)
        data.append('idSucursal', payload.idSucursal)
        if (payload.prods)
            data.append('prods', payload.prods, 'prods.csv')

        this.setState({isLoading: true, msg: ""})
        fetch("/api/master/prods/import", {
            method: "post",
            body: data,
        })
            .then(async res => [await res.json(), res.status])
            .then(d => {
                const [data, status] = d
                if (status === 200) {
                    notificarMsg(data.msg)
                    this.setState({msg: data.msg})
                }
                if (status === 422)
                    notificarError(data.msg)
            })
            .catch(() => notificarError("No se pudo realizar la importación"))
            .finally(() => this.setState({isLoading: false}))
    }

    render() {
        return (
            <section className="ventas-fpay-section-vh cienvh">
                <div className="container pt-5">
                    <div className="row mt-2">
                        {this.state.msg.length ? (
                            <div className=" col-sm alert alert-info">
                                {this.state.msg}
                            </div>
                        ) : null}
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <EmpresaPicker onSelectEmpresa={(e) => {
                                this.setState((state, props) => {
                                    return {
                                        payload: {
                                            ...state.payload,
                                            _empresa: e,
                                            idEmpresa: e ? e.ID : null,
                                        }
                                    }
                                })
                            }} />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm">
                            <AsyncSucursalPicker filterAll={true} onChangeSucursales={(e) => {
                                this.setState((state, props) => {
                                    return {
                                        payload: {
                                            ...state.payload,
                                            _sucursal: e,
                                            idSucursal: e ? e.IdSucursal : null,
                                        }
                                    }
                                })
                            }} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <div className="caja-fondo-blanco-m-2">
                            <div className="form-group">
                                <label>Archivo CSV</label>
                                <input type="file" className="form-control" onChange={e => {
                                    const target = e.target;
                                    this.setState((state, props) => {
                                        return {
                                            payload: {
                                                ...state.payload,
                                                prods: target.files.length ? target.files[0] : null,
                                            }
                                        }
                                    })
                                }} />
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm d-flex justify-content-end">
                            <button disabled={this.state.isLoading} className="btn btn-outline-primary btn-verde-mfp"
                                onClick={this.onSendImport}>
                                Importar
                            </button>
                        </div>
                    </div>

                </div>
            </section>
        )
    }

}
