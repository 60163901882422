import React from "react"
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactExport from "react-data-export";
import BotonesAccion from "./BotonesAccion"
import Modal from '../clientes/ClienteModal';
import NotasCDFormController from "./NotasCDFormController"
import swal from "sweetalert"
import {pdf} from "@react-pdf/renderer"


// para generar reporte en pdf
import ModalPDF from '../Compras/Reportes/modalPDF'
import VisorPDF from '../Compras/Reportes/visorPDF'
import ReporteNC from './Reportes/RptNC'
import PageLoading from '../PageLoading';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class NotasCDList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            modalIsOpen: false,
            accion: "",

            cabnc: [],
            detnc: [],
            modalIsOpenPDF: false,
        }
    }

    handleOpenModal = (e) => {
        this.props.history.push("/note")
    }

    handleSendSUNAT = async (rowsel) => {
        // await this.props.sendxml(rowsel.IdRegistroVenta, rowsel.idNc)
        this.props.history.push(`/note/${rowsel.idNc}/1`)
    }


    handleDelete = (e) => {
        swal({
            title: 'Atención..!',
            text: `Registro Eliminado`,
            icon: 'error'
        })
    }

    handleCloseModal = (doRefresh) => {
        if (doRefresh)
            this.props.getListaNotasCD(1)
        this.setState({
            modalIsOpen: false
        })
    }

    handleCloseModalPDF = (e) => {
        this.setState({
            modalIsOpenPDF: false
        })
    }

    handleOpenModalPDF = async (reg) => {
        await this.NotaCD(reg.IdRegistroVenta)
        this.setState({
            modalIsOpenPDF: true
        })
    }

    NotaCD = async (idrv) => {
        try {

            const response = await fetch(`/api/notascd/item/${idrv}`);
            const reg = await response.json();
            const cnc = reg[0]
            const dnc = reg[1]
            this.setState({
                cabnc: cnc[0],
                detnc: dnc
            })

        } catch (error) {
            console.error(error);
        }
    }

    handleEnviarCorreo = async (data) => {

        if (!data.ClienteCorreo || data.ClienteCorreo === '') {
            swal({
                title: 'Atención...! ',
                text: 'El Cliente no tiene un correo electrónico asociado...! ',
                icon: 'warning'
            })
        } else {
            // cargamos data
            await this.NotaCD(data.IdRegistroVenta)

            // obtenemos el pdf en blob
            const blob = await pdf(
                <ReporteNC
                    cabnc={this.state.cabnc}
                    detnc={this.state.detnc}
                />
            ).toBlob()

            // enviar blob
            let form = new FormData();
            form.append('pdf', blob, 'mi.pdf');
            form.append('data', JSON.stringify({tipo: 'NC', data: data}));
            let config = {
                method: 'POST',
                body: form
            }

            const res = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/sendemail`, config);
            const rpta = res.ok;

            if (rpta) {
                console.log("correo enviado safisfactoriamente...")
                swal({
                    title: 'El correo fue enviado con éxito...! ',
                    text: `Cuenta destino: ${data.ClienteCorreo}`,
                    icon: 'success'
                })
            } else {
                swal({
                    title: 'Correo NO enviado, intente luego... ',
                    text: `Cuenta destino: ${data.ClienteCorreo}`,
                    icon: 'warning'
                })
            }
        }
    }

    getConfigForExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: "Estado",
                        width: {wpx: 90}
                    },
                    {
                        title: "Tipo NC",
                        width: {wpx: 100}
                    },
                    {
                        title: "Serie NC",
                        width: {wpx: 200}
                    },
                    {
                        title: "Número NC",
                        width: {wpx: 100}
                    },
                    {
                        title: "Fecha Emisión",
                        width: {wpx: 100}
                    },
                    {
                        title: "Serie CE",
                        width: {wpx: 100}
                    },
                    {
                        title: "Número CE"
                    },
                    {
                        title: "IGV"
                    },
                    {
                        title: "Base"
                    },
                    {
                        title: "ICBPER"
                    },
                    {
                        title: "Total"
                    }
                ],

                data: dataSet.map(row => {
                    return [
                        {
                            value: row.Estado,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.DescripcionTipoNCD,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.SerieCD || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NumeroCD || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.FechaEmision,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.SerieCE,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NumeroCE,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: Number(row.igv).toFixed(2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: Number(row.gravadas).toFixed(2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: Number(row.icbper).toFixed(2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: Number(row.totalVenta).toFixed(2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ];
    }

    render() {

        if (this.props.error)
            return `Error: ${this.props.error.message}`;

        return (
            <section className="ventas-fpay-section pt-5 pb-5">
                <div className="container d-flex justify-content-end">
                    <div className="row mr-1 ml-1">
                        <div className="">
                            <button
                                className="btn btn-success btn-verde-mfp mr-2"
                                title="nuevo"
                                onClick={this.handleOpenModal}
                            >Crear Nota</button>
                        </div>
                        <div className="d-flex justify-content-end mb-4">
                            <ExcelFile
                                element={
                                    <button className="btn btn-primary btn-excel-mfp">
                                        Descargar Excel
                                    </button>
                                }
                                filename="ReporteNotasCD"
                            >
                                <ExcelSheet
                                    dataSet={this.getConfigForExcel(this.props.dataNotasCD)}
                                    name="Productos"
                                />
                            </ExcelFile>
                        </div>
                    </div>
                </div>

                <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                    <span className="align-self-center letra-fecha-fpay">
                        Desde:
                    </span>
                    <div className="col-sm">
                        <input disabled={this.props.loading} type="date" onChange={this.props.onChangeFecha}
                               name="fechaInicio"
                               className="input__linea mr-1 mt-3 mb-3 linea-fecha-fpay" value={this.props.fechaInicio}/>
                    </div>
                    <span className="align-self-center letra-fecha-fpay">
                        Hasta:
                    </span>
                    <div className="col-sm">
                        <input disabled={this.props.loading} type="date" onChange={this.props.onChangeFecha}
                               name="fechaFin" className="input__linea mr-1 mt-3 mb-3 linea-fecha-fpay"
                               value={this.props.fechaFin}/>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="col-sm-12 no-pad">
                        {this.props.loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                    </div>
                                </div>
                            ) :

                            <ReactTable
                                data={this.props.dataNotasCD}
                                filterable
                                style={{"width": "100%"}}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                columns={[
                                    {
                                        Header: "Acciones",
                                        id: "idNc",
                                        accessor: d => d.idNc,
                                        width: 350,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["idNc"]
                                            }),
                                        Cell: f => {
                                            return (
                                                <BotonesAccion
                                                    history={this.props.history}
                                                    onclickSendSUNAT={this.handleSendSUNAT}
                                                    onclickDelete={this.handleDelete}
                                                    rowsel={f.original}
                                                    downloadPDF={this.handleOpenModalPDF}
                                                    enviarCorreo={this.handleEnviarCorreo}
                                                />
                                            )
                                        }
                                    },
                                    {
                                        Header: "Estado",
                                        id: "Estado",
                                        accessor: d => d.Estado,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Estado"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Tipo NC",
                                        id: "DescripcionTipoNCD",
                                        accessor: d => d.DescripcionTipoNCD,
                                        filterMethod: (filter, row) =>
                                            row[filter.id].startsWith(filter.value) &&
                                            row[filter.id].endsWith(filter.value)
                                    },
                                    {
                                        Header: "Serie NC",
                                        accessor: "SerieCD",
                                        filterMethod: (filter, row) =>
                                            row[filter.id].startsWith(filter.value) &&
                                            row[filter.id].endsWith(filter.value)
                                    },
                                    {
                                        Header: "Número NC",
                                        id: "NumeroCD",
                                        accessor: d => d.NumeroCD,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["NumeroCD"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Fecha Emisión",
                                        accessor: "FechaEmision"
                                    },
                                    {
                                        Header: "Serie CE",
                                        accessor: "SerieCE",
                                        filterMethod: (filter, row) =>
                                            row[filter.id].startsWith(filter.value) &&
                                            row[filter.id].endsWith(filter.value)
                                    },
                                    {
                                        Header: "Número CE",
                                        id: "NumeroCE",
                                        accessor: d => d.NumeroCE,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["NumeroCE"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "IGV",
                                        id: "igv",
                                        accessor: d => Number(d.igv).toFixed(2),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["igv"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Base",
                                        id: "gravadas",
                                        accessor: d => Number(d.gravadas).toFixed(2),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["gravadas"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "ICBPER",
                                        id: "icbper",
                                        accessor: d => Number(d.icbper).toFixed(2),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["icbper"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Total",
                                        id: "totalVenta",
                                        accessor: d => Number(d.totalVenta).toFixed(2),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["totalVenta"]}),
                                        filterAll: true
                                    },
                                ]}
                                defaultPageSize={10}
                                className="-striped -highlight"
                                SubComponent={row => {
                                    return (
                                        <>
                                            <div className="alert alert-info p-3" width="70%">
                                                {row.original.Codigo ? `Código: ${row.original.Codigo || "-1"} :::::: Descripción: ${row.original.DescripcionCodigo || ""} ` : ``}
                                            </div>
                                        </>
                                    )

                                }}
                            />
                        }

                    </div>
                </div>

                <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal} width='900px'
                       title={`REGISTRO DE NOTA DE CRÉDITO`}>
                    <NotasCDFormController
                        onClose={this.handleCloseModal}
                        dataTipoNCD={this.props.dataTipoNCD}
                        getListaNotasCD={this.props.getListaNotasCD}
                        sendxml={this.props.sendxml}
                    />
                </Modal>

                <ModalPDF
                    isOpen={this.state.modalIsOpenPDF}
                    onClose={this.handleCloseModalPDF}
                    width='900px'
                    title="Visor PDF"
                >
                    <VisorPDF fileName={`Reporte_NC.pdf`}>
                        <ReporteNC
                            extImg={this.props.extImg}
                            cabnc={this.state.cabnc}
                            detnc={this.state.detnc}
                        />
                    </VisorPDF>
                </ModalPDF>
            </section>
        )
    }
}

export default NotasCDList
