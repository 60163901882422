import React from "react";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class InvoiceDetailChanger extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            items: props.items ? props.items : []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.invoiceId && prevProps.invoiceId !== this.props.invoiceId)
            this.fetchInvoice()
    }

    fetchInvoice() {
        this.setState({isLoading: true, items: []})
        fetch(`/api/preVentas/get-items?idVenta=${this.props.invoiceId}`)
            .then(r => r.json())
            .then(d => d.map(d => {
                return {...d, descuento: 0, descripcion: d.nombre}
            }))
            .then(x => {
                this.setState({items: x})
                return x;
            }).then(y => this.onChange(y))
            .finally(() => this.setState({isLoading: false}))
            .catch(() => notificarError("Ha ocurrido un error al momento de obtener los items"))
    }

    onChangeAttr(e, id) {
        const items = [...this.state.items];
        let item = items.find(i => String(i.idDetVenta) === String(id))
        item[e.target.getAttribute('name')] = e.target.value;
        this.setState({items: items})
        this.onChange(items)
    }

    removeItem(item) {
        const items = [...this.state.items];
        const i = items.indexOf(item);
        items.splice(i, 1);
        this.setState({items: items})
        this.onChange(items)
    }

    onChange(items) {
        this.props.onChangeItems(items)
    }

    render() {
        const {items, isLoading} = this.state;

        return (
            <>
                {isLoading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div>
                    :
                    <>
                        <label>{this.props.placeholder || ""}</label>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <td>Nombre</td>
                                <td>Cantidad</td>
                                <td>Precio</td>
                                <td>Descuento</td>
                                <td>Opciones</td>
                            </tr>
                            </thead>
                            <tbody>
                            {items.map(i => (
                                <tr key={`tr-item-${i.idDetVenta}`}>
                                    <td>
                                        <input
                                            name="descripcion"
                                            disabled={this.props.readOnly}
                                            className="form-control"
                                            onChange={e => this.onChangeAttr(e, i.idDetVenta)}
                                            type="text" value={i.descripcion}/>
                                    </td>
                                    <td>
                                        <input
                                            onChange={e => this.onChangeAttr(e, i.idDetVenta)}
                                            name="cantidad"
                                            disabled={this.props.readOnly}
                                            className="form-control"
                                            type="number" value={i.cantidad}/>
                                    </td>
                                    <td>
                                        <input
                                            onChange={e => this.onChangeAttr(e, i.idDetVenta)}
                                            name="precio"
                                            disabled={this.props.readOnly}
                                            className="form-control"
                                            type="number" value={i.precio}/>
                                    </td>
                                    <td>
                                        <input
                                            name="descuento"
                                            onChange={e => this.onChangeAttr(e, i.idDetVenta)}
                                            className="form-control"
                                            disabled={this.props.readOnly}
                                            type="number" value={i.descuento}/>
                                    </td>
                                    <td>
                                        <button onClick={() => this.removeItem(i)} disabled={this.props.readOnly}
                                                className="btn btn-outline-danger">
                                            Quitar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>
                }

            </>
        )
    }
}
