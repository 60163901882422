import React from "react";
import {ConfPicker} from "../shared/ConfPicker";
import $ from "jquery"

const behaviours = [
    {id: "0", label: "Desactivar-Activar"},
    {id: "1", label: "Activar-Desactivar"},
]

export class DisableEnableConfForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {}
        }
    }

    render() {
        return (
            <>
                <ConfPicker onChange={e => this.setState((state) => {
                    return {
                        payload: {...state.payload, configuration: e}
                    }
                })}/>
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Comportamiento
                    </div>
                    <div className="col-sm">
                        <select
                            onChange={e => {
                                const val = $(e.target).val()
                                this.setState((state) => {
                                    return {
                                        payload: {
                                            ...state.payload,
                                            isEnableFirst: val && val.length ? Boolean(parseInt(val)) : ""
                                        }
                                    }
                                })
                            }} className="form-control">
                            <option value="">Seleccionar</option>
                            {behaviours.map(e => (
                                <option key={e.id} value={e.id}>{e.label}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Fecha de inicio
                    </div>
                    <div className="col-sm">
                        <input type="datetime-local" className="form-control" onChange={e => {
                            const val = e.target.value
                            this.setState((state) => {
                                return {
                                    payload: {...state.payload, startDate: val.replace("T", " ")}
                                }
                            })
                        }}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Fecha de fin
                    </div>
                    <div className="col-sm">
                        <input type="datetime-local" className="form-control" onChange={e => {
                            const val = e.target.value
                            this.setState((state) => {
                                return {
                                    payload: {...state.payload, endDate: val.replace("T", " ")}
                                }
                            })
                        }}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm">
                        <button className="btn btn-outline-primary" disabled={this.props.disabled}
                                onClick={() => this.props.onSave(this.state.payload)}>Guardar
                        </button>
                    </div>
                </div>
            </>
        );
    }
}
