import moment from "moment";
import React from "react";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {convertObjToQueryParams} from "../../helpers/utils";
import _ from 'lodash';

export default class FiltroReportDocSunat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            isLoading: false,
        }
        this.searchDocs = this.searchDocs.bind(this);
        this.onChangeTipoDoc = this.onChangeTipoDoc.bind(this);
        this.onChangeSucursales = this.onChangeSucursales.bind(this);
        this.searchSucursales = this.searchSucursales.bind(this);
    }

    searchDocs(query) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true});
            const customQueryParams = this.props.customQueryParams;
            const customParams = customQueryParams && Object.keys(customQueryParams).length ?
                convertObjToQueryParams(customQueryParams) : '';
            fetch(`/api/planesSunat/tipo-docs/?query=${query}${customParams.length ? `&${customParams}` : ''}`)
                .then(r => r.json())
                .then(docs => {
                    resolve(docs.map(d => {
                        return {...d, value: d.IdTipoDocumentoSunat, label: d.Descripcion}
                    }))
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        });
    }

    searchSucursales(query) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true});
            fetch(`/api/reportes/sucursales?search=${query}`)
                .then(r => r.json())
                .then(docs => {
                    resolve(docs.map(d => {
                        return {...d, value: d.IdSucursal, label: `${d.Nombre}`}
                    }))
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        });
    }

    onChangeTipoDoc(e) {
        this.setState({
            payload: {
                ...this.state.payload,
                idsTipoDoc: e ? e.map(x => x.IdTipoDocumentoSunat) : [],
                _tipoDocs: e ? e : [],
            }
        })
    }

    onChangeSucursales(e) {
        this.setState({
            payload: {
                ...this.state.payload,
                idSucursales: e ? e.map(x => x.IdSucursal) : [],
                _sucursales: e ? e : [],
            }
        })
    }

    render() {
        const {payload} = this.state;

        const showDocs = !_.isUndefined(this.props.showDocs) ? Boolean(this.props.showDocs) : true;
        const showSucursales = !_.isUndefined(this.props.showSucursales) ? Boolean(this.props.showSucursales) : true;

        return (
            <>
                    <div className="no-pad">
                        <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                            <span className="align-self-center letra-fecha-fpay">
                                Desde:
                            </span>

                            <input type="date" className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                                onChange={e => this.setState({
                                    payload: {
                                        ...this.state.payload,
                                        fechaInicio: e.target.value
                                    }
                                })}
                                value={payload.fechaInicio ? moment(payload.fechaInicio).format('YYYY-MM-DD') : ""} />

                            <span className="align-self-center letra-fecha-fpay">
                                Hasta:
                            </span>

                            <input type="date" className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                                onChange={e => this.setState({
                                    payload: {
                                        ...this.state.payload,
                                        fechaFin: e.target.value
                                    }
                                })}
                                value={payload.fechaFin ? moment(payload.fechaFin).format('YYYY-MM-DD') : ""} />

                        </div>
                        {/* <div className="col-sm-auto">
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            this.setState({
                                payload: {
                                    ...this.state.payload,
                                    fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
                                    fechaFin: moment(new Date()).format('YYYY-MM-DD'),
                                }
                            })
                        }}>Hoy</a>
                        </div> */}
                    </div>
                    <div className="mt-3 container">
                        {showDocs ? (
                            <>
                                <div className="caja-fondo-blanco-m-2">
                                    <span className="sucursal-text-color pad-15-fpay">
                                        Seleccione documentos
                                    </span>
                                    <div className="mt-2">
                                        <AsyncSelect
                                            defaultOptions={true}
                                            onChange={this.onChangeTipoDoc}
                                            loadOptions={this.searchDocs}
                                            isClearable={true}
                                            isLoading={this.state.isLoading}
                                            isMulti
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {showSucursales ?
                            <>
                                <div className="caja-fondo-blanco-m-2">
                                    <span className="sucursal-text-color pad-15-fpay">
                                        Sucursales
                                    </span>
                                    <div className="mt-2">
                                        <AsyncSelect
                                            defaultOptions={true}
                                            onChange={this.onChangeSucursales}
                                            loadOptions={this.searchSucursales}
                                            isClearable={true}
                                            isLoading={this.state.isLoading}
                                            isMulti
                                        />
                                    </div>
                                </div>
                            </> : "Sucursal: " + (this.props.sucursal ? this.props.sucursal.Nombre : "Sin sucursal")
                        }


                        <div className="col-sm-auto seccion-btn-verde-mfp no-pad">
                            <button onClick={() => {
                                let tmpPayload = { ...payload }
                                tmpPayload._sucursales = showSucursales ? payload._sucursales : [this.props.sucursal]
                                tmpPayload.idSucursales = showSucursales ? payload.idSucursales : [this.props.sucursal.IdSucursal]
                                this.props.onFilterPressed(tmpPayload)
                            }}
                                className="btn btn-outline-primary btn-verde-mfp" disabled={this.props.isLoading}>
                                Filtrar
                            </button>
                        </div>
                    </div>
            </>
        );
    }
}
