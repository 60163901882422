import React from 'react';

class PreventaModalDescuentos extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetchDescuentoPredefinido();
    }

    componentWillUnmount() {
        this.props.onBlurDescuento(null, this.props.ItemDescuento)
    }

    async fetchDescuentoPredefinido() {
        const idPresentacion = this.props.ItemDescuento.IdPresentacion;

        const req = await fetch(`/api/presentaciones/${idPresentacion}/descuento-predefinido`);

        if (!req.ok) {
            return;
        }

        const data = await req.json();

        this.props.DescuentoChange({
            target: { value: data.Descuento }
        }, this.props.ItemDescuento);
    }

    render() {
        const item = this.props.ItemDescuento;
        const afectsIgv = this.props.afectsIgv;

        return (
            <React.Fragment>
                <div className="row form-group col-12">
                    {this.props.ItemDescuento.descripcion}
                </div>
                {this.props.ItemDescuento.NombrePresentacion === "PRINCIPAL" ? null :
                    <div className="row form-group col-12">

                    </div>
                }
                <div className="row form-group col-12">

                    <label className="mr-1">Descuento</label>
                    <input
                        type="text"
                        readOnly={this.props.load}
                        disabled={this.props.load}
                        step="1"
                        min="0"
                        onClick={e => e.target.select()}
                        value={this.props.ItemDescuento.Descuento}
                        onChange={e => this.props.DescuentoChange(e, this.props.ItemDescuento)}
                        onBlur={e => this.props.onBlurDescuento(e, this.props.ItemDescuento)}
                    />
                </div>
                <div className="row form-group col-12">
                    <label className="mr-2 mt-1" htmlFor="customCheckDescuento">¿Gratuito?</label>

                    <select className="form-control"
                            value={afectsIgv.find(i => String(i.IdAfectacionIgv === String(item.IdAfectacionIgv))) ? item.IdAfectacionIgv : "-1"}
                            onChange={e => this.props.onChangeCheckBoxGratuito(e, this.props.ItemDescuento)}
                            disabled={this.props.load}>
                        <option key={"afec-igv--1"} value="-1">No</option>
                        {afectsIgv.map(a => <option key={`afec-igv-${a.IdAfectacionIgv}`}
                                                    value={a.IdAfectacionIgv}>{a.Descripcion.replace('Gravado', '')}</option>)}
                    </select>
                </div>
            </React.Fragment>
        )
    }
}

export default PreventaModalDescuentos
