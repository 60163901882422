import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { decimalAdjust } from "../../Global";
import { notificarError, notificarMsg } from "../Almacenes/AlmacenNotify";
import { BotonDeletePequeno, BotonAddItem} from "../Plantillas/Botones";
import ProveedorForm from "./ProveedorForm";

const DEFAULT_DETAIL = {
  cantidad: 0.0,
  precio: 0.0,
  descripcion: "",
};

function ListaItemsForm(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [items, setItems] = useState([]);
  const [detail, setDetail] = useState({ ...DEFAULT_DETAIL });

  useEffect(() => {
    if (isNaN(props.compraExternaId)) {
      return;
    }

    fetchItems();
  }, [props.compraExternaId]);

  useEffect(() => {
    if (typeof errorMessage === "string" && errorMessage !== "") {
      notificarError(errorMessage);
    }
  }, [errorMessage]);

  const removeItem = async (id) => {
    try {
      const response = await fetch(
        `/api/comprasexternas/items/${props.compraExternaId}/detalles/${id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        setErrorMessage(
          "No se pudo eliminar el item. Por favor, intentelo otra vez."
        );
      }

      await fetchItems();
    } catch (e) {
      console.error(e);
    }
  };

  const fetchItems = async () => {
    try {
      const response = await fetch(
        `/api/comprasexternas/items/${props.compraExternaId}/detalles`
      );
      const [estado, dbItems] = await response.json();

      setItems(
        dbItems.map((x) => ({
          id: x.IdDetalleCompraExterna,
          cantidad: x.Cantidad,
          precio: x.Precio,
          descripcion: x.Descripcion,
        }))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onAddItem = async () => {
    setErrorMessage("");

    try {
      const response = await fetch(
        `/api/comprasexternas/items/${props.compraExternaId}/detalles`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cantidad: detail.cantidad,
            precio: detail.precio,
            descripcion: detail.descripcion,
          }),
        }
      );

      if (!response.ok) {
        setErrorMessage(
          "No se pudo agregar el item. Por favor, intentelo otra vez."
        );
      }

      await fetchItems();

      setDetail({ ...DEFAULT_DETAIL });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="row">
      <div className="form-group col-12">
        <label className="font-weight-bold" htmlFor="descripcion">
          Descripcion:
        </label>
        <textarea
          className="form-control"
          name="descripcion"
          id="descripcion"
          cols="4"
          value={detail.descripcion}
          onChange={(e) =>
            setDetail({ ...detail, descripcion: e.target.value })
          }
        ></textarea>
      </div>

      <div className="form-group col-12">
        <label className="font-weight-bold" htmlFor="documentoSerie">
          Precio
        </label>
        <input
          type="number"
          value={detail.precio}
          className="form-control"
          onChange={(e) => setDetail({ ...detail, precio: e.target.value })}
        />
      </div>

      <div className="form-group col-12">
        <label className="font-weight-bold" htmlFor="documentoSerie">
          Cantidad
        </label>
        <input
          type="number"
          value={detail.cantidad}
          className="form-control"
          onChange={(e) => setDetail({ ...detail, cantidad: e.target.value })}
        />
      </div>

      <div className="col-12 d-flex justify-content-end mb-3">
        <BotonAddItem
          title="Agregar ítem"
          type="button"
          onClick={() => onAddItem()}
        />
      </div>

      <div className="col-12">
        <ReactTable
          data={items}
          disabled={false}
          columns={[
            {
              Header: "Descripción",
              accessor: "descripcion",
            },
            {
              Header: "Cantidad",
              accessor: "cantidad",
              Cell: (row) => (
                <div style={{ textAlign: "right" }}>{row.value}</div>
              ),
            },
            {
              Header: "Total",
              accessor: "precio",
              Cell: (row) => (
                <div style={{ textAlign: "right" }}>
                  {decimalAdjust("floor", row.value, -2)}
                </div>
              ),
            },
            {
              Header: "Acciones",
              accessor: "id",
              Cell: (f) => {
                return (
                  <div align="center">
                    <BotonDeletePequeno
                      title="Eliminar"
                      type="button"
                      onClick={() => removeItem(f.value)}
                    />
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={20}
          showPagination={true}
          style={{
            height: "200px",
            width: "100%",
            fontSize: "13px",
          }}
          className="-striped -highlight"
        />
      </div>
    </div>
  );
}

export default function CompraExternaForm(props) {
  const TABS = ["datos-proveedor", "lista-items"];

  const [payload, setPayload] = useState({
    idProveedor: undefined,
    fechaMovimiento: new Date(),
    idTipoDocumentoAsociado: "",
    documentoAsociadoSerie: "",
    documentoAsociadoNumero: "",
    total: 0,
    glosa: "",
  });
  const [currentId, setCurrentId] = useState(undefined);
  const [currentTab, setCurrentTab] = useState("datos-proveedor");
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [listItemsAvailable, setListItemsAvailable] = useState(false);
  const [mayBeInvalidDocument, setMayBeInvalidDocument] = useState(false);

  useEffect(() => {
    if (props?.currentId === undefined) {
      return;
    }

    setCurrentId(props.currentId);

    setListItemsAvailable(true);

    const fetchCurrentCompraExterna = async () => {
      try {
        const response = await fetch(
          `/api/comprasexternas/items/${props.currentId}`
        );
        const [estado, [compraExterna]] = await response.json();

        setMayBeInvalidDocument(!compraExterna.FueValidado)

        setPayload({
          idProveedor: compraExterna.IdProveedor,
          fechaMovimiento: compraExterna.FechaMovimiento.split(" ")[0],
          idTipoDocumentoAsociado: compraExterna.IdTipoDocumentoSunat,
          documentoAsociadoSerie: compraExterna.NroDocumentoSunat.split("-")[0],
          documentoAsociadoNumero:
            +compraExterna.NroDocumentoSunat.split("-")[1],
          total: compraExterna.Total,
          glosa: compraExterna.Glosa,
        });
      } catch (e) {
        console.error(e);
      }
    };

    fetchCurrentCompraExterna();
  }, [props.currentId]);

  useEffect(() => {
    const fetchTipoDocumentos = async () => {
      try {
        const response = await fetch(`/api/movimientosalmacen/tipo-docs/1/${props.currentSucursalId}`);
        const data = await response.json();

        if (Array.isArray(data)) {
          const documents = data.filter(x => [1, 3].includes(x.Codigo))
          setTipoDocumentos(documents);

          if (documents.length > 0) {
            setPayload({...payload, idTipoDocumentoAsociado: documents[0].IdTipoDocumentoSunat })
          }
        }
      } catch (e) {
        setHasError(true);
        console.error(e);
      }
    };

    fetchTipoDocumentos();
  }, []);

  useEffect(() => {
    if (
      currentId != props.currentId &&
      typeof props.onChangeCurrentId === "function"
    ) {
      props.onChangeCurrentId(currentId);
    }
  }, [currentId]);

  const getTurno = async () => {
    try {
        const response = await fetch(`/api/cajas/verificar/Turno`);

        if (!response.ok) {
          throw new Error(response.text)
        }

        const turnoInfo = await response.json();
        return turnoInfo.Turno ? turnoInfo.Turno[0][0] : null;
    } catch (e) {
        console.error(e);
    }

    return null;
  }

  const onCancel = () => {
    if (typeof props.onCancel !== "function") {
      alert("Ocurrió un error inesperado. Refresque la página");
      return;
    }

    props.onCancel();
  };

  const onComplete = () => {
    notificarMsg("Operación completada exitosamente.");
    props.onCancel();
  }

  const onUpdate = async () => {
    if (payload.idProveedor == null) {
      notificarError("El campo proveedor es requerido.")
      return;
    }

    try {
      const response = await fetch(`/api/comprasexternas/items/${currentId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idProveedor: payload.idProveedor,
          fechaMovimiento: `${moment(payload.fechaMovimiento).format(
            "YYYY-MM-DD"
          )} ${moment(new Date()).format("HH:mm")}`,
          idTipoDocumentoAsociado: payload.idTipoDocumentoAsociado,
          documentoAsociadoSerie: payload.documentoAsociadoSerie,
          documentoAsociadoNumero: `${+payload.documentoAsociadoNumero}`,
          total: payload.total,
          glosa: payload.glosa,
        }),
      });

      if (!response.ok) {
        notificarError("No se pudo registrar la compra correctamente. Itente otra vez.");
        return;
      }

      notificarMsg("La compra se ha actualizado de manera exitosa.");
    } catch (e) {
      console.error(e);
    }
  }

  const onValidate = async () => {
    let query = { }
    setMayBeInvalidDocument(false);

    if (payload.idProveedor == null) {
      notificarError("El campo proveedor es requerido.")
      return;
    } else {
      try {
        const response = await fetch(`/api/proveedores/item/${payload.idProveedor}`);

        const data = await response.json();

        if (!Array.isArray(data) || !Array.isArray(data[0]) || !data[0][0]) {
          throw new Error();
        }

        query['numDoc'] = data[0][0]['Ruc']
        
      } catch (e) {
        console.error(e)
        notificarError("El proveedor seleccionado no es válido. Por favor, intentelo otra vez.")
      }
    }

    const tipoDocumento = tipoDocumentos.find(x => x.IdTipoDocumentoSunat == payload.idTipoDocumentoAsociado)

    if (tipoDocumento) {
      query["tipoDoc"] = `0${tipoDocumento.Codigo}`
    } else {
      notificarError("El tipo de documento seleccionado no es válido. Por favor, intentelo otra vez.")
    }

    if (payload.documentoAsociadoSerie.trim() != "") {
      query["serie"] = payload.documentoAsociadoSerie;
    } else {
      notificarError("La serie ingresada no es válido. Por favor, intentelo otra vez.")
    }

    if (payload.documentoAsociadoNumero.trim() != "" && !isNaN(+payload.documentoAsociadoNumero)) {
      query["correlativo"] = `${+payload.documentoAsociadoNumero}`;
    } else {
      notificarError("La serie ingresada no es válido. Por favor, intentelo otra vez.")
    }

    if (payload.total) {
      query["montoImporteVenta"] = `${payload.total}`;
    } else {
      notificarError("El monto total no es válido. Por favor, intentelo otra vez.")
    }

    if (payload.fechaMovimiento) {
      query["fechaEmision"] = moment(payload.fechaMovimiento).format("YYYY-MM-DD")
    }

    try {
      const response = await fetch("/api/comprasexternas/validar-comprobante", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ consultaComprobante: {...query} })
      });

      const body = await response.json();

      if (!response.ok) {
        throw new Error(body.message)
      }

      notificarMsg("Comprobante ha sido validado correctamente.");

      await onRegister(true);
    } catch (error) {
      notificarError(error.message);
      setMayBeInvalidDocument(true);
    }
  }

  const onRegister = async (wasValidated = false) => {
    if (payload.idProveedor == null) {
      notificarError("El campo proveedor es requerido.")
      return;
    }

    try {
      const response = await fetch("/api/comprasexternas/items", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idProveedor: payload.idProveedor,
          fechaRegistro: moment(new Date()).format("YYYY-MM-DD HH:mm"),
          fechaMovimiento: `${moment(payload.fechaMovimiento).format(
            "YYYY-MM-DD"
          )} ${moment(new Date()).format("HH:mm")}`,
          idTipoDocumentoAsociado: payload.idTipoDocumentoAsociado,
          documentoAsociadoSerie: payload.documentoAsociadoSerie,
          documentoAsociadoNumero: `${+payload.documentoAsociadoNumero}`,
          total: payload.total,
          glosa: payload.glosa,
          fueValidado: wasValidated
        }),
      });

      if (!response.ok) {
        notificarError("No se pudo registrar la compra correctamente. Itente otra vez.")
      }

      const data = await response.json();

      const [[[{ estado }]]] = data;

      if (typeof estado === "string") {
        setCurrentId(estado);
        setListItemsAvailable(true);

        notificarMsg("Se ha registrado la compra de manera exitosa.");

        setCurrentTab("lista-items");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {
        mayBeInvalidDocument && <div className="alert alert-warning" role="alert">
          <span className="font-weight-bold">Importante:</span> No se ha comprobado la validez del comprobante. Puede que no se encuentre registrado o no haya sido informado a SUNAT.
        </div>
      }

      <hr width="1080px" />

      <ul className="nav nav-tabs" id="pills-tab" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link ${
              currentTab === "datos-proveedor" ? "active" : ""
            }`}
            id="datos-proveedor-tab"
            data-toggle="tab"
            href="#datos-proveedor"
            role="tab"
            aria-controls="datos-proveedor"
            onClick={() => setCurrentTab("datos-proveedor")}
          >
            Datos compra externa
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${
              currentTab === "lista-items" ? "active" : ""
            } ${!listItemsAvailable ? "d-none" : ""}`}
            id="lista-items-tab"
            data-toggle="tab"
            href="#lista-items"
            role="tab"
            aria-controls="lista-items"
            onClick={() => setCurrentTab("lista-items")}
          >
            Lista de items
          </a>
        </li>
      </ul>

      <div className="tab-content container" id="pills-tabContent">
        <div
          className={`tab-pane fade ${
            currentTab === "datos-proveedor" ? "show active" : ""
          }`}
          id="datos-proveedor"
          role="tabpanel"
          aria-labelledby="datos-proveedor-tab"
        >
          <div className="row mt-3">
            <div className="col-12">
              <ProveedorForm
                currentId={payload.idProveedor}
                onChange={(e) => setPayload({ ...payload, idProveedor: e })}
              />
            </div>
            <div className="form-group col-12">
              <label className="font-weight-bold" htmlFor="fechaMovimiento">
                Fecha Movimiento
              </label>
              <input
                type="date"
                value={moment(payload.fechaMovimiento).format("YYYY-MM-DD")}
                className="form-control"
                onChange={(e) =>
                  setPayload({ ...payload, fechaMovimiento: e.target.value })
                }
              />
            </div>
            <div className="form-group col-12">
              <label className="font-weight-bold" htmlFor="documentoAsociado">
                Documento asociado
              </label>
              <select
                className="form-control"
                value={payload.idTipoDocumentoAsociado}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    idTipoDocumentoAsociado: e.target.value,
                  })
                }
                id="documentoAsociado"
              >
                {tipoDocumentos.map((item) => {
                  return (
                    <option
                      key={item.IdTipoDocumentoSunat}
                      value={item.IdTipoDocumentoSunat}
                      title={item.Descripcion}
                    >{`0${item.Codigo} ${item.Descripcion}`}</option>
                  );
                })}
              </select>
            </div>
            <div className="form-group col-12">
              <label className="font-weight-bold" htmlFor="documentoSerie">
                Serie
              </label>
              <input
                type="text"
                value={payload.documentoAsociadoSerie}
                className="form-control"
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    documentoAsociadoSerie: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group col-12">
              <label className="font-weight-bold" htmlFor="documentoNumero">
                Nro Documento
              </label>
              <input
                type="text"
                value={payload.documentoAsociadoNumero}
                className="form-control"
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    documentoAsociadoNumero: e.target.value,
                  })
                }
              />
            </div>

            <div className="form-group col-12">
              <label className="font-weight-bold" htmlFor="total">
                Total
              </label>
              <input
                type="number"
                value={payload.total}
                className="form-control"
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    total: e.target.value,
                  })
                }
              />
            </div>

            <div className="form-group col-12">
              <label className="font-weight-bold" htmlFor="glosa">
                Glosa
              </label>
              <textarea
                className="form-control"
                ccols="3"
                value={payload.glosa}
                onChange={(e) =>
                  setPayload({ ...payload, glosa: e.target.value })
                }
              ></textarea>
            </div>

            <div className="col-12 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-secondary mx-2"
                onClick={() => onCancel()}
              >
                Cancelar
              </button>
              { currentId ? <button type="button" className="btn btn-info mx-2" onClick={() => onUpdate()}>Guardar cambios</button>
                : <button type="button" className="btn btn-info mx-2" onClick={() => onValidate()}>Validar comprobante</button>}

              { (!currentId && mayBeInvalidDocument) && <button type="button" className="btn btn-warning mx-2" onClick={() => onRegister()}>Continuar registro</button> }
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            currentTab === "lista-items" ? "show active" : ""
          }`}
          id="lista-items"
          role="tabpanel"
          aria-labelledby="lista-items-tab"
        >
          <div className="mt-3">
            <ListaItemsForm
              compraExternaId={currentId}
              tipoDocumentos={[...tipoDocumentos]}
              compraExterna={payload}
            />
          </div>

          <div className="col-12 d-flex justify-content-center mt-3">
            <button
              type="button"
              className="btn btn-secondary mx-2"
              onClick={() => onCancel()}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-info mx-2"
              onClick={() => onComplete()}
            >
              Registrar
            </button>
          </div>
        </div>
      </div>
      
    </>
  );
}
