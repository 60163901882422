import React from "react";

//React-Table
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import { BotonConfiguracion,BotonEditar } from "../Plantillas/Botones";

//Modales
import Modal from "../../componentes/Modal.js";

import EmpresasConfig from "../../componentes/EmpresasConfig.js"


function listEmpresas (ID, onClick){
        return(
            <div>
                
                {/* <td>{empresa.Ruc}</td>
                <td>{empresa.RazonSocial}</td>
                <td>{empresa.Direccion}</td>
                <td>{empresa.Telefono}</td>
                <td>{empresa.Ubigeo}</td>
                <td>{empresa.NombreComercial}</td>
                <td>{empresa.Sector}</td>
                <td>{empresa.Retenedor}</td>
                <td>{empresa.Percepcion}</td>
                <td>{empresa.Plan}</td> */}
                {/* <td> */}
                    <BotonEditar
                        to={"/empresas/edit/"+ID}
                        className="mr-2"
                    />
                    {/* Modales */}
                    <label>   </label>
                    <BotonConfiguracion
                    // type="button"
                     title="Funcionalidad"
                    onClick={onClick(ID)}
                    />
                    {/* <button type="button" title="Funcionalidad" className="btn btn-outline-info ml-1 mr-1" onClick={onClick(ID)}><i class="fas fa-cogs"></i></button> */}
                    {/* Fin Modales */}
                    {/* <a className="badge badge-light" href={"/empresas/config/"+empresa.ID}> 
                        Funcionalidad
                    </a> */}
                    {/* <a className="btn btn-outline-info ml-1 mr-1" title="Facturación Electrónica" href={"/empresas/configFacEl/"+ID}><i class="fas fa-file-contract"></i></a> */}
                {/* </td> */}
                {/* <td>{this.props.EstadoEmpresa(empresa.Estado, empresa.ID, empresa.RazonSocial)}</td> */}
            </div>
            )
}

class EmpresasTablaList extends React.Component {
    render() {
      return (
        <React.Fragment>
            {/* Inicio react table */}
            <ReactTable
              
            data={this.props.empresasFiltradas}
            filterable
            defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}
                columns={[
                    {
                    columns: [
                        {
                            Header: "Editar",
                            accessor: "Editar",
                            width:190,
                            filterable: false,
                            Cell: (f) => {
                                return (
                                    <div align="center">
                                        {listEmpresas(f.original.ID, this.props.handleOnClick)}
                                    </div>
                                );
                            },
                        },
                            {
                            Header: "RUC",
                            id:"Ruc",
                            width:120,
                            // headerClassName:'card-header bg-dark text-white',
                            accessor: d => d.Ruc,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["Ruc"] }),
                            filterAll: true
                        },
                        {
                            Header: "Razón Social",
                            id:"RazonSocial",
                            width:230,
                            // headerClassName:'card-header bg-dark text-white',
                            accessor: d => d.RazonSocial,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["RazonSocial"] }),
                            filterAll: true
                        },
                        {
                            Header: "Dirección",
                            id:"Direccion",
                            width:190,
                            // headerClassName:'card-header bg-dark text-white',
                            accessor: d => d.Direccion,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["Direccion"] }),
                            filterAll: true
                        },
                        {
                            Header: "Teléfono",
                            id:"Telefono",
                            width:150,
                            // headerClassName:'card-header bg-dark text-white',
                            accessor: d => d.Telefono,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["Telefono"] }),
                            filterAll: true
                        },
                        {
                            Header: "Ubigeo",
                            id:"Ubigeo",
                            width:90,
                            // headerClassName:'card-header bg-dark text-white',
                            accessor: d => d.Ubigeo,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["Ubigeo"] }),
                            filterAll: true
                        },
                        // {
                        //     Header: "Nombre Comercial",
                        //     id:"NombreComercial",
                        //     accessor: "NombreComercial",
                        //     width:150,
                            // headerClassName:'card-header bg-dark text-white',
                        //     accessor: d => d.NombreComercial,
                        //     filterMethod: (filter, rows) =>
                        //         matchSorter(rows, filter.value, { keys: ["NombreComercial"] }),
                        //     filterAll: true
                        // },
                        // {
                        //     Header: "Sector",
                        //     id:"Sector",
                        //     accessor: "Sector",
                        //     width:150,
                            // headerClassName:'card-header bg-dark text-white',
                        //     accessor: d => d.Sector,
                        //     filterMethod: (filter, rows) =>
                        //         matchSorter(rows, filter.value, { keys: ["Sector"] }),
                        //     filterAll: true
                        // },
                        // {
                        //     Header: "Retenedor",
                        //     id:"Retenedor",
                        //     accessor: "Retenedor",
                        //     width:100,
                            // headerClassName:'card-header bg-dark text-white',
                        //     accessor: d => d.Retenedor,
                        //     filterMethod: (filter, rows) =>
                        //         matchSorter(rows, filter.value, { keys: ["Retenedor"] }),
                        //     filterAll: true
                        // },
                        // {
                        //     Header: "Percepción",
                        //     id:"Percepcion",
                        //     accessor: "Percepcion",
                        //     width:100,
                            // headerClassName:'card-header bg-dark text-white',
                        //     accessor: d => d.Percepcion,
                        //     filterMethod: (filter, rows) =>
                        //         matchSorter(rows, filter.value, { keys: ["Percepcion"] }),
                        //     filterAll: true
                        // },
                        {
                            Header: "Plan Sunat",
                            id:"Plan",
                            width:190,
                            // headerClassName:'card-header bg-dark text-white',
                            accessor: d => d.Plan,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["Plan"] }),
                            filterAll: true
                        }
                                        ]
                                    },
                                ]}
                                 defaultPageSize={25}
                                //pageSize={10}
                                className="-striped -highlight"
                                // Text de paginación
                                previousText= 'Anterior'
                                nextText= 'Siguiente'
                                loadingText= 'Cargando...'
                                noDataText= 'No se encontraron registros'
                                pageText= 'Página'
                                ofText= 'de'
                                rowsText= 'filas'                        
                                pageSizeOptions= {[5, 10, 20, 25, 50, 100]}
                                
                                />


            {/* Fin react table */}
          {/* <div id="table" >
            <table className="table table-hover">
                <thead className="text-center bg-dark text-white">
                    <tr>
                        <th scope="col">Ruc</th>
                        <th scope="col">Razón Social</th>
                        <th scope="col">Dirección</th>
                        <th scope="col">Teléfono</th>
                        <th scope="col">Ubigeo</th>
                        <th scope="col">Nombre Comercial</th>
                        <th scope="col">Sector</th>
                        <th scope="col">Agente Retenedor</th>
                        <th scope="col">Agente Perceptor</th>
                        <th scope="col">Tipo Plan Sunat</th>
                        <th scope="col">Editar</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {listEmpresas(this.props.empresasFiltradas, this.props.handleOnClick)}
                </tbody>
            </table>
          </div> */}
          <Modal
            isOpen={this.props.statusModal}
            onClose={this.props.handleOnCloseModal}
            handleKeyUp={this.props.handleKeyUp}
            title="CONFIGURACION DE LA EMPRESA"
            >
            <EmpresasConfig
            ID = {this.props.ID}
            
            />

        </Modal>
        </React.Fragment>
      );
    }
  }
  export default EmpresasTablaList;
  