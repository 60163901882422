import {cfetch} from "../helpers/wrappers";
import {decimalAdjust} from "../Global";
import FpayQR from "../pages/RegistroVentas/images/qr_fpay.png"
import swal from "sweetalert";


export default class FpayService {
    constructor() {
        this.clientId = ""
        this.clientSecret = ""
        this.link = ""
        this.logFpay = this.getUserFpay()
    }

    async authToken() {
        const configuration = {
            headers: {
                "Content-Type": "application/json",
                "fp-flow-country": "pe",
                "Authorization": `Basic ${this.clientId}:${this.clientSecret}`,
            },
            url: `${this.link}/sso/oauth2/v2/token`,
            body: JSON.stringify({grant_type: "client_credentials"}),
            method: "POST"
        }
        const response = await cfetch(configuration)
        return response ? response.access_token : ""
    }

    async getUserFpay() {
        try {
            const response = await fetch(`/api/puntosVenta/fpay/user`);
            const usuario = await response.json();
            const {Client_id,Client_secret, Link} = usuario.user
            this.clientId = Client_id
            this.clientSecret = Client_secret
            this.link = Link
        } catch (error) {
            console.log({error})
        }
    }

    async intentionPay(token, detail) {
        const configuration = {
            headers: {
                "Authorization": `Bearer ${token}`, "Content-Type": "application/json", "fp-flow-country": "pe",
            },
            url: `${this.link}/checkout/payments`,
            body: JSON.stringify(this.payBodyBuilder(detail)),
            method: "POST"
        }
        const response = await cfetch(configuration)
        return response

    }

    async getQR(token, url) {
        const configuration = {
            headers: {
                "Authorization": `Bearer ${token}`, "Content-Type": "application/json", "fp-flow-country": "pe",
            }, method: "GET"
        }
        let qr = ""
        await fetch(url, configuration).then((response) => qr = response.text())
        return qr
    }

    async getImage(url) {
        try {
            const configuration = {
                method: "GET"
            }
            const response = await fetch(url, configuration)
            return response.text()
        } catch (e) {
            return FpayQR
        }

    }

    async getStatus(token, url) {
        const configuration = {
            headers: {
                "Authorization": `Bearer ${token}`, "Content-Type": "application/json", "fp-flow-country": "pe",
            }, url: url, method: "GET"
        }
        const response = await cfetch(configuration)
        return response ? response.state : ""
    }

    async reversedlIntetionPay(token, merchantId) {
        const configuration = {
            headers: {
                "Authorization": `Bearer ${token}`, "Content-Type": "application/json", "fp-flow-country": "pe",
            },
            url: `${this.link}/checkout/payments`,
            body: JSON.stringify(this.reversedBodyBuilder(merchantId)),
            method: "PUT"
        }
        await cfetch(configuration)
    }

    async refundIntentionPay(token, detail) {
        const {transaction, links} = detail
        const listLinks = this.getLinks(links)
        const url = listLinks.refund_method.href
        const configuration = {
            headers: {
                "Authorization": `Bearer ${token}`, "Content-Type": "application/json", "fp-flow-country": "pe",
            }, url: url, body: JSON.stringify(this.refusedBodyBuilder(transaction)), method: "POST"
        }
        const refund = await this.refund(configuration)
        const code = refund.gateway.resume.authorizations.code
        await this.updateAuthCode(transaction.merchant_unique_id, code)
        await this.updateStatus(transaction.merchant_unique_id, "ANULADO")


    }

    async refund(configuration) {
        let data = ""
        await cfetch(configuration).then((resp) => {

            if (resp.statusCode == 400 || resp.statusCode == 401 || resp.statusCode == 404 || resp.statusCode == 500) {
                swal({
                    title: 'Anulación de Comprobante Fallida', text: "", icon: 'warning'
                })
            } else {
                swal({
                    title: 'Anulación Exitosa',
                    text: "",
                    icon: 'success'
                })
            }
           data = resp
        })
        return data
    }


    payBodyBuilder(detail) {
        const itemsArray = detail.items.map(x => {
            //Todo: preguntar cetegoria
            return {
                sku: x.IdProducto,
                name: x.NombreProducto,
                description: x.descripcion,
                quantity: x.Cantidad,
                price: decimalAdjust("floor", x.PrecioVenta, -2),
                tax: 0,
                category: x.NombrePresentacion,
            };
        })
        const precio = decimalAdjust("floor", detail.total, -2)
        const PAY_BODY = {
            intent: "sale", payment_method: "WALLET_QR", pst_origen: "MI FACTURA PERU", transaction: {
                merchant_unique_id: detail.merchantId,
                purchase_order: detail.branchInfo.VentasFpay + 1, // envio orden, debe incrementarse
                description: "Compra en " + detail.branchInfo.NombreComercial,
                soft_descriptor: "WALLET-QR-PAYMENT",
                merchant_fantasy_name: "Fpay " + detail.branchInfo.NombreComercial,// nombre de negocio
                reconciliation_id: "JZYBGTCR",
                invoice_type: "BOLETA",
                terminal_id: detail.infoPay.terminal_id,
                store_id: detail.infoPay.store_id,
                channel: "WEB",
                on_behalf_of: detail.branchInfo.Codigo,
                invoice_number: "15607881042363421", // 01 fecha transanccion, y hora con segundos + codigo anexo
                item_list: {
                    shipping_method: "DIGITAL", items: itemsArray, shipping_address: {
                        line1: detail.branchInfo.Direccion,
                        city: detail.branchInfo.NombreUbigeo,
                        country_code: "PE",
                        phone: detail.branchInfo.Telefono,
                        type: "HOME_OR_WORK",
                        recipient_name: "Jhon Doe Son",
                    },
                },
                amount: {
                    currency: "PEN", total: precio, details: {
                        subtotal: precio, tax: 0, shipping: 0, shipping_discount: 0,
                    },
                },
            }, additional_attributes: {}, // redirect_urls: {
            //     return_url:
            //         "http://www.conntesol.org/wp-content/uploads/2018/03/PaymentSuccessful-1.png",
            //     cancel_url: "http://www.sclance.com/pngs/error-png/error_png_451017.jpg",
            // },
        }
        return PAY_BODY
    }

    reversedBodyBuilder(id) {
        const REFUSED_BODY = {
            "transaction": {
                "merchant_unique_id": id
            }
        }
        return REFUSED_BODY
    }

    refusedBodyBuilder(transaction) {
        const REFUSED_BODY = {
            "refunded_amount": transaction.amount.total,
            "refund_merchant_unique_id": transaction.merchant_unique_id,
            "operation_type": transaction.invoice_type,
            "items": transaction.item_list.items,
        }
        return REFUSED_BODY
    }

    getLinks(links) {
        let listLinks = {}
        links.forEach(x => listLinks[x.rel] = x)
        return listLinks
    }

    body_resgistro(data, info) {
        // let date = new Date(new Date().setDate(new Date().getDate()))
        // date = moment(date).format('YYYY-MM-DD');
        const body = {
            Correlativo: 0,
            TerminalId: data.infoPay.terminal_id,
            StoreId: data.infoPay.store_id,
            Estado: "SOLICITADO",
            Monto: data.total,
            IdCaja: data.branchInfo.IdCaja,
            MerchantId: data.merchantId,
            Info: JSON.stringify(info),
        }
        return body
    }

    async nuevoRegistroFpay(data, info) {
        await fetch("/api/ventas/new/fpay", {
            method: "POST", body: JSON.stringify(this.body_resgistro(data, info)), headers: {
                Accept: "application/json", "Content-Type": "application/json",
            },
        });
    }

    async updateStatus(merchantId, estado) {
       await fetch("/api/ventas/update/fpay", {
            method: "POST", body: JSON.stringify({merchantId, estado}), headers: {
                Accept: "application/json", "Content-Type": "application/json",
            },
        });
    }

    async updateAuthCode(merchantId, code) {
        await fetch("/api/ventas/update/fpay/auth_code", {
            method: "POST", body: JSON.stringify({merchantId, code}), headers: {
                Accept: "application/json", "Content-Type": "application/json",
            },
        });
    }

    async getFpayListCaja(IdCaja, IdTurno) {
        return await fetch(`/api/ventas/reporte/caja/ventas/fpay/${IdCaja}/${IdTurno}`);
    }
}

