import React from "react";
import moment from "moment";
import { decimalAdjust } from "../../Global";

export default class TablaPagoMovComponent extends React.Component {
    render() {
        const {
            pagos,
            onProcesar,
            onEliminar,
            readOnly,
            onChangeFields,
            isLoading,
            fechaEditable,
        } = this.props;

        return (
            <div className="container">
                <div className="card-container">
                    {pagos.map((p, i) => {
                        const isPending = p.estado === "PENDIENTE" && !readOnly;
                        const cardClass = isPending ? "card-danger" : "";

                        return (
                            <div key={`pago-${p.id}`} className={`card ${cardClass}`}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="caja-fondo-blanco-m-2">
                                                <div className="row">
                                                    <div className="col-12 col-sm-4 card-item">
                                                        <div className="card-item-label">Fecha</div>
                                                        <div className="card-item-value">
                                                            {Boolean(fechaEditable) ? (
                                                                <input
                                                                    name="fechaPago"
                                                                    type="date"
                                                                    onChange={(e) => onChangeFields(e, p)}
                                                                    value={moment(p.fechaPago).format("YYYY-MM-DD")}
                                                                />
                                                            ) : (
                                                                `${moment(p.fechaPago).format("DD/MM/YYYY")}`
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-4 card-item">
                                                        <div className="card-item-label">Cuota</div>
                                                        <div className="card-item-value">{i + 1}</div>
                                                    </div>
                                                    <div className="col-12 col-sm-4 card-item">
                                                        <div className="card-item-label">Monto cuota</div>
                                                        <div className="card-item-value">
                                                            {decimalAdjust("floor", p.monto, -2)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-item">
                                                    <div className="card-item-label">Total pagado</div>
                                                    <div className="card-item-value">
                                                        {isPending ? (
                                                            <input
                                                                className="custom-input"
                                                                name="totalPagado"
                                                                min={0}
                                                                onChange={(e) => onChangeFields(e, p)}
                                                                type="number"
                                                                value={p.totalPagado}
                                                            />
                                                        ) : (
                                                            p.totalPagado
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card-item caja-fondo-blanco-m-2">
                                                <div className="card-item-label">Banco/Descripción</div>
                                                <div className="card-item-value">
                                                    {isPending ? (
                                                        <textarea className="w-100"
                                                            name="observacion"
                                                            cols="30"
                                                            onChange={(e) => onChangeFields(e, p)}
                                                        >
                                                            {p.observacion}
                                                        </textarea>
                                                    ) : (
                                                        p.observacion && p.observacion !== "null" ? p.observacion : "-"
                                                    )}
                                                </div>
                                            </div>
                                            <div className="card-item caja-fondo-blanco-m-2">
                                                <div className="card-item-label">Núm. Movimiento</div>
                                                <div className="card-item-value">
                                                    {isPending ? (
                                                        <input
                                                            className="custom-input"
                                                            name="numMov"
                                                            type="text"
                                                            onChange={(e) => onChangeFields(e, p)}
                                                        />
                                                    ) : (
                                                        p.numMov && p.numMov !== "null" ? p.numMov : "-"
                                                    )}
                                                </div>
                                            </div>
                                            <div className="card-item caja-fondo-blanco-m-2">
                                                <div className="card-item-label">Total pendiente</div>
                                                <div className="card-item-value">
                                                    {decimalAdjust("floor", p.saldo, -2)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 card-footer d-flex justify-content-end">
                                        {isPending ? (
                                            <button
                                                className="custom-button btn-verde-mfp"
                                                disabled={isLoading}
                                                onClick={(e) => onProcesar(e, p)}
                                            >
                                                Procesar
                                            </button>
                                        ) : (
                                            !p.wasFromMov && (
                                                <button
                                                    className="custom-button danger-button"
                                                    disabled={isLoading}
                                                    onClick={(e) => onEliminar(e, p)}
                                                >
                                                    Eliminar
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
