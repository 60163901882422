import React from 'react';

import LinkButton from "../../componentes/LinkButton";
import {Link} from 'react-router-dom';
import PageLoading from '../PageLoading.js';
import jsPDF from "jspdf";
import VehiculosDeEmpresaList from './VehiculosDeEmpresaList';
import {BotonGuardarLetras, BotonAdd, BotonCancelar, BotonPDF, BotonExcel} from "../Plantillas/Botones";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Vehiculos extends React.Component {

    constructor() {
        super()
        this.state = {
            Estado: 'activo',
            checkEstado: true,
            dataVehiculos: {
                Vehiculos: []
            },
            return: "/vehiculos/edit/",
            loading: false,
            error: null,
        }
        this.handleSavePDF = this.handleSavePDF.bind(this);
    }

    componentDidMount() {
        this.obtenerVehiculos()
        // this.intervalo=setInterval(this.obtenerVehiculos,1500)
    }


    componentWillUnmount() {
        clearInterval(this.intervalo)
    }

    obtenerVehiculos = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/vehiculos/');
            const data = await response.json();
            this.setState({

                dataVehiculos: data,
                loading: true,
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }
    EstadoChange = (e) => {
        if (this.state.Estado == 'activo') {
            this.setState({
                Estado: 'inactivo',
                checkEstado: false
            });
        } else if (this.state.Estado == 'inactivo') {
            this.setState({
                Estado: 'activo',
                checkEstado: true
            });
        }
    }

    handleSavePDF(e) {
        e.preventDefault();
        let doc = new jsPDF({
            orientation: "landscape"
        });

        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();


        doc.setFontSize(12);
        doc.text("RELACION DE VEHÍCULOS", 110, 10);
        // console.log(this.state.data.GuiasRemision)

        doc.autoTable({
            body: this.state.dataVehiculos.Vehiculos,
            columns: [
                {header: 'MODELO', dataKey: 'Modelo'},
                {header: 'PLACA', dataKey: 'Placa'},
                {header: 'FECHA REVISIÓN TECNICA', dataKey: 'FechaRevisionTecnica'},
                {header: 'FECHA SOAT', dataKey: 'FechaSOAT'},
                {header: 'EMPRESA SOAT', dataKey: 'EmpresaSOAT'},
                {header: 'NÚMERO SOAT', dataKey: 'NumeroSOAT'},
                {header: 'ESTADO', dataKey: 'Estado'},
            ],
            startY: 20,
            margin: 5,
            styles: {
                overflow: "linebreak",
                lineWidth: 0.1,
                tableWidth: "auto",
                columnWidth: "auto",
                halign: "center"
            },
            theme: "plain"
        }).setFontSize(11);
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("relación_Vehiculos.pdf");
    }

    getDataExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: `RELACION DE VRHICULOS`,
                        width: {wpx: 80}
                    }
                ],
                data: []
            },
            {
                columns: [
                    {
                        title: `PLACA`,
                        width: {wpx: 150}
                    },
                    {
                        title: `MODELO`,
                        width: {wpx: 150}
                    },
                    {
                        title: `FECHA DE REVISION`,
                        width: {wpx: 150}
                    },
                    {
                        title: `FECHA SOAT`,
                        width: {wpx: 150}
                    },
                    {
                        title: `EMPRESA SOAT`,
                        width: {wpx: 80}
                    },
                    {
                        title: `NUMERO SOAT`,
                        width: {wpx: 150}
                    }
                ],
                data: dataSet.map(row => {
                    return [
                        {
                            value: row.Placa,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Modelo,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.FechaRevisionTecnica,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.FechaSOAT,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.EmpresaSOAT,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NumeroSOAT,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ];
    }

    render() {
        if (this.state.loading === false) {
            return <PageLoading/>;
        }


        // if(this.state.dataVehiculos.Vehiculos.length == 0){
        //     return (
        //         <div>
        //             <h4>No se encontraron registros...</h4>
        //             <Link className="btn btn-primary" to= "/vehiculos/add">Crear Nuevo Vehiculo</Link>
        //         </div>
        //     );
        // }else{
        const vehiculosFiltrados = this.state.dataVehiculos.Vehiculos.filter((vehiculo) => {
            return vehiculo.Estado.includes(this.state.Estado)
        })
        const dataExport = this.getDataExportExcel(vehiculosFiltrados)
        if (this.state.dataVehiculos.error) {
            return `Error: ${this.state.error.message}`;
        }
        return (
            <section className='ventas-fpay-section-vh cienvh'>
                <form className="d-flex justify-content-center">
                    <div className="container pt-5">
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                {/* <input id="buscar" type="text" placeholder="Buscar" value={this.state.buscar}
                                onChange={this.handleChange}/> */}
                                <div align="right" className="mr-4">
                                    {/* <label>AQUI FALTA REVISAR DONDE PONER EL ACCESO A ESTA VENTANA (QUE ES LA LISTA POR EMPRESA DEL USUARIO REGISTRADO)</label> */}

                                    {/* <LinkButton title="Nuevo Vehiculo" className="btn btn-outline-primary ml-1 mr-1" to= "/vehiculos/add"><i className="ml-1 fa fa-plus-square" ></i></LinkButton> */}
                                    {/* <button title="Guardar PDF" className="btn btn-outline-danger ml-1 mr-1" onClick={this.handleSavePDF}> <i className="fas fa-file-pdf" ></i></button> */}
                                </div>
                            </div>
                            <div className="almacenDiv">
                                <div className='container' align="right">
                                    <b>Activos</b>
                                    <label className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                                type="checkbox"
                                                name="Estado"
                                                className="toggle-state"
                                                onChange={this.EstadoChange}
                                                defaultChecked={this.state.Estado}
                                            />
                                            <div className="toggle-inner">
                                                <div className="indicator" />
                                            </div>
                                            <div className="active-bg" />
                                        </div>
                                    </label>
                                </div>
                                <div className='seccion-btn-verde-mfp pt-3'>
                                <BotonAdd
                                    title="Nuevo Vehiculo" className="btn btn-outline-primary btn-verde-mfp letra14" state={this.state} to={`/vehiculos/add`}
                                >Crear Vehículo</BotonAdd>
                                    <BotonPDF
                                        hidden={this.state.mostrarButtonAdd}
                                        title="Guardar PDF"
                                        onClick={this.handleSavePDF}
                                        className="ml-3"
                                    />
                                    <ExcelFile
                                        element={
                                            <BotonExcel
                                                hidden={this.state.mostrarButtonAdd}
                                                className="ml-3"
                                                type="button"
                                                title="Guardar Excel"
                                                style={{display: `${this.state.displayButton}`}}/>
                                        }
                                        filename={`Relacion_Vehiculos`}
                                    >
                                        <ExcelSheet

                                            dataSet={dataExport}
                                            name="VehiculosFile"
                                        >
                                        </ExcelSheet>
                                    </ExcelFile>
                                    {/* <select onChange={this.EstadoChange} className="btn btn-outline-dark dropdown-toggle m-5" aria-haspopup="true" aria-expanded="false">
                                                <option value="activo">Activos</option>
                                                <option value="inactivo">Inactivos</option>
                                            </select> */}
                                </div>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <VehiculosDeEmpresaList
                                state={this.state}
                                vehiculosFiltrados={vehiculosFiltrados}
                                RemoveVehiculo={this.RemoveVehiculo}
                            /></div>
                        {/* <div align ="right" >
                                <label>Estado de Vehículos</label>
                                    <select onChange={this.EstadoChange} className="btn btn-outline-dark dropdown-toggle m-5" aria-haspopup="true" aria-expanded="false">
                                        <option value="activo">Activos</option>
                                        <option value="inactivo">Inactivos</option>
                                    </select>
                                    </div> */}
                    </div>
                </form>
            </section>
        )
    }

    // }

}

export default Vehiculos;
