import React from 'react'

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,

} from "@react-pdf/renderer";
import {calcularVenta} from "../../../helpers/calcularVenta.js";

const Estilos = StyleSheet.create({
    page: {
        fontSize: 10,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 1,
        height: "10%"

    },
    emp_logo: {
        display: "flex",
        flexDirection: "column",
        borderColor: "#bfbfbf",
        borderWidth: 0,
        width: "20%",
        padding: 5
    },
    emp_info: {
        display: "flex",
        width: "79%",
        padding: 10,
        flexDirection: "col",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        textAlign: "center",
    },
    emp_info_title: {
        fontSize: "14",
        fontWeight: "800",
        borderWidth: 0,
        margin: 5
    },
    emp_info_detalle: {
        fontSize: "7",
        margin: 1
        // fontWeight:"800",
    },
    body: {
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 0,
        marginTop: 5,
        height: "70%"
    },
    body_prov: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        // width:"100%"
    },
    body_prov_info: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 3,
        width: "80%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
    },
    body_prov_info_c1: {
        display: "flex",
        flexDirection: "column",
        width: "60%",
    },
    body_prov_info_c2: {
        display: "flex",
        flexDirection: "column",
        width: "39%",
    },
    body_prov_oc: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
        width: "20%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
    },
    cell_etiqueta: {
        fontSize: 9,
        marginBottom: 2,
        fontWeight: "900",
        color: "#bfbfbf",
    },
    cell: {
        marginBottom: 2,
        color: "#58595d",
        fontSize: 9
    },
    fluidBody: {
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 0,
        marginTop: 5,
    },
    body_oc: {
        // display: "flex",
        // flexDirection: "row",
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        // width:"100%"
    },
    body_oc_info: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 3,
        width: "100%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
        marginTop: 5
    },
    body_oc_info_c1: {
        width: "35%",
    },
    body_oc_info_c2: {
        width: "35%",
    },
    body_oc_info_c3: {
        width: "30%",
    },

    body_detalle: {
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        borderWidth: 0,
        marginTop: 5
    },


    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,

    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#f0f0f0"
    },
    tableCol: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        color: "#58595d",
        // fontSize: 12,
        fontWeight: 500,
    },
    tableCell: {
        margin: "auto",
        margin: 5,
        color: "#58595d",
        fontSize: 9,
    },


    footer: {
        display: "flex",
        flexDirection: "column",
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 1,
        marginTop: 5,
        height: "20%"

    },

    footer_seccion_1: {
        display: "flex",
        flexDirection: "column",

    },
    footer_seccion_2: {
        display: "flex",
        flexDirection: "row",
        // alignContent:"space-between",
        justifyContent: "space-between",
        // alignItems: "center",
        // border: 1,
        // borderBottom: 1,
        padding: 5
    },
    footer_seccion_2_seccion: {
        textAlign: "center",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        fontSize: 8,
        width: "32%",
        height: 90,
        padding: 3
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

});


function calcularTotales(items, incIGV) {
    // debugger
    if (incIGV == "Si") {
        var lista = items.map(item => {
            const {
                Cantidad,
                PrecioReferencial,
            } = item
            return ({
                PrecioVenta: incIGV == "Si" ? parseFloat(PrecioReferencial) : 0,
                Tributos: [1],
                TasaISC: 0,
                Cantidad: parseFloat(Cantidad),
                Gratuito: 0,
                Descuento: 0,
                IdAfectacionIgv: 1
            })
        })
    } else {
        var lista = items.map(item => {
            const {
                Cantidad,
                PrecioReferencial,
            } = item
            return ({
                ValorUnitario: incIGV == "Si" ? 0 : parseFloat(PrecioReferencial),
                Tributos: [1],
                TasaISC: 0,
                Cantidad: parseFloat(Cantidad),
                Gratuito: 0,
                Descuento: 0,
                IdAfectacionIgv: 1
            })
        })
    }

    const detalle = {
        Items: lista
    }

    return calcularVenta(detalle)
}

const FIRST_PAGE_MAX_ITEMS = 24;
const OTHER_PAGE_MAX_ITEMS = 30;

function divideArray(inputArray) {
    let index = 0;
    let subArrays = [];

    if (inputArray.length > 0) {
        subArrays.push(inputArray.slice(index, index + FIRST_PAGE_MAX_ITEMS));
        index += FIRST_PAGE_MAX_ITEMS;
    }

    while (index < inputArray.length) {
        subArrays.push(inputArray.slice(index, index + OTHER_PAGE_MAX_ITEMS));
        index += OTHER_PAGE_MAX_ITEMS;
    }

    return subArrays;
}

function RptOC(props) {
    const detalles = props.detoc;
    
    const totalesOC = calcularTotales(props.detoc, props.caboc.IncluyeIGV);
    const v_SubTotal = totalesOC.Gravadas.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    );

    const v_IGV = totalesOC.IGV.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    );

    const v_Total = totalesOC.Total.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    );

    const urlImage = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${props.caboc.RucEmpresa}/Img/logo_${props.caboc.RucEmpresa}.${props.extImg}`;

    if (detalles.length > FIRST_PAGE_MAX_ITEMS) {
        const details = divideArray(detalles);
        const [first, ...rest] = details;
        
        return <Document>
            <Page size="A4" style={Estilos.page}>
                <View style={Estilos.header}>
                    <View style={Estilos.emp_logo}>
                        <Image source={urlImage}/>
                    </View>
                    <View style={Estilos.emp_info}>
                        <Text style={Estilos.emp_info_title}>{`Orden de Compra N°${props.caboc.Numero}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Principal:  ${props.caboc.NombreEmpresa} :: ${props.caboc.DireccionEmpresa}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.caboc.NombreSucursal} :: ${props.caboc.DireccionSucursal}`}</Text>
                    </View>
                </View>
                <View style={Estilos.fluidBody}>
                    <View style={Estilos.body_prov}>
                        <View style={Estilos.body_prov_info}>
                            <View style={Estilos.body_prov_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Proveedor:</Text>
                                <Text style={Estilos.cell}>{props.caboc.NombreProveedor}</Text>
                                <Text style={Estilos.cell_etiqueta}>Dirección:</Text>
                                <Text style={Estilos.cell}>{props.caboc.DireccionProveedor || '--'}</Text>
                                <Text style={Estilos.cell_etiqueta}>Teléfono:</Text>
                                <Text style={Estilos.cell}>{props.caboc.TelefonoProveedor || '--'}</Text>
                            </View>
                            <View style={Estilos.body_prov_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>RUC:</Text>
                                <Text style={Estilos.cell}>{props.caboc.RucProveedor}</Text>
                                <Text style={Estilos.cell_etiqueta}>Email:</Text>
                                <Text style={Estilos.cell}>{props.caboc.CorreoProveedor || '--'}</Text>
                            </View>
                        </View>
                        <View style={Estilos.body_prov_oc}>
                            <Text style={[Estilos.cell, {alignContent: 'center', fontSize: 11}]}>{`RUC`}</Text>
                            <Text style={[Estilos.cell, {
                                alignContent: 'center',
                                fontSize: 11
                            }]}>{`${props.caboc.RucEmpresa}`}</Text>
                            <Text style={[Estilos.cell, {alignContent: 'center'}]}>{`Orden Compra:`}</Text>
                            <Text style={[Estilos.cell, {alignContent: 'center'}]}>{`${props.caboc.Numero}`}</Text>
                        </View>
                    </View>
                    <View style={Estilos.body_oc}>
                        <View style={Estilos.body_oc_info}>
                            <View style={Estilos.body_oc_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Fecha Emisión:</Text>
                                <Text style={Estilos.cell}>{props.caboc.FechaEmision}</Text>
                                <Text style={Estilos.cell_etiqueta}>Fecha Entrega:</Text>
                                <Text style={Estilos.cell}>{props.caboc.FechaEntrega}</Text>
                            </View>
                            <View style={Estilos.body_oc_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>Moneda:</Text>
                                <Text style={Estilos.cell}>{props.caboc.NombreMoneda}</Text>
                                <Text style={Estilos.cell_etiqueta}>Tipo Cambio:</Text>
                                <Text style={Estilos.cell}>{props.caboc.TipoCambio}</Text>
                            </View>
                            <View style={Estilos.body_oc_info_c3}>
                                <Text style={Estilos.cell_etiqueta}>Forma de Pago:</Text>
                                <Text style={Estilos.cell}>{props.caboc.FormaPago}</Text>
                                <Text style={Estilos.cell_etiqueta}>Incluye IGV:</Text>
                                <Text style={Estilos.cell}>{props.caboc.IncluyeIGV}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{marginTop: 5}}><Text>Detalle de la OC</Text></View>
                    <View style={Estilos.body_detalle}>
                        <View style={Estilos.table}>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>N°</Text>
                                </View>
                                {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                <Text style={Estilos.tableCellHeader}>Código</Text>
                                </View> */}
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                </View>
                                {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                <Text style={Estilos.tableCellHeader}>UM</Text>
                                </View> */}
                                <View style={[Estilos.tableColHeader, {width: "62%", textAlign: "left"}]}>
                                    <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Precio ${props.caboc.SimboloMoneda}`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Total ${props.caboc.SimboloMoneda}`}</Text>
                                </View>
                            </View>
                            {
                                first.map((item, index) => {
                                    return (
                                        <View key={item.Correlativo} style={Estilos.tableRow}>
                                            <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                            </View>
                                            {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                        <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.Codigo}</Text>
                                        </View> */}
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                            </View>
                                            {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                        <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.NomUnidad}</Text>
                                        </View> */}
                                            <View style={[Estilos.tableCol, {width: "62%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.PrecioReferencial}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Total}</Text>
                                            </View>
                                        </View>
                                    )
                                })}
                        </View>
                    </View>
                </View>

                <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed/>
            </Page>

            { rest.map((d, page) => {
                return (
                    <Page size="A4" style={Estilos.page}>
                        <View style={Estilos.fluidBody}>
                            <View style={Estilos.body_detalle}>
                                <View style={Estilos.table}>
                                    <View style={Estilos.tableRow}>
                                        <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                            <Text style={Estilos.tableCellHeader}>N°</Text>
                                        </View>
                                        {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                        <Text style={Estilos.tableCellHeader}>Código</Text>
                                        </View> */}
                                        <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                            <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                        </View>
                                        <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                            <Text style={Estilos.tableCellHeader}>UM</Text>
                                        </View>
                                        <View style={[Estilos.tableColHeader, {width: "52%", textAlign: "left"}]}>
                                            <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                        </View>
                                        <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                            <Text style={Estilos.tableCellHeader}>{`Precio ${props.caboc.SimboloMoneda}`}</Text>
                                        </View>
                                        <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                            <Text style={Estilos.tableCellHeader}>{`Total ${props.caboc.SimboloMoneda}`}</Text>
                                        </View>
                                    </View>
                                    {
                                        d.map((item, index) => {
                                            return (
                                                <View key={item.Correlativo} style={Estilos.tableRow}>
                                                    <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                        <Text
                                                            style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                                    </View>
                                                    {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                                    <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.Codigo}</Text>
                                                </View> */}
                                                    <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                        <Text
                                                            style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                                    </View>
                                                    <View style={[Estilos.tableCol, {width:"10%"}]}>
                                                        <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.NomUnidad}</Text>
                                                    </View>
                                                    <View style={[Estilos.tableCol, {width: "52%"}]}>
                                                        <Text
                                                            style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                                    </View>
                                                    <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                        <Text
                                                            style={[Estilos.tableCell, {textAlign: "right"}]}>{item.PrecioReferencial}</Text>
                                                    </View>
                                                    <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                        <Text
                                                            style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Total}</Text>
                                                    </View>
                                                </View>
                                            )
                                        })
                                    }
                                    {
                                        (page + 2 > rest.length) &&
                                        <>
                                        <View style={Estilos.tableRow}>
                                            <View style={[Estilos.tableColHeader, {
                                                width: "88%",
                                                textAlign: "right",
                                                borderBottom: 0
                                            }]}>
                                                <Text
                                                    style={Estilos.tableCellHeader}>{`Sub Total ${props.caboc.SimboloMoneda}`}</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{v_SubTotal}</Text>
                                            </View>
                                        </View>
                                        <View style={Estilos.tableRow}>
                                            <View style={[Estilos.tableColHeader, {
                                                width: "88%",
                                                textAlign: "right",
                                                borderBottom: 0
                                            }]}>
                                                <Text style={Estilos.tableCellHeader}>{`IGV ${props.caboc.SimboloMoneda}`}</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{v_IGV}</Text>
                                            </View>
                                        </View>
                                        <View style={Estilos.tableRow}>
                                            <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{`Total ${props.caboc.SimboloMoneda}`}</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{v_Total}</Text>
                                            </View>
                                        </View>    
                                    </>
                                }
                                </View>
                            </View>
                        </View>

                        {
                            (page + 2 > rest.length) && <View style={Estilos.footer} fixed>
                                <View style={Estilos.footer_seccion_1}>
                                    <Text style={Estilos.cell_etiqueta}>Observaciones:</Text>
                                    <Text style={Estilos.cell}>{props.caboc.Glosa}</Text>
                                </View>
                                <View style={Estilos.footer_seccion_2}>
                                    <View style={Estilos.footer_seccion_2_seccion}><Text>Gestor de Compras:</Text></View>
                                    <View style={Estilos.footer_seccion_2_seccion}><Text>Aprobado Por:</Text></View>
                                    <View style={Estilos.footer_seccion_2_seccion}><Text>Área Almacén:</Text></View>
                                </View>
                            </View>
                        }

                        <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed/>
                    </Page>
                )
            })}
        </Document>
    }

    return (
        <Document>
            <Page style={Estilos.page}>
                <View style={Estilos.header}>
                    <View style={Estilos.emp_logo}>
                        <Image source={urlImage}/>
                    </View>
                    <View style={Estilos.emp_info}>
                        <Text style={Estilos.emp_info_title}>{`Orden de Compra N°${props.caboc.Numero}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Principal:  ${props.caboc.NombreEmpresa} :: ${props.caboc.DireccionEmpresa}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.caboc.NombreSucursal} :: ${props.caboc.DireccionSucursal}`}</Text>
                    </View>
                </View>
                <View style={Estilos.body}>
                    <View style={Estilos.body_prov}>
                        <View style={Estilos.body_prov_info}>
                            <View style={Estilos.body_prov_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Proveedor:</Text>
                                <Text style={Estilos.cell}>{props.caboc.NombreProveedor}</Text>
                                <Text style={Estilos.cell_etiqueta}>Dirección:</Text>
                                <Text style={Estilos.cell}>{props.caboc.DireccionProveedor || '--'}</Text>
                                <Text style={Estilos.cell_etiqueta}>Teléfono:</Text>
                                <Text style={Estilos.cell}>{props.caboc.TelefonoProveedor || '--'}</Text>
                            </View>
                            <View style={Estilos.body_prov_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>RUC:</Text>
                                <Text style={Estilos.cell}>{props.caboc.RucProveedor}</Text>
                                <Text style={Estilos.cell_etiqueta}>Email:</Text>
                                <Text style={Estilos.cell}>{props.caboc.CorreoProveedor || '--'}</Text>
                            </View>
                        </View>
                        <View style={Estilos.body_prov_oc}>
                            <Text style={[Estilos.cell, {alignContent: 'center', fontSize: 11}]}>{`RUC`}</Text>
                            <Text style={[Estilos.cell, {
                                alignContent: 'center',
                                fontSize: 11
                            }]}>{`${props.caboc.RucEmpresa}`}</Text>
                            <Text style={[Estilos.cell, {alignContent: 'center'}]}>{`Orden Compra:`}</Text>
                            <Text style={[Estilos.cell, {alignContent: 'center'}]}>{`${props.caboc.Numero}`}</Text>
                        </View>
                    </View>
                    <View style={Estilos.body_oc}>
                        <View style={Estilos.body_oc_info}>
                            <View style={Estilos.body_oc_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Fecha Emisión:</Text>
                                <Text style={Estilos.cell}>{props.caboc.FechaEmision}</Text>
                                <Text style={Estilos.cell_etiqueta}>Fecha Entrega:</Text>
                                <Text style={Estilos.cell}>{props.caboc.FechaEntrega}</Text>
                            </View>
                            <View style={Estilos.body_oc_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>Moneda:</Text>
                                <Text style={Estilos.cell}>{props.caboc.NombreMoneda}</Text>
                                <Text style={Estilos.cell_etiqueta}>Tipo Cambio:</Text>
                                <Text style={Estilos.cell}>{props.caboc.TipoCambio}</Text>
                            </View>
                            <View style={Estilos.body_oc_info_c3}>
                                <Text style={Estilos.cell_etiqueta}>Forma de Pago:</Text>
                                <Text style={Estilos.cell}>{props.caboc.FormaPago}</Text>
                                <Text style={Estilos.cell_etiqueta}>Incluye IGV:</Text>
                                <Text style={Estilos.cell}>{props.caboc.IncluyeIGV}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{marginTop: 5}}><Text>Detalle de la OC</Text></View>
                    <View style={Estilos.body_detalle}>
                        <View style={Estilos.table}>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>N°</Text>
                                </View>
                                {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                  <Text style={Estilos.tableCellHeader}>Código</Text>
                                </View> */}
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                </View>
                                <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                    <Text style={Estilos.tableCellHeader}>UM</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "52%", textAlign: "left"}]}>
                                    <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Precio ${props.caboc.SimboloMoneda}`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Total ${props.caboc.SimboloMoneda}`}</Text>
                                </View>
                            </View>
                            {
                                props.detoc.map((item) => {
                                    return (
                                        <View key={item.Correlativo} style={Estilos.tableRow}>
                                            <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                            </View>
                                            {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                          <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.Codigo}</Text>
                                        </View> */}
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width:"10%"}]}>
                                                <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.NomUnidad}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "52%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.PrecioReferencial}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Total}</Text>
                                            </View>
                                        </View>
                                    )
                                })}
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {
                                    width: "88%",
                                    textAlign: "right",
                                    borderBottom: 0
                                }]}>
                                    <Text
                                        style={Estilos.tableCellHeader}>{`Sub Total ${props.caboc.SimboloMoneda}`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{v_SubTotal}</Text>
                                </View>
                            </View>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {
                                    width: "88%",
                                    textAlign: "right",
                                    borderBottom: 0
                                }]}>
                                    <Text style={Estilos.tableCellHeader}>{`IGV ${props.caboc.SimboloMoneda}`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{v_IGV}</Text>
                                </View>
                            </View>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Total ${props.caboc.SimboloMoneda}`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{v_Total}</Text>
                                </View>
                            </View>

                        </View>
                    </View>
                </View>

                <View style={Estilos.footer} fixed>
                    <View style={Estilos.footer_seccion_1}>
                        <Text style={Estilos.cell_etiqueta}>Observaciones:</Text>
                        <Text style={Estilos.cell}>{props.caboc.Glosa}</Text>
                    </View>
                    <View style={Estilos.footer_seccion_2}>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Gestor de Compras:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Aprobado Por:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Área Almacén:</Text></View>
                    </View>
                </View>

                <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed/>
            </Page>

        </Document>

    )
}

export default RptOC;
