import React from "react";
import MovimientoAlmacenForm from "./MovimientoAlmacenForm"
import swal from "sweetalert";
import PageLoading from '../PageLoading';
import {
    calcularTotales, calcularTotalesDetMovAlmacen,
    decimalAdjust,
    EXONERADA,
    ID_TIPO_TRANSACCION,
    INAFECTA
} from "../../Global";
import {isNumber} from "../../helpers/utils";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import moment from "moment";
import _ from "lodash";

class MovimientoAlmacenEditar extends React.Component {

    constructor(props) {
        const idMovAlmacen = window.sessionStorage.getItem('idMovAlmacen');

        super(props)
        this.state = {
            loading: true,
            error: null,
            idMovAlmacen: 0,
            dataTipoMovimiento: [],
            dataTipoTransaccion: [],
            dataTipoDocumentoSunat: [],
            dataListaProductos: [],
            dataListaProveedores: [],
            dataDetalleMovimientoAlmacen: [],
            form: {
                accion: null,
                modalProveedorIsOpen: false,
                EsCompraLocal: true,
                IdProveedor: null,
                IdMovimientoAlmacen: null,
                NroMA: '',
                FechaActual: new Date(),
                FechaMovimiento: new Date(),
                IdTipoMovimientoAlmacen: null,
                IdTipoTransaccionAlmacen: null,
                IdTipoDocumentoSunat: null,
                NroDocumentoSunat: '',
                IdOrdenCompra: null,
                NroOrdenCompra: '',
                Estado: 'Sin Procesar',

                IdUnidad: null,
                IdPresentacion: null,
                IdProducto: null,
                IdLote: null,
                IdStock: null,

                id_SelectSearch: null,
                Precio: 0.00,
                Cantidad: 0.00,
                precioCostoIgv: 0.00,
                Flete: 0.00,
                FletePorProducto: 0.00,
                total: 0,
                Total: 0.00,
                SubTotal: 0.00,
                IGV: 0.00,

                vTotal: 0.00,
                vSubTotal: 0.00,
                vIGV: 0.00,

                nroSerie: "",
                totalExoneradas: 0,
                totalInafectas: 0,
                tipoCambio: 0,
                precioUnidadDolar: 0,
                precioIgvDolar: 0,
                tipoDoc: {},
                rucProveedor: ""
            },
            tipoDoc: '',
            prod: {},
            activeItems: !!idMovAlmacen,
            showPrecioSolIgv: false,
        }
        this.handleChangePrecioUnidadDolar = this.handleChangePrecioUnidadDolar.bind(this);
        this.handleChangeTipoCambio = this.handleChangeTipoCambio.bind(this);
        this.handleChangeTotal = this.handleChangeTotal.bind(this);
        this.handleChangePrecioDolarIgv = this.handleChangePrecioDolarIgv.bind(this);
        this.fetchDocs = this.fetchDocs.bind(this);
        this.buscarProveedorById = this.buscarProveedorById.bind(this);
        this.buscarProveedorByRuc = this.buscarProveedorByRuc.bind(this);
        this.asignaProveedor = this.asignaProveedor.bind(this);
        this.handleAddQuantityItem = this.handleAddQuantityItem.bind(this);
    }

    componentDidMount() {
        //console.log({propsEditar: this.props})
        this.inicio()
    }

    inicio = async () => {
        await this.listaTipoMovimiento()
        await this.listaProveedores();
        this.setTipoDoc();
        if (this.props.accion === 'Editar') {
            await this.traerMovimientoAlmacenPorId();
            await this.listaDetalleMovimientoAlmacen()
        }
    }

    listaTipoMovimiento = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch("/api/movimientosalmacen/tipomovimiento/list");
            const data = await response.json();
            if (data) {
                this.setState({
                    dataTipoMovimiento: data[1],
                    form: {
                        ...this.state.form,
                        IdTipoMovimientoAlmacen: data[1][0].IdTipoMovimientoAlmacen,
                    }
                });
                await this.fetchDocs(data[1][0].IdTipoMovimientoAlmacen)
            } else {
                console.log("data vacia")
            }
        } catch (error) {
            this.setState({loading: false, error: error});
        }

    }

    async fetchDocs(idTipoMov) {
        let res = await fetch(`/api/movimientosalmacen/tipo-docs/v2/${idTipoMov}/${this.props.turnoCaja.IdCaja}`)
        if (res.ok) {
            const data = await res.json()
            this.setState({
                dataTipoDocumentoSunat: data,
                form: {
                    ...this.state.form,
                    IdTipoDocumentoSunat: this.props.accion === "Editar" ? this.state.form.IdTipoDocumentoSunat : data[0].IdTipoDocumentoSunat,
                    tipoDoc: this.props.accion === "Editar" ? data.find(d => String(d.IdTipoDocumentoSunat) === String(this.state.form.IdTipoDocumentoSunat)) : data[0]
                }
            });
            await this.listaTipoTransaccion(this.props.accion === "Editar" ? this.state.form.IdTipoDocumentoSunat : data[0].IdTipoDocumentoSunat)
        }
    }

    listaTipoTransaccion = async (idTipoDoc) => {
        const idTipoMovAlmacen = this.state.form.IdTipoMovimientoAlmacen;
        try {
            const response = await fetch(`/api/movimientosalmacen/tipotransaccion/list/${idTipoDoc}?idTipoMovAlmacen=${idTipoMovAlmacen}`);
            const data = await response.json();
            if (data) {
                this.setState({
                    dataTipoTransaccion: data[1],
                    form: {
                        ...this.state.form,
                        IdTipoTransaccionAlmacen: data[1].length ? data[1][0].IdTipoTransaccionAlmacen : "",
                        EsCompraLocal: data[1].length && parseInt(data[1][0].IdTipoTransaccionAlmacen) === 2,
                        IdOrdenCompra: data[1].length && parseInt(data[1][0].IdTipoTransaccionAlmacen) === 2 && this.props.accion !== "Editar" ? this.state.form.IdOrdenCompra : null,
                        NroOrdenCompra: data[1].length && parseInt(data[1][0].IdTipoTransaccionAlmacen) === 2 && this.props.accion !== "Editar" ? this.state.form.NroOrdenCompra : null,
                    }
                });
            } else {
                console.log("data vacia")
            }
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    listaDetalleMovimientoAlmacen = async (id = null) => {
        this.setState({loading: true, error: null});
        let idAux = this.state.form.IdMovimientoAlmacen === null ?
            window.sessionStorage.getItem('idMovAlmacen') : this.state.form.IdMovimientoAlmacen;
        let idMov = id === null ? idAux : id;
        try {
            const response = await fetch(`/api/movimientosalmacen/detallemovimiento/list/${idMov || "0"}`);
            const data = await response.json();
            if (data) {
                this.setState({
                    loading: false,
                });
                await this.TributosInProductos(data[1])
            } else {
                console.log("data vacia")
            }
        } catch (error) {
            console.log("error ", error);
            this.setState({loading: false, error: error});
        }
    }

    TributosInProductos = async (detalle) => {
        let newDetalle = [...detalle];

        newDetalle.sort((a, b) => {
            if (a.IdDetalleMovimientosAlmacen <= b.IdDetalleMovimientosAlmacen)
                return 1;
            return 0;
        });

        for (let i = 0; i < newDetalle.length; i++)
            newDetalle[i].Tributos = JSON.parse(newDetalle[i].Tributos)

        this.setState({
            loading: false,
            dataDetalleMovimientoAlmacen: newDetalle
        })
        this.calcularTotales(newDetalle)
    }

    setTipoDoc = (idTipoDoc = null) => {
        const tipoDoc =
            idTipoDoc ? this.state.dataTipoDocumentoSunat.find(s => String(s.IdTipoDocumentoSunat) === String(idTipoDoc)) :
                this.state.dataTipoDocumentoSunat.find(s => String(s.IdTipoDocumentoSunat) === String(this.state.form.IdTipoDocumentoSunat))
        this.setState({tipoDoc: tipoDoc, prod: {}})
    }

    listaProveedores = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/proveedores/list/`);
            const data = await response.json();
            console.log({data})
            this.setState({
                loading: false,
                dataListaProveedores: data[1],
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    buscarProveedorById(IdProveedor){
        return this.state.dataListaProveedores.find( x => x.IdProveedor == IdProveedor)
    }
    buscarProveedorByRuc(ruc){
        return this.state.dataListaProveedores.find( x => x.Ruc == ruc)
    }

    asignaProveedor(ruc){
        const proveedor = this.buscarProveedorByRuc(ruc)
        //IdProveedor: parseInt(dataProveedor[0]),
        this.setState({
            form: {
                ...this.state.form,
                IdProveedor: proveedor.IdProveedor,
                rucProveedor: proveedor.Ruc
        }
        })
    }

    traerMovimientoAlmacenPorId = async () => {
        this.setState({loading: true, error: null});
        try {
            const savedId = window.sessionStorage.getItem('idMovAlmacen');
            let id = savedId ?
                savedId : this.props.itemmovimientoalmacen.idmovimientoalmacen;
            const response = await fetch(`/api/movimientosalmacen/item/${id}`);
            const obj = await response.json();
            let [serie, nroDoc] = this.extractSerieYDoc(obj[1][0].NroDocumentoSunat)
            const proveedor = this.buscarProveedorById(obj[1][0].IdProveedor)
            this.setState({
                form: {
                    ...this.state.form,
                    IdMovimientoAlmacen: obj[1][0].IdMovimientoAlmacen,
                    NroMA: obj[1][0].Numero,
                    FechaMovimiento: obj[1][0].FechaMovimiento,
                    IdTipoMovimientoAlmacen: obj[1][0].IdTipoMovimientoAlmacen,
                    IdAlmacen: obj[1][0].IdAlmacen,
                    IdTipoDocumentoSunat: obj[1][0].IdTipoDocumentoSunat,
                    NroDocumentoSunat: nroDoc,
                    IdOrdenCompra: obj[1][0].IdOrdenCompra,
                    NroOrdenCompra: obj[1][0].NroOrdenCompra,
                    Estado: obj[1][0].Estado,
                    IdProveedor: obj[1][0].IdProveedor,
                    Glosa: obj[1][0].Glosa,
                    SubTotal: obj[1][0].SubTotal,
                    IGV: obj[1][0].IGV,
                    Total: obj[1][0].Total,
                    nroSerie: serie,
                    tipoCambio: obj[1][0].tipoCambio,
                    rucProveedor: proveedor.Ruc
                }
            }, async () => {
                this.setTipoDoc(obj[1][0].IdTipoDocumentoSunat)
                await this.fetchDocs(obj[1][0].IdTipoMovimientoAlmacen)
            });


        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    extractSerieYDoc(nroDoc) {
        let i = nroDoc.lastIndexOf('-');
        if (i !== -1) {
            let numDoc = nroDoc.substr(i + 1, nroDoc.length);
            let serie = nroDoc.substr(0, i);
            return [serie, numDoc];
        }
        return ["", nroDoc];
    }

    handleSubmit = async (e) => {
        if (this.state.form.IdTipoMovimientoAlmacen &&
            this.state.form.IdTipoMovimientoAlmacen &&
            this.state.form.NroDocumentoSunat !== '' &&
            this.state.form.nroSerie !== "" && this.state.form.nroSerie.length &&
            this.isValidNroDocSunat(this.state.form.NroDocumentoSunat, this.state.form.nroSerie)
        ) {
            if (this.props.accion === 'Nuevo')
                await this.crearMovimientoAlmacen();
            else
                await this.actualizarMovimientoAlmacen();
        } else {
            const r = this.props.accion === 'Nuevo' ? "Crear" : "Actualizar";
            notificarError(`No se puede ${r} el registro de Movimiento de Almacén, información incompleta o errónea...`)
        }
    }

    isValidNroDocSunat(nroDoc, nroSerie) {
        let nroDocRegex = /[0-9a-fA-T]{4}$/g;
        let nroSerieRegex = /^[0-9]{1,7}$/;
        return (nroDocRegex.test(String(nroSerie)) && nroSerieRegex.test(String(nroDoc)));
    }

    crearMovimientoAlmacen = async () => {
        let infoSerie = this.state.form.tipoDoc.datosSerie ? this.state.form.tipoDoc.datosSerie.split(",") : null;

        window.sessionStorage.removeItem('idMovAlmacen')

        try {
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdMovimientoAlmacen: 1,
                    Numero: '',
                    FechaRegistro: moment(this.state.form.FechaActual).format('YYYY-MM-DD HH:mm'),
                    FechaMovimiento: `${moment(this.state.form.FechaMovimiento).format("YYYY-MM-DD")} ${moment(new Date()).format("HH:mm")}`,
                    Glosa: this.state.form.Glosa,
                    IdAlmacen: this.props.IdAlmacen,
                    IdTipoDocumentoSunat: this.state.form.IdTipoDocumentoSunat,
                    NroDocumentoSunat: `${this.state.form.nroSerie}-${this.state.form.NroDocumentoSunat}`,
                    IdOrdenCompra: this.state.form.IdOrdenCompra,
                    NroOrdenCompra: this.state.form.NroOrdenCompra,
                    Estado: this.state.form.Estado,
                    IdProveedor: this.state.form.IdProveedor,
                    tipoCambio: Number(this.state.form.tipoCambio),
                    idCce: infoSerie ? infoSerie[0] : infoSerie,
                    idTipoMov: this.state.form.IdTipoMovimientoAlmacen,
                    total: this.state.form.total
                })
            };
            const response = await fetch(`/api/movimientosalmacen/add/`, config);
            const rpta = await response.json();

            if (!('respuesta' in rpta)) {
                if (parseInt(rpta[0][0][0].estado) !== -1) {
                    window.sessionStorage.setItem('idMovAlmacen', rpta[0][0][0].estado);
                    this.setState({
                        activeItems: false,
                        form: {
                            ...this.state.form,
                            IdMovimientoAlmacen: rpta[0][0][0].estado,
                        },
                        idMovAlmacen: rpta[0][0][0].estado
                    })
                    await this.listaDetalleMovimientoAlmacen(rpta[0][0][0].estado)
                    await this.props.cambiarAccion('Editar');


                    notificarMsg("Movimiento de Almacen creado, registre su detalle...")

                    await this.props.listaMovimientosAlmacenPorAlmacen(this.props.IdAlmacen);

                } else {
                    notificarError("Error al Crear registro Movimiento Almacén...")
                }
            } else
                notificarError(rpta.respuesta)


        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    actualizarMovimientoAlmacen = async () => {
        try {
            const config = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdMovimientoAlmacen: this.state.form.IdMovimientoAlmacen,
                    FechaMovimiento: `${moment(this.state.form.FechaMovimiento).format("YYYY-MM-DD")} ${moment(new Date()).format("HH:mm")}`,
                    Glosa: this.state.form.Glosa,
                    IdAlmacen: this.props.IdAlmacen,
                    IdTipoDocumentoSunat: this.state.form.IdTipoDocumentoSunat,
                    NroDocumentoSunat: `${this.state.form.nroSerie}-${this.state.form.NroDocumentoSunat}`,
                    IdOrdenCompra: this.state.form.IdOrdenCompra,
                    NroOrdenCompra: this.state.form.NroOrdenCompra,
                    IdProveedor: this.state.form.IdProveedor,
                    tipoCambio: Number(this.state.form.tipoCambio),
                    idTipoMov: this.state.form.IdTipoMovimientoAlmacen
                })
            };
            let idMovAlmacen = this.state.form.IdMovimientoAlmacen;
            const res = await fetch(`/api/movimientosalmacen/update/${idMovAlmacen}`, config);
            const rpta = await res.json();

            if (rpta.respuesta) // actualizó correctamente
            {
                notificarMsg("Se Actualizó correctamente el Movimiento de Almacén...")
                await this.props.listaMovimientosAlmacenPorAlmacen(this.props.IdAlmacen);
            } else {
                notificarError(`Error al Actualizar el Movimiento de Almacèn: ${rpta.msg}`)
            }
            this.props.onClose();

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    handleChangeForm = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
        });
    }

    handleChangeFechaMovimiento = (date) => {
        const value = date.target.value
        this.setState({
            form: {
                ...this.state.form,
                FechaMovimiento: value,
            },
        });
    }

    handleChangeTipoMovimiento = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                IdTipoMovimientoAlmacen: parseInt(e.target.value),
            }
        });
        await this.fetchDocs(parseInt(e.target.value))
        this.setState({loading: false, error: null});
    }

    handleChangeTipoTransaccion = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                IdTipoTransaccionAlmacen: parseInt(e.target.value),
                EsCompraLocal: parseInt(e.target.value) === 2,
                IdOrdenCompra: parseInt(e.target.value) === 2 ? this.state.form.IdOrdenCompra : null,
                NroOrdenCompra: parseInt(e.target.value) === 2 ? this.state.form.NroOrdenCompra : null,
            }
        });
    }

    handleChangeTipoDocumentoSunat = async (e) => {
        const tipoDoc = this.state.dataTipoDocumentoSunat.find(t => String(t.IdTipoDocumentoSunat) === String(e.target.value));
        let infoSerie = tipoDoc.datosSerie ? tipoDoc.datosSerie.split(",") : null;

        let dataSerie = {
            serie: infoSerie ? infoSerie[1] : this.state.form.nroSerie,
            num: infoSerie ? Number(infoSerie[2]) + 1 : this.state.form.NroDocumentoSunat
        }

        this.setState({
            form: {
                ...this.state.form,
                IdTipoDocumentoSunat: parseInt(e.target.value),
                tipoDoc: tipoDoc,
                nroSerie: dataSerie.serie,
                NroDocumentoSunat: dataSerie.num
            }
        });

        this.listaTipoTransaccion(e.target.value)
        this.setTipoDoc(parseInt(e.target.value));
    }

    handleChangeProveedor = async (e) => {
        const idProveedorRuc = e.target.value;
        const dataProveedor = idProveedorRuc.split("-");
        this.setState({
            form: {
                ...this.state.form,
                IdProveedor: parseInt(dataProveedor[0]),
                rucProveedor: dataProveedor[1],
            }
        });
    }

    handleClickNuevoProveedor = (e) => {
        // e.preventDefault();
        this.setState({
            form: {
                ...this.state.form,
                modalProveedorIsOpen: true,
                accion: 'NuevoProveedor'
            }
        })

    }

    handleClickBuscarProveedor = () => {
        this.setState({
            form: {
                ...this.state.form,
                modalProveedorIsOpen: true,
                accion: 'BuscarProveedor'
            }
        })
    }

    handleCloseModalProv = () => {
        this.setState({
            form: {
                ...this.state.form,
                modalProveedorIsOpen: false,
            }
        })
    }

    AsignarValorDoc = (dataProveedor) => {
        this.listaProveedores().then(() => {
            this.setState({
                form: {
                    ...this.state.form,
                    IdProveedor: dataProveedor.IdProveedor
                },
            });
        })
    }

    handleChange_SelectSearch = (selectedOption) => {
        if (selectedOption)
            this.setState({
                form: {
                    ...this.state.form,
                    id_SelectSearch: selectedOption.value,
                    IdProducto: selectedOption.IdProducto,
                    Precio: selectedOption.PrecioCosto,
                    IdUnidad: selectedOption.IdUnidad,
                    IdPresentacion: selectedOption.IdPresentacion,
                    precioCostoIgv: selectedOption.precioCostoIgv,
                },
                prod: selectedOption,
                showPrecioSolIgv: String(selectedOption.afectacionFree) === "0"
            });
        else
            this.setState({prod: {}})
    }

    handleChangePrecio = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                Precio: e
            }
        });
    }

    handleChangeFlete = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                FletePorProducto: e
            }
        });
    }

    handleChangePrecioIgv = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                Precio: this.state.showPrecioSolIgv ? (e / 1.18) : 0
            }
        });
    }

    handleChangeCantidad = (e) => {

        this.setState({
            form: {
                ...this.state.form,
                Cantidad: e
            }
        });
    }

    handleEliminarItem = (reg) => {
        swal({
            title: 'Atención...! ',
            text: `Desea Eliminar el item ${reg.NomProducto}?`,
            icon: 'error',
            buttons: [
                'No',
                'Si'
            ],
        }).then((isConfirm) => {
            //debugger
            if (isConfirm) {
                this.eliminarItem(reg);
            }
        })

    }

    eliminarItem = async (reg) => {
        var id = reg.IdDetalleMovimientosAlmacen
        try {
            if (id) {
                const response = await fetch(`/api/movimientosalmacen/detallemovimiento/delete/${id}`, {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
                const rpta = await response.json();
                if (rpta) {
                    this.setState({activeItems: true})
                    await this.listaDetalleMovimientoAlmacen();
                    notificarMsg("Item eliminado correctamente...")
                }

            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }

    }

    async handleAddQuantityItem(detalle, cantidad) {
        if (!detalle.IdDetalleMovimientosAlmacen || isNaN(+cantidad)) {
            return;
        }

        const newQuantity = +detalle.Cantidad + +cantidad;

        if (newQuantity <= 0) {
            notificarError('La cantidad no puede ser igual o inferior a 0.');
            return;
        }

        try {
            const req = await fetch(`/api/movimientosalmacen/detallemovimiento/${detalle.IdDetalleMovimientosAlmacen}`, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Cantidad: newQuantity,
                    Total: decimalAdjust('floor', newQuantity * detalle.Precio, -2)
                })
            });

            if (!req.ok) {
                throw new Error("Ocurrió un error al actualizar el campo 'cantidad'");
            }

            this.setState({activeItems: true})
            await this.listaDetalleMovimientoAlmacen();
        } catch (e) {
            this.setState({
                loading: false,
                error: e
            });
        }
    }

    handleAgregarItem = async () => {
        const prod = this.state.prod;

        if (!this.state.form.IdProducto || _.isNull(prod)) {
            notificarError(`Elija un Producto...`)
        } else if (parseFloat(this.state.form.Cantidad) <= 0 || parseFloat(this.state.form.Precio) <= 0) {
            notificarError(`Verifique valores de Precio y Cantidad...`)
        } else {

            const precio = prod.IdGrupoAfectacionIGV === EXONERADA || prod.IdGrupoAfectacionIGV === INAFECTA ? this.state.form.Precio : this.state.form.Precio * 1.18;
            const precioDolar = prod.IdGrupoAfectacionIGV === EXONERADA || prod.IdGrupoAfectacionIGV === INAFECTA ? this.state.form.precioUnidadDolar : this.state.form.precioIgvDolar;
            const precioDolarSinIgv = prod.IdGrupoAfectacionIGV === EXONERADA || prod.IdGrupoAfectacionIGV === INAFECTA ? this.state.form.precioUnidadDolar : this.state.form.precioUnidadDolar;

            const isTotalZero = String(this.state.form.IdTipoTransaccionAlmacen) === String(ID_TIPO_TRANSACCION.ID_BONIFICACION)
                || String(this.state.form.IdTipoMovimientoAlmacen) === "2" || String(this.state.form.IdTipoDocumentoSunat) === "52";

            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdDetalleMovimientosAlmacen: "1",
                    Cantidad: this.state.form.Cantidad,
                    FechaCaducidad: null,
                    IdMovimientoAlmacen: this.state.form.IdMovimientoAlmacen,
                    IdProducto: this.state.form.IdProducto,
                    IdLote: this.state.form.IdLote,
                    IdPresentacion: this.state.form.IdPresentacion,
                    IdUnidad: this.state.form.IdUnidad,
                    IdStock: this.state.form.IdStock,
                    Precio: String(this.state.form.IdTipoTransaccionAlmacen) === String(ID_TIPO_TRANSACCION.ID_BONIFICACION) ? 0 : precio,
                    precioUnidadDolar: String(this.state.form.IdTipoTransaccionAlmacen) === String(ID_TIPO_TRANSACCION.ID_BONIFICACION) ? 0 : precioDolar,
                    precioUnidadDolarSinIgv: String(this.state.form.IdTipoTransaccionAlmacen) === String(ID_TIPO_TRANSACCION.ID_BONIFICACION) ? 0 : precioDolarSinIgv,
                    Total: isTotalZero ? 0 : ((this.state.form.Cantidad * precio) + (this.state.form.Cantidad * this.state.form.FletePorProducto) ).toFixed(6),
                    idTipoTransaccion: this.state.form.IdTipoTransaccionAlmacen,
                    Flete: this.state.form.Flete != null ? this.state.form.FletePorProducto: 0
                })
            };
            const response = await fetch(`/api/movimientosalmacen/detallemovimiento/add/`, config);
            const rpta = await response.json();

            if (response.ok) {
                if (rpta[0][0][0].estado === 'Insertado') {
                    this.setState({activeItems: true, prod: null})
                    await this.listaDetalleMovimientoAlmacen()
                    notificarMsg("Item agregado con éxito...")
                } else if (rpta[0][0][0].estado === 'Duplicado') {
                    notificarError(`El item elegido ya está en lista...`)
                }
            } else {
                notificarError(rpta.msg)
            }
        }
    };

    calcularTotales(items) {
        let lista = items.map(item => {
            const {
                Cantidad,
                Precio,
                Tributos,
                Flete
            } = item
            return ({
                ...item,
                PrecioVenta: Precio,
                Tributos,
                TasaISC: 0,
                Cantidad: Cantidad,
                Total: Number(decimalAdjust('floor', Cantidad * Precio, -2)),
                Flete: Flete,
                Gratuito: 0,
                Descuento: 0,
                IdAfectacionIgv: 1,
                codAfectIgv: item.codAfectIgv,
            })
        });
        const totalesMA = calcularTotalesDetMovAlmacen(lista);
        this.setState({
            form: {
                ...this.state.form,
                totalExoneradas: Number(Number(totalesMA.exonerados).toFixed(3)),
                totalInafectas: Number(Number(totalesMA.inafectos).toFixed(3)),
                vSubTotal: totalesMA.gravados,
                vIGV: decimalAdjust('floor', totalesMA.gravados * 0.18, -2),
                vTotal: totalesMA.baseConIgv,
                SubTotal: decimalAdjust('floor', totalesMA.gravados + totalesMA.inafectos + totalesMA.exonerados, -2),
                IGV: decimalAdjust('floor', totalesMA.gravados * 0.18, -2),
                Flete: decimalAdjust('floor', totalesMA.fletes, -2),
                Total: decimalAdjust('floor', totalesMA.total, -2),
            }
        })
    }

    handleChangeTipoCambio(e) {
        this.setState({
            form: {
                ...this.state.form,
                tipoCambio: isNumber(e.target.value) ? e.target.value : 0
            }
        })
    }

    handleChangeTotal(e) {
        this.setState({
            form: {
                ...this.state.form,
                total: isNumber(e.target.value) ? e.target.value : 0
            }
        })
    }

    handleChangePrecioUnidadDolar(e) {
        this.setState({
            form: {
                ...this.state.form,
                precioUnidadDolar: e,
                precioIgvDolar: e * 1.18,
                Precio: String(e) !== "0" ? e * this.state.form.tipoCambio : this.state.Precio
            }
        })
    }

    handleChangePrecioDolarIgv(e) {
        this.setState({
            form: {
                ...this.state.form,
                precioIgvDolar: this.state.showPrecioSolIgv ? e : 0,
                precioUnidadDolar: e / 1.18,
                Precio: String(e) !== "0" ? (e * this.state.form.tipoCambio) / 1.18 : this.state.Precio
            }
        });
    }

    render() {
        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }
        if (
            (this.state.loading === true && this.state.dataTipoMovimiento.length === 0) ||
            (this.state.loading === true && this.state.dataListaProductos.length === 0) ||
            (this.state.loading === true && this.state.dataTipoDocumentoSunat.length === 0) ||
            (this.state.loading === true && this.state.dataTipoTransaccion.length === 0) ||
            (this.state.loading === true && this.state.dataDetalleMovimientoAlmacen.length === 0)
        ) {
            return <div>
                <hr width="1024px"/>
                <PageLoading/>
            </div>
        }

        return (
            <div>
                <MovimientoAlmacenForm
                    accion={this.props.accion}
                    handleCloseModalMA={this.props.onClose}
                    dataTipoMovimiento={this.state.dataTipoMovimiento}
                    dataTipoTransaccion={this.state.dataTipoTransaccion}
                    dataTipoDocumentoSunat={this.state.dataTipoDocumentoSunat}
                    dataDetalleMovimientoAlmacen={this.state.dataDetalleMovimientoAlmacen}
                    dataListaProductos={this.state.dataListaProductos}
                    dataListaProveedores={this.state.dataListaProveedores}
                    form={this.state.form}
                    prod={this.state.prod}
                    handleChangeFechaMovimiento={this.handleChangeFechaMovimiento}
                    handleClickNuevoProveedor={this.handleClickNuevoProveedor}
                    handleClickBuscarProveedor={this.handleClickBuscarProveedor}
                    handleCloseModalProv={this.handleCloseModalProv}
                    AsignarValorDoc={this.AsignarValorDoc}
                    handleChangeTipoMovimiento={this.handleChangeTipoMovimiento}
                    handleChangeTipoTransaccion={this.handleChangeTipoTransaccion}
                    handleChangeTipoDocumentoSunat={this.handleChangeTipoDocumentoSunat}
                    handleChangePrecio={this.handleChangePrecio}
                    handleChangeFlete={this.handleChangeFlete}
                    handleChangePrecioIgv={this.handleChangePrecioIgv}
                    handleChangeCantidad={this.handleChangeCantidad}
                    handleAgregarItem={this.handleAgregarItem}
                    handleSubmit={this.handleSubmit}
                    handleChangeForm={this.handleChangeForm}
                    handleChange_SelectSearch={this.handleChange_SelectSearch}
                    handleEliminarItem={this.handleEliminarItem}
                    handleAddQuantityItem={this.handleAddQuantityItem}
                    handleChangeProveedor={this.handleChangeProveedor}
                    activeItems={this.state.activeItems}
                    showPrecioSolIgv={this.state.showPrecioSolIgv}
                    handleChangePrecioUnidadDolar={this.handleChangePrecioUnidadDolar}
                    handleChangeTipoCambio={this.handleChangeTipoCambio}
                    handleChangePrecioDolarIgv={this.handleChangePrecioDolarIgv}
                    handleChangeTotal={this.handleChangeTotal}
                    rucUsuario={this.props.rucUsuario}
                    asignaProveedor={this.asignaProveedor}
                />
            </div>
        )

    }
}

export default MovimientoAlmacenEditar
