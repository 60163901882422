import React from 'react'
import "./styles/Campos.css"

export const CampoInput = ({label, className, ...props}) => {
    return (
        <div className={`campo_input ${className}`}>
            <label className='w-100'>{label}
                <div><input className='w-100' {...props} /></div>
            </label>
        </div>
    )
}

export const CampoVer = ({label, className, value, ...props}) => {
    return (
        <div className={`campo_ver form-group ${className}`}>
            <label>{label}</label>
            <div {...props}>{value}</div>
        </div>
    )
}