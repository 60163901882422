import React from "react";
import {BotonAddCliente2, BotonSearchCliente2} from "../Plantillas/Botones";
import ModalCliente from '../clientes/ClienteModal';
import ClienteNuevo from "../clientes/ClientesNuevo";
import Modal from "../Modal";
import ModalBuscarCliente from "../Preventas/ModalBuscarCliente";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class ClientPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddClient: false,
            showSearchClient: false,
            doc: "",
            isLoading: false
        }
        this.onSelectClient = this.onSelectClient.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
    }

    componentDidMount() {
        this.fetchClient(this.props.defaultClient || "99999999")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.defaultClient !== this.props.defaultClient)
            this.fetchClient(this.props.defaultClient || "99999999")
    }

    onSelectClient(c) {
        this.setState({showAddClient: false, showSearchClient: false})
        let cli = {...c}
        if (Object.keys(cli).length) {
            cli.RazonSocial = cli.RazonSocial && cli.RazonSocial.length ? cli.RazonSocial : `${cli.PrimerNombre} ${cli.SegundoNombre} ${cli.ApellidoPaterno} ${cli.ApellidoMaterno}`
            this.props.onSelectClient(cli)
        }
    }

    onSearchChange(e) {
        const val = e.target.value;
        this.setState({
            doc: val
        })
        if (val.length === 8 || val.length === 11)
            this.fetchClient(val)
    }

    fetchClient(val) {
        pfetch({
            fetch: {
                url: `/api/preVentas/traerCliente/${val}`,
                method: "GET",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoading: true}),
            then: (d) => this.onSelectClient(d[0]),
            error: () => notificarError("No se pudo buscar al cliente"),
            finally: () => this.setState({isLoading: false})
        })
    }

    render() {
        return (
            <>
                <div className="row col-12 no-pad ml-0">
                    <div className="col-12 col-md-6 mb-3 d-flex flex-column justify-content-center botones-desk" style={{paddingLeft: "0px"}}>
                        <BotonAddCliente2
                            onClick={() => this.setState({ showAddClient: true })}
                            className="mr-2 botones-agregar-buscar"
                            title="Nuevo Cliente"
                            // style={{width: "75%"}}
                        />
                        <BotonSearchCliente2
                            className="mt-2 mr-2 botones-agregar-buscar"
                            onClick={() => this.setState({ showSearchClient: true })}
                            title="Buscar Cliente en la lista"
                            // style={{width: "75%"}}
                        />
                    </div>
                    <div className="row col-12 col-md-6 mb-3 d-flex justify-content-center botones-resp" style={{margin: "auto"}}>
                        <div className="col-12 no-pad">
                            <BotonAddCliente2
                                onClick={() => this.setState({ showAddClient: true })}
                                className="w-100"
                                title="Nuevo Cliente"
                                style={{height: "45px"}}
                            />
                        </div>
                        {/* <div className="col-12">
                            <BotonSearchCliente2
                                className="mt-3"
                                onClick={() => this.setState({ showSearchClient: true })}
                                title="Buscar Cliente en la lista"
                                style={{width: "260px", height: "45px"}}
                            />
                        </div> */}
                    </div>
                    <div className="col-12 col-md-6 no-pad">
                    <input
                        className="DocClientePreventa mr-2 borde-15"
                        style={{ width: "100%" }}
                        onChange={this.onSearchChange}
                        type="text"
                        value={this.state.doc}
                        placeholder="DNI/RUC"
                        disabled={this.state.isLoading}
                    />
                    </div>
                </div>    
                    <ModalCliente isOpen={this.state.showAddClient}
                        onClose={() => this.setState({ showAddClient: false })}
                        title="NUEVO CLIENTE">
                        <ClienteNuevo onClose={() => this.setState({ showAddClient: false })}
                            AsignarValorDoc={this.onSelectClient}
                        />
                    </ModalCliente>

                    <Modal title="BUSCAR CLIENTE" isOpen={this.state.showSearchClient}
                        onClose={() => this.setState({ showSearchClient: false })}>
                        <ModalBuscarCliente
                            GetCliente={this.onSelectClient}
                        />
                    </Modal>
                
            </>
        );
    }

}
