import React from "react";
import {isNumber, showErrorMsg} from "../../helpers/validators";
import {CREDITO, decimalAdjust} from "../../Global";
import TablaPagoMovComponent from "./TablaPagoMovComponent";

export default class FormPagoMov extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            payload: {},
            pagos: [...props.deuda.pagos],
            restante: props.deuda.saldo,
        }
        this.onChangeFields = this.onChangeFields.bind(this);
        this.onProcesarHanler = this.onProcesarHanler.bind(this);
        this.onAddPagosHandler = this.onAddPagosHandler.bind(this);
        this.onEliminarPagoHandler = this.onEliminarPagoHandler.bind(this);
        this.procesarPago = this.procesarPago.bind(this);
    }

    onChangeFields(e, pagoItem) {
        let pagos = [...this.state.pagos];
        let pago = pagos.find(p => p.id === pagoItem.id);
        pago[e.target.getAttribute('name')] = e.target.value;
        pago.saldo = pago.monto - pago.totalPagado
        this.setState({pagos: pagos})
    }

    async onProcesarHanler(e, p) {
        if (isNumber(p.totalPagado) && p.totalPagado > 0) {
            await this.procesarPago(p);
        } else
            showErrorMsg("El total pagado tiene que ser un número mayor que 0.")
    }

    async procesarPago(p) {
        let pagos = [...this.state.pagos];
        const i = pagos.indexOf(p);
        pagos[i].estado = await this.updatePago(p);
        this.setState({pagos: pagos, restante: this.state.restante - pagos[i].totalPagado});
    }

    async updatePago(p) {
        this.setState({isLoading: true})
        let res = await fetch(`/api/pago-movs/procesar-pago?idPago=${p.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                estado: 'PAGADO',
                totalPagado: p.totalPagado,
                observacion: p.observacion || "",
                numMov: p.numMov || "",
                fechaPago: p.fechaPago,
            })
        })
        let json = await res.json();
        this.setState({isLoading: false})
        return json.estado;
    }

    async onAddPagosHandler() {
        let pago = await this.addPago();
        let pagos = [...this.state.pagos];
        pagos.push(pago);
        this.setState({pagos: pagos});
    }

    async addPago() {
        this.setState({isLoading: true})
        let res = await fetch(`/api/pago-movs/add-pago`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idMov: this.props.deuda.IdMovimientoAlmacen,
                observacion: "",
                estado: "PENDIENTE",
                idTipoPago: CREDITO,
                monto: this.state.restante,
                wasFromMov: false
            })
        })
        let json = await res.json()
        this.setState({isLoading: false})
        return {...json.pago, saldo: json.pago.monto - json.pago.totalPagado};
    }

    async onEliminarPagoHandler(e, p) {
        let pagos = [...this.state.pagos]
        await this.deletePago(p.id)
        pagos.splice(pagos.indexOf(p), 1)
        this.setState({pagos: pagos})
    }

    async deletePago(idPago) {
        this.setState({isLoading: true})
        await fetch(`/api/pago-movs/delete-pago?idPago=${idPago}`, {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        this.setState({isLoading: false})
    }

    render() {
        const {pagos, restante} = this.state;

        if (this.state.isLoading)
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                    </div>
                </div>
            )
        return (
            <div>
                <div className="container mt-1 mb-1 d-flex justify-content-between">
                    <div className="">
                        Total restante: {decimalAdjust('floor', restante, -2)}
                    </div>
                    <div className="">
                        {Number(decimalAdjust('floor', restante, -2)) > 0 ? (
                            <button
                                onClick={this.onAddPagosHandler}
                                className="btn btn-verde-mfp">
                                Agregar
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <TablaPagoMovComponent pagos={pagos} onProcesar={this.onProcesarHanler}
                                               fechaEditable={true}
                                               readOnly={false}
                                               onChangeFields={this.onChangeFields}
                                               isLoading={this.state.isLoading}
                                               onEliminar={this.onEliminarPagoHandler}/>
                    </div>
                </div>

            </div>
        );
    }

}
