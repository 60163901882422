import React from "react";
import moment from "moment";
import {getApiDniStrategies} from "../../Global";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class AddTransportistaForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {
                Celular: "123123123",
                Telefono: "123123123",
                FechaIngreso: moment(new Date()).format("YYYY-MM-DD"),
                Estado: "activo",
                Direccion: "---------",
                Categoria: "A-I"
            },
            isLoading: false
        }
        this.searchPerson = this.searchPerson.bind(this)
        this.saveTransportista = this.saveTransportista.bind(this)
    }

    async searchPerson() {
        this.setState({isLoading: true})
        let dniObj = getApiDniStrategies()[1]
        let data;
        try {
            data = await dniObj.getData(this.state.payload.DNI, dniObj.url);
        } catch (e) {
            data = {}
        }

        this.setState({
            isLoading: false,
            payload: {
                ...this.state.payload,
                Nombres: data.nombres ? `${data.nombres[0] ? data.nombres[0] : ''} ${data.nombres[1] ? data.nombres[1] : ''}` : "no se encontró",
                Apellidos: data.apPaterno && data.apMaterno ? `${data.apPaterno ? data.apPaterno : ''} ${data.apMaterno ? data.apMaterno : ''}` : "no se encontró",
            }
        })
    }

    async saveTransportista() {
        this.setState({isLoading: true})
        let res = await fetch('/api/transportistas/add-express', {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(this.state.payload),
        })

        this.setState({isLoading: false})

        if (res.ok)
            this.props.onSaveTransportista(await res.json())
        else if (res.status === 422) {
            let msg = await res.json()
            notificarError(msg.msg)
        } else
            notificarError("Ha ocurrido un error")
    }

    render() {
        const {payload, isLoading} = this.state;

        return (
            <div className="container">

                <div className="mt-3">
                    <div className="form-group">
                        <label><strong>DNI</strong></label>
                        <input disabled={isLoading} type="text" className="form-control" value={payload.DNI}
                               onChange={e => {
                                   this.setState({
                                       payload: {
                                           ...payload,
                                           DNI: e.target.value,
                                           Correo: `${e.target.value}@test.com`,
                                       }
                                   })
                               }}/>
                    </div>
                    
                </div>
                <div className="seccion-btn-verde-mfp mt-3">
                        <button disabled={isLoading} onClick={this.searchPerson}
                                className="btn-verde-mfp btn btn-outline-secondary">
                            Buscar
                        </button>
                </div>              

                <div className="row mt-3">
                    <div className="col-sm">
                        <label><strong>Nombres:</strong> {payload.Nombres}</label>
                    </div>
                    <div className="col-sm">
                        <label><strong>Apellidos:</strong> {payload.Apellidos}</label>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm form-group">
                        <label><strong>Número de licencia:</strong></label>
                        <input type="text" className="form-control" value={payload.NumeroLicencia} onChange={e => {
                            this.setState({
                                payload: {
                                    ...payload,
                                    NumeroLicencia: e.target.value
                                }
                            })
                        }}/>
                    </div>

                    <div className="col-sm form-group">
                        <label><strong>Categoría:</strong></label>
                        <select id="Categoria" value={payload.Categoria} className="form-control"
                                onChange={e => {
                                    this.setState({
                                        payload: {
                                            ...payload,
                                            Categoria: e.target.value,
                                        }
                                    })
                                }}>
                            <option value="A-I" defaultValue>A-I</option>
                            <option value="A-IIa">A-IIa</option>
                            <option value="A-IIb">A-IIb</option>
                            <option value="A-IIIa">A-IIIa</option>
                            <option value="A-IIIb">A-IIIb</option>
                            <option value="A-IIIc">A-IIIc</option>
                            <option value="B-I">B-I</option>
                            <option value="B-IIa">B-IIa</option>
                            <option value="B-IIb">B-IIb</option>
                            <option value="B-IIc">B-IIc</option>
                        </select>
                    </div>
                </div>

                <div className="mt-3 mb-3">
                    <div className="seccion-btn-verde-mfp">
                        <button onClick={this.saveTransportista} disabled={isLoading}
                                className="btn-verde-mfp btn btn-outline-primary">Registrar
                        </button>
                    </div>
                </div>

            </div>
        )
    }

}
