import React, { useState, useEffect } from "react";
import { decimalAdjust, calcularTotales } from "../../../Global";
import { defaultPostHeaders } from "../../../helpers/wrappers";
import { notificarError, notificarMsg } from "../../Almacenes/AlmacenNotify";
import moment from "moment";
import { BotonCancelarLetras } from "../../Plantillas/Botones";
import ReactTable from "react-table";
import GananciaIndicator from "../GananciaIndicator";

export default function (props) {
  const [observacion, setObservacion] = useState("");
  const [venta, setVenta] = useState(undefined);
  const [fechaEntrega, setFechaEntrega] = useState(
    moment(new Date()).add(7, "days").format("YYYY-MM-DD")
  );
  const [escalaGanancia, setEscalaGanancia] = useState(null);

  useEffect(() => {
    fetchEscala();
  }, [])

  const fetchEscala = async () => {
    try {
      const req = await fetch(`/api/nota-pedidos/escala`);

      if (!req.ok) {
        throw new Error('Error al obtener la escala');
      }

      setEscalaGanancia(await req.json());
    } catch (e) {
      notificarError('Error al obtener configuración para precios. Intente nuevamente');
      props.onCancel();
    }
  }

  const saveNotaPedido = async () => {
    const totales = calcularTotales(props.items);

    const payload = {
      detalles: props.items.map((item) => ({
        IdPresentacion: item.IdPresentacion,
        IdProducto: item.IdProducto,
        EsFacturable: item.EsFacturable,
        ValorUnitario: item.ValorUnitario,
        Gratuito: item.Gratuito,
        Descuento: item.Descuento,
        IdAfectacionIgv: item.IdAfectacionIgv,
        IdStock: item.IdStock,
        IdTipoStock: item.IdTipoStock,
        Precio: item.Precio,
        TipoCambio: item.TipoCambio,
        IdAlmacen: item.IdAlmacen,
        PrecioReferencial: item.PrecioReferencial,
        PrecioVenta: item.PrecioVenta,
        Cantidad: item.Cantidad,
        Total: item.Total,
      })),
      IdTipoDocumentoSunat: 1,
      IdCliente: props.client.IdCliente,
      Total: totales.total,
    };

    try {
      const req = await fetch("/api/nota-pedidos/", {
        headers: { ...defaultPostHeaders() },
        method: "POST",
        body: JSON.stringify(payload),
      });

      if (!req.ok) {
        throw new Error("Ocurrio un error al registrar");
      }

      return await req.json();
    } catch (e) {
      notificarError(e.message);
    }
  };

  
  const save = async () => {
    try {
      const { IdNotaPedido } = await saveNotaPedido();
      notificarMsg("La nota de pedido se registró correctamente.");
      props.onPedidoRegistered(IdNotaPedido);
    } catch (e) {
      notificarError("La nota de pedido no se ha registrado. Intentelo otra vez.");
      console.error(e);
    }
  };

  if (!escalaGanancia) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
            <ReactTable
                data={props.items}
                disabled={false}
                columns={[
                    {
                        Header: "Producto",
                        accessor: "descripcion",
                    },
                    {
                        Header: "Cantidad",
                        accessor: "Cantidad",
                        Cell: row => <div style={{textAlign: "center"}}>{row.value}</div>,
                        width: 200,
                    },
                    {
                        Header: "Total",
                        accessor: "Total",
                        Cell: row => <div style={{textAlign: "center"}}>{row.value}</div>,
                        width: 200,
                    },
                    {
                        Header: "",
                        accessor: "Total",
                        width: 100,
                        Cell: row => <GananciaIndicator escalaGanancia={escalaGanancia} items={[row.original]}/>
                    },
                ]}
                defaultPageSize={20}
                showPagination={true}
                style={{
                    height: "200px",
                    width: "100%",
                    fontSize: "13px"
                }}
                className="-striped -highlight"
            />
        </div>
        <div className="col-12 my-3">
          <div class="form-group">
            <label for="observacion">Observación</label>
            <textarea
              class="form-control"
              id="observacion"
              rows="4"
              value={observacion}
              disabled={!!venta}
              onChange={(e) => setObservacion(e.target.value)}
            ></textarea>
          </div>

          <div className="form-group d-none">
            <label for="observacion">Fecha de entrega</label>
            <input
              type="date"
              value={moment(fechaEntrega).format("YYYY-MM-DD")}
              className="form-control"
              onChange={(e) => setFechaEntrega(e.target.value)}
            />
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-outline-primary btn-verde-mfp btn-block"
        onClick={save}
      >
        Registrar
      </button>

      <BotonCancelarLetras
        onClick={() => props.onCancel()}
        className="mb-2 mt-2 btn-block"
      />
    </>
  );
}
