import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/es';
import "../styles/ListaMovimientos.css"
import {registroMovCajaPDF} from "../Caja/printDataCaja";

class ListMovimientos extends React.Component {
    constructor(props) {
        super(props);

    this.imprimirPDF = this.imprimirPDF.bind(this);
    }

    async imprimirPDF(obj) {
        const data = {idTurno: this.props.idTurno, data: {...obj, TipoMovimiento: obj.Tipo}}
        await registroMovCajaPDF(data);
    }
    render() {
        let verTipoMov = this.props.verTipoMov === undefined || this.props.verTipoMov === null ? true : Boolean(this.props.verTipoMov);
        return (
            <React.Fragment>
                <div id="tableProdPreventa" className="lista_productos" style={{minHeight: "auto", width: "100%"}}>
                    <div className="lista_productos__item">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>Día</th>
                                <th>Hora</th>
                                <th hidden={!verTipoMov}>Tipo</th>
                                <th>Monto</th>
                                <th>Observacion</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.movs.map((obj) => {
                                    return (
                                        <tr className="prodPreventaTr" key={obj.IdMovimientoCaja}>
                                            <td><Moment locale="es" format="DD-MMM-YYYY">{obj.FechaHora}</Moment>
                                            </td>
                                            <td><Moment locale="es-ES" format="h:mm:ss a">{obj.FechaHora}</Moment>
                                            </td>
                                            {verTipoMov ? (<td>{obj.Tipo}</td>) : null}
                                            <td>S/. {obj.Monto}</td>
                                            <td>{obj.Observacion}</td>
                                            <td>
                                                <button type={"button"} onClick={() =>{this.imprimirPDF(obj).then()}} className={"btn btn-info"}><i className="far fa-file-pdf"></i>
                                                </button>
                                            </td>

                                        </tr>)
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ListMovimientos;
