import React from 'react'

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";
import {
    calcularVentaDetalleMovPDF
} from "../../../helpers/calcularVenta.js";

const Estilos = StyleSheet.create({
    page: {
        fontSize: 10,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 1,
        height: "10%"

    },
    emp_logo: {
        display: "flex",
        flexDirection: "column",
        borderColor: "#bfbfbf",
        borderWidth: 0,
        width: "20%",
        padding: 5
    },
    emp_info: {
        display: "flex",
        width: "79%",
        padding: 10,
        flexDirection: "col",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        textAlign: "center",
    },
    emp_info_title: {
        fontSize: "14",
        fontWeight: "800",
        borderWidth: 0,
        margin: 5
    },
    emp_info_detalle: {
        fontSize: "7",
        margin: 1
        // fontWeight:"800",
    },
    body: {
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 0,
        marginTop: 5,
        height: "70%"
    },
    fluidBody: {
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 0,
        marginTop: 5,
    },
    body_prov: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        // width:"100%"
    },
    body_prov_info: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 3,
        width: "80%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
    },
    body_prov_info_c1: {
        display: "flex",
        flexDirection: "column",
        width: "60%",
    },
    body_prov_info_c2: {
        display: "flex",
        flexDirection: "column",
        width: "39%",
    },
    body_prov_oc: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
        width: "20%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
    },
    cell_etiqueta: {
        fontSize: 9,
        marginBottom: 2,
        fontWeight: "900",
        color: "#bfbfbf",
    },
    cell: {
        marginBottom: 2,
        color: "#58595d",
        fontSize: 9
    },

    body_oc: {
        // display: "flex",
        // flexDirection: "row",
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        // width:"100%"
    },
    body_oc_info: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 3,
        width: "100%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
        marginTop: 5
    },
    body_oc_info_c1: {
        width: "35%",
    },
    body_oc_info_c2: {
        width: "35%",
    },
    body_oc_info_c3: {
        width: "30%",
    },

    body_detalle: {
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        borderWidth: 0,
        marginTop: 5
    },


    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,

    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#f0f0f0"
    },
    tableCol: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        color: "#58595d",
        // fontSize: 12,
        fontWeight: 500,
    },
    tableCell: {
        margin: "auto",
        margin: 5,
        color: "#58595d",
        fontSize: 9,
    },


    footer: {
        display: "flex",
        flexDirection: "column",
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 1,
        marginTop: 5,
        height: "20%"

    },

    footer_seccion_1: {
        display: "flex",
        flexDirection: "column",

    },
    footer_seccion_2: {
        display: "flex",
        flexDirection: "row",
        // alignContent:"space-between",
        justifyContent: "space-between",
        // alignItems: "center",
        // border: 1,
        // borderBottom: 1,
        padding: 5
    },
    footer_seccion_2_seccion: {
        textAlign: "center",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        fontSize: 8,
        width: "32%",
        height: 90,
        padding: 3
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

});


function calcularTotales(items) {
    // debugger
    console.log(items)
    var lista = items.map(item => {

        const {
            Cantidad,
            Precio,
            Flete
        } = item
        return ({
            PrecioVenta: parseFloat(Precio),
            Tributos: [1],
            TasaISC: 0,
            Cantidad: parseFloat(Cantidad),
            Gratuito: 0,
            Descuento: 0,
            IdAfectacionIgv: 1,
            Flete: parseFloat(Flete),
        })
    })

    const detalle = {
        Items: lista
    }

    return calcularVentaDetalleMovPDF(detalle)
}

const FIRST_PAGE_MAX_ITEMS = 22;
const OTHER_PAGE_MAX_ITEMS = 32;

function divideArray(inputArray) {
    let index = 0;
    let subArrays = [];

    if (inputArray.length > 0) {
        subArrays.push(inputArray.slice(index, index + FIRST_PAGE_MAX_ITEMS));
        index += FIRST_PAGE_MAX_ITEMS;
    }

    while (index < inputArray.length) {
        subArrays.push(inputArray.slice(index, index + OTHER_PAGE_MAX_ITEMS));
        index += OTHER_PAGE_MAX_ITEMS;
    }

    return subArrays;
}

function RptMA(props) {
    const totalesMA = calcularTotales(props.detma);
    console.log({totalesMA})
    const v_SubTotal = totalesMA.Gravadas.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    );

    const v_IGV = totalesMA.IGV.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    );

    const v_Flete = totalesMA.Flete.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    );
    const v_Total = totalesMA.Total.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    );
    const urlImage = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${props.cabma.RucEmpresa}/Img/logo_${props.cabma.RucEmpresa}.${props.extImg}`;
    const isNota = props.cabma.IdTipoDocumentoSunat == 52 || props.cabma.IdTipoDocumentoSunat == 53;
    return isNota?
        ReporteNotaIngresoEgreso(props,urlImage) : ReporteMovimientoAlmacen(props,urlImage,v_SubTotal,v_IGV,v_Flete,v_Total)

}

function  ReporteNotaIngresoEgreso(props, urlImage){
    const detalles = props.detma;

    if (detalles.length > FIRST_PAGE_MAX_ITEMS) {
        const details = divideArray(detalles);

        const [first, ...rest] = details;

        return <Document>
            <Page style={Estilos.page}>
                <View style={Estilos.header}>
                    <View style={Estilos.emp_logo}>
                        <Image src={urlImage}/>
                    </View>
                    <View style={Estilos.emp_info}>
                        <Text
                            style={Estilos.emp_info_title}>{`Movimiento de Almacén N° ${props.cabma.Numero}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Principal:  ${props.cabma.NombreEmpresa} :: ${props.cabma.DireccionEmpresa}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.cabma.NombreSucursal} :: ${props.cabma.DireccionSucursal}`}</Text>
                    </View>
                </View>
                <View style={Estilos.fluidBody}>
                    <View style={{marginTop: 5}}><Text>Datos Generales</Text></View>
                    <View style={Estilos.body_oc}>
                        <View style={Estilos.body_oc_info}>
                            <View style={Estilos.body_oc_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Fecha Movimiento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.FechaMovimiento}</Text>
                                <Text style={Estilos.cell_etiqueta}>Documento Asociado:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NomTipoDocumentoSunat}</Text>
                            </View>
                            <View style={Estilos.body_oc_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>Tipo Movimiento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NomTipoMovimiento}</Text>
                                <Text style={Estilos.cell_etiqueta}>Nro Documento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NroDocumentoSunat}</Text>
                            </View>
                            {props.cabma.NroOrdenCompra ? (
                                <View style={Estilos.body_oc_info_c3}>
                                    <Text style={Estilos.cell_etiqueta}>Nro Orden Compra:</Text>
                                    <Text style={Estilos.cell}>{props.cabma.NroOrdenCompra}</Text>
                                </View>) : null
                            }
                        </View>
                    </View>
                    <View style={{marginTop: 5}}><Text>Detalle Movimiento</Text></View>
                    <View style={Estilos.body_detalle}>
                        <View style={Estilos.table}>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>N°</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "84%", textAlign: "left"}]}>
                                    <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                </View>
                            </View>
                            {
                                first.map((item) => {
                                    return (
                                        <View key={item.Correlativo} style={Estilos.tableRow}>
                                            <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "84%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                </View>
            </Page>

            { rest.map((d, index) => {
                return (
                    <Page size="A4" style={Estilos.page}>
                        <View style={Estilos.fluidBody}>
                            <View style={Estilos.body_detalle}>
                                <View style={Estilos.table}>
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                        <Text style={Estilos.tableCellHeader}>N°</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                        <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "84%", textAlign: "left"}]}>
                                        <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                    </View>
                                </View>
                                {
                                    d.map((item) => {
                                        return (
                                            <View key={item.Correlativo} style={Estilos.tableRow}>
                                                <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "84%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                                </View>
                                            </View>
                                        )
                                    })}
                                </View>
                            </View>
                        </View>

            <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                `${pageNumber} / ${totalPages}`
            )} fixed/>
                    </Page>
                )
            })}
        </Document>
    }

    return (
        <Document>
            <Page style={Estilos.page}>
                <View style={Estilos.header}>
                    <View style={Estilos.emp_logo}>
                        <Image src={urlImage}/>
                    </View>
                    <View style={Estilos.emp_info}>
                        <Text
                            style={Estilos.emp_info_title}>{`Movimiento de Almacén N° ${props.cabma.Numero}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Principal:  ${props.cabma.NombreEmpresa} :: ${props.cabma.DireccionEmpresa}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.cabma.NombreSucursal} :: ${props.cabma.DireccionSucursal}`}</Text>
                    </View>
                </View>
                <View style={Estilos.body}>
                    <View style={{marginTop: 5}}><Text>Datos Generales</Text></View>
                    <View style={Estilos.body_oc}>
                        <View style={Estilos.body_oc_info}>
                            <View style={Estilos.body_oc_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Fecha Movimiento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.FechaMovimiento}</Text>
                                <Text style={Estilos.cell_etiqueta}>Documento Asociado:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NomTipoDocumentoSunat}</Text>
                            </View>
                            <View style={Estilos.body_oc_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>Tipo Movimiento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NomTipoMovimiento}</Text>
                                <Text style={Estilos.cell_etiqueta}>Nro Documento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NroDocumentoSunat}</Text>
                            </View>
                            {props.cabma.NroOrdenCompra ? (
                                <View style={Estilos.body_oc_info_c3}>
                                    <Text style={Estilos.cell_etiqueta}>Nro Orden Compra:</Text>
                                    <Text style={Estilos.cell}>{props.cabma.NroOrdenCompra}</Text>
                                </View>) : null
                            }
                        </View>
                    </View>
                    <View style={{marginTop: 5}}><Text>Detalle Movimiento</Text></View>
                    <View style={Estilos.body_detalle}>
                        <View style={Estilos.table}>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>N°</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "84%", textAlign: "left"}]}>
                                    <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                </View>
                            </View>
                            {
                                detalles.map((item) => {
                                    return (
                                        <View key={item.Correlativo} style={Estilos.tableRow}>
                                            <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "84%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                </View>

                <View style={Estilos.footer} fixed>
                    <View style={Estilos.footer_seccion_1}>
                        <Text style={Estilos.cell_etiqueta}>Observaciones:</Text>
                        <Text style={Estilos.cell}>{props.cabma.Glosa}</Text>
                    </View>
                    <View style={Estilos.footer_seccion_2}>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Aprobado Por:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Entregado Por:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Recibido por :</Text></View>
                    </View>
                </View>

                <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed/>
            </Page>
        </Document>
    )
}

function  ReporteMovimientoAlmacen(props,urlImage,v_SubTotal,v_IGV,v_Flete,v_Total){
    const detalles = props.detma;

    if (detalles.length > FIRST_PAGE_MAX_ITEMS) {    
        const details = divideArray(detalles);

        const [first, ...rest] = details;

        return (
            <Document>
                <Page size="A4" style={Estilos.page}>
                    <View style={Estilos.header}>
                        <View style={Estilos.emp_logo}>
                            <Image src={urlImage}/>
                        </View>
                        <View style={Estilos.emp_info}>
                            <Text
                                style={Estilos.emp_info_title}>{`Movimiento de Almacén N° ${props.cabma.Numero}`}</Text>
                            <Text
                                style={Estilos.emp_info_detalle}>{`Principal:  ${props.cabma.NombreEmpresa} :: ${props.cabma.DireccionEmpresa}`}</Text>
                            <Text
                                style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.cabma.NombreSucursal} :: ${props.cabma.DireccionSucursal}`}</Text>
                        </View>
                    </View>
                    <View style={Estilos.fluidBody}>
                        <View style={{marginTop: 5}}><Text>Datos Generales</Text></View>
                        <View style={Estilos.body_oc}>
                            <View style={Estilos.body_oc_info}>
                                <View style={Estilos.body_oc_info_c1}>
                                    <Text style={Estilos.cell_etiqueta}>Fecha Movimiento:</Text>
                                    <Text style={Estilos.cell}>{props.cabma.FechaMovimiento}</Text>
                                    <Text style={Estilos.cell_etiqueta}>Documento Asociado:</Text>
                                    <Text style={Estilos.cell}>{props.cabma.NomTipoDocumentoSunat}</Text>
                                </View>
                                <View style={Estilos.body_oc_info_c2}>
                                    <Text style={Estilos.cell_etiqueta}>Tipo Movimiento:</Text>
                                    <Text style={Estilos.cell}>{props.cabma.NomTipoMovimiento}</Text>
                                    <Text style={Estilos.cell_etiqueta}>Nro Documento:</Text>
                                    <Text style={Estilos.cell}>{props.cabma.NroDocumentoSunat}</Text>
                                </View>
                                {props.cabma.NroOrdenCompra ? (
                                    <View style={Estilos.body_oc_info_c3}>
                                        <Text style={Estilos.cell_etiqueta}>Nro Orden Compra:</Text>
                                        <Text style={Estilos.cell}>{props.cabma.NroOrdenCompra}</Text>
                                    </View>) : null
                                }
                            </View>
                        </View>
                        <View style={{marginTop: 5}}><Text>Detalle Movimiento</Text></View>
                        <View style={Estilos.body_detalle}>
                            <View style={Estilos.table}>
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                        <Text style={Estilos.tableCellHeader}>N°</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                        <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "62%", textAlign: "left"}]}>
                                        <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                        <Text style={Estilos.tableCellHeader}>{`Precio S/`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text style={Estilos.tableCellHeader}>{`Total S/`}</Text>
                                    </View>
                                </View>
                                {
                                    first.map((item) => {
                                        return (
                                            <View key={item.Correlativo} style={Estilos.tableRow}>
                                                <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "62%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Precio}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Total}</Text>
                                                </View>
                                            </View>
                                        )
                                    })}
                            </View>
                        </View>
                    </View>

                    <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed/>
                </Page>

                { rest.map((d, index) => {
                    return (
                        <Page size="A4" style={Estilos.page}>
                            <View style={Estilos.fluidBody}>
                                <View style={Estilos.body_detalle}>
                                    <View style={Estilos.table}>
                                        <View style={Estilos.tableRow}>
                                            <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                                <Text style={Estilos.tableCellHeader}>N°</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                                <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "62%", textAlign: "left"}]}>
                                                <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{`Precio S/`}</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{`Total S/`}</Text>
                                            </View>
                                        </View>
                                        {
                                            d.map((item) => {
                                                return (
                                                    <View key={item.Correlativo} style={Estilos.tableRow}>
                                                        <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                            <Text
                                                                style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                                        </View>
                                                        <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                            <Text
                                                                style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                                        </View>
                                                        <View style={[Estilos.tableCol, {width: "62%"}]}>
                                                            <Text
                                                                style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                                        </View>
                                                        <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                            <Text
                                                                style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Precio}</Text>
                                                        </View>
                                                        <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                            <Text
                                                                style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Total}</Text>
                                                        </View>
                                                    </View>
                                                )
                                            })}
                                    </View>
                                    {
                                index === rest.length - 1 && (
                                    <>
                                        <View style={Estilos.tableRow}>
                                            <View style={[Estilos.tableColHeader, {
                                                width: "88%",
                                                textAlign: "right",
                                                borderBottom: 0
                                            }]}>
                                                <Text style={Estilos.tableCellHeader}>{`Sub Total S/`}</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{v_SubTotal}</Text>
                                            </View>
                                        </View>
                                        <View style={Estilos.tableRow}>
                                            <View style={[Estilos.tableColHeader, {
                                                width: "88%",
                                                textAlign: "right",
                                                borderBottom: 0
                                            }]}>
                                                <Text style={Estilos.tableCellHeader}>{`IGV S/`}</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{v_IGV}</Text>
                                            </View>
                                        </View>
                                        <View style={Estilos.tableRow}>
                                            <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{`Flete `}</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{v_Flete}</Text>
                                            </View>
                                        </View>
                                        <View style={Estilos.tableRow}>
                                            <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{`Total S/`}</Text>
                                            </View>
                                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                                <Text style={Estilos.tableCellHeader}>{v_Total}</Text>
                                            </View>
                                        </View>
                                    </>
                                )
                            }
                                </View>
                            </View>

                            {
                                index === rest.length - 1 && (
                            <View style={Estilos.footer} fixed>
                    <View style={Estilos.footer_seccion_1}>
                        <Text style={Estilos.cell_etiqueta}>Observaciones:</Text>
                        <Text style={Estilos.cell}>{props.cabma.Glosa}</Text>
                    </View>
                    <View style={Estilos.footer_seccion_2}>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Aprobado Por:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Entregado Por:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Recibido por :</Text></View>
                    </View>
                </View>)}

                <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed/>
                        </Page>
                    )
                })}
            </Document>
        )
    }

    return (
        <Document>
            <Page size="A4" style={Estilos.page} wrap={true}>
                <View style={Estilos.header}>
                    <View style={Estilos.emp_logo}>
                        <Image src={urlImage}/>
                    </View>
                    <View style={Estilos.emp_info}>
                        <Text
                            style={Estilos.emp_info_title}>{`Movimiento de Almacén N° ${props.cabma.Numero}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Principal:  ${props.cabma.NombreEmpresa} :: ${props.cabma.DireccionEmpresa}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.cabma.NombreSucursal} :: ${props.cabma.DireccionSucursal}`}</Text>
                    </View>
                </View>
                <View style={Estilos.body}>
                    <View style={{marginTop: 5}}><Text>Datos Generales</Text></View>
                    <View style={Estilos.body_oc}>
                        <View style={Estilos.body_oc_info}>
                            <View style={Estilos.body_oc_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Fecha Movimiento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.FechaMovimiento}</Text>
                                <Text style={Estilos.cell_etiqueta}>Documento Asociado:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NomTipoDocumentoSunat}</Text>
                            </View>
                            <View style={Estilos.body_oc_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>Tipo Movimiento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NomTipoMovimiento}</Text>
                                <Text style={Estilos.cell_etiqueta}>Nro Documento:</Text>
                                <Text style={Estilos.cell}>{props.cabma.NroDocumentoSunat}</Text>
                            </View>
                            {props.cabma.NroOrdenCompra ? (
                                <View style={Estilos.body_oc_info_c3}>
                                    <Text style={Estilos.cell_etiqueta}>Nro Orden Compra:</Text>
                                    <Text style={Estilos.cell}>{props.cabma.NroOrdenCompra}</Text>
                                </View>) : null
                            }
                        </View>
                    </View>
                    <View style={{marginTop: 5}}><Text>Detalle Movimiento</Text></View>
                    <View style={Estilos.body_detalle}>
                        <View style={Estilos.table}>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>N°</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "62%", textAlign: "left"}]}>
                                    <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Precio S/`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Total S/`}</Text>
                                </View>
                            </View>
                            {
                                detalles.map((item) => {
                                    return (
                                        <View key={item.Correlativo} style={Estilos.tableRow}>
                                             <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Cantidad}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "62%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "left"}]}>{item.NomProducto}</Text>
                                            </View>
                                             <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Precio}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{item.Total}</Text>
                                            </View>
                                        </View>
                                    )
                                })}
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {
                                    width: "88%",
                                    textAlign: "right",
                                    borderBottom: 0
                                }]}>
                                    <Text style={Estilos.tableCellHeader}>{`Sub Total S/`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{v_SubTotal}</Text>
                                </View>
                            </View>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {
                                    width: "88%",
                                    textAlign: "right",
                                    borderBottom: 0
                                }]}>
                                    <Text style={Estilos.tableCellHeader}>{`IGV S/`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{v_IGV}</Text>
                                </View>
                            </View>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Flete `}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{v_Flete}</Text>
                                </View>
                            </View>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Total S/`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{v_Total}</Text>
                                </View>
                            </View>

                        </View>
                    </View>
                </View>

                <View style={Estilos.footer} fixed>
                    <View style={Estilos.footer_seccion_1}>
                        <Text style={Estilos.cell_etiqueta}>Observaciones:</Text>
                        <Text style={Estilos.cell}>{props.cabma.Glosa}</Text>
                    </View>
                    <View style={Estilos.footer_seccion_2}>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Aprobado Por:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Entregado Por:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Recibido por :</Text></View>
                    </View>
                </View>

                <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed/>
            </Page>
        </Document>
    )
}

export default RptMA;
