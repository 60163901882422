import React from 'react';


class VehiculosForm extends React.Component {

    render() {
        return (
            <div className='container no-pad'>
                {/*  */}
                {/* Placa y modelo */}
                <div className="almacenDiv pt-4">
                    <b className="">Placa</b>
                    <div className="input__Almacen">
                        <input required
                               name="Placa"
                               className="input__linea"
                               type="text"
                               value={this.props.state.Placa}
                               onChange={this.props.handleChangeUnicos}
                        />
                    </div>
                </div>
                <div className="almacenDiv pt-3">
                    <b className="">Modelo</b>
                    <div className="input__Almacen">
                        <input required
                               name="Modelo"
                               className="input__linea"
                               type="text"
                               value={this.props.state.Modelo}
                               onChange={this.props.handleChangeUnicos}
                        />
                    </div>
                </div>

            </div>
        )

    }

}

export default VehiculosForm;
