import React from "react";
import {DisableEnableConfForm} from "./DisableEnableConfForm";
import {defaultPostHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";

export class DisableEnableConf extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        this.onSave = this.onSave.bind(this)
    }

    onSave(payload) {
        pfetch({
            fetch: {
                url: `/api/jobs/add/disable-enable-conf`,
                headers: defaultPostHeaders(),
                method: "POST",
                body: JSON.stringify(payload)
            },
            before: () => this.setState({isLoading: true}),
            then: () => {
                notificarMsg("Se agregó con éxito")
                this.props.history.push("/jobs/")
            },
            finally: () => this.setState({isLoading: false}),
            formErrors: (d) => notificarError(d.msg),
            error: () => notificarError("Ha ocurrido al momento de agregar el job")
        })
    }

    render() {
        return (
            <div className="container">
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        <button className="btn btn-link" onClick={() => this.props.history.goBack()}>Regresar</button>
                    </div>
                    <div className="col-sm-auto">
                        <h4>Activar desactivar configuración</h4>
                    </div>
                </div>
                <div className="mt-3">
                    <DisableEnableConfForm disabled={this.state.isLoading} onSave={this.onSave}/>
                </div>
            </div>
        )
    }
}
