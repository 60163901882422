import React, {Component} from "react";
import SucursalPicker from "./ReporteMovAlmacen/SucursalPicker";
import {defaultGetHeaders, pfetch} from "../helpers/wrappers";
import moment from "moment";
import {convertObjToQueryParams} from "../helpers/utils";
import {notificarError} from "./Almacenes/AlmacenNotify";
import {decimalAdjust} from "../Global";

export class SucursalGeneralReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            isLoading: false,
            totales: [],
            msg: null
        }
        this.fetchReport = this.fetchReport.bind(this)
    }

    fetchReport() {
        const query = convertObjToQueryParams(this.state.payload)
        pfetch({
            fetch: {
                url: `${this.props.reportUrl}?${query}`,
                headers: defaultGetHeaders(),
            },
            before: () => this.setState({isLoading: true, msg: null}),
            then: (d) => {
                d = d.filter(x => x.sucursal !== "-")
                this.setState({totales: d, msg: d && d.length ? null : "No se han encontrado resultados."})
            },
            formErrors: (d) => notificarError(d.msg),
            error: () => notificarError("No se pudo extraer el reporte"),
            finally: () => this.setState({isLoading: false})
        })
    }

    render() {
        return (
            <div className="container">
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        <button onClick={() => this.props.history.goBack()} className="btn btn-link">Regresar</button>
                    </div>
                    <div className="col-sm-auto">
                        <h3>{this.props.title}</h3>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Fecha
                    </div>
                    <div className="col-sm">
                        <input type="date" className="form-control" value={this.state.payload.fechaInicio || ""}
                               onChange={e => {
                                   const value = e.target.value
                                   this.setState((state) => {
                                       return {
                                           payload: {...state.payload, fechaInicio: value}
                                       }
                                   })
                               }}
                        />
                    </div>
                    <div className="col-sm-auto">
                        entre
                    </div>
                    <div className="col-sm">
                        <input type="date" className="form-control" value={this.state.payload.fechaFin || ""}
                               onChange={e => {
                                   const value = e.target.value
                                   this.setState((state) => {
                                       return {
                                           payload: {...state.payload, fechaFin: value}
                                       }
                                   })
                               }}
                        />
                    </div>
                    <div className="col-sm-auto">
                        <button onClick={() => {
                            this.setState((state) => {
                                return {
                                    payload: {
                                        ...state.payload,
                                        fechaInicio: moment(new Date()).format("YYYY-MM-DD"),
                                        fechaFin: moment(new Date()).format("YYYY-MM-DD"),
                                    }
                                }
                            })
                        }} className="btn btn-link">
                            Hoy
                        </button>
                    </div>
                </div>
                <div className="row mt-3 no-marg">
                    <div className="col-sm">
                        <SucursalPicker selectAll={true} showDefaultOption={false} multiple={true}
                                        onChangeSucursal={e => this.setState((state) => {
                                            return {
                                                payload: {
                                                    ...state.payload,
                                                    sucursales: e && e.length ? e.join(",") : ""
                                                },
                                            }
                                        })}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        <button onClick={this.fetchReport} disabled={this.state.isLoading}
                                className="btn btn-outline-primary">
                            {this.state.isLoading ? 'Cargando' : "Generar"}
                        </button>
                    </div>
                    <div className="col-sm-auto">
                        {this.state.msg ? this.state.msg : null}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-12">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>Sucursal</th>
                                <th>{this.props.labelAccepted}</th>
                                <th>Total anulados S./</th>
                                <th>Total pagado S./</th>
                                <th>Total redondeo S./</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.totales.map((t, i) => <tr key={i}>
                                <td>{t.sucursal}</td>
                                <td>{decimalAdjust('floor', t.totalAceptados, -6)}</td>
                                <td>{decimalAdjust('floor', t.totalAnulados, -6)}</td>
                                <td>{decimalAdjust('floor', t.totalPagados, -6)}</td>
                                <td>{decimalAdjust('floor', t.totalRedondeos, -6)}</td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

}
