import React from "react";
import "react-moment";
import moment from "moment";
import {notificationSuccess,notificationError} from './EmpleadosNotify'
import { validarInputsEmpleado } from "./EmpleadoValidator";
import {CambiarEstadoEmpleado} from './EstadoEmpleados';
import EmpleadoForm from './EmpleadoForm'
import PageLoading from '../PageLoading.js';
import cheerio from "cheerio";
import { BotonCancelar } from "../Plantillas/Botones";
import {getApiDniStrategies} from "../../Global";

class EditarEmpleado extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      IdEmpleado: "",
      Nombres: "",
      Apellidos: "",
      DNI: "",
      Telefono: "",
      Celular: "",
      FechaIngreso: "",
      Estado: "",
      Correo: "",
      Direccion: "",
      Errores: {
        DNI: "",
        Nombres: "",
        Apellidos: "",
        Telefono: "",
        Celular: "",
        Correo: "",
        FechaIngreso:""
      }

    };
    this.handleChange = this.handleChange.bind(this);
    this.getEmpleadoById = this.getEmpleadoById.bind(this);
    this.editEmpleado = this.editEmpleado.bind(this);
  }

  componentDidMount() {
    const IdEmpleado = this.props.match.params.IdEmpleado;
    this.getEmpleadoById(IdEmpleado);
  }

  getEmpleadoById(IdEmpleado) {
    this.setState({ loading: true, error: null });
    //console.log(IdEmpleado);
    try {
      fetch(`/api/empleados/edit/${IdEmpleado}`)
      .then(res => res.json())
      .then(data => {
        this.setState({
          loading:false,
          IdEmpleado: data[0].IdEmpleado,
          Nombres: data[0].Nombres,
          Apellidos: data[0].Apellidos,
          DNI: data[0].DNI,
          Telefono: data[0].Telefono,
          Celular: data[0].Celular,
          Correo: data[0].Correo,
          Direccion: data[0].Direccion,
          FechaIngreso: this.fecha(data[0].FechaIngreso),
          Estado: data[0].Estado
        });
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error,
      });
    }

  }

  handleChange(e) {
    const { name, value } = e.target;

      this.setState({
        [name]: value
      });

    let Errores = this.state.Errores;

    //mensajes de validacion
    validarInputsEmpleado(Errores, name, value);

    this.setState({ Errores, [name]: value });
    //console.log({ Errores, [name]: value })
  }



  editEmpleado(e) {
    let valido = true
    Object.values(this.state.Errores).forEach(val=>{
      val.length>1 && (valido=false)
    })
    //si esta ok, muestra mensaje y edita
    if (valido) {
      try {
        notificationSuccess()
      fetch(`/api/empleados/edit/${this.state.IdEmpleado}`, {
        method: "PUT",
        body: JSON.stringify({
          Nombres: this.state.Nombres,
          Apellidos: this.state.Apellidos,
          DNI: this.state.DNI,
          Telefono: this.state.Telefono,
          Celular: this.state.Celular,
          FechaIngreso: this.state.FechaIngreso,
          Correo: this.state.Correo,
          Direccion: this.state.Direccion
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .then(data => console.log(data))
        .catch(err => console.log(err));
        this.props.history.push('/empleados')
      } catch (error) {
        this.setState({
          loading: false,
          error: error,
        });
        console.log(error)
      }

    } else {
      //notificacion de error
      notificationError()
    }

    e.preventDefault();

  }

  fecha(FechaIngreso) {
    return moment(FechaIngreso).format("YYYY-MM-DD");
  }

  handleSubmitDNI= async (e) => {
    e.preventDefault();
    this.TraerPersonaPorDNI(this.state.DNI);
  };


  TraerPersonaPorDNI = async (dni) => {
    try {

      let dniObj = getApiDniStrategies()[1];
      let data = await dniObj.getData(dni, dniObj.url);

      this.setState({
        Apellidos: data.apPaterno + " " + data.apMaterno,
        Nombres: data.nombres.join(" ")
      });

    } catch (error) {
        console.log(`Error --- >${error}`);
    }
  };

  render() {
    if(this.state.loading === true){
      return <PageLoading/>;
    }
    return (
      <React.Fragment>
              <div className="ml-4 mt-4">
                  {/* <BotonCancelar to="/empleados"/> */}
              </div>
              {/*formulario de empleado*/}
              <EmpleadoForm
                onChange={this.handleChange}
                onSubmit={this.editEmpleado}
                onSubmitDNI={this.handleSubmitDNI}
                state={this.state}
                volver="/empleados"
                CambiarEstadoEmpleado={CambiarEstadoEmpleado(
                  this.state.Estado,
                  this.state.IdEmpleado,
                  this.state.Nombres,
                  this.state.Apellidos,
                  this.props.history,
                  "/empleados"
                )}
              />
              {/*botones de cambio de estado*/}
      </React.Fragment>
    );
  }
}

export default EditarEmpleado;
