import React, { useEffect, useState } from "react";
import Modal from "../clientes/ClienteModal";
import ClienteForm from "./forms/Cliente";
import ProductosForm from "./forms/Productos";
import ResumenForm from "./forms/Resumen";


export function Registrar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [canContinue, setCanContinue] = useState(false);
  const [client, setClient] = useState(null);
  const [items, setItems] = useState([]);
  const [pedidoId, setPedidoId]= useState(undefined);

  useEffect(() => {
  }, [props.currentSucursalId])

  useEffect(() => {
    if (pedidoId) {
      props.onPedidoRegistered(pedidoId);
      onClose();
    }
  }, [pedidoId])

  const onClose = () => {
    setStep(0);
    setIsOpen(false);
  }

  const nextStep = () => {
    setStep(step + 1);
  }

  if(!Boolean(props.canRegistrar)) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        className="ml-3 btn btn-outline-primary btn-verde-mfp mb-2"
        onClick={() => setIsOpen(true)} 
      >
        Registrar
      </button>
      <Modal isOpen={isOpen} title="Registrar pedido" onClose={() => onClose()}>
        <hr width="1080px" className="border-0" />
        <div className="container" style={{overflow: "hidden"}}>
            <div className="progress mb-3">
                <div
                  className={`progress-bar ${step === 0 ? 'bg-success' : 'bg-info'}`}
                  role="progressbar"
                  style={{ width: `${(step / 2) * 100}%` }}
                  aria-valuenow={(step/ 2) * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  Paso {step + 1} de 3
                </div>
            </div>
            { step === 0 && <ClienteForm onChangeClient={(c) => setClient(c)} nextStep={nextStep} onCancel={onClose}/> }
            { step === 1 && <ProductosForm client={client} onChangeItems={(items) => setItems(items)} onCancel={onClose} nextStep={nextStep}/> }
            { step === 2 && <ResumenForm sucursalId={props.currentSucursalId} client={client} items={items} onCancel={onClose} onPedidoRegistered={e => setPedidoId(e)}/> }
        </div>
      </Modal>
    </>
  );
}
