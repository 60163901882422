import { decimalAdjust } from "../../Global";


export const CURRENT_QUOTATION_KEY = 'CURRENT_QUOTATION';

export function calculateVueltoToDisplay(data) {
    let vuelto = '0.00';

    if (data.Vuelto == 0) {
        return vuelto;
    }

    if (data.Redondeo > 0) {
        return decimalAdjust('floor', data.VueltoRedondeo, -2).toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    return decimalAdjust('floor', data.Vuelto, -2).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
