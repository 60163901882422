import React from "react";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class VehiculoAddForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            isLoading: false,
        }
        this.saveVehiculo = this.saveVehiculo.bind(this)
    }

    async saveVehiculo(e) {
        this.setState({isLoading: true})

        let res = await fetch('/api/vehiculos/add', {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(this.state.payload),
        })

        this.setState({isLoading: false})

        if (res.ok)
            this.props.onSaveVehiculo(await res.json())
        else if (res.status === 422) {
            let msg = await res.json()
            notificarError(msg.msg)
        } else
            notificarError("Ha ocurrido un error")
    }

    render() {
        const {payload, isLoading} = this.state;

        return (
            <div className="pt-3 container"> 
                <div className="row">
                    <div className="col-sm form-group">
                        <label><strong>Placa</strong></label>
                        <input type="text" className="form-control" value={payload.Placa} onChange={e => {
                            this.setState({
                                payload: {
                                    ...payload,
                                    Placa: e.target.value,
                                }
                            })
                        }}/>
                    </div>

                </div>

                <div className="row">
                    <div className="col-sm form-group">
                        <label><strong>Modelo</strong></label>
                        <input type="text" className="form-control" value={payload.Modelo} onChange={e => {
                            this.setState({
                                payload: {
                                    ...payload,
                                    Modelo: e.target.value
                                }
                            })
                        }}/>
                    </div>
                </div>

                
                    <div className="seccion-btn-verde-mfp pb-3">
                        <button disabled={isLoading}
                                onClick={this.saveVehiculo} className="btn-verde-mfp btn btn-outline-primary">
                            Registrar
                        </button>
                    </div>
                
            </div>
        )
    }
}
