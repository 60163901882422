import React, { useState, useEffect } from 'react';
import apiFetch from "../../helpers/apiFetch"
import convertMenuListToMenuTree from '../../helpers/convertMenuListToMenuTree';
import Notify from '../Notify';
import CheckboxToggle from '../CheckboxToggle';

const MenuEmpresas = ({Empresa}) => {

    /**
     * En el estado siguiente (`menuEmpresas`) se guardarán los estados viejos y los estados nuevos de los módulos (MenuEmpresas) con el formato:
     * ```
     * {
     *   [IdMenu1]: { state: !!Estado1, newState: !!Estado1 },
     *   [IdMenu2]: { state: !!Estado2, newState: !!Estado2 },
     * }
     *
     * // Ejemplos con datos reales
     * {
     *   '1': {state: true,     newState: true},   // Módulo con IdMenu 1: Habilitado
     *   '2': {state: false,    newState: false},  // Módulo con IdMenu 2: Deshabilitado
     *   '3': {state: true,     newState: false},  // Módulo con IdMenu 3: Este módulo se va a deshabilitar
     *   '4': {state: false,    newState: true}    // Módulo con IdMenu 4: Este módulo se va a habilitar
     * }
     * ```
     * El atributo `state` indica si dicho módulo está habilitado o no en la BD.
     *
     * Al principio, ambos estados `state` y `newState` son iguales.
     * Si el módulo está deshabilitado, para habilitarlo se debe cambiar el atributo `newState` a `true`.
     * Si el módulo está habilitado, para deshabilitarlo se debe cambiar el atributo `newState` a `false`.
     *
     * La funcion `handleSaveChanges()` se encarga de guardar los cambios a la BD.
     */
    const [menuEmpresas, setMenuEmpresas] = useState(null);

    // Aqui se guardará la lista de objetos jerárquica que se utilizará como formato para renderizar la interfaz de configuraciones
    const [menuEmpresasTree, setMenuEmpresasTree] = useState(null);

	/*******************************************************
	 * Obtener menuEmpresas cuando se cargue el componente *
	 *******************************************************/
    useEffect( () => {
        const getMenuEmpresas = async () => {
            const {data_tables, error} = await apiFetch(`/api/master/menuEmpresas/${Empresa.ID}`, ["_estado_menuEmpresas_list"]);
            if (error)
                return;
            const [menuEmpresasList] = data_tables;
            const menuEmpresasObj = menuEmpresasList.reduce( (menuEmpresasObj, menuEmpresa) => {
                const {IdMenu, Estado} = menuEmpresa;
                menuEmpresasObj[IdMenu] = { state: !!Estado, newState: !!Estado };
                return menuEmpresasObj;
            }, {})
            setMenuEmpresas(  menuEmpresasObj  );
            setMenuEmpresasTree(  convertMenuListToMenuTree(menuEmpresasList)  );
        }
        getMenuEmpresas();
    }, [Empresa]);

    const handleCheckboxBuilder = IdMenu => {
        return event => {
            const newState = event.target.checked;
            const newMenuEmpresas = {...menuEmpresas};
            newMenuEmpresas[IdMenu].newState = newState;
            setMenuEmpresas(newMenuEmpresas);
        }
    }

    const handleSaveChanges = async () => {
        let addList = [];
        let removeList = [];
        /**
         * Se va a iterar sobre los keys y values del objeto `menuEmpresas`
         * Se ignorarán aquellos cuyo estado no ha cambiado
         * Y los que han cambiado se asignarán a la lista a agregar o eliminar (`addList` y `removeList` respectivamente)
         *
         * Las listas están separadas debido a que habilitar un menú implica hacer un INSERT en la BD
         * y deshabilitar un menú implica hacer un DELETE.
         */
        Object.entries(menuEmpresas).forEach( ([IdMenu, states]) => {
            const oldState = states.state;
            const newState = states.newState;
            if (oldState===newState) return;    // No hay cambios, nada que hacer con este menú.

            (newState===true)
                ? addList.push(Number(IdMenu))
                : removeList.push(Number(IdMenu))
        })
        const estadosChequear = []
        if (addList.length > 0)     estadosChequear.push("_estado_menuEmpresas_insert")
        if (removeList.length > 0)  estadosChequear.push("_estado_menuEmpresas_delete")

        // Salir si no hay cambios que enviar
        if (estadosChequear.length === 0)
            return Notify("No hubo cambios", "warning", 1500);
        /**
         * Una vez identificados y clasificados los cambios, se procede a enviarlos a la API
         */
        const data = {addList, removeList}
        const {error} = await apiFetch(`/api/master/menuEmpresas/${Empresa.ID}`, estadosChequear, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        if (!error)
            Notify("Cambios guardados exitosamente");
    }

    const renderMenuEmpresas = menuEmpresasTree => (
        <ul className="pl-4">
            {
                menuEmpresasTree.map(menuEmpresa => {
                    const menuItem = (
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <CheckboxToggle
                                    checked={menuEmpresas[menuEmpresa.IdMenu].newState}
                                    onChange={handleCheckboxBuilder(menuEmpresa.IdMenu)}
                                />
                                {menuEmpresa.Nombre}
                            </div>
                        </div>
                    )
                    return (
                    <div key={menuEmpresa.IdMenu}>
                        { menuItem }
                        { renderMenuEmpresas(menuEmpresa.children) }
                    </div>
                    )
                })
            }
        </ul>
    )

    return (
        <div className="d-flex justify-content-center">
            <div className="mt-3 container">
                <div className="d-flex justify-content-end mb-3">
                    <input className="btn btn-primary btn-verde-mfp" type="button" value="Guardar" onClick={handleSaveChanges}/>
                </div>
                {menuEmpresasTree && renderMenuEmpresas(menuEmpresasTree)}
            </div>
        </div>
    )
}


export default MenuEmpresas;