import React from "react";
import { BotonLetras, BotonCancelar } from "../Plantillas/Botones.js";
import '../styles/Kardex.css';
import sunat from "../images/sunat.png"
import sinComprobantes from "../images/sinComprobantes.jpg"
import { notificarError, notificarMsg } from "../Almacenes/AlmacenNotify.js";
import swal from "sweetalert";

class EscogerStockInicial extends React.Component{

    async reiniciarStock () {

        const restart = await swal({
            title: "¿Está seguro?",
            text: "¿Desea reiniciar el stock de todos los productos?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            closeOnClickOutside: false
        });

        if (!restart) {
            return
        }

        try {
            const req = await fetch('/api/kardex/reiniciar-stock')
            if (!req.ok) {
                throw new Error('Error en la petición')
            }

            notificarMsg('Stock reiniciado exitosamente')
        } catch (error) {
            notificarError('Intente nuevamente! Ocurrió un error al reiniciar el stock.')
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="ProcesarBoton mt-1">
                    <BotonCancelar
                        onClick={this.props.history.goBack}
                        className="ml-4"
                    />
                </div>

                <div className="kardex__container">
                    <div className="kardex">
                        <div className="reporteVentasProd">

                            <div className="KardexDocumento mb-4 mt-4" >
                                <div className="imgcomprobante">
                                   <img src={sunat} alt="comprobante"/> 
                                </div>
                                <div className="input__KardexDivSmall imgcomprobante mt-4">
                                    <BotonLetras
                                        type="button"
                                        to="/stockinicial" 
                                    >Con Comprobante
                                    </BotonLetras>
                                </div>
                            </div>

                            <div className="KardexDocumento mb-4" >
                                <div className="imgcomprobante">
                                   <img src={sinComprobantes} style={{height: '83px'}} alt="sin comprobante"/> 
                                </div>
                                <div className="input__KardexDivSmall imgcomprobante mt-2">
                                    <BotonLetras
                                        type="button"
                                        to="/stockinicialSC" 
                                    >Sin Comprobante
                                    </BotonLetras>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="kardek">
                        <div className="reporteVentasProd">
                            <BotonLetras
                                type="button"
                                onClick={() => this.reiniciarStock()}
                            >Reiniciar stock
                            </BotonLetras>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default EscogerStockInicial