import React from "react";
import "../styles/tabla.css"
import {BotonBuscar, BotonGuardarLetras, BotonCancelar} from "../Plantillas/Botones";

class EmpresasAddFormDatosSunat extends React.Component {
    render() {
        return (
            <React.Fragment>

                <div className="almacenDiv">
                    <div className="caja-fondo-blanco-m-2">
                        <b className="">*Ruc</b>
                        <input required
                            name="RucPre"
                            className="input__linea"
                            placeholder="Ruc..."
                            onChange={this.props.handleChange}
                            type="text"
                            autoFocus />
                        <div className="d-flex justify-content-end pt-3">    
                        <BotonBuscar
                            type="submit"
                            onClick={this.props.handleSubmitRUC}
                        />
                        </div>
                        <div className="labelValidAlmacen">
                            {this.props.state.Errores.Ruc.length > 0 && (<span>{this.props.state.Errores.Ruc}</span>)}
                        </div>
                    </div>
                </div>

                <div className="almacenDiv">
                    <div className="caja-fondo-blanco-m-2">
                        <b className="">Razón Social</b>
                        <input required
                            name="RazonSocial"
                            className="input__linea"
                            placeholder="Razón Social..."
                            onChange={this.props.handleChange}
                            value={this.props.state.RazonSocial}
                            type="text" />
                        <div className="labelValidAlmacen">
                            {this.props.state.Errores.RazonSocial.length > 0 && (
                                <span>{this.props.state.Errores.RazonSocial}</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="almacenDiv">
                    <div className="caja-fondo-blanco-m-2">
                        <b className="">Domicilio Fiscal</b>
                        <input required
                            name="Direccion"
                            className="input__linea"
                            placeholder="Domicilio Fiscal..."
                            onChange={this.props.handleChange}
                            value={this.props.state.Direccion}
                            type="text" />
                        <div className="labelValidAlmacen">
                            {this.props.state.Errores.Direccion.length > 0 && (
                                <span>{this.props.state.Errores.Direccion}</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="almacenDiv ">
                    <div className="caja-fondo-blanco-m-2">
                        <b className="">Nombre Comercial</b>
                        <input
                            required
                            className="input__linea"
                            placeholder="Nombre Comercial..."
                            name="NombreComercial"
                            value={this.props.state.NombreComercial}
                            onChange={this.props.handleChange}
                            type="text" />
                    </div>
                </div>

                <div className="almacenDiv">
                    <div className="caja-fondo-blanco-m-2">
                        <div className="">
                        <b className="">Condición del Contribuyente </b>
                        <input required
                            placeholder="Condición del Contribuyente"
                            name="ContribuyenteCondicion"
                            className=" input__linea "
                            onChange={this.props.handleChange}
                            value={this.props.state.ContribuyenteCondicion}
                            type="text" />
                        </div>
                        <div className="mt-3">
                        <b className="">Estado del Contribuyente </b>
                        <input required
                            placeholder="Estado del Contribuyente"
                            name="ContribuyenteEstado"
                            className=" input__linea"
                            onChange={this.props.handleChange}
                            value={this.props.state.ContribuyenteEstado}
                            type="text" />
                        </div>
                    </div>
                </div>

                <div className="almacenDiv">
                    <div className="caja-fondo-blanco-m-2">
                        <b className="">Código Ubigeo </b>
                        <input required
                            name="Ubigeo"
                            placeholder="Código Ubigeo"
                            className="input__linea"
                            value={this.props.state.Ubigeo}
                            onChange={this.props.handleChange}
                            type="text" />
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default EmpresasAddFormDatosSunat;
