import React from "react";
import moment from "moment";
import { decimalAdjust } from "../../Global";

export default class TablaPagosComponent extends React.Component {
    render() {
        const { pagos, onProcesar, onEliminar, readOnly, onChangeFields, isLoading, tiposPago } = this.props;

        return (
            <div className="container">
                <div className="card-container">
                    {pagos.map((p, i) => {
                        const isPending = p.Estado === "PENDIENTE" && !readOnly;
                        const cardClass = isPending ? "card-danger" : "";

                        return (
                            <div key={`pago-${p.IdPago}`} className={`card ${cardClass}`}>
                                <div className="card-body ">
                                    <div className=" caja-fondo-blanco-m-2">
                                        <div className="row">
                                        <div className="card-item mt-1 col-12 col-sm-4">
                                            <div className="card-item-label">Fecha</div>
                                            <div className="card-item-value">
                                                {moment(p.FechaPago).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                        <div className="card-item mt-1 col-12 col-sm-4">
                                            <div className="card-item-label">Cuota</div>
                                            <div className="card-item-value">{i + 1}</div>
                                        </div>
                                        <div className="card-item mt-1 col-12 col-sm-4">
                                            <div className="card-item-label">Monto cuota</div>
                                            <div className="card-item-value">
                                                {decimalAdjust("floor", p.Monto, -2)}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card-item mt-1">
                                            <div className="card-item-label">Total pagado</div>
                                            <div className="card-item-value">
                                                {isPending ? (
                                                    <input
                                                        className="custom-input"
                                                        name="TotalPagado"
                                                        type="number"
                                                        min={0}
                                                        onChange={(e) => onChangeFields(e, p)}
                                                        value={p.TotalPagado}
                                                    />
                                                ) : (
                                                    p.TotalPagado
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item mt-1 caja-fondo-blanco-m-2">
                                        <div className="card-item-label">Método de pago</div>
                                        <div className="card-item-value">
                                            {isPending ? (
                                                <select
                                                    className="custom-select"
                                                    name="TipoPago"
                                                    onChange={(e) => onChangeFields(e, p)}
                                                    value={p.TipoPago}
                                                >
                                                    {tiposPago.map((t, i) => (
                                                        <option key={`opt-pago-${i}`} value={t}>
                                                            {t}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                p.TipoPago
                                            )}
                                        </div>
                                    </div>
                                    <div className="card-item mt-1 caja-fondo-blanco-m-2">
                                        <div className="card-item-label">Núm. Movimiento</div>
                                        <div className="card-item-value">
                                            {isPending ? (
                                                <input
                                                    className="custom-input"
                                                    name="NumeroMovimiento"
                                                    type="text"
                                                    onChange={(e) => onChangeFields(e, p)}
                                                />
                                            ) : (
                                                p.NumeroMovimiento === "null" || p.NumeroMovimiento === null ? "-" : p.NumeroMovimiento
                                            )}
                                        </div>
                                    </div>
                                    <div className="card-item mt-1 caja-fondo-blanco-m-2">
                                        <div className="card-item-label">Total pendiente</div>
                                        <div className="card-item-value">
                                            {decimalAdjust("floor", p.capital, -2)}
                                        </div>
                                    </div>
                                    <div className="mt-3 card-footer d-flex justify-content-end">
                                        {isPending ? (
                                            <button
                                                className="custom-button btn-verde-mfp"
                                                disabled={isLoading}
                                                onClick={(e) => onProcesar(e, p)}
                                            >
                                                Procesar
                                            </button>
                                        ) : (
                                            !p.wasFromVenta && (
                                                <button
                                                    className="custom-button danger-button"
                                                    disabled={isLoading}
                                                    onClick={(e) => onEliminar(e, p)}
                                                >
                                                    Eliminar
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}