import React, {Component} from "react";
import PagosUI from "./PagosUI";
import {
    decimalAdjust,
    BOLETA,
    calcularTotales,
    CONTADO,
    CREDITO,
    FPAY,
    ESTADO_PREVENTA,
    FACTURA,
    IVAP,
    PAGO_CREDITO,
    TIPO_STOCK,
    VALE,
} from "../../Global";
import jsPDF from "jspdf";
import {Redirect} from "react-router-dom";
import printTicket from "./Ticket";
import swal from "sweetalert";
import "react-moment";
import moment from "moment";
import calcularVenta from "../../helpers/calcularVenta";
import Notify from "../../componentes/Notify";
import {enviaremail} from "../../helpers/funcionesGlobales";
import printA4, {crearDocPDF} from "../../helpers/A4";
import PageLoading from "../../componentes/PageLoading";
import Modal from "../../componentes/Modal";
import {CuotasCreditoModal} from "../../componentes/Preventas/CuotasCreditoModal";
import {ModalFpay} from "../../componentes/Preventas/BilleterasDigitales/Fpay/ModalFpay";
import {ACTION, ENTITY} from "contatrib-utils";
import ModalFpay2 from "../../componentes/ModalFpay2";
import {notificarMsg} from "../../componentes/Almacenes/AlmacenNotify";
import { CURRENT_QUOTATION_KEY } from "./utils";

class Pagos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            preventa: {},
            detallePreventa: [],
            IdPreventa: this.props.match.params.IdPreventa,
            Pagos: [],
            TipoComprobante: null,
            Descuento: 0,
            Vuelto: null,
            IdRegistroVenta: null,
            Venta: null,
            DetallesVenta: [],
            Total: 0.0,
            Subtotales: [],
            Gravadas: [],
            Exoneradas: [],
            Inafectas: [],
            ICBPERArray: [],
            TotalGravadas: 0.0,
            TotalExoneradas: 0.0,
            TotalInafectas: 0.0,
            TotalICBPER: 0.0,
            TotalIGV: 0.0,
            IGVs: [],
            TotalPagado: 0.0,
            Observaciones: null,
            Redirect: null,
            TipoModalidadPago: "CONTADO",
            PlazoCredito: 1,
            Gratuitas: 0.0,
            ISC: 0.0,
            IVAP: 0.0,
            BotonPagado: false,
            ClickPagar: true,

            clicksEnter: 0,
            MostrarPagina: false,
            ImprimirA4: false,
            PagoEfectivoUnico: false,
            Redondeo: 0,
            showModalCuotasCredito: false,
            showModalYape: false,
            showModalFpay: false,
            infoCuotasCredito: {},
            extImg: 'png',
            creditoSelected: false,
            cuentasBancarias: [],
            hasPayed: false,
            hasHidden: true,
            showPrint: false,
            IdCaja: "",
            docSunat: "",
            ifActiveFpay: false,
            permisosEditar: false,

            OrdenCompra: "",
            GuiaRemision: null,

            vueltoCalculado: 0
        };
        this.conf = JSON.parse(window.localStorage.getItem('extraConf'))
        this.handleTipoPago = this.handleTipoPago.bind(this);
        this.updateMontoPago = this.updateMontoPago.bind(this);
        this.updateReferenciaPago = this.updateReferenciaPago.bind(this);
        this.handleTipoComprobante = this.handleTipoComprobante.bind(this);
        this.handleDescuento = this.handleDescuento.bind(this);
        this.handlePagar = this.handlePagar.bind(this);
        this.getVenta = this.getVenta.bind(this);
        this.getTotales = this.getTotales.bind(this);
        this.setTotalPagado = this.setTotalPagado.bind(this);
        this.getVuelto = this.getVuelto.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.handleObservaciones = this.handleObservaciones.bind(this);
        this.setRedirect = this.setRedirect.bind(this);
        this.printTicketVenta = this.printTicketVenta.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.getVale = this.getVale.bind(this);
        this.verificarTurno = this.verificarTurno.bind(this);
        this.getInfoDocSunat = this.getInfoDocSunat.bind(this);

        this.updateFechaPago = this.updateFechaPago.bind(this);
        this.handleEnviarVenta = this.handleEnviarVenta.bind(this);
        this.handlePagoEnterKeyUp = this.handlePagoEnterKeyUp.bind(this);
        this.removePago = this.removePago.bind(this);
        this.handleEnviarEmail = this.handleEnviarEmail.bind(this);
        this.onKeyUpModalCuotasCredito = this.onKeyUpModalCuotasCredito.bind(this);
        this.onKeyUpModalYape = this.onKeyUpModalYape.bind(this);
        this.onKeyUpModalFpay = this.onKeyUpModalFpay.bind(this);
        this.onAceptarCuotasCredito = this.onAceptarCuotasCredito.bind(this);
        this.onAgregarPagoFpay = this.onAgregarPagoFpay.bind(this);
        this.consultarConfiguracioImprimeA4 = this.consultarConfiguracioImprimeA4.bind(this);
        this.fetchCuentasBancarias = this.fetchCuentasBancarias.bind(this);
        this.fetchNumerosCuenta = this.fetchNumerosCuenta.bind(this);
        this.verificarEditarVenta = this.verificarEditarVenta.bind(this);

        this.handleGuiaRemision = this.handleGuiaRemision.bind(this);
        this.handleOrdenCompra = this.handleOrdenCompra.bind(this);
    }

    async fetchCuentasBancarias() {
        this.setState({isLoading: true});
        let res = await fetch('/api/cuentas/');
        if (res.ok)
            this.setState({cuentasBancarias: await res.json()})
        this.setState({isLoading: false});
    }

    async fetchNumerosCuenta() {
        this.setState({loading: true});
        let res = await fetch('/api/cuentas/numeroscuenta');
        const cuentas = await res.json();
        // console.log({cuentas: cuentas})
        if (res.ok)
            this.setState({cuentasBancarias: cuentas})
        this.setState({loading: false});
    }

    decimalAdjust(type, value, exp) {
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    consultarConfiguracioImprimeA4 = async () => {
        this.setState({isLoading: true, error: null});
        try {
            const response = await fetch(`/api/ventas/revisar/ImprimirA4`);
            const ImprimirA4 = await response.json();
            this.setState({
                ImprimirA4: ImprimirA4.ImprimirA4[0],
                extImg: ImprimirA4.extImg
            })
        } catch (error) {
            this.setState({isLoading: false, error: error});
        }
    }

    verificarTurno = async () => {
        this.setState({isLoading: true, error: null});
        try {
            const response = await fetch(`/api/cajas/verificar/Turno`);
            const data = await response.json();
            this.setState({
                isLoading: false,
                IdTurno: data.Turno ? data.Turno[0][0].IdTurnoCaja : null,
                IdCaja: data.Turno ? data.Turno[0][0].IdCaja : null,
            });
            if (this.state.IdTurno === null) {
                await this.validarUsuarioCaja()
                this.props.history.push(`/gestionpreventas`);
            }
        } catch (error) {
            this.setState({isLoading: false, error: error});
        }
    };

    async verificarEditarVenta() {
        try {
            const responseVal =await fetch(`/api/ventas/editarVenta/validar`);
            const data = await responseVal.json();
            this.setState({permisosEditar: true})
        } catch (e) {
            this.setState({permisosEditar: false})
        }
    }

    async validarUsuarioCaja(){
        try {
            const responseValidar = await fetch(`/api/cajas/user/validar`);
            const validarUser = await responseValidar.json();
            notificarMsg("La caja se encuentra cerrada, se debe aperturar", "warning")
        }catch (e) {
            console.log("")
        }
        // return  validarUser.ValidarUser == true ? true : false
    }

    getInfoDocSunat = async (idCaja) => {
        const response = await fetch(`/api/puntosVenta/billeterasDigitales/pv/${idCaja}`);
        const docBilleteras = await response.json();
        this.setState({
            loading: false,
            docSunat: docBilleteras,
        });
    }

    // addNewInvoiceFpayCount = async (idCaja) => {
    //     const response = await fetch(`/api/puntosVenta/billeterasDigitales/fpay/add/${idCaja}`);
    // }
    addNewInvoiceFpayCount = async (idCaja) => {
        await fetch(`/api/puntosVenta/billeterasDigitales/fpay/add/${idCaja}`);
    }

    async componentDidMount() {
        await this.verificarTurno();
        await this.verificarEditarVenta();
        await this.consultarConfiguracioImprimeA4();
        // await this.fetchCuentasBancarias();
        await this.fetchNumerosCuenta();
        if (this.state.IdTurno !== null) {
            const IdPreventa = this.state.IdPreventa;
            const IdCaja = this.state.IdCaja;
            await this.getPreventa(IdPreventa);
            await this.getDetallePreventa(IdPreventa);
            await this.getInfoDocSunat(IdCaja)
            this.getTotales(this.state.Descuento);
        }
    }

    // async setTotalPagado(Pagos) {
    //     let pagado = await Pagos.map((pago) => {
    //         if (pago.Monto > 0) {
    //             return Number.isNaN(pago.Monto) ? Number(pago.Monto) : pago.Monto;
    //         } else {
    //             return 0.0;
    //         }
    //     });
    //     const pagos = this.state.Pagos.length
    //     let reducer_pagos = (totalpagos, pagado) => Number(totalpagos) + Number(pagado);
    //     let acumulado = 0
    //     if (pagos > 1) {
    //         acumulado = this.state.Pagos.reduce((a, b) => Number(a.Monto) + Number(b.Monto))
    //     }
    //     await this.setState({
    //         TotalPagado: pagado.reduce(reducer_pagos, 0),
    //     });
    // }
    async setTotalPagado(Pagos) {
        let pagado = await Pagos.map((pago) => {
            if (pago.Monto > 0) {
                return Number.isNaN(pago.Monto) ? Number(pago.Monto) : pago.Monto;
            } else {
                return 0.0;
            }
        });
    
        let reducer_pagos = (totalpagos, pagado) => Number(totalpagos) + Number(pagado);
        await this.setState({
            TotalPagado: pagado.reduce(reducer_pagos, 0),
        });
    }
    
      

    async getVuelto() {
        if (this.state.TotalPagado > this.state.Total) {
            let vuelto = this.state.TotalPagado - this.state.Total;
            await this.setState({
                Vuelto: vuelto,
            });
        } else {
            await this.setState({
                Vuelto: 0.0,
            });
        }
    }

    async getTotales(Descuento) {

        const totales = calcularTotales([...this.state.detallePreventa]);

        this.setState({
            Total: totales.total - Descuento,
            TotalInafectas: totales.inafectos,
            TotalExoneradas: totales.exonerados,
            TotalGravadas: totales.gravados,
            TotalIGV: totales.gravados * 0.18,
            TotalICBPER: totales.icbper,
            Gratuitas: totales.gratuitos,
            MostrarPagina: true,
            TotalRedondeado: totales.totalRedondeado,
            redondeo: totales.redondeo,
        });
    }

    async getPreventa(IdPreventa) {
        try {
            const response = await fetch(
                `/api/gestionPreventas/preventa/${IdPreventa}`
            );
            const preventa = await response.json();

            if (preventa.respuesta[0].Estado === ESTADO_PREVENTA.PROCESADA || preventa.respuesta[0].Estado === ESTADO_PREVENTA.CANCELADA)
                return this.props.history.push(`/gestionpreventas`);

            this.setState({
                preventa: preventa.respuesta[0],
                isLoading: false,
                TipoComprobante:
                    preventa.respuesta[0].IdTipoDocumentoSunat === FACTURA
                        ? FACTURA
                        : (preventa.respuesta[0].IdTipoDocumentoSunat === VALE ? VALE : BOLETA)
            });
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getDetallePreventa(IdPreventa) {
        await fetch(`/api/gestionPreventas/detallepreventa/${IdPreventa}`)
            .then((response) => response.json())
            .then((detallePreventa) =>
                this.setState({
                    detallePreventa: detallePreventa.respuesta,
                    isLoading: false,
                })
            )
            .catch((error) => this.setState({error, isLoading: false}));
    }

    handleTipoPago(IdTipoPago) {
        if (IdTipoPago === PAGO_CREDITO) {
            return () => this.setState({showModalCuotasCredito: true})
        } if (IdTipoPago === FPAY)
            return () => this.setState({showModalFpay: true})
        return () => this.addDetallePago(IdTipoPago);
    }

    addDetallePago(IdTipoPago) {
        // Todo: fragmento
        let pagoEfectivoUnico = this.state.PagoEfectivoUnico
        if (IdTipoPago === 1)
            pagoEfectivoUnico = !this.state.PagoEfectivoUnico;

        const pagos = this.state.Pagos.length
        let acumulado = 0
        if(pagos > 1){
            acumulado = this.state.Pagos.reduce((a,b) => Number(a.Monto) + Number(b.Monto))
        }
        const pagoActual = {
            IdTipoPago,
            Monto: acumulado === this.state.Total? 0 :this.state.Total - this.state.TotalPagado,
            Referencia: null,
            FechaPago:
                IdTipoPago === PAGO_CREDITO
                    ? new Date(new Date().setDate(new Date().getDate()))
                    : new Date(new Date().setDate(new Date().getDate())),
            PlazoCredito: IdTipoPago === PAGO_CREDITO && 1,
        }

        this.setState({
            PagoEfectivoUnico: pagoEfectivoUnico,
            Pagos: [
                ...this.state.Pagos, pagoActual,
            ],
        });
    }

    async updateFechaPago(Index, date) {
        let Pagos = [...this.state.Pagos];

        Pagos[Index].FechaPago = date;
        Pagos[Index].PlazoCredito = Number(date);

        this.setState({
            Pagos: Pagos,
        });
    }

    async removePago(Index) {
        let Pagos = [...this.state.Pagos];


        if (Pagos[Index].IdTipoPago === 1)
            this.setState({
                PagoEfectivoUnico: !this.state.PagoEfectivoUnico
            })
        Pagos.splice(Index, 1);

        let credits = Pagos.filter(p => p.IdTipoPago === PAGO_CREDITO);

        this.setState({
            Pagos: Pagos,
            creditoSelected: credits.length > 0,

        });
        await this.setTotalPagado(Pagos);
    }

    async handleFocus(event) {
        event.target.select();
        await this.setTotalPagado(this.state.Pagos);
        this.setState({
            TipoModalidadPago: this.state.Pagos.some(
                ({IdTipoPago}) => IdTipoPago === PAGO_CREDITO
            )
                ? "CRÉDITO"
                : "CONTADO",
        });
    }

    async updateMontoPago(Index, Monto) {
        let Pagos = [...this.state.Pagos];
        Pagos[Index].Monto = await Monto;
        await this.setState({
            Pagos: Pagos,
        });
        await this.setTotalPagado(this.state.Pagos);
        await this.getVuelto();
    }

    updateReferenciaPago(Index, Referencia) {
        let Pagos = [...this.state.Pagos];
        Pagos[Index].Referencia = Referencia;
        this.setState({
            Pagos: Pagos,
        });
    }

    handleTipoComprobante(TipoComprobante) {
        let dets = [...this.state.detallePreventa];
        let contieneSinComprobante = dets.filter(d => String(d.IdTipoStock) === String(TIPO_STOCK.SIN_COMPROBANTE)).length;

        if (contieneSinComprobante && String(TipoComprobante) !== String(VALE))
            swal({
                title: "Alerta de venta",
                text: "Revise sus productos",
                icon: "warning",
                buttons: "Ok"
            });
        else
            this.setState({TipoComprobante: TipoComprobante,});
    }

    async handleDescuento(e) {
        this.setState({
            Descuento: Number(e.target.value),
        });

        await this.getTotales(e.target.value);

        await this.setTotalPagado(this.state.Pagos);

        await this.getVuelto();
    }

    async handleObservaciones(e) {
        this.setState({
            Observaciones: e.target.value,
        });
    }

    async calcularVenta(Descuento) {
        const TasaIGV = this.state.preventa.TasaIGV;
        const TasaICBPER = this.state.preventa.TasaICBPER;
        const TasaIVAP = IVAP;

        let datosParaCalculo = {
            Descuento,
            TasaIGV,
            TasaICBPER,
            TasaIVAP,
            Items: this.state.detallePreventa.map((detalle) => {
                return {
                    ValorUnitario: detalle.ValorUnitario,
                    Cantidad: detalle.Cantidad,
                    Descuento: detalle.Descuento,
                    Tributos: JSON.parse(detalle.Tributos),
                    TasaISC: detalle.TasaIsc,
                    IdStock: detalle.IdStock,
                    PrecioReferencial: detalle.PrecioReferencial,
                    IdAfectacionIgv: detalle.IdAfectacionIgv,
                    IdPresentacion: detalle.IdPresentacion,
                    TipoCambio: detalle.TipoCambio,
                    Gratuito: detalle.Gratuito,
                    PrecioVenta: detalle.PrecioVenta,
                };
            }),
        };

        let dets = [...this.state.detallePreventa];
        let tmpVenta = calcularVenta(datosParaCalculo);
        tmpVenta.Items = tmpVenta.Items.map(d => {
            return {
                ...d,
                PrecioVenta: dets.find(x => String(x.IdPresentacion) === String(d.IdPresentacion)).PrecioVenta,
            }
        })
        return tmpVenta;
    }

    async handlePagar() {
        let tieneProductosNoFacturables = this.tieneProductosNoFacturables(this.state.detallePreventa);
        let tipoDoc = this.state.TipoComprobante;
        if (tipoDoc > 1 && tieneProductosNoFacturables) {
            swal({
                title: "Alerta de venta",
                text: "Revise sus productos",
                icon: "warning",
                buttons: "Ok"
            });
        } else
            await this.continuePayment();
    }

    tieneProductosNoFacturables(dets) {
        for (let i = 0; i < dets.length; i++)
            if (!dets[i].EsFacturable)
                return true;
        return false;
    }

    async continuePayment() {
        try {
            if (this.state.TotalPagado < this.state.Total) {
                Notify("¡ERROR! Monto Pagado menor al Monto a Pagar");
            } else {
                this.setState({
                    BotonPagado: true,
                    error: null,
                    ClickPagar: false,
                    isLoading: true,
                    showPrint: false
                })
                const ventacalculada = await this.calcularVenta(this.state.Descuento);

                ventacalculada.Items = this.state.detallePreventa.map(d => {
                    return {...d, Total: d.Total}
                })

                this.registrarVenta(ventacalculada);
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                error: error,
            });
        }
    }

    async handleEnviarVenta(IdRegistroVentas) {
        await this.sendxml(IdRegistroVentas);
    }

    async handleEnviarEmail(IdRegistroVenta, DetalleVenta, Venta) {
        const response = await fetch(
            `/api/configFactElectronica/RegistroVenta/${IdRegistroVenta}`
        );
        const dataCorreo = await response.json();
        var docpdf = await crearDocPDF(DetalleVenta, Venta, this.state.extImg, this.state.cuentasBancarias);
        await enviaremail(dataCorreo.data[0], docpdf);
    }

    // async sendxml(IdRegistroVentas) {
    //     try {
    //         const response = await fetch(
    //             `/api/configFactElectronica/ce/${IdRegistroVentas}`
    //         );
    //         const xmlresponse = await response.json();
    //     } catch (error) {
    //         this.setState({error, isLoading: false});
    //     }
    // }
    async sendxml(IdRegistroVentas) {
        try {
            const response = await fetch(
                `/api/configFactElectronica/ce/${IdRegistroVentas}`
            );
            return response.json();
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }    

    async registrarVenta(ventaCalculada) {
        const totales = calcularTotales([...this.state.detallePreventa]);
        const pagos = [...this.state.Pagos]
        const pagosInfo = pagos? [...pagos.map(p => p.extra_metadata)].filter(e => !!e) : {}
        const body = {
                    IdCliente: this.state.preventa.IdCliente,
                    IdTipoOperacion: this.state.preventa.IdPreventa,
                    TipoTabla: "Preventas",
                    Estado: this.state.preventa.Estado,
                    IdModalidadPago: this.state.Pagos.some(
                        ({IdTipoPago}) => IdTipoPago === PAGO_CREDITO
                    )
                        ? CREDITO
                        : CONTADO,
                    IdTipoComprobante: this.state.TipoComprobante,
                    IdMoneda: this.state.preventa.IdMoneda,
                    TipoCambio: this.state.preventa.TipoCambio,
                    TasaIGV: this.state.preventa.TasaIGV,
                    TasaICBPER: this.state.preventa.TasaICBPER,
                    DescuentoTotal: this.state.Descuento,
                    Gravadas: totales.gravados,
                    Exoneradas: totales.exonerados,
                    Inafectas: totales.inafectos,
                    ICBPER: totales.icbper,
                    Exportacion: 0.0,
                    Total: totales.total,
                    Vuelto: this.state.Vuelto || "0",
                    Observacion: this.state.Observaciones,
                    IGV: totales.gravados * 0.18,
                    ISC: ventaCalculada.ISC,
                    Gratuitas: totales.gratuitos,
                    PorcentajeDescuento: ventaCalculada.PorcentajeDescuento,
                    IVAP: ventaCalculada.IVAP,
                    TasaIVAP: ventaCalculada.TasaIVAP,
                    TasaISC: ventaCalculada.ISC,
                    descuento: ventaCalculada.Descuento,
                    Redondeo: totales.redondeo,
                    pagosInfo: JSON.stringify(pagosInfo),
                    guiaRemision: this.state.GuiaRemision,
                    ordenCompra: this.state.OrdenCompra,
                }

        try {
            const response = await fetch("/api/ventas/new", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const responseData = await response.json();

            const currentQuotation = sessionStorage.getItem(CURRENT_QUOTATION_KEY);

            if (!isNaN(Number(currentQuotation))) {
                try {
                    await fetch(`/api/cotizaciones/update/${currentQuotation}`, {
                        method: 'PUT',
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            estado: 'FACTURADO'
                        })
                    });
                } catch(e) {
                    console.error(e);
                }

                sessionStorage.removeItem(CURRENT_QUOTATION_KEY);
            }

            const [[table_data], [header]] = responseData;
            let error = false;
            if (header._estado)
                error = header._estado;
            else if (header._estadoRegistroVentas !== true)
                error = header._estadoRegistroVentas;

            if (error) {
                Notify("ERROR " + error, "error");
                this.setState({
                    error: error,
                    isLoading: false,
                })
            } else {
                const [{IdRegistroVenta}] = table_data;

                this.setState({IdRegistroVenta: IdRegistroVenta});

                await this.registrarPago(IdRegistroVenta);

                if (this.state.TipoComprobante !== VALE) {
                    await this.saveDetalleVenta(ventaCalculada, IdRegistroVenta);

                    let [Venta, DetalleVenta] = await Promise.all([
                        this.getVenta(IdRegistroVenta),
                        this.getDetalleVenta(IdRegistroVenta),

                    ]);
                    this.setState({isLoading: false, hasPayed: true})

                    this.handleEnviarVenta(IdRegistroVenta)
                        .then(() => this.handleEnviarEmail(IdRegistroVenta, DetalleVenta, Venta))


                    if (this.state.ImprimirA4.Estado === 1) {
                        await printA4(DetalleVenta, Venta, this.state.extImg, this.state.cuentasBancarias);
                    } else {
                        await this.printTicketVenta(Venta, DetalleVenta);
                    }

                } else {
                    await this.saveDetalleVale(ventaCalculada, IdRegistroVenta);

                    let [Venta, DetalleVenta] = await Promise.all([
                        this.getVale(IdRegistroVenta),
                        this.getDetalleVale(IdRegistroVenta),
                    ]);
                    this.setState({isLoading: false, hasPayed: true})


                    if (this.state.ImprimirA4.Estado === 1) {
                        await printA4(DetalleVenta, Venta, this.state.extImg, this.state.cuentasBancarias);
                        // this.props.history.push("/gestionpreventas");
                    } else {
                        await this.printTicketVenta(Venta, DetalleVenta);
                    }
                }
            }

        } catch (error) {
            console.log("error trye ", error)
            this.setState({error, isLoading: false});
        }

    }

    async saveDetalleVenta(detallesCalculados, IdRegistroVenta) {
        const Items = detallesCalculados.Items.map((detalle) => {
            return [
                IdRegistroVenta,
                detalle.IdStock,
                detalle.Cantidad,
                detalle.PrecioReferencial,
                detalle.PrecioVenta,
                detalle.IdAfectacionIgv,
                detalle.IdPresentacion,
                detalle.Total,
                detalle.Descuento,
                detalle.TipoCambio,
                detalle.ISC,
                detalle.IGV,
                detalle.ICBPER,
                detalle.IVAP,
                detalle.TasaISC,
                detalle.ValorUnitario,
                detalle.DescuentoSI,
                detalle.Gratuito,
            ];
        });

        try {
            const response = await fetch(`/api/ventas/detalles/new`, {
                method: "POST",
                body: JSON.stringify({
                    Items,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            return await response.json();
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async saveDetalleVale(detallesCalculados, IdVale) {

        const Items = detallesCalculados.Items.map((detalle) => {
            return [
                IdVale,
                detalle.IdStock,
                detalle.Cantidad,
                detalle.PrecioReferencial,
                detalle.PrecioVenta,
                detalle.IdAfectacionIgv,
                detalle.IdPresentacion,
                detalle.Total,
                detalle.Descuento,
                detalle.TipoCambio,
                detalle.ISC,
                detalle.IGV,
                detalle.ICBPER,
                detalle.IVAP,
                detalle.TasaISC,
                detalle.ValorUnitario,
                detalle.DescuentoSI,
                detalle.Gratuito,
            ];
        });

        try {
            const response = await fetch(`/api/ventas/detalles/vales/new`, {
                method: "POST",
                body: JSON.stringify({
                    Items,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            return await response.json();
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getVenta(IdRegistroVenta) {
        try {
            const response = await fetch(`/api/ventas/get/${IdRegistroVenta}`);
            const Venta = await response.json();
            this.setState({
                Venta: Venta.respuesta[0],
            });
            Notify("¡CORRECTO! Venta Registrada");
            return Venta.respuesta[0];
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getDetalleVenta(IdRegistroVenta) {
        try {
            const response = await fetch(`/api/ventas/detalles/${IdRegistroVenta}`);
            const DetallesVenta = await response.json();
            this.setState({
                DetallesVenta: DetallesVenta.respuesta,
            });
            return DetallesVenta.respuesta;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getVale(IdVale) {
        try {
            const response = await fetch(`/api/vales/get/${IdVale}`);
            const Venta = await response.json();
            this.setState({
                Venta: Venta.respuesta[0],
            });

            Notify("¡CORRECTO! Venta Registrada");
            return Venta.respuesta[0];
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getDetalleVale(IdVale) {
        try {
            const response = await fetch(`/api/vales/detalles/${IdVale}`);
            const DetallesVenta = await response.json();

            this.setState({
                DetallesVenta: DetallesVenta.respuesta,
            });
            return DetallesVenta.respuesta;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    registrarPago(IdRegistroVenta) {

        const DetallesPagos = this.state.Pagos.map((pago) => {
            return [
                IdRegistroVenta,
                pago.IdTipoPago,
                Number(pago.Monto),
                pago.IdTipoPago === PAGO_CREDITO ? "PENDIENTE" : "PAGADO",
                pago.Referencia,
                pago.FechaPago && moment(pago.FechaPago).format("YYYY-MM-DD"),
                true,
                pago.descuento
            ];
        });
        if (this.state.TipoComprobante !== VALE) {
            fetch("/api/pagos/new", {
                method: "POST",
                body: JSON.stringify({
                    DetallesPagos,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.affectedRows === 0) {
                        Notify("ERROR ");
                        this.setState({
                            ClickPagar: true
                        })
                    }
                })
                .catch((error) => {
                    this.setState({error, isLoading: false});
                });
        } else {
            fetch("/api/pagos/vale/new", {
                method: "POST",
                body: JSON.stringify({
                    DetallesPagos,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.affectedRows === 0) {
                        Notify("error");
                        this.setState({
                            ClickPagar: true
                        })
                    }
                })
                .catch((error) => {

                    this.setState({error, isLoading: false});
                });
        }
    }

    async handleSavePDF() {
        let doc = await new jsPDF({});
        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text(
            this.state.Venta.TipoComprobante +
            " " +
            this.state.Venta.Serie +
            "-" +
            this.state.Venta.NumeroComprobante,
            90,
            10
        );
        doc.text(this.state.Venta.RazonSocial, 70, 20);
        doc.autoTable({
            body: this.state.DetallesVenta,
            startY: 120,
            columns: [
                {header: "CANTIDAD", dataKey: "Cantidad"},
                {header: "PRODUCTO", dataKey: "NombreProducto"},
                {header: "PRESENTACIÓN", dataKey: "NombrePresentacion"},
                {header: "PRECIO", dataKey: "PrecioVenta"},
                {header: "IMPORTE", dataKey: "Importe"},
            ],
            theme: "plain",
        });
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.autoPrint();
        window.open(doc.output("bloburl"), "_blank");

        doc.save(
            this.state.Venta.Serie + "-" + this.state.Venta.NumeroComprobante + ".pdf"
        );
    }

    setRedirect(ruta) {
        if (this.conf.useAccessCode) {
            const data = JSON.stringify({
                action: ACTION.UPDATE,
                entity: ENTITY.PREVENTA,
                entityId: this.state.IdPreventa,
                redirect: `/preVentas/edit/${this.state.IdPreventa}`,
                description: `Editar preventa PV-${this.state.IdPreventa}`
            })
            this.props.history.push(`/check-access/?data=${data}`)
        } else {

            this.setState({
                Redirect: ruta,
            });
        }
    }

    async printTicketVenta(Venta, DetalleVenta) {
        await printTicket(DetalleVenta, Venta, this.state.extImg);
        // this.props.history.push("/gestionpreventas");
    }

    handlePagoEnterKeyUp(event) {

        if (event.key === "Enter" && this.state.clicksEnter === 0) {
            this.handlePagar();
        }
        this.setState({
            clicksEnter: this.state.clicksEnter + 1
        })
    }

    onKeyUpModalCuotasCredito(e) {
        if (e.key === "Escape" || e.key === "Enter")
            this.setState({showModalCuotasCredito: false});
    }

    onKeyUpModalYape(e) {
        if (e.key === "Escape" || e.key === "Enter")
            this.setState({showModalYape: false});
    }

    onKeyUpModalFpay(e) {
        if (e.key === "Escape" || e.key === "Enter")
            this.setState({showModalFpay: false});
    }

    onAceptarCuotasCredito(data) {
        let IdTipoPago = PAGO_CREDITO;

        let pagoEfectivoUnico = this.state.PagoEfectivoUnico
        if (IdTipoPago === 1)
            pagoEfectivoUnico = !this.state.PagoEfectivoUnico;

        let total = this.state.Total;
        let montoPagar = total / data.cantCuotas;
        let pagos = [];
        let initialDate = moment(new Date()).add(data.cantDias, 'days');

        for (let i = 1; i <= data.cantCuotas; i++) {
            pagos.push({
                IdTipoPago,
                Monto: montoPagar,
                Referencia: null,
                FechaPago: initialDate,
                PlazoCredito: IdTipoPago === PAGO_CREDITO && 1,
                descuento: data.descuento
            })
            initialDate = moment(initialDate).add(data.cantDias, 'days');
        }

        this.setState({
            infoCuotasCredito: data,
            showModalCuotasCredito: false,
            PagoEfectivoUnico: pagoEfectivoUnico,
            creditoSelected: true,
            Pagos: [
                ...this.state.Pagos,
                ...pagos
            ],
        });

    }

    async onAgregarPagoFpay(data) {
        // Todo: fragmento
        let IdTipoPago = FPAY;
        let pagoEfectivoUnico = this.state.PagoEfectivoUnico

        const pagos = this.state.Pagos.length
        let acumulado = 0
        if (pagos > 1) {
            acumulado = this.state.Pagos.reduce((a, b) => Number(a.Monto) + Number(b.Monto))
        }
        const pagoActual = {
            IdTipoPago,
            Monto: data.monto,
            Referencia: null,
            FechaPago:
                IdTipoPago === PAGO_CREDITO
                    ? new Date(new Date().setDate(new Date().getDate()))
                    : new Date(new Date().setDate(new Date().getDate())),
            PlazoCredito: IdTipoPago === PAGO_CREDITO && 1,
            extra_metadata: {type:"Fpay", fpayResponse: {...data.intentionFpay}},
        }
        this.setState({
            PagoEfectivoUnico: pagoEfectivoUnico,
            Pagos: [
                ...this.state.Pagos, pagoActual,
            ],
        });
        const {branchInfo} = data
        // const resp = await this.addNewInvoiceFpayCount(branchInfo.IdCaja)
        await this.addNewInvoiceFpayCount(branchInfo.IdCaja)
    }

    handleGuiaRemision(guiaRemision) {
        this.setState({
            GuiaRemision: guiaRemision.IdGuiaRemision,
        });
    }

    handleOrdenCompra(e) {
        this.setState({
            OrdenCompra: e.target.value,
        });
    }

    render() {
        if (this.state.Redirect) {
            return (
                <Redirect
                    to={{
                        pathname: this.state.Redirect,
                        from: this.props.location,
                    }}
                />
            );
        }
        if (this.state.MostrarPagina === false) {
            return (
                <React.Fragment>
                    <div>
                        <PageLoading/>
                    </div>
                </React.Fragment>
            )
        }
        return (
            <>
                <PagosUI
                    {...this.state}
                    handleTipoPago={this.handleTipoPago}
                    updateMontoPago={this.updateMontoPago}
                    updateReferenciaPago={this.updateReferenciaPago}
                    handleTipoComprobante={this.handleTipoComprobante}
                    handleDescuento={this.handleDescuento}
                    handlePagar={this.handlePagar}
                    handleSavePDF={this.handleSavePDF}
                    handleObservaciones={this.handleObservaciones}
                    setRedirect={this.setRedirect}
                    printTicketVenta={this.printTicketVenta}
                    handleFocus={this.handleFocus}
                    updateFechaPago={this.updateFechaPago}
                    handlePagoEnterKeyUp={this.handlePagoEnterKeyUp}
                    removePago={this.removePago}
                    handleGuiaRemision={this.handleGuiaRemision}
                    handleOrdenCompra={this.handleOrdenCompra}
                />
                <Modal
                    isOpen={this.state.showModalCuotasCredito}
                    onClose={() => this.setState({showModalCuotasCredito: false})}
                    title={"Forma de pago crédito"}
                    handleKeyUp={this.onKeyUpModalCuotasCredito}
                >
                    <div className={this.state.showModalCuotasCredito ? "container" : null}
                         style={this.state.showModalCuotasCredito ? {width: "200px"} : null}>
                        <CuotasCreditoModal
                            onAceptarCuotasCredito={this.onAceptarCuotasCredito}
                        />
                    </div>
                </Modal>
                <ModalFpay2
                    isOpen={this.state.showModalFpay}
                    onClose={() => this.setState({showModalFpay: false})}
                    title={"Forma de pago Fpay"}
                    handleKeyUp={this.onKeyUpModalFpay}
                >
                    <div className={this.state.showModalFpay ? "container" : null}
                         style={this.state.showModalFpay ? {width: "700px"} : null}>
                        <ModalFpay
                            onClose={() => this.setState({showModalFpay: false})}
                            pagoUnico={this.state.Total}
                            montoActual={this.state.TotalPagado}
                            productos={this.state.detallePreventa}
                            onAgregarPagoFpay={this.onAgregarPagoFpay}
                            docInfo={this.state.docSunat}
                        />
                    </div>
                </ModalFpay2>
            </>
        );
    }
}

export default Pagos;
