import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactExport from "react-data-export";
import "react-moment";
import moment from "moment";
import {BotonLetras} from "../../componentes/Plantillas/Botones";

import MonthPickerInput from "react-month-picker-input";

import("react-month-picker-input/dist/react-month-picker-input.css");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function getDataExportExcel(dataSet) {
    return [
        {
            columns: [
                {
                    title: "CodigoUnicoOperacion",
                    width: {wpx: 90}
                },
                {
                    title: "FechaEmision",
                    width: {wpx: 100}
                },
                // {
                //   title: "FechaVencimiento",
                //   width: { wpx: 100 }
                // },
                {
                    title: "TipoComprobante",
                    width: {wpx: 100}
                },
                {
                    title: "Serie",
                    width: {wpx: 60}
                },
                {
                    title: "Numero Comprobante",
                    width: {wpx: 100}
                },
                {
                    title: "TipoDocumentoCliente",
                    width: {wpx: 100}
                },
                {
                    title: "NroTipoDocumentoCliente",
                    width: {wpx: 120}
                },
                {
                    title: "RazonSocial",
                    width: {wpx: 200}
                },
                {
                    title: "Estado del Comprobante",
                    width: {wpx: 100}
                },
                {
                    title: "Modalidad de Pago",
                    width: {wpx: 100}
                },
                {
                    title: "Cantidad de Días",
                    width: {wpx: 100}
                },
                {
                    title: "Fecha Pago Crédito",
                    width: {wpx: 100}
                },
                {
                    title: "Exportación",
                    width: {wpx: 100}
                },
                {
                    title: "Gravadas",
                    width: {wpx: 100}
                },
                {
                    title: "Exoneradas",
                    width: {wpx: 100}
                },
                {
                    title: "Inafectas",
                    width: {wpx: 100}
                },
                {
                    title: "ISC",
                    width: {wpx: 100}
                },
                {
                    title: "IGV",
                    width: {wpx: 100}
                },
                {
                    title: "ICBPER",
                    width: {wpx: 100}
                },
                {
                    title: "Total",
                    width: {wpx: 100}
                },
                {
                    title: "Redondeo",
                    width: {wpx: 100}
                },
                {
                    title: "TotalRedondeo",
                    width: {wpx: 100}
                },
                {
                    title: "TipoCambio",
                    width: {wpx: 100}
                },
                {
                    title: "FechaComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "CodigoTipoComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "SerieComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "NumeroComprobanteModificado",
                    width: {wpx: 100}
                }
            ],

            data: dataSet.map(row => {
                return [
                    {
                        value: row.CodigoUnicoOperacion,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaEmision,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    // {
                    //   value: row.FechaVencimiento,
                    //   style: {
                    //     fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                    //   }
                    // },
                    {
                        value: row.TipoComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Serie,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoDocumentoCliente,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NroTipoDocumentoCliente,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.RazonSocial,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },


                    {
                        value: row.Estado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.IdModalidadPago,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.CantidadDiasCredito,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaPago,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },


                    {
                        value: row.Exportacion || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Gravadas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Exoneradas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Inafectas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.ISC || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.IGV || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.ICBPER || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Total || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Redondeo || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TotalRedondeo || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoCambio,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.CodigoTipoComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.SerieComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    }
                ];
            })
        }
    ];
}

const RegistrosVentasUI = props => {
    if (props.reportePLE) {
        var dataExport = getDataExportExcel(props.reportePLE);
    }
    const mystyle = {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    };
    const mystyle2 = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    };

    return (
        <>
            <div className="container mt-5">
                <div className="mb-1" style={mystyle2}>
                    <BotonLetras
                        title="Haz click para ver"
                        onClick={props.handleMasReportesVentas}
                        className="mr-5"
                    >
                        {" "}
                        <i className="fas fa-plus"/> Reportes de Ventas Personalizados
                    </BotonLetras>
                    <div className="" style={mystyle}>
                        <div>
                            <span>Selecciona un Mes</span>
                            <MonthPickerInput
                                year={props.Year}
                                month={props.Mes}
                                onChange={props.handleMonthPickerChange}
                                closeOnSelect="true"
                                lang="es"
                                mode="calendarOnly"
                            />
                        </div>

                        <button
                            className="boton__pequeno--y ml-3 mr-2"
                            title="Descarga TXT PLE"
                            onClick={props.txtPLE}
                        >
                            {<i class="fas fa-file-export"></i>}
                        </button>
                        <button
                            className="boton__Rojo mr-2"
                            onClick={props.pdfViewer}
                            title="Descarga reporte mensual de Ventas en PDF"
                        >
                            {<i className="fas fa-file-pdf"></i>}
                        </button>
                        <ExcelFile
                            element={
                                <button
                                    className="boton__verdeOscuro"
                                    title="Descarga reporte mensual de Ventas en EXCEL"
                                >
                                    <i className="fas fa-file-excel"></i>
                                </button>
                            }
                            filename="ReporteVentas"
                        >
                            <ExcelSheet dataSet={dataExport} name="Ventas"></ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>
                <ReactTable
                    id="ventas"
                    // width='1720'
                    data={props.ventas}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                    }
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "Opciones",
                                    id: "IdRegistroVenta",
                                    width: 90,
                                    filterable: false,
                                    accessor: d => d.IdRegistroVenta,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IdRegistroVenta"]
                                        }),
                                    Cell: f => {
                                        return (
                                            <div align="center">
                                                <button
                                                    className="boton__outline-pequeno--c"
                                                    onClick={props.handleVerVenta(
                                                        f.original.IdRegistroVenta
                                                    )}
                                                    title="Ver detalle de Venta"
                                                >
                                                    {<i class="fas fa-money-check"></i>}
                                                </button>

                                                {/*<button*/}
                                                {/*    className="btn btn-outline-success"*/}
                                                {/*    onClick={props.handleEnviarVenta(*/}
                                                {/*        f.original.IdRegistroVenta*/}
                                                {/*    )}*/}
                                                {/*>*/}
                                                {/*    {<i class="fas fa-file-import"></i>}*/}
                                                {/*</button>*/}

                                                {/*<button*/}
                                                {/*    IdRegistroVenta={f.value}*/}
                                                {/*    value={f.original.IdRegistroVenta}*/}
                                                {/*    type="button"*/}
                                                {/*    className="btn btn-outline-danger ml-3"*/}
                                                {/*    onClick={props.handleAnularVenta(f.original.IdRegistroVenta)}*/}
                                                {/*>*/}
                                                {/*    {<i className="fas fa-trash-alt"></i>}*/}
                                                {/*</button>*/}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    Header: "Cliente",
                                    id: "RazonSocial",
                                    width: 290,
                                    accessor: d => d.RazonSocial,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Sucursal",
                                    id: "Sucursal",
                                    width: 120,
                                    accessor: d => d.Sucursal,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["Sucursal"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Fecha Emisión",
                                    id: "FechaEmision",
                                    width: 100,
                                    accessor: d => moment(d.FechaEmision).format("DD-MM-YYYY"),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Modalidad Pago",
                                    id: "IdModalidadPago",
                                    width: 100,
                                    accessor: d => d.IdModalidadPago,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IdModalidadPago"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Días Crédito",
                                    id: "CantidadDiasCredito",
                                    width: 120,
                                    accessor: d => d.CantidadDiasCredito,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["CantidadDiasCredito"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Fecha de Pago",
                                    id: "FechaPago",
                                    width: 120,
                                    accessor: d => d.FechaPago != '-' ? moment(d.FechaPago).format("DD-MM-YYYY") : '-',
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["FechaPago"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Estado",
                                    id: "Estado",
                                    width: 100,
                                    accessor: d => d.Estado,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Estado"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Tipo Comprobante",
                                    id: "TipoComprobante",
                                    width: 110,
                                    accessor: d => d.TipoComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["TipoComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Serie",
                                    id: "Serie",
                                    width: 90,
                                    accessor: d => d.Serie,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Serie"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Numero Comprobante",
                                    id: "NumeroComprobante",
                                    width: 90,
                                    accessor: d => d.NumeroComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["NumeroComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Moneda",
                                    id: "Abreviatura",
                                    width: 90,
                                    accessor: d => d.Abreviatura,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Abreviatura"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Total",
                                    id: "Total",
                                    width: 90,
                                    accessor: d =>
                                        d.Total.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Total"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Redondeo",
                                    id: "Redondeo",
                                    accessor: d => d.Redondeo == null ? '0.00' : d.Redondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Redondeo"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "TotalRedondeo",
                                    id: "TotalRedondeo",
                                    accessor: d => d.TotalRedondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) ? d.TotalRedondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) : 0.0,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["TotalRedondeo"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Gravadas",
                                    id: "Gravadas",
                                    width: 90,
                                    accessor: d =>
                                        d.Gravadas.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Gravadas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Exoneradas",
                                    id: "Exoneradas",
                                    width: 90,
                                    accessor: d =>
                                        d.Exoneradas.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Exoneradas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Inafectas",
                                    id: "Inafectas",
                                    width: 90,
                                    accessor: d =>
                                        d.Inafectas.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Inafectas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "IGV",
                                    id: "IGV",
                                    width: 90,
                                    accessor: d =>
                                        d.IGV.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IGV"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "ICBPER",
                                    id: "ICBPER",
                                    width: 90,
                                    accessor: d =>
                                        d.ICBPER.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["ICBPER"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Descuento",
                                    id: "DescuentoTotal",
                                    width: 90,
                                    accessor: d =>
                                        d.DescuentoTotal || "0",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["DescuentoTotal"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={10}
                    // showPagination={false}
                    //pageSize={10}
                    className="-striped -highlight"
                    // Text de paginación
                    showPageJump={false}
                    previousText='Anterior'
                    nextText='Siguiente'
                    loadingText='Cargando...'
                    noDataText='No se encontraron registros'
                    pageText='Página'
                    ofText='de'
                    rowsText='filas'
                    // width='100%'
                    style={{
                        // width: "2000",
                        fontSize: "14px"
                    }}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                />
            </div>
        </>
    );
};

export default RegistrosVentasUI;
