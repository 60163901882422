import React from "react";
import UpdateCostJobForm from "./UpdateCostJobForm";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import {decimalAdjust} from "../../Global";

let _isMounted = false

export default class UpdateCostJobScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        Object.defineProperty(this, 'isMounted', {
            set(v) {
                _isMounted = v
            },
            get() {
                return _isMounted
            }
        })
        this.addJob = this.addJob.bind(this)
    }

    componentDidMount() {
        this.isMounted = true
    }

    componentWillUnmount() {
        this.isMounted = false
    }

    addJob(payload) {
        const tmpPayload = JSON.parse(JSON.stringify(payload))
        tmpPayload.fechaInicio = `${tmpPayload.fechaInicio} ${tmpPayload.horaInicio}`
        tmpPayload.fechaFin = `${tmpPayload.fechaFin} ${tmpPayload.horaFin}`;
        tmpPayload.items = tmpPayload.items && tmpPayload.items.length ? tmpPayload.items.map(i => {
            const precioMenorFinal = Boolean(i.afectacionFree) ? i.precioMenorFinal : i.precioMenorFinal / 1.18
            const precioMayorFinal = Boolean(i.afectacionFree) ? i.precioMayorFinal : i.precioMayorFinal / 1.18
            return {
                ...i,
                precioMenorFinal: decimalAdjust("floor", precioMenorFinal, -6),
                precioMayorFinal: decimalAdjust("floor", precioMayorFinal, -6)
            }
        }) : []
        tmpPayload.items.forEach(i => {
            delete i.precios
        });

        this.setState({isLoading: true})
        fetch('/api/jobs/add/update-cost', {
            method: "POST",
            body: JSON.stringify(tmpPayload),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async res => [await res.json(), res.status])
            .then(d => {
                const [data, status] = d
                if (status === 200) {
                    notificarMsg("Se agregó con éxito")
                    this.props.history.push("/jobs/")
                }
                if (status === 422)
                    notificarError(data.msg)
            })
            .catch(() => notificarError("Ha ocurrido un error al momento de agregar"))
            .finally(() => {
                if (this.isMounted)
                    this.setState({isLoading: false})
            })

    }

    render() {
        return (
            <div className="container">
                <UpdateCostJobForm onRegresar={() => this.props.history.push("/jobs/")}
                                   isLoading={this.state.isLoading}
                                   onAgregar={this.addJob}/>
            </div>
        );
    }
}
