import React from 'react';
import "react-moment";
//Componentes
import EmpresasAddFormDatosSunat from "./empresasComponentes/EmpresasAddFormDatosSunat.js"
import EmpresasAddFormDatosGenerales from "./empresasComponentes/EmpresasAddFormDatosGenerales.js"
import EmpresasAddFormDatosLogoFirmas from "./empresasComponentes/EmpresasAddFormDatosLogoFirmas.js"
import {validarInputsEmpresa} from "./empresasComponentes/EmpresasValidator.js";
import {notificationError} from './empresasComponentes/EmpresasNotify.js'
import PageLoading from './PageLoading.js';
import './styles/tabla.css';
import apiFetch from '../helpers/apiFetch.js';
import {BotonGuardarLetras, BotonCancelar} from "./Plantillas/Botones";
import "react-moment";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Notify from './Notify.js';


class AddEmpresa extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mostrarCorreosOpcionales: true,
            mostrarTelefonosOpcionales: true,
            AddOrEdit: "Add",
            mostrarLogos: false,
            RucPre: "",
            Ruc: null,
            RazonSocial: "",
            Direccion: "",
            Telefono: "123123123",
            TelefonoDos: "",
            TelefonoTres: "",
            IdUbigeo: null,
            NombreComercial: "",
            PaginaWeb: "",
            CorreoElectronico: "test_email@test.com",
            CorreoElectronicoDos: "",
            CorreoElectronicoTres: "",
            Sector: 'Comercial',
            FechaDeConstitucion: moment().format("YYYY-MM-DD"),
            RepresentanteLegal: "Nombre Genérico",
            Retenedor: "Inactivo",
            Percepcion: "Inactivo",
            EsRuss: "Inactivo",
            IdPlanSunat: 1,
            IdMoneda: 1,
            ContribuyenteCondicion: "",
            ContribuyenteEstado: "",
            FechaDeInscripcion: moment().format("YYYY-MM-DD"),
            SistemaContabilidad: "MANUAL",
            SistemaEmision: "MANUAL",
            UsuarioSolPrincipal: "lmao",
            ClaveSolPrincipal: "lmao",
            ubigeoNombrecodigo: "",
            IdEmpresaNew: '',
            Banco:"",
            NumeroCuenta:"",
            CCI:"",
            Errores: {
                Ruc: "",
                RazonSocial: "",
                Direccion: "",
                Telefono: "",
                TelefonoDos: "",
                TelefonoTres: "",
                NombreComercial: "",
                PaginaWeb: "",
                CorreoElectronico: "",
                CorreoElectronicoDos: "",
                CorreoElectronicoTres: "",
                Sector: "",
                RepresentanteLegal: "",
                FechaDeInscripcion: "",
                FechaDeConstitucion: ""
            },

            data: {
                ubigeos: []
            },
            dataP: {
                planesSunat: []
            },
            dataM: {
                results: []
            },

            checkRetenedor: false,
            checkPercepcion: false,
            checkEsRuss: false,

            ubigeoReg: [],
            ubigeoProv: [],
            ubigeoDis: [],
            valueRegion: '01',
            valueProvincia: '01',
            valueDistrito: '01',
            Hoy: '',
            pruebaLogo: new FormData(),
            pruebaFirma: new FormData(),

            formDataL: new FormData(),
            formDataF: new FormData()
        }
        //this.TraerEmpresaPorRUC = this.TraerEmpresaPorRUC.bind(this)
        this.TraerEmpresaPorRUC_API = this.TraerEmpresaPorRUC_API.bind(this)

        this.newEmpresa = this.newEmpresa.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.EsRussChange = this.EsRussChange.bind(this);
        this.getUbigeo = this.getUbigeo.bind(this)
        this.getPlanesSunat = this.getPlanesSunat.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getMoneda = this.getMoneda.bind(this);
        this.SubirLogo = this.SubirLogo.bind(this);
        this.SubirFirma = this.SubirFirma.bind(this);
    }

    SubirLogo = async (e) => {
        var logoInput = document.getElementById('logoInput');
        let file = logoInput.files[0];


        var archivoRuta = logoInput.value;
        var extPermitidas = /(.png|.jpg|.jpeg|.PNG|.JPG|.JPEG)$/i;
        if (!extPermitidas.exec(archivoRuta)) {
            Notify('Ingrese una imagen con alguno de los siguientes formatos: .jpeg/.jpg/.png.', "warning", 1500);
            logoInput.value = '';
            //document.getElementById('visorLogo').innerHTML = ''; //Visualizar el logo
            return false;
        } else if (logoInput.files && logoInput.files[0]) {
            var visor = new FileReader();
            visor.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () { //Mantener el function ya que con = () => se cae, hacer la prueba de bandera con function ()
                    // console.log("img ------ ",this.width);
                    //Acá se necesita hacer la validacion del tamaño de la imagen y evitar que se guarde. //si cumple el tamaño insertarlo en visorLogo

                    if (this.width >= 10 && this.width <= 5000) {

                        document.getElementById('visorLogo').innerHTML = '<img name="Logo" src="' + e.target.result + '" >';//El que permite visaulizar el logo subido

                    } else {
                        alert('La imagen debe ser tener un ancho entre 10 y 5000');
                        // logoInput.value='';
                        return false;
                    }
                };
            }//bandera para ver si se hace el readAsDataURL o no se ejecuta primero el readAsDataURl y el append y luego el onload. ver el orden
            visor.readAsDataURL(logoInput.files[0]);//console.log(visor.result);
        } //Bandera para saber si se hace el append o no
        // this.state.pruebaLogo.append("Logo",e.target.files[0],`${this.state.Ruc}.jpeg`); //El que guarda y esta listo para enviarse al back


        this.state.formDataL.append("file", file);
        // const respuesta = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/upload/img/${this.state.Ruc}/logo`, {method: "POST", body: formData});
        // const rpt = await respuesta.json();
        // console.log(rpt.success)// para controlar si se suve o no
    }
    SubirFirma = async (e) => {
        var FirmaInput = document.getElementById('FirmaInput');
        let file = FirmaInput.files[0];


        var archivoRuta = FirmaInput.value;
        var extPermitidas = /(.png|.jpg|.jpeg|.PNG|.JPG|.JPEG)$/i;
        if (!extPermitidas.exec(archivoRuta)) {
            alert('Ingrese una imagen con alguno de los siguientes formatos: .jpeg/.jpg/.png.');
            FirmaInput.value = '';
            //document.getElementById('visorFirma').innerHTML = ''; //Visualizar el Firma
            return false;
        } else if (FirmaInput.files && FirmaInput.files[0]) {
            var visor = new FileReader();
            visor.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () { //Mantener el function ya que con = () => se cae, hacer la prueba de bandera con function ()
                    // console.log("img ------ ",this.width);
                    if (this.width >= 10 && this.width <= 5000) {
                        document.getElementById('visorFirma').innerHTML = '<img name="Firma" src="' + e.target.result + '" >';
                    } else {
                        alert('La imagen debe ser tener un ancho entre 50 y 500');
                        FirmaInput.value = '';
                        return false;
                    }
                };
            }

            visor.readAsDataURL(FirmaInput.files[0]);
        }


        this.state.formDataF.append("file", file);
        // console.log("FirmaInput: ",FirmaInput.value)
        // const respuesta = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/upload/img/${this.state.Ruc}/firma`, {method: "POST", body: formData});
        // const rpt = await respuesta.json();
    }


    componentDidMount = () => {
        this.getUbigeo();
        this.getPlanesSunat();
        this.getMoneda();
        const Fecha = new Date();
        this.setState({
            Hoy: moment(Fecha).format('YYYY-MM-DD')
        });
    }

    getMoneda = async () => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch('/api/empresas/monedas/');
            const data = await response.json();
            // console.log(data)
            this.setState({
                loading: false,
                dataM: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getPlanesSunat = async () => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch('/api/planesSunat/planes');
            const data = await response.json();
            //console.log(data.planesSunat)
            this.setState({
                loading: false,
                dataP: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getUbigeo = async () => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch('/api/ubigeos/ubigRegion');
            const data = await response.json();
            this.setState({
                loading: false,
                data: data,
                valueRegion: data.ubigeos[0].CodRegion,
                valueProvincia: data.ubigeos[0].CodProvincia,
                IdUbigeo: data.ubigeos[0].IdUbigeo,
                valueDistrito: data.ubigeos[0].IdUbigeo
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }
    RetenedorChange = (e) => {
        if (this.state.Retenedor === "Activo") {
            this.setState({Retenedor: "Inactivo", checkRetenedor: false})
        } else {
            this.setState({Retenedor: "Activo", checkRetenedor: true})
        }
    }

    PercepcionChange = () => {
        if (this.state.Percepcion === "Activo") {
            this.setState({Percepcion: "Inactivo", checkPercepcion: false})
        } else {
            this.setState({Percepcion: "Activo", checkPercepcion: true})
        }
    }
    EsRussChange = () => {
        if (this.state.EsRuss === "Activo") {
            this.setState({EsRuss: "Inactivo", checkEsRuss: false})
        } else {
            this.setState({EsRuss: "Activo", checkEsRuss: true})
        }
        // console.log("EsRussChange: ",this.state.EsRuss)
    }
    newEmpresa = async (e) => {

        let valido = true
        Object.values(this.state.Errores).forEach(val => {
            val.length > 1 && (valido = false)
        })
        //console.log(this.state)

        if (valido) {
            //notificationAdd()
            apiFetch('/api/empresas/', ["_estadoInsertarEmpresa"], {
                method: 'POST',
                body: JSON.stringify({
                    Ruc: this.state.Ruc,
                    RazonSocial: this.state.RazonSocial,
                    Direccion: this.state.Direccion,
                    Telefono: this.state.Telefono,
                    TelefonoDos: this.state.TelefonoDos,
                    TelefonoTres: this.state.TelefonoTres,
                    IdUbigeo: this.state.IdUbigeo,
                    NombreComercial: this.state.NombreComercial,
                    PaginaWeb: this.state.PaginaWeb,
                    IdMoneda: this.state.IdMoneda,
                    CorreoElectronico: this.state.CorreoElectronico,
                    CorreoElectronicoDos: this.state.CorreoElectronicoDos,
                    CorreoElectronicoTres: this.state.CorreoElectronicoTres,
                    Sector: this.state.Sector,
                    FechaDeConstitucion: this.state.FechaDeConstitucion,
                    RepresentanteLegal: this.state.RepresentanteLegal,
                    Retenedor: this.state.Retenedor,
                    Percepcion: this.state.Percepcion,
                    EsRuss: this.state.EsRuss,
                    IdPlanSunat: this.state.IdPlanSunat,
                    ContribuyenteCondicion: this.state.ContribuyenteCondicion,
                    ContribuyenteEstado: this.state.ContribuyenteEstado,
                    FechaDeInscripcion: this.state.FechaDeInscripcion,
                    SistemaContabilidad: this.state.SistemaContabilidad,
                    SistemaEmision: this.state.SistemaEmision,
                    UsuarioSolPrincipal: this.state.UsuarioSolPrincipal,
                    ClaveSolPrincipal: this.state.ClaveSolPrincipal,
                    Banco:this.state.Banco,
                    NumeroCuenta:this.state.NumeroCuenta,
                    CCI:this.state.CCI,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(async ({data_tables, error}) => { //data tables arreglo de tablas
                    const [table_data] = data_tables;
                    if (!error) {
                        const [{IdEmpresa}] = table_data;
                        this.setState({IdEmpresaNew: IdEmpresa});
                        this.props.history.push("/empresas/");
                    }
                })
                .catch(err => console.log(err))
        } else {
            notificationError()
        }
        e.preventDefault()
    }


    PlanChange = (event) => {
        this.setState({IdPlanSunat: event.target.value})
        // console.log(event.target.value);
    }

    MonedaChange = (event) => {
        this.setState({IdMoneda: event.target.value})
    }

    hallarUbigeo = async (codigoUbigeo) => {
        try {
            const response = await fetch(`/api/ubigeos/ubigeoPorCodigo/${codigoUbigeo}`);
            const data = await response.json();
            // console.log(data[0][0])
            this.setState({
                loading: false,
                ubigeo: data[0][0].NombreUbigeo,
                ubigeoNombrecodigo: data[0][0].NombreUbigeo.concat(" - ", data[0][0].Ubigeo).toUpperCase(),
                IdUbigeo: data[0][0].IdUbigeo
            })
        } catch (err) {
            console.log(`Error --- >${err}`);
        }
    }

    TraerEmpresaPorRUC_API = async (ruc) => {

        try {
            const response = await fetch(`${process.env.REACT_APP_API_CONSULTA_RUC2}/${ruc}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API_RUC2}`
                }
            });
            const empresa = await response.json();
            if (empresa) {
                /*
                this.setState({
                    Ruc: empresa.ruc,
                    RazonSocial: empresa.razon_social,
                    Direccion: empresa.direccion,
                    NombreComercial: empresa.razon_social,
                    ContribuyenteCondicion: empresa.condicion_domicilio,
                    ContribuyenteEstado: empresa.estado,
                    codigoUbigeo: empresa.ubigeo,
                    mostrarLogos: true
                });
                // console.log(this.state.Ruc)
                this.hallarUbigeo(empresa.ubigeo)
                //VIejo link ruc
                 */
                this.setState({
                    Ruc: empresa.doc_number,
                    RazonSocial: empresa.name,
                    Direccion: empresa.address,
                    NombreComercial: empresa.name,
                    ContribuyenteCondicion: empresa.condition,
                    ContribuyenteEstado: empresa.status,
                    codigoUbigeo: empresa.ubigeo,
                    mostrarLogos: true
                });
                // console.log(this.state.Ruc)
                this.hallarUbigeo(empresa.ubigeo)
            } else {
                this.setState({
                    Ruc: "",
                    RazonSocial: "",
                    Direccion: "",
                    NombreComercial: "",
                    ContribuyenteCondicion: "",
                    ContribuyenteEstado: "",
                    codigoUbigeo: "",
                    IdUbigeo: "",
                    ubigeoNombrecodigo: "",

                    msjerror: "Ruc No Existe en BD SUNAT",
                    mostrarLogos: false
                })
            }
        } catch (error) {
            this.setState({
                mostrarLogos: false
            })
            console.log(`Error --- >${error}`);
        }
    };


    handleSubmit = (e) => {
        e.preventDefault();
    }

    handleSubmitRUC = async (e) => {
        e.preventDefault();
        this.TraerEmpresaPorRUC_API(this.state.RucPre);
    };


    handleChange = (event) => {
        const {name, value} = event.target
        // console.log(this.state.Ruc)
        // console.log(event.target.name, " - ", event.target.value)
        let Errores = this.state.Errores;
        validarInputsEmpresa(Errores, name, value);
        this.setState({
            Errores,
            [event.target.name]: event.target.value
        });
    }

    mostrarTelefonosOpcionales = (e) => {
        if (this.state.mostrarTelefonosOpcionales == true) {
            this.setState({
                mostrarTelefonosOpcionales: false
            });
        } else if (this.state.mostrarTelefonosOpcionales == false) {
            this.setState({
                mostrarTelefonosOpcionales: true
            });
        }
    }

    mostrarCorreosOpcionales = (e) => {
        if (this.state.mostrarCorreosOpcionales == true) {
            this.setState({
                mostrarCorreosOpcionales: false
            });
        } else if (this.state.mostrarCorreosOpcionales == false) {
            this.setState({
                mostrarCorreosOpcionales: true
            });
        }

    }

    handleChangeSistemaContabilidad = (e) => {
        this.setState({
            SistemaContabilidad: e.target.value
        })
        // console.log(e.target.value)
    }

    handleChangeSistemaEmision = (e) => {
        this.setState({
            SistemaEmision: e.target.value
        })
        // console.log(e.target.value)
    }

    MonedaChecked = (IdMoneda) => {
        if (this.state.IdMoneda == IdMoneda)
            return true
        else {
            return false
        }
    }

    ContabilidadChecked = (contabilidad) => {
        if (this.state.SistemaContabilidad == contabilidad)
            return true
        else {
            return false
        }
    }

    EmisionChecked = (emision) => {
        if (this.state.SistemaEmision == emision)
            return true
        else {
            return false
        }
    }

    render = () => {
        if (this.state.loading === true) {
            return <PageLoading/>;
        }
        return (
            <section className='ventas-fpay-section'>

                <div className="container pt-4">
                    <BotonCancelar
                        onClick={() => this.props.history.goBack()}
                        className="mb-2 mt-2"
                    />
                </div>
                <form onSubmit={this.newEmpresa} className="container">
                    <div className="container">


                        <EmpresasAddFormDatosSunat
                            onSubmit={this.newEmpresa}
                            state={this.state}
                            handleChange={this.handleChange}
                            handleSubmitRUC={this.handleSubmitRUC}
                        />
                        <EmpresasAddFormDatosGenerales
                            onSubmit={this.newEmpresa}
                            state={this.state}
                            handleChange={this.handleChange}
                            handleSubmitRUC={this.handleSubmitRUC}
                            PlanChange={this.PlanChange}
                            RegionChange={this.RegionChange}
                            ProvinciaChange={this.ProvinciaChange}
                            DistritoChange={this.DistritoChange}
                            getPlanesSunat={this.getPlanesSunat}
                            getUbigeo={this.getUbigeo}
                            RetenedorChange={this.RetenedorChange}
                            PercepcionChange={this.PercepcionChange}
                            EsRussChange={this.EsRussChange}
                            MonedaChange={this.MonedaChange}
                            getMoneda={this.getMoneda}
                            mostrarTelefonosOpcionales={this.mostrarTelefonosOpcionales}
                            mostrarCorreosOpcionales={this.mostrarCorreosOpcionales}
                            handleChangeSistemaContabilidad={this.handleChangeSistemaContabilidad}
                            handleChangeSistemaEmision={this.handleChangeSistemaEmision}
                            MonedaChecked={this.MonedaChecked}
                            ContabilidadChecked={this.ContabilidadChecked}
                            EmisionChecked={this.EmisionChecked}
                        />
                        <div className="AlmacenBoton mt-2 mb-2">
                            <BotonGuardarLetras type="submit"/>
                        </div>
                    </div>
                </form>
            </section>
        )
    }

}

export default AddEmpresa
