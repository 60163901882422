import React from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import {BotonAddPequeño} from '../Plantillas/Botones';


class OrdenCompraListSearch extends React.Component {

    constructor() {
        super()
        this.state = {
            loading: true,
            error: null,

            rowEdit: null,
            selectedRowIndex: [],
            selectionChanged: false,

            dataListaOC: undefined
        }
    }

    componentDidMount() {
        this.inicio()
    }

    inicio = async () => {
        await this.listarOC()
    }

    listarOC = async () => {
        try {
            this.setState({loading: true, error: null});

            const response = await fetch(`/api/ordenescompra/list/suc/poratender`);
            const data = await response.json();

            this.setState({
                dataListaOC: data[1]
            })

        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    ClickSeleccionar = (reg) => {
        this.props.form.IdOrdenCompra = reg.IdOrdenCompra;
        this.props.form.NroOrdenCompra = reg.Numero;
        console.log({reg})
        this.props.asignarDatosProveedor(reg.RucProveedor)
        this.props.onClose();
    }

    render() {
        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }

        if (this.state.loading === true && this.state.dataListaOC === undefined) {
            return <p>cargando...</p>;
        }

        return (
            <div>
                <div className="form-row">
                    <div className="col">
                        <ReactTable
                            data={this.state.dataListaOC}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Código",
                                            id: "Numero",
                                            width: '100',
                                            accessor: d => d.Numero,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Numero"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                        },
                                        {
                                            Header: "RUC",
                                            id: "Ruc",
                                            width: '100',
                                            accessor: d => d.RucProveedor,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["RucProveedor"]}),
                                            filterAll: true,
                                        },
                                        {
                                            Header: "Proveedor",
                                            id: "NombreProveedor",
                                            width: '250',
                                            accessor: d => d.NombreProveedor,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["NombreProveedor"]}),
                                            filterAll: true,
                                        },
                                        {
                                            Header: "Acciones",
                                            accessor: "IdMovimientoAlmacen",
                                            width: '50',
                                            filterable: false,
                                            Cell: (f) => {
                                                if (!f) {
                                                    return null
                                                }
                                                return (
                                                    <div align='rigth'>
                                                        <BotonAddPequeño
                                                            className="mr-2"
                                                            id={f.value}
                                                            onClick={
                                                                () => {
                                                                    this.ClickSeleccionar(f.original)
                                                                }
                                                            }
                                                            title="Seleccionar"
                                                        />
                                                    </div>
                                                )
                                            },
                                        },
                                    ]
                                },
                            ]}
                            defaultPageSize={10}
                            showPagination={false}
                            className="-striped -highlight"
                            style={{
                                width: "500px",
                                height: "250px",
                                fontSize: "12px"
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

export default OrdenCompraListSearch
