import React from "react";
import $ from "jquery";

export class ConfPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            confs: []
        }
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        fetch(`/api/detalleConfiguraciones/public`)
            .then(response => response.json())
            .then(confs => {
                this.setState({confs})
            })
            .finally(() => this.setState({isLoading: false}))
    }


    onChange(e) {
        this.props.onChange($(e.target).val())
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-auto">
                        Configuración
                    </div>
                    <div className="col-sm">
                        <select className="form-control" onChange={this.onChange}>
                            <option value="">Seleccionar</option>
                            {this.state.confs.map((c, i) => (
                                <option key={i} value={c.IdConfiguracion}>{c.Configuracion} - Estado
                                    actual: {c.Estado ? "Activo" : "Inactivo"}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        );
    }
}
