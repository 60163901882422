import _ from 'lodash';
import {numbersFactory} from "./calcularVenta";
import {TIPO_MOV_CAJA} from "../Global";

export function convertObjToQueryParams(obj) {
    let query = "";
    let separator = "&";
    if (obj) {
        const lastPos = Object.keys(obj).length - 1
        Object.keys(obj).forEach((k, i) => {
            const ok = (x) => {
                x = String(x)
                return x !== "null" && x !== "undefined" && x.length
            }
            if (ok(obj[k])) {
                query += `${k}=${obj[k]}` + (i === lastPos ? "" : separator)
            }
        });
    }
    return query;
}

export function isUndefinedOrNull(p) {
    return _.isUndefined(p) || _.isEmpty(p) || _.isNull(p);
}

export function isNumber(str) {
    let s = String(str)
    let n = Number(s && s.length ? s : "null");
    return _.isNumber(n) && !_.isNaN(n);
}

export async function safe(func, defaultReturn = {}) {
    try {
        if (func.constructor.name === "AsyncFunction")
            return await func();
        return func();
    } catch (e) {
        return defaultReturn;
    }
}


function Unidades(num) {

    switch (num) {
        case 1:
            return "UN";
        case 2:
            return "DOS";
        case 3:
            return "TRES";
        case 4:
            return "CUATRO";
        case 5:
            return "CINCO";
        case 6:
            return "SEIS";
        case 7:
            return "SIETE";
        case 8:
            return "OCHO";
        case 9:
            return "NUEVE";
    }

    return "";
}

function Decenas(num) {

    var decena = Math.floor(num / 10);
    var unidad = num - (decena * 10);

    switch (decena) {
        case 1:
            switch (unidad) {
                case 0:
                    return "DIEZ";
                case 1:
                    return "ONCE";
                case 2:
                    return "DOCE";
                case 3:
                    return "TRECE";
                case 4:
                    return "CATORCE";
                case 5:
                    return "QUINCE";
                default:
                    return "DIECI" + Unidades(unidad);
            }
        case 2:
            switch (unidad) {
                case 0:
                    return "VEINTE";
                default:
                    return "VEINTI" + Unidades(unidad);
            }
        case 3:
            return DecenasY("TREINTA", unidad);
        case 4:
            return DecenasY("CUARENTA", unidad);
        case 5:
            return DecenasY("CINCUENTA", unidad);
        case 6:
            return DecenasY("SESENTA", unidad);
        case 7:
            return DecenasY("SETENTA", unidad);
        case 8:
            return DecenasY("OCHENTA", unidad);
        case 9:
            return DecenasY("NOVENTA", unidad);
        case 0:
            return Unidades(unidad);
    }
}//Unidades()

function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0)
        return strSin + " Y " + Unidades(numUnidades)

    return strSin;
}//DecenasY()

function Centenas(num) {

    var centenas = Math.floor(num / 100);
    var decenas = num - (centenas * 100);

    switch (centenas) {
        case 1:
            if (decenas > 0)
                return "CIENTO " + Decenas(decenas);
            return "CIEN";
        case 2:
            return "DOSCIENTOS " + Decenas(decenas);
        case 3:
            return "TRESCIENTOS " + Decenas(decenas);
        case 4:
            return "CUATROCIENTOS " + Decenas(decenas);
        case 5:
            return "QUINIENTOS " + Decenas(decenas);
        case 6:
            return "SEISCIENTOS " + Decenas(decenas);
        case 7:
            return "SETECIENTOS " + Decenas(decenas);
        case 8:
            return "OCHOCIENTOS " + Decenas(decenas);
        case 9:
            return "NOVECIENTOS " + Decenas(decenas);
    }

    return Decenas(decenas);
}//Centenas()

function Seccion(num, divisor, strSingular, strPlural) {
    var cientos = Math.floor(num / divisor)
    var resto = num - (cientos * divisor)

    var letras = "";

    if (cientos > 0)
        if (cientos > 1)
            letras = Centenas(cientos) + " " + strPlural;
        else
            letras = strSingular;

    if (resto > 0)
        letras += "";

    return letras;
}//Seccion()

function Miles(num) {
    var divisor = 1000;
    var cientos = Math.floor(num / divisor)
    var resto = num - (cientos * divisor)

    var strMiles = Seccion(num, divisor, "MIL", "MIL");
    var strCentenas = Centenas(resto);

    if (strMiles == "")
        return strCentenas;

    return strMiles + " " + strCentenas;

    //return Seccion(num, divisor, "UN MIL", "MIL") + " " + Centenas(resto);
}//Miles()

function Millones(num) {
    var divisor = 1000000;
    var cientos = Math.floor(num / divisor)
    var resto = num - (cientos * divisor)

    var strMillones = Seccion(num, divisor, "UN MILLON", "MILLONES");
    var strMiles = Miles(resto);

    if (strMillones === "")
        return strMiles;

    return strMillones + " " + strMiles;

    //return Seccion(num, divisor, "UN MILLON", "MILLONES") + " " + Miles(resto);
}//Millones()

function NumeroALetras(num, centavos) {
    var data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: "",
    };
    //Revisar en caso no salgan los soles  centimos correctamente: https://www.lawebdelprogramador.com/codigo/JavaScript/5192-Conversor-de-numeros-a-letras.html
    if (centavos == undefined || centavos == false) {
        data.letrasMonedaPlural = "SOLES";
        data.letrasMonedaSingular = "SOL";
    } else {
        data.letrasMonedaPlural = "CENTIMOS";
        data.letrasMonedaSingular = "CENTIMO";
    }

    if (data.centavos > 0)
        data.letrasCentavos = "CON " + NumeroALetras(data.centavos, true);

    if (data.enteros == 0)
        return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    if (data.enteros == 1)
        return Millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
    else
        return Millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
}//NumeroALetras()

export function convertCronToDate(cron) {
    const [minute, hour, day, month, monthDay, year] = cron.split(" ")
    return new Date(year, month, day, hour, minute, 0, 0)
}

export function formatCorrelativo(corr) {
    const _corr = String(corr)
    if (_corr && _corr.length < 7) {
        return numbersFactory(7 - _corr.length, "0") + _corr
    }
}

export async function execInContext(context) {
    const execInfo = await safe(() => {
        let params = context.props.location.search.replace("?", "").replace("code=", "").replace("exec=", "")
        let [exec, code] = params.split("&")
        exec = JSON.parse(exec)
        return [exec, code]
    }, null)

    if (execInfo && execInfo.length) {
        let [exec, code] = execInfo
        exec.data['accessCode'] = code
        context[exec.method](exec.data)
    }
}


export function getPayloadFromPreVenta(payload) {
    const copiedPayload = JSON.parse(JSON.stringify(payload))
    let tmpPayload = {
        ...copiedPayload,
        IdTipoDocumentoSunat: copiedPayload._docType.key,
        IdCliente: copiedPayload._client.IdCliente,
        Total: copiedPayload._totales.totalRedondeado
    }
    Object.keys(tmpPayload).forEach(k => {
        if (k.startsWith("_"))
            delete tmpPayload[k]
    })

    const needKeys = ["Cantidad", "Total", "IdStock", "IdPresentacion", "IdAfectacionIgv", "Descuento",
        "PrecioReferencial", "PrecioVenta", "TipoCambio", "IdAlmacen", "ValorUnitario", "Precio", "Gratuito",
        "IdProducto", "IdTipoStock", "EsFacturable"]

    tmpPayload.detalles = tmpPayload.detalles.map(d => {
        const allKeys = Object.keys(d);
        const deleteKeys = _.difference(allKeys, needKeys);
        for (let i = 0; i < deleteKeys.length; i++)
            delete d[deleteKeys[i]]
        return d
    })
    return tmpPayload;
}

export function calcularMovsCaja(movCajas) {
    let salidas = movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.SALIDA).map(m => m.Monto).reduce((a, b) => a + b, 0);
    let salidasComprobante = movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.SALIDA).filter(s => s.Observacion.includes('del comprobante:')).map(m => m.Monto).reduce((a, b) => a + b, 0);
    let salidasNormales = movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.SALIDA).filter(s => !s.Observacion.includes('del comprobante:')).map(m => m.Monto).reduce((a, b) => a + b, 0);
    let entradas = movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.ENTRADA).map(m => m.Monto).reduce((a, b) => a + b, 0);
    return [entradas, salidas, salidasComprobante, salidasNormales];
}

export async function getTotalRedondeo(estadoVenta, estadoVale) {
    let queryParams = `estadoVenta=${estadoVenta}&estadoVale=${estadoVale}`.replace(/\s/g, "");
    let response = await fetch(`/api/cajas/cierre/montosfinales/?${queryParams}`);
    let resJson = await response.json();
    return Number(resJson.total);
}
