import React, { useEffect, useState } from 'react';
import ReactTable from "react-table";
import moment from 'moment';
import ReactExport from "react-data-export";
import { BotonExcel } from "./../Plantillas/Botones";
import { registroMovCajaPDF } from '../Caja/printDataCaja'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const TurnosCajas = (props) => {
    const [queryParams, setQueryParams] = useState({
        inicio: moment().startOf('month').format("YYYY-MM-DD"),
        fin: moment(new Date()).format("YYYY-MM-DD")
    });
    const [movimientos, setMovimientos] = useState([]);

    useEffect(() => {
        setTimeout(() => applyFilter(), 250);
    }, [])

    const updateQueryParam = (key, value) => {
        setQueryParams({...queryParams, [key]: value });
    }

    const applyFilter = async () => {
        const { inicio, fin } = queryParams;
        try {
            const req = await fetch(`/api/turnos/movimientos?inicio=${inicio}&fin=${fin}`);

            if (!req.ok) {
                throw new Error(req.text);
            }

            setMovimientos(await req.json());
        } catch (e) {
            setMovimientos([]);
            console.error(e)
        }
    }

    const imprimirPDF = async (obj) => {
        await registroMovCajaPDF({ idTurno: obj.IdTurno, data: { ...obj, TipoMovimiento: obj.Tipo}}, true);
    }

    const getDataExportExcel = (dataSet) => {
        return [
          {
            columns: [
              {
                title: `FECHA DE REGISTRO`,
                width: { wpx: 100 }
              },
              {
                title: `CAJA`,
                width: { wpx: 150 }
              },
              {
                title: `USUARIO`,
                width: { wpx: 100 }
              },
              {
                title: `ENTRADA `,
                width: { wpx: 150 }
              },
              {
                title: `SALIDA `,
                width: { wpx: 150 }
              },
              {
                title: `MENSAJE`,
                width: { wpx: 300 }
              },
            ],
            data: dataSet.map(row => {
              return [
                {
                  value: row.FechaHora,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Caja,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Usuario || "",
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Entrada,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Salida,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Observacion,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                }
              ];
            })
          }
        ];
      }

    return <div className="container">
        <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2 mt-5">
            <div className='col-12 col-md-6'>
                <div className="col-sm-auto">
                    <b style={{ color: "red" }}>* </b> Inicio:
                </div>
                <div className="col-sm">
                    <input required
                        type="date"
                        name="fechaBusquedaInicio"
                        className="input__lineaTurnos"
                        onChange={e => updateQueryParam('inicio', e.target.value)}
                        defaultValue={queryParams.inicio}
                    />
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className="col-sm-auto">
                    <b style={{ color: "red" }}>* </b> Cierre:
                </div>
                <div className="col-sm">
                    <input required
                        type="date"
                        name="fechaBusquedaInicio"
                        className="input__lineaTurnos"
                        onChange={e => updateQueryParam('fin', e.target.value)}
                        defaultValue={queryParams.fin}
                    />
                </div>
            </div>
        </div>

        <div className="mt-3 d-flex justify-content-end">
            <ExcelFile
                element={
                    <BotonExcel
                        className="mr-2"
                        type="button"
                        title="Guardar Excel"
                    />
                }
                filename={`MovimientosTurnosCajas`}
            >
            <ExcelSheet
                dataSet={getDataExportExcel(movimientos)}
                name="EmpresasFile"
            >
                </ExcelSheet>
            </ExcelFile>
            
            <button className="btn btn-verde-mfp" onClick={() => applyFilter()}>Filtrar</button>
        </div>
        
        <div className="row my-3">
            <div className="col-12">
                <ReactTable
                    data={movimientos}
                    filterable
                    columns={[{
                        columns: [
                            {
                                Header: "Fecha y hora",
                                id: "FechaHora",
                                width: 150,
                                accessor: d => d.FechaHora,
                            },
                            {
                                Header: "Caja",
                                id: "Caja",
                                width: 160,
                                accessor: d => d.Caja,
                            },
                            {
                                Header: "Usuario",
                                id: "Usuario",
                                width: 120,
                                accessor: d => d.Usuario,
                            },
                            {
                                Header: "Entrada",
                                id: "Entrada",
                                width: 115,
                                accessor: d => d.Entrada,
                            },
                            {
                                Header: "Salida",
                                id: "Salida",
                                width: 115,
                                accessor: d => d.Salida,
                            },
                            {
                                Header: "Mensaje",
                                id: "Mensaje",
                                width: 150,
                                accessor: d => d.Observacion,
                            },
                            {
                                Header: "",
                                id: "Acciones",
                                width: 100,
                                accessor: d => d.Acciones,
                                Cell: props =>  <button type={"button"} onClick={() =>{imprimirPDF(props.original).then()}} className={"btn btn-info"}><i className="far fa-file-pdf"></i></button>
                            }
                        ]
                    }]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Siguiente"
                    noDataText="No se encontraron registros"
                    loadingText="Cargando..."
                    ofText="de"
                    rowsText="filas"
                    pageText="Página"
                    pageSizeOptions={[10, 20, 25, 50, 100]}
                />
            </div>
        </div>
    </div>
}

export default TurnosCajas;
