import React from "react";
import { BotonGuardarLetras, BotonCancelar } from "../Plantillas/Botones";
//import SelectSearch from "react-select-search";

class AlmacenForm extends React.Component {
  render() {
    return (
      <section className="ventas-fpay-section">

        <div className="container pt-4">
          <BotonCancelar to="/almacenes" />
        </div>

        <form onSubmit={this.props.onSubmit} className="d-flex justify-content-center">
          <div className="container mt-5">
            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Empresa</b>
                <select
                  required
                  className="input__linea "
                  aria-haspopup="true"
                  name="empresa"
                  value={this.props.state.IdEmpresa}
                  onChange={this.props.EmpresaChange}
                >
                  <option value="">--Seleccionar Empresa--</option>
                  {this.props.state.dataEmpresas.map(empresa => {
                    return (
                      <option key={empresa.ID} value={empresa.ID}>
                        {empresa.RazonSocial}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Localidad/Sede</b>
                <select
                  required
                  className="input__linea "
                  aria-haspopup="true"
                  name="sucursal"
                  value={this.props.state.IdSucursal}
                  onChange={this.props.SucursalChange}
                >
                  {this.props.state.sucursalesPorEmpresa.map(sucursal => {
                    return (
                      <option
                        key={sucursal.IdSucursal}
                        value={sucursal.IdSucursal}
                      >
                        {sucursal.Nombre}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">Responsable</b>
                <select
                  className="input__linea "
                  aria-haspopup="true"
                  name=""
                  value={this.props.state.IdEmpleado}
                  onChange={this.props.EmpleadoChange}
                >
                  {this.props.state.empleadosPorEmpresa.map(empleado => {
                    return (
                      <option
                        key={empleado.IdEmpleado}
                        value={empleado.IdEmpleado}
                      >
                        {empleado.Nombres} {empleado.Apellidos}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* <div className="row form-group col-12">
              <div className="col-12">
                <label className="labelAlmacen mr-3 col-3">Responsable</label>
                <SelectSearch
                  name="empleado"
                  value={this.props.state.IdEmpleado}
                  onChange={this.props.EmpleadoChange}
                  options={this.props.state.empleadosPorEmpresa.map(empleado => {
                    return { key:empleado.IdEmpleado,
                      name: empleado.Nombres.concat(" ",empleado.Apellidos), 
                      value: empleado.IdEmpleado};
                  })}
                  className="select-search-box"
                  placeholder="Selecciona"
                  required
                />
              </div>
            </div> */}

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Teléfono</b>

                <input
                  className="input__linea"
                  name="Telefono"
                  onChange={this.props.handleChange}
                  type="text"
                  value={this.props.state.Telefono}
                  placeholder="Teléfono..."
                  required
                />
              </div>
              <div className="labelValidAlmacen">
                {this.props.state.Errores.Telefono.length > 0 && (
                  <span>
                    {this.props.state.Errores.Telefono}
                  </span>
                )}
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Celular</b>
                <input
                  className="input__linea"
                  name="Celular"
                  onChange={this.props.handleChange}
                  type="text"
                  value={this.props.state.Celular}
                  placeholder="Celular..."
                  required
                />
              </div>
              <div className="labelValidAlmacen">
                {this.props.state.Errores.Celular.length > 0 && (
                  <span>
                    {this.props.state.Errores.Celular}
                  </span>
                )}
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Clase</b>

                <select
                  required
                  className="input__linea "
                  aria-haspopup="true"
                  name="clase"
                  value={this.props.state.Clase}
                  onChange={this.props.ClaseChange}
                >
                  <option value="PT">
                    (PT) Mercadería y Productos Terminados
                  </option>
                  <option value="IS">
                    (IS) MP, Insumos, Suministros y Otros
                  </option>
                  <option value="PP">(PP) Productos en Proceso</option>
                  <option value="GE">(GE) General</option>
                  <option value="OT">(OT) Otros</option>
                </select>
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2 d-flex justify-content-end">
                <b className="ml-4">Valorización</b>
                <label className="checkbox-toggle">
                  <div className="toggle">
                    <input
                      type="checkbox"
                      name="Valorizacion"
                      className="toggle-state"
                      onChange={this.props.onChangeCheckBox}
                      checked={this.props.state.Valorizacion}
                    />
                    <div className="toggle-inner">
                      <div className="indicator" />
                    </div>
                    <div className="active-bg" />
                  </div>
                </label>

                <b className="ml-4">Conteo de Monto</b>
                <label className="checkbox-toggle">
                  <div className="toggle">
                    <input
                      type="checkbox"
                      name="ContMon"
                      className="toggle-state"
                      onChange={this.props.onChangeCheckBox}
                      checked={this.props.state.ContMon}
                    />
                    <div className="toggle-inner">
                      <div className="indicator" />
                    </div>
                    <div className="active-bg" />
                  </div>
                </label>
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Nombre / Descripción</b>
                <input
                  className="input__linea"
                  name="Nombre"
                  onChange={this.props.handleChange}
                  type="text"
                  value={this.props.state.Nombre}
                  placeholder="Nombre..."
                  required
                />
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Ubicación</b>
                <div className="mt-3 row pl-3 pr-3">
                  <select
                    required
                    className="col-12 col-sm-4"
                    aria-haspopup="true"
                    aria-expanded="false"
                    name="region"
                    value={this.props.state.valueRegion}
                    onChange={this.props.RegionChange}
                  >
                    {this.props.state.ubigeoReg.map(obj => {
                      return (
                        <option key={obj.IdUbigeo} value={obj.CodRegion}>
                          {obj.NombreUbigeo}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    required
                    className="col-12 col-sm-4"
                    aria-haspopup="true"
                    aria-expanded="false"
                    name="provincia"
                    value={this.props.state.valueProvincia}
                    onChange={this.props.ProvinciaChange}
                  >
                    {this.props.state.ubigeoProv.map(obj => {
                      return (
                        <option key={obj.IdUbigeo} value={obj.CodProvincia}>
                          {obj.NombreUbigeo}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    required
                    className="col-12 col-sm-4"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="distrito"
                    value={this.props.state.valueDistrito}
                    name="IdUbigeo"
                    onChange={this.props.DistritoChange}
                  >
                    {this.props.state.ubigeoDis.map(obj => {
                      return (
                        <option key={obj.IdUbigeo} value={obj.IdUbigeo}>
                          {obj.NombreUbigeo}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Dirección</b>
                <input
                  className="input__linea"
                  name="Direccion"
                  onChange={this.props.handleChange}
                  type="text"
                  value={this.props.state.Direccion}
                  placeholder="Dirección..."
                  required
                />
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Última Entrada</b>
                <input
                  className="input__linea"
                  name="UltimaEntrada"
                  onChange={this.props.handleChange}
                  type="number"
                  step="any"
                  min="0"
                  max="999999.999999"
                  value={this.props.state.UltimaEntrada}
                  required
                />
              </div>
              <div className="labelValidAlmacen">
                {this.props.state.Errores.UltimaEntrada.length > 0 && (
                  <span>
                    {this.props.state.Errores.UltimaEntrada}
                  </span>
                )}
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Última Salida</b>
                <input
                  className="input__linea"
                  name="UltimaSalida"
                  onChange={this.props.handleChange}
                  type="number"
                  step="any"
                  min="0"
                  max="999999.999999"
                  value={this.props.state.UltimaSalida}
                  required
                />
              </div>
              <div className="labelValidAlmacen">
                {this.props.state.Errores.UltimaSalida.length > 0 && (
                  <span>
                    {this.props.state.Errores.UltimaSalida}
                  </span>
                )}
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Última Transferencia</b>
                <input
                  className="input__linea"
                  name="UltimaTransferencia"
                  onChange={this.props.handleChange}
                  type="number"
                  step="any"
                  min="0"
                  max="999999.999999"
                  value={this.props.state.UltimaTransferencia}
                  required
                />
              </div>
              <div className="labelValidAlmacen">
                {this.props.state.Errores.UltimaTransferencia.length > 0 && (
                  <span>
                    {this.props.state.Errores.UltimaTransferencia}
                  </span>
                )}
              </div>
            </div>

            <div className="almacenDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Último Ajuste</b>
                <input
                  className="input__linea"
                  name="UltimoAjuste"
                  onChange={this.props.handleChange}
                  type="number"
                  step="any" //acepta n cantidad de decimales
                  min="0"
                  max="999999.999999"
                  value={this.props.state.UltimoAjuste}
                  required
                />
              </div>
              <div className="labelValidAlmacen">
                {this.props.state.Errores.UltimoAjuste.length > 0 && (
                  <span>
                    {this.props.state.Errores.UltimoAjuste}
                  </span>
                )}
              </div>
            </div>

            <div className="AlmacenBoton mb-3 d-flex justify-content-end">
              {this.props.CambiarEstadoAlmacen ? this.props.CambiarEstadoAlmacen : null}
              <BotonGuardarLetras type="submit" className="ml-2" />
            </div>

          </div>

        </form>
      </section>
    );
  }
}
export default AlmacenForm;
