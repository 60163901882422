import React, { useEffect, useState } from "react";
import {
  BotonAddProveedorDos,
  BotonSearchProveedorDos,
} from "../Plantillas/Botones";
import ModalProveedor from "../clientes/ClienteModal";
import ProveedorNuevo from "../Proveedores/ProveedorNuevo";
import ProveedoresListaSearch from "../Proveedores/ProveedoresListaSearch";

function RegistrarProveedor(props) {
  const [enabled, setEnabled] = useState(false);

  return (
    <>
      <BotonAddProveedorDos
        type="button"
        name="BtnAddProveedor"
        className="mr-2 botones-proveedor"
        onClick={() => setEnabled(true)}
        title="agregar proveedor"
      />

      <ModalProveedor
        isOpen={enabled}
        onClose={() => setEnabled(false)}
        title="NUEVO PROVEEDOR"
      >
        <ProveedorNuevo
          onClose={() => setEnabled(false)}
          AsignarValorDoc={(e) => props.onRegistered?.({ ...e })}
          sw="oc_nuevo"
        />
      </ModalProveedor>
    </>
  );
}

function BuscarProveedor(props) {
  const [enabled, setEnabled] = useState(false);
  let proveedor = {};

  const onClose = () => {
    setEnabled(false);
    if (typeof props.onChose == "function") {
      props.onChose(proveedor);
    }
  }

  return (
    <>
      <BotonSearchProveedorDos
        type="button"
        name="BtnAddProveedor"
        className="botones-proveedor"
        onClick={() => setEnabled(true)}
        title="buscar proveedor"
      />
      <ModalProveedor isOpen={enabled} onClose={() => setEnabled(false)} title="BUSCAR PROVEEDOR">
        <ProveedoresListaSearch
            onClose={onClose}
            form={proveedor}
        />
      </ModalProveedor>
    </>
  )
}

export default function ProveedorForm(props) {
  const [provedores, setProveedores] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [id, setId] = useState("");
  const [ephimeralProveedor, setEphimeralProveedor] = useState(undefined);

  useEffect(() => {
    if (props.currentId === undefined) {
      return;
    }

    setId(props.currentId);
  }, [props.currentId])

  useEffect(() => {
    if (props.currentId != id && typeof props.onChange === 'function') {
      props.onChange(id)
    }
  }, [id])

  useEffect(() => {
    retrieveProveedores();
  }, []);

  const retrieveProveedores = async () => {
    try {
      const response = await fetch("/api/proveedores/list/")
      const data = await response.json();

      if (Array.isArray(data)) {
        const [, items] = data;
        setProveedores([...items]);

        let hasEphimeralProveedor = ephimeralProveedor ? !!(items.find(x => x.IdProveedor === ephimeralProveedor.IdProveedor)) : true;

        if (!hasEphimeralProveedor) {
          setProveedores([...items, ephimeralProveedor]);
        }
      }
    } catch (e) {
      setHasError(true);
      console.error(e);
    }
  };

  const onProveedorChose = async (e) => {
    if ("NomProveedor" in e) {
      setEphimeralProveedor({
        IdProveedor: e.IdProveedor,
        NombreComercial: e.NomProveedor,
      })
    }

    if ("RazonSocial" in e) {
      setEphimeralProveedor({
        IdProveedor: e.IdProveedor,
        NombreComercial: e.RazonSocial,
      })
    }

    await retrieveProveedores();
    setId(e.IdProveedor);
  }

  return (
    <>
      <div className="form-row">
        <div className="form-group col-12 col-sm-8">
          <label htmlFor="IdProveedor">
            <strong>Proveedor</strong>
          </label>
          <select
            className="selectpicker form-control"
            id="IdProveedor"
            value={id}
            onChange={(e) => setId(e.target.value)}
          >
            <option value="">SELECCIONAR</option>
            {provedores.map((x) => (
              <option
                key={x.IdProveedor}
                value={x.IdProveedor}
                title={x.NombreComercial}
              >
                {x.NombreComercial}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group col-12 col-sm-4 d-flex justify-content-end">
          <RegistrarProveedor onRegistered={e => onProveedorChose(e)} />
          <BuscarProveedor onChose={e => onProveedorChose(e)} />
        </div>
      </div>
    </>
  );
}
