import React, { useEffect, useState } from 'react';
import Modal from "../../componentes/Modal.js";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import { notificarError } from '../../componentes/Almacenes/AlmacenNotify';


export function GuiaRemisionSelect(props) {
    const [guiasDisponibles, setGuiasDiponibles] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [guiaRemision, setGuiaRemision] = useState(null);

    useEffect(() => {
        fetchGuiaRemision();
    }, []);

    useEffect(() => {
        if (!guiaRemision) {
            return;
        }

        if (guiaRemision) {
            onCloseModal();
        }

        if (props.onChange) {
            props.onChange(guiaRemision);
        }
    }, [guiaRemision]);

    const fetchGuiaRemision = async () => {
        setGuiasDiponibles([]);

        try {
            const response = await fetch('/api/guiaRemision/List');
        
            if (!response.ok) {
                throw new Error("No se pudo obtener la información sobre guias de remision");
            }

            const data = await response.json();

            if ('GuiasRemision' in data) {
                setGuiasDiponibles(data.GuiasRemision);
            }
        } catch (error) {
            notificarError(error.message);
        }
    }

    const onOpenModal = () => {
        setGuiaRemision(null);
        setModalIsOpen(true);
    }

    const onCloseModal = () => {
        setModalIsOpen(false);
    }

    return (
        <>
            <div className="input__container">
                <label>Guia Remision</label>
                <input
                    style={{overflow: "hidden"}}
                    className="input"
                    aria-label="Guia de remisión"
                    placeholder=""
                    tabIndex="0"
                    readOnly={true}
                    value={guiaRemision ? guiaRemision.SerieNumeroComprobante : ""}
                    onClick={() => onOpenModal()}
                />
            </div>

            <Modal isOpen={modalIsOpen} onClose={onCloseModal} title="Guías de remisión">
                <div style={{ width: '800px' }}>
                    <ReactTable
                        id="GuiasSelector"
                        data={guiasDisponibles}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                        }
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Seleccionar",
                                        id: "IdGuiaRemision",
                                        width: 100,
                                        accessor: d => d.IdGuiaRemision,
                                        filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                            keys: ["IdGuiaRemision"]
                                        }),
                                        Cell: f => {
                                            return (
                                                <div align="center">
                                                    <button
                                                        className="boton__outline-pequeno--c"
                                                        onClick={() => setGuiaRemision(f.original)}
                                                    >
                                                        {<i className="fas fa-download"/>}
                                                    </button>

                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        Header: "Guía",
                                        id: "SerieNumeroComprobante",
                                        accessor: d => d.SerieNumeroComprobante,
                                    },
                                    {
                                        Header: `Motivo de traslado`,
                                        id: "MotivoTraslado",
                                        accessor: d => d.MotivoDeTraslado,
                                    },
                                    {
                                        Header: `Fecha de inicio de traslado`,
                                        id: "FechaInicioTraslado",
                                        accessor: d => d.FechaInicioTraslado,
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        previousText="Anterior"
                        nextText="Siguiente"
                        noDataText="No se encontraron registros"
                        loadingText="Cargando..."
                        ofText="de"
                        rowsText="filas"
                        pageText="Página"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                    />
                </div>
            </Modal>
        </>
    );
}
