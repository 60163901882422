import React, { useState } from 'react'
import SelectSearch from "react-select-search";
import "../../pages/styles/SelectSearch.css";
import ReactTable from "react-table";
import DetalleOrdenCompraItem from "./DetalleOrdenCompraItem"
import {BotonDeletePequeno, BotonEditPequeno, BotonCheckPequeno, BotonCancelPequeno} from '../Plantillas/Botones';
import { notificarError, notificarMsg } from '../Almacenes/AlmacenNotify';


function EditableQuantity(props) {
    if (!props.isEditing) {
        return <div style={{textAlign: "right"}}>{props.row.value}</div>
    }

    return <div className='px-2'>
        <input className='form-control form-control-sm text-right w-100' defaultValue={props.row.value} onChange={e => props.item.Cantidad = e.target.value}/>
    </div>
}


function ListItems(props) {
    const [currentItemEditing, setCurrentItemEditing] = useState(null);

    const saveChange = async () => {
        if (isNaN(+currentItemEditing.Cantidad) || +currentItemEditing.Cantidad < 0) {
            notificarError("Ingrese una cantidad válida.")
            return;
        }

        try {
            const req = await fetch(`/api/ordenescompra/detalleoc/${currentItemEditing.IdDetalleOC}`, {
                method: 'PATCH',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Cantidad: +currentItemEditing.Cantidad,
                    Total: +currentItemEditing.PrecioReferencial * +currentItemEditing.Cantidad
                })
            })

            if (!req.ok) {
                throw new Error()
            }

            notificarMsg("Completado! Se ha actualizado exitosamente el registro.")
            setCurrentItemEditing(null);
            props.listaDetalleOC();
        } catch (e) {
            console.error(e)
            notificarError("Ocurrio un error al actualizar. Inténtelo de nuevo.")
        }
    }

    return (
        <ReactTable
            data={props.dataDetalleOC}
            columns={[
                {
                    Header: "Producto",
                    accessor: "Nombre"
                },
                {
                    Header: "Precio",
                    accessor: "PrecioReferencial",
                    Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>

                },
                {
                    Header: "Cantidad",
                    accessor: "Cantidad",
                    width: 150,
                    Cell: row => <EditableQuantity row={row} item={currentItemEditing} isEditing={currentItemEditing && row.original.IdDetalleOC === currentItemEditing.IdDetalleOC} />
                },
                {
                    Header: "Total",
                    accessor: "Total",
                    Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>
                },
                {
                    Header: "Acciones",
                    accessor: "IdDetalleOC",
                    Cell: (f) => {
                        if (!currentItemEditing) {
                            return (
                                <div align='center'>
                                    <BotonDeletePequeno
                                        title="Eliminar"
                                        type='button'
                                        onClick={
                                            () => props.handleEliminarItem(
                                                f.original)
                                        }
                                    />
    
                                    <BotonEditPequeno
                                        title="Editar"
                                        type='button'
                                        className="mx-2"
                                        onClick={() => setCurrentItemEditing(f.original)}
                                     />
                                </div>
                            )
                        }

                        if (f.original.IdDetalleOC !== currentItemEditing.IdDetalleOC) {
                            return null
                        }

                        return (
                            <div align='center'>
                                <BotonCheckPequeno
                                    title="Guardar"
                                    type='button'
                                    onClick={() => saveChange()}
                                />

                                <BotonCancelPequeno
                                    title="Cancelar"
                                    type='button'
                                    className="mx-2"
                                    onClick={() => setCurrentItemEditing(null)}
                                    />
                            </div>
                        )
                    },
                },
            ]}

            defaultPageSize={20}
            showPagination={false}
            style={{
                height: "250px",
                width: "100%",
                fontSize: "14px"
            }}
            className="-striped -highlight"
        />

    )
}

export default ListItems;
