import React from "react";
import moment from "moment";
import Noty from "noty";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import {decimalAdjust} from "../Global";
import Modal from "../componentes/Modal";
import FormPagoMov from "../componentes/PagoMovs/FormPagoMov";
import DetallePagoMov from "../componentes/PagoMovs/DetallePagoMov";

export default class PagoMovs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            fechaFin: moment(new Date()).format('YYYY-MM-DD'),
            proveedor: {},
            isLoading: false,
            deudas: [],
            isOpenPagoModal: false,
            selectedDeuda: null,
            isOpenDetalleModal: false,
        }
        this.searchProveedores = this.searchProveedores.bind(this);
        this.onChangeProveedor = this.onChangeProveedor.bind(this);
        this.onChangeFecha = this.onChangeFecha.bind(this);
        this.buscarDeudas = this.buscarDeudas.bind(this);
        this.onRealizarPagoHandler = this.onRealizarPagoHandler.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    searchProveedores(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/proveedores/buscar/?search=${query}`)
                .then(r => r.json())
                .then(provs => {
                    const mappedProvs = provs.map(p => {
                        return {
                            label: `${p.RazonSocial} - ${p.Ruc}`,
                            value: p.IdProveedor
                        }
                    });
                    resolve(mappedProvs)
                }).catch(reject)
        })
    }

    onChangeProveedor(test) {
        this.setState({
            proveedor: test
        })
    }

    onChangeFecha(e) {
        const elem = e.target;
        this.setState((state, props) => {
            let newState = {...state};
            newState[elem.getAttribute('name')] = elem.value
            return newState;
        })
    }

    async buscarDeudas() {
        const isValid = this.state.fechaInicio.length && this.state.fechaInicio.length;
        if (isValid)
            await this.fetchDeudas();
        else
            new Noty({
                type: "warning",
                theme: "bootstrap-v4",
                layout: "topCenter",
                text: `Los datos ingresados son inválidos`,
                timeout: 1500
            }).show();
    }

    async fetchDeudas() {
        this.setState({isLoading: true})
        let idProvQuery = this.state.proveedor && this.state.proveedor.value ? `&idProveedor=${this.state.proveedor.value}` : '';
        const query = `fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}&${idProvQuery}`;
        let resDeudas = await fetch(`/api/pago-movs/movs?${query}`);
        let deudas = await resDeudas.json();
        this.setState({deudas: deudas, isLoading: false})
    }

    onRealizarPagoHandler(deuda) {
        this.setState({isOpenPagoModal: true, selectedDeuda: deuda})
    }

    onCloseModal() {
        this.setState({isOpenPagoModal: false, isOpenDetalleModal: false})
        this.fetchDeudas();
    }

    onDetallePagosHandler(deuda) {
        this.setState({isOpenDetalleModal: true, selectedDeuda: deuda})
    }

    render() {
        return (
            <div className="ventas-fpay-section">
                <div 
                    className="pt-5">
                    <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                        <span className="align-self-center letra-fecha-fpay">
                            Desde:
                        </span>
                            <input type="date" onChange={this.onChangeFecha} name='fechaInicio' className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                                value={this.state.fechaInicio} />
                        <span className="align-self-center letra-fecha-fpay">
                            Hasta:
                        </span>
                            <input type="date" onChange={this.onChangeFecha} name='fechaFin' className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                                value={this.state.fechaFin} />
                    </div>

                    <div className="container mt-3">
                        <div className="caja-fondo-blanco-m-2">
                            <h5 className="sucursal-text-color">
                                Proveedor
                            </h5>
                            <div className="mt-2">
                                <AsyncSelect
                                    isClearable={true}
                                    defaultOptions={true}
                                    onChange={this.onChangeProveedor}
                                    value={this.state.proveedor}
                                    loadOptions={this.searchProveedores}
                                />
                            </div>
                        </div>
                        <div className="col-sm-auto seccion-btn-verde-mfp no-pad">
                                <button disabled={this.state.isLoading} onClick={this.buscarDeudas}
                                    className="btn btn-outline-primary btn-verde-mfp">
                                    Buscar
                                </button>
                        </div>
                    </div>

                    <div className="no-pad mt-3 container">
                        <div className="col-sm-12">
                            <ReactTable
                                data={this.state.deudas}
                                filterable
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "Número",
                                                id: "Numero",
                                                width: "80",
                                                accessor: d => d.Numero,
                                                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["Numero"] }),
                                                filterable: true
                                            },
                                            {
                                                Header: "Razón social",
                                                id: "RazonSocial",
                                                width: "180",
                                                accessor: d => d.RazonSocial || "Sin proveedor",
                                                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["RazonSocial"] }),
                                                filterable: true
                                            },
                                            {
                                                Header: "Serie-Correlativo",
                                                id: "NroDocumentoSunat",
                                                width: "150",
                                                accessor: d => d.NroDocumentoSunat,
                                                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["NroDocumentoSunat"] }),
                                                filterable: true
                                            },
                                            {
                                                Header: "Importe total",
                                                id: "total",
                                                width: "150",
                                                filterable: true,
                                                Cell: (d) => decimalAdjust('floor', d.original.total, -2)

                                            },
                                            {
                                                Header: "Cant. Cuotas",
                                                id: "cantPagos",
                                                width: "100",
                                                accessor: d => d.cantPagos,
                                                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["cantPagos"] }),
                                                filterable: true
                                            },
                                            {
                                                Header: "Saldo",
                                                id: "saldo",
                                                width: "100",
                                                filterable: true,
                                                Cell: d => decimalAdjust('floor', d.original.saldo, -2)
                                            },
                                            {
                                                Header: "Estado",
                                                id: "estado",
                                                width: "100",
                                                accessor: d => d.estado,
                                                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["estado"] }),
                                                filterable: true
                                            },
                                            {
                                                Header: "Acciones",
                                                width: "300",
                                                filterable: false,
                                                Cell: (d) => {
                                                    if (d.original.estado === "Pagado")
                                                        return (
                                                            <div>
                                                                <button className="btn btn-outline-info"
                                                                    onClick={() => this.onDetallePagosHandler(d.original)}
                                                                    disabled={this.state.isLoading}>
                                                                    Detalle de Pagos
                                                                </button>
                                                            </div>
                                                        )
                                                    return (
                                                        <div>
                                                            <button onClick={() => this.onRealizarPagoHandler(d.original)}
                                                                className="btn btn-outline-secondary"
                                                                disabled={this.state.isLoading}>
                                                                Realizar pago
                                                            </button>
                                                            <button className="btn btn-outline-info"
                                                                onClick={() => this.onDetallePagosHandler(d.original)}
                                                                disabled={this.state.isLoading}>
                                                                Detalle de Pagos
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                    </div>

                    <Modal title="Realizar pagos" isOpen={this.state.isOpenPagoModal} onClose={this.onCloseModal}>
                        <FormPagoMov deuda={this.state.selectedDeuda} />
                    </Modal>

                    <Modal title="Detalles de pagos y créditos" isOpen={this.state.isOpenDetalleModal}
                        onClose={this.onCloseModal}>
                        <DetallePagoMov deuda={this.state.selectedDeuda} />
                    </Modal>

                </div>
            </div>
        );
    }

}
