import React from "react";
import $ from "jquery";
import { decimalAdjust, TIPO_STOCK } from "../../Global";
import { extractTwoDecimalsForNumber } from "../../helpers/calcularVenta";
import {
  BotonAddPequeño,
  BotonDown,
  BotonMenosPequeño,
  BotonRemovePequeño,
} from "../Plantillas/Botones";

export default class DetalleCotizaciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cantidadInput: 1,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let divCarrito = $("#tableDetProdPreventa");
    let rows = $("#IdDetProductoCarrito").find("tr");
    if (rows.length >= 6) divCarrito.scrollTop(divCarrito.prop("scrollHeight"));
  }

  render() {
    return (
      <table className="table table-hover d-block" style={{ minHeight: '300px' }}>
        <thead>
          <tr>
            <th
              className="text-nowrap"
              style={{
                fontSize: "18px",
                backgroundColor: "#01A59C",
                color: "white",
                width: "23%",
              }}
            >
              CANTIDAD
            </th>
            <th
              className="text-nowrap"
              style={{
                fontSize: "18px",
                backgroundColor: "#01A59C",
                color: "white",
                width: "23%",
              }}
            >
              / PRECIO
            </th>
            <th
              className="text-nowrap"
              style={{
                fontSize: "18px",
                backgroundColor: "#01A59C",
                color: "white",
                width: "23%",
              }}
            >
              / TOTAL
            </th>
            <th
              style={{
                fontSize: "18px",
                backgroundColor: "#01A59C",
                color: "white",
                width: "31%",
              }}
            ></th>
          </tr>
        </thead>
        <tbody id="">
          {this.props.detalles.map((det) => {
            const style = {
                
              backgroundColor:
                det.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ? "" : "#f78b2d",
            };

            return (
              <>
                <tr
                  key={`det-head-${det.IdPresentacion}`}
                  style={style}
                  onKeyUp={(e) => this.props.onSecretKeyPress(e, det)}
                >
                    <td colSpan={4} style={{maxWidth: '1em'}}>
                        <div className="d-flex justify-content-between">
                            <span>{det.descripcion} {det.unidadMedida}</span>
                            <div>
                                <select
                                    required
                                    disabled={this.props.load}
                                    className="mt-1 mr-1"
                                    value={det.Precio}
                                    onChange={(e) => this.props.PrecioChange(e, det)}
                                >
                                    <option value={det.PrecioMenor}>Menor/Lista</option>
                                    <option value={det.PrecioFamiliar}>Familiar</option>
                                    <option value={det.PrecioEspecial}>Especial</option>
                                </select>
                                <span>Precio Costo:
                                    {det.PrecioCosto.toLocaleString("en-GB", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr key={`det-body-${det.IdPresentacion}`}> 
                    <td className="p-0 border-0">
                        <input
                            onClick={(e) => {
                            e.target.select();
                            }}
                            readOnly={this.props.load}
                            type="number"
                            style={{ backgroundColor: "rgb(53,96,90,0.18)" }}
                            step="1"
                            min="1"
                            name="Cantidada"
                            value={det.cantidad}
                            className="inputCantidadDet pr-2 h-auto"
                            onChange={(e) =>
                            this.props.InputCantidadDetalleChange(e, det)
                            }
                            onBlur={(e) => this.props.onBlurInputCantidad(e, det)}
                        />
                    </td>
                    <td className="p-0 border-0">
                        <input
                            onClick={(e) => {
                            e.target.select();
                            }}
                            readOnly={this.props.load}
                            type="number"
                            style={{ backgroundColor: "#01a59c", color: "white" }}
                            step="1"
                            min="0"
                            value={extractTwoDecimalsForNumber(det.precioVenta)}
                            onChange={(e) =>
                            this.props.InputPrecioVentaChange(e, det)
                            }
                            name="PrecioVenta"
                            className="inputPrecioVentaDet pr-2 h-auto"
                            onBlur={(e) => this.props.onBlurPrecioVenta(e, det)}
                        />
                    </td>
                    <td className="p-0 border-0 text-white text-right pt-1 " style={{backgroundColor: "#034b2f" }}>
                        <span className="pr-2 h-auto">{det.Simbolo} {extractTwoDecimalsForNumber(det.total)}</span>
                    </td>
                    <td className="p-0 border-0">
                        <div className="d-flex justify-content-end">
                            <BotonMenosPequeño
                                disabled={this.props.load}
                                className="ml-2"
                                type="button"
                                title="Disminuir en 1"
                                onClick={() => this.props.RestarCantidadDet(det)}
                            />
                            <BotonAddPequeño
                                disabled={this.props.load}
                                className="ml-2"
                                type="button"
                                title="Aumentar en 1"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.props.AgregarCantidadDet(det)
                                }}
                            />
                            <BotonRemovePequeño
                                disabled={this.props.load}
                                className="ml-2"
                                type="button"
                                title="Quitar item"
                                onClick={() =>
                                    this.props.RemoveProductoInList(det)
                                }
                            />

                            <BotonDown
                                disabled={this.props.load}
                                className="btn-sm ml-2"
                                type="button"
                                title="Descuento"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.props.handleOpenModalDescuento(det)
                                }}
                            />
                        </div>
                    </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  }
}
