import React, { useEffect } from "react";
import {BotonAddCliente2} from "../Plantillas/Botones";
import _ from 'lodash';


export function BuscarClientePorDocVale(props) {
    useEffect(() => {
        setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const doc = urlParams.get('cliente');

            if (doc && doc.match(/^[0-9]+$/) && (doc.length === 8 || doc.length === 11)) {
                props.DocChange({ target: { value: doc }});
            }
        }, 500);
    }, []);

    return <>
        <div className="row">
            <div className="col-12 col-md-7 col-lg-5 pr-md-0">
                <div class="input-group mr-3">
                    <input type="text" className="px-3 py-2 rounded-left" autoFocus={true} onChange={props.DocChange} value={props.Documento} onFocus={(e) => props.setActiveInputDoc(e, "Documento")} style={{ border: "2px solid #01A59C", boxShadow: "4px 4px 5px 0px rgba(122,121,121,0.50)", background: "#DBEDEC"}} placeholder="DNI/RUC"/>
                    <div className="input-group-append">
                        <BotonAddCliente2
                            hidden={_.isUndefined(props.hideAddCliente) ? false : (props.hideAddCliente)}
                            onClick={props.handleClickNuevo}
                            title="Nuevo Cliente" style={{ width: "100%", borderRadius: '0 10px 10px 0' }} className="px-3 py-2"
                        />
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-4 col-lg-3">
                <input
                    className="px-3 py-2 w-100 rounded-left" style={{ border: "2px solid #01A59C", boxShadow: "4px 4px 5px 0px rgba(122,121,121,0.50)", background: "#DBEDEC"}}
                    name="Alias"
                    onChange={props.AliasChange}
                    type="text"
                    value={props.Alias}
                    placeholder={props.placeholder || "Alias (opcional)"}
                />
            </div>
        </div>
    </>
}

export default BuscarClientePorDocVale;
