import React from "react";
import "../styles/Almacen.css";
import PageLoading from "../PageLoading.js";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
import { BotonPDF, BotonExcel, BotonAdd, BotonEditar, BotonAddAlmacen } from "../Plantillas/Botones";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Almacenes extends React.Component {
  constructor() {
    super();
    this.state = {
      Activo:true,
      buscarEstado: "Activo",
      buscar: "",
      buscarEmpresa: "",
      data: {
        //lista de almacenes
        results: []
      },
      dataEmpresas: [],
      loading: true,
      error: null,
      nombreEmpresa:""
    };
    this.getAlmacenes = this.getAlmacenes.bind(this);
    this.getEmpresas = this.getEmpresas.bind(this);
    this.EstadoChange = this.EstadoChange.bind(this);
    this.handleSavePDF = this.handleSavePDF.bind(this)
  }

  componentDidMount() {
    this.getEmpresas();
    this.getAlmacenes();
    this.intervalo = setInterval(this.getAlmacenes, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalo);
  }

  getAlmacenes = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/almacenes");
      const data = await response.json();
      this.setState({
        loading: false,
        data: data
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
    //console.log(this.state.data)
  };

  getEmpresas = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/empresas");
      const data = await response.json();
      const empresas = data.empresas.filter(act => {
        return act.Estado == "Activo";
      });
      this.setState({
        loading: false,
        dataEmpresas: empresas
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
    //console.log(this.state.dataEmpresas)
  };

  EstadoChange(e) {
    const { checked } = e.target;
    //console.log(checked)
    if (checked === false) {
      this.setState({
        buscarEstado:"Inactivo",
        Activo: checked
      });
    } else {
      this.setState({
        buscarEstado:"Activo",
        Activo: checked
      });
    }
    
  }

  EmpresaChange = e => {
    this.setState({
      buscarEmpresa: e.target.value,
      nombreEmpresa: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
    });
    //console.log(this.state.buscarEmpresa);
  };

  handleSavePDF(e) {
    e.preventDefault();
    let doc = new jsPDF({
      orientation: "landscape"
    });
    
    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.setFontSize(14);
    doc.text(`RELACION DE ALMACENES ${this.state.buscarEstado.toUpperCase()}S- ${this.state.nombreEmpresa} -`, 10, 10);
    doc.autoTable({
      body: this.state.data.results.filter(almacenes => {
        return (
          almacenes.Nombre.toLowerCase().includes(
            this.state.buscar.toLowerCase()
          ) &&
          almacenes.Estado === this.state.buscarEstado &&
          almacenes.RazonSocial === this.state.buscarEmpresa
        );
      }),
      columns:[
        {header: 'NOMBRE', dataKey: 'Nombre'},
        {header: 'NOMBRE DE SUCURSAL', dataKey: 'NombreSucursal'},
        {header: 'UBIGEO', dataKey: 'NombreUbigeo'},
        {header: 'DIRECCIÓN', dataKey: 'Direccion'},
        {header: 'TELÉFONO', dataKey: 'Telefono'},
        {header: 'CELULAR', dataKey: 'Celular'},
        {header: 'CLASE', dataKey: `Clase`}
      ],
      styles: {
        fontSize: 11,
    },
    }).setFontSize(11);
    doc.setFontSize(10);
    doc.setFontType("italic");
    doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

    doc.save(`relación_Almacenes_${this.state.nombreEmpresa}.pdf`);
  }

  getDataExportExcel(dataSet) {
    return [
      {
        columns: [
          {
            title: `RELACION DE ALMACENES ${this.state.buscarEstado.toUpperCase()}S- ${this.state.nombreEmpresa} -`,
            width: { wpx: 80 }
          }
        ],
        data:[]
      },
      {
        columns:[
          {
            title: `NOMBRE`,
            width: { wpx: 150 }
          },
          {
            title: `NOMBRE DE SUCURSAL`,
            width: { wpx: 150 }
          },
          {
            title: `UBIGEO `,
            width: { wpx: 150 }
          },
          {
            title: `DIRECCIÓN`,
            width: { wpx: 150 }
          },
          {
            title: `TELÉFONO `,
            width: { wpx: 80 }
          },
          {
            title: `CELULAR  `,
            width: { wpx: 150 }
          },
          {
            title: `CLASE`,
            width: { wpx: 150 }
          }
        ],
        data: dataSet.map(row => {
          return [
            {
              value: row.Nombre,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreSucursal,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreUbigeo,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Direccion,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Telefono,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Celular,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Clase,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            }
          ];
        })
      }
    ];
  }

  //para mostrar si es principal 1 = Si , 0 = No
  showPrincipal(principal) {
    
    if (principal === 1) {
      return "Principal";
    } else {
      return "Secundario";
    }
  }

  render() {
    const almacenes = this.state.data.results.filter(almacenes => {
      return (
        almacenes.Nombre.toLowerCase().includes(
          this.state.buscar.toLowerCase()
        ) &&
        almacenes.Estado === this.state.buscarEstado &&
        almacenes.RazonSocial === this.state.buscarEmpresa
      );
    })
    const dataExport = this.getDataExportExcel(almacenes)
    if (this.state.loading === true && this.state.data === undefined) {
      return (
        <React.Fragment>
          <div className="d-flex justify-content-center">
            <label>Cargando...</label>
            <div className="spinner-border text-primary ml-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </React.Fragment>
      );
    }
    //mientras carga
    if (this.state.data.results.length === 0) {
      return (
        <React.Fragment>
          <div className="p-5 container">
          <div className="form-group">
                  <label className="mr-3">Buscar por Empresa</label>
                  <select
                    required
                    className="dropdown-toggle input__lineaMediana"
                    aria-haspopup="true"
                    name="empresa"
                    value={this.state.buscarEmpresa}
                    onChange={this.EmpresaChange}
                  >
                    <option>Seleccionar Empresa</option>
                    {this.state.dataEmpresas.map(empresa => {
                      return (
                        <option
                          key={empresa.RazonSocial}
                          value={empresa.RazonSocial}
                        >
                          {empresa.RazonSocial}
                        </option>
                      );
                    })}
                  </select>
                  <label className="mr-3 ml-3">Activos</label>
                  <label className="checkbox-toggle">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        name="Valorizacion"
                        className="toggle-state"
                        onChange={this.EstadoChange}
                        checked={this.state.Activo}
                      />
                      <div className="toggle-inner">
                        <div className="indicator"/>
                        </div>
                      <div className="active-bg"/>
                    </div>
                  </label>
                  <BotonAdd
                    className = "ml-2"  
                    to="/almacenes/add/" 
                    title="Nuevo Almacén"
                    />

                  <BotonPDF
                    className = "ml-2 mr-2"  
                  title="Guardar PDF"
                  onClick={this.handleSavePDF}
                  />

                  <ExcelFile
                    element={
                      <BotonExcel type="button" title="Guardar Excel"/>
                    }
                    filename={`relación_Almacenes_${this.state.nombreEmpresa}`}
                  >
                    <ExcelSheet
                      dataSet={dataExport}
                      name="AlmacenesFile"
                    >
                    </ExcelSheet>
                  </ExcelFile>

                </div>
            <div>
              <PageLoading />
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      const filtrarAlmacenes = this.state.data.results.filter(almacenes => {
        return (
          almacenes.Nombre.toLowerCase().includes(
            this.state.buscar.toLowerCase()
          ) &&
          almacenes.Estado === this.state.buscarEstado &&
          almacenes.RazonSocial === this.state.buscarEmpresa
          //traer todos
          //almacenes.RazonSocial.includes(this.state.buscarEmpresa)
        );
      });

      if (this.state.error) {
        return `Error: ${this.state.error.message}`;
      }
      //no hay coincidencias con la busqueda
      if (filtrarAlmacenes.length === 0) {
        return (
          <section className="ventas-fpay-section-vh cienvh">
            <div className="pt-5 container">
              <div className="form-group d-flex justify-content-between">
                <div>
                  <label className="mr-3 ml-3 mt-3">Activos</label>
                  <label className="checkbox-toggle">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        name="Valorizacion"
                        className="toggle-state"
                        onChange={this.EstadoChange}
                        checked={this.state.Activo}
                      />
                      <div className="toggle-inner">
                        <div className="indicator" />
                      </div>
                      <div className="active-bg" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="caja-fondo-blanco-m-2">
                <label className="mr-3">Buscar por Empresa</label>
                <select
                  required
                  className="dropdown-toggle input__lineaMediana w-100"
                  aria-haspopup="true"
                  name="empresa"
                  value={this.state.buscarEmpresa}
                  onChange={this.EmpresaChange}
                >
                  <option>Seleccionar Empresa</option>
                  {this.state.dataEmpresas.map(empresa => {
                    return (
                      <option
                        key={empresa.RazonSocial}
                        value={empresa.RazonSocial}
                      >
                        {empresa.RazonSocial}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="d-flex justify-content-end">
                <BotonPDF
                  className="ml-2 mr-2"
                  title="Guardar PDF"
                  onClick={this.handleSavePDF}
                />

                <ExcelFile
                  element={
                    <BotonExcel type="button" title="Guardar Excel" />
                  }
                  filename={`relación_Almacenes_${this.state.nombreEmpresa}`}
                >
                  <ExcelSheet
                    dataSet={dataExport}
                    name="AlmacenesFile"
                  >
                  </ExcelSheet>
                </ExcelFile>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <BotonAddAlmacen
                  className="ml-2"
                  to="/almacenes/add/"
                  title="Nuevo Almacén"
                />
              </div>
              <div className="mt-3">
                <h3>No se encontraron almacenes</h3>
              </div>
            </div>
          </section>
        );
      }

      return (
        <section className="ventas-fpay-section-vh cienvh">
          <div className="pt-5 container">
            <div className="form-group d-flex justify-content-between">
              <div>
                <label className="mr-3 ml-3 mt-3">Activos</label>
                <label className="checkbox-toggle">
                  <div className="toggle">
                    <input
                      type="checkbox"
                      name="Valorizacion"
                      className="toggle-state"
                      onChange={this.EstadoChange}
                      checked={this.state.Activo}
                    />
                    <div className="toggle-inner">
                      <div className="indicator" />
                    </div>
                    <div className="active-bg" />
                  </div>
                </label>
              </div>
            </div>
            <div className="caja-fondo-blanco-m-2">
              <label className="mr-3">Buscar por Empresa</label>
              <select
                required
                className="dropdown-toggle input__lineaMediana w-100"
                aria-haspopup="true"
                name="empresa"
                value={this.state.buscarEmpresa}
                onChange={this.EmpresaChange}
              >
                <option>Seleccionar Empresa</option>
                {this.state.dataEmpresas.map(empresa => {
                  return (
                    <option
                      key={empresa.RazonSocial}
                      value={empresa.RazonSocial}
                    >
                      {empresa.RazonSocial}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex justify-content-end">
              <BotonPDF
                className="ml-2 mr-2"
                title="Guardar PDF"
                onClick={this.handleSavePDF}
              />

              <ExcelFile
                element={
                  <BotonExcel type="button" title="Guardar Excel" />
                }
                filename={`relación_Almacenes_${this.state.nombreEmpresa}`}
              >
                <ExcelSheet
                  dataSet={dataExport}
                  name="AlmacenesFile"
                >
                </ExcelSheet>
              </ExcelFile>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <BotonAddAlmacen
                className="ml-2"
                to="/almacenes/add/"
                title="Nuevo Almacén"
              />
            </div>

            <div className="row mt-3">
              <ReactTable
                data={filtrarAlmacenes}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    columns: [
                      {
                        Header: "Acción",
                        filterable: false,
                        headerClassName: "",
                        width: 80,
                        Cell: original => {
                          return (
                            <BotonEditar
                              to={"/almacenes/edit/" + original.original.IdAlmacen}
                            />
                          );
                        }
                      },
                      {
                        Header: "Nombre/Descripcion",
                        id: "Nombre",
                        // accessor: "Nombre",
                        headerClassName: "",
                        width: 300,
                        accessor: d => d.Nombre,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["Nombre"] }),
                        filterAll: true
                      },
                      {
                        Header: "Sucursal",
                        id: "NombreSucursal",
                        // accessor: "NombreSucursal",
                        headerClassName: "",
                        width: 300,
                        accessor: d => d.NombreSucursal,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["NombreSucursal"]
                          }),
                        filterAll: true
                      },
                      // {
                      //   Header: "Responsable",
                      //   id: "NombresEmpleado",
                      //   // accessor: "NombresEmpleado",
                      //   headerClassName: "",
                      //   accessor: d => d.NombresEmpleado,
                      //   filterMethod: (filter, rows) =>
                      //     matchSorter(rows, filter.value, {
                      //       keys: ["NombresEmpleado"]
                      //     }),
                      //   filterAll: true
                      // },
                      {
                        Header: "Clase",
                        id: "Clase",
                        // accessor: "Clase",
                        headerClassName: "",
                        width: 60,
                        accessor: d => d.Clase,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["Clase"] }),
                        filterAll: true
                      },
                      {
                        Header: "Dirección",
                        id: "Direccion",
                        // accessor: "Direccion",
                        headerClassName: "",
                        width: 300,
                        accessor: d => d.NombreUbigeo.concat(" - ", d.Direccion),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Direccion"]
                          }),
                        filterAll: true
                      },
                      {
                        Header: "Principal",
                        id: "EsPrincipal",
                        // accessor: "EsPrincipal",
                        headerClassName: "",
                        Cell: original => {
                          return this.showPrincipal(
                            original.original.EsPrincipal
                          );
                        },
                        accessor: d => this.showPrincipal(d.EsPrincipal),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["EsPrincipal"]
                          }),
                        filterAll: true
                      }
                    ]
                  }
                ]}
                defaultPageSize={5}
                //pageSize={10}
                className="-striped -highlight"
                // Text de paginación
                previousText="Anterior"
                nextText="Siguiente"
                loadingText="Cargando..."
                noDataText="No se encontraron registros"
                pageText="Página"
                ofText="de"
                rowsText="filas"
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              />
            </div>
          </div>
        </section>
      );
    }
  }
}

export default Almacenes;
