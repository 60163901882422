import React from "react";
import ReactDOM from "react-dom";
import "../componentes/styles/Modal.css";

function Modal2({ isOpen, handleKeyUp, onClose, children, title }) {
    if (!isOpen) {
        return null;
    }

    const _handleKeyUp =
        handleKeyUp ||
        (e => {
            if (e.key === "Escape") onClose();
        });

    return ReactDOM.createPortal(
        <div
            className="Modal2"
            onKeyUp={_handleKeyUp}
            onMouseDown={onClose}
            tabIndex="-1"
        >
            <div className="contenedor" onMouseDown={e => e.stopPropagation()} >
                <div className="header">
                    <label>{title}</label>
                    <button className="close" onClick={onClose}>
                        <i className="fa fa-window-close"></i>
                    </button>
                </div>
                <div className="body">
                    {children}
                </div>
            </div>
        </div>,
        document.getElementById("modal")
    );
}

export default Modal2;
