import React from "react";
import ProductosUI from "./ProductosUI";
import PageError from "../componentes/PageError";
import PageLoading from "../componentes/PageLoading";
import swal from "sweetalert";
import jsPDF from "jspdf";
import ProductoService from "../services/ProductoService";
import {notificarError} from "../componentes/Almacenes/AlmacenNotify";


class Productos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            error: null,
            IdProducto: null,
            statusModal: false,
            respuestaDeleteProducto: null,
            table: {
                pageSize: 50,
                sorted: false,
                filtered: false,
                isLoading: true,
                pages: -1,
            },
            filterParams: {},
        };
        this.prodService = new ProductoService();
        this.handleOnClickProducto = this.handleOnClickProducto.bind(this);
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleOnDeleteProducto = this.handleOnDeleteProducto.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.onFilterProds = this.onFilterProds.bind(this);
        this.setTableState = this.setTableState.bind(this);
    }

    handleOnCloseModal() {
        this.setState({
            statusModal: false
        });
    }

    handleKeyUp(event) {
        if (event.key === "Escape") {
            this.setState({
                statusModal: false
            });
        }
    }

    handleOnClickProducto(IdProducto) {
        return () => {
            this.setState({
                statusModal: true,
                IdProducto: IdProducto
            });
        };
    }

    async deleteProducto(IdProducto) {
        await fetch(`/api/productos/delete/${IdProducto}`, {
            method: "POST",
            body: JSON.stringify({
                IdProducto: IdProducto
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(data => {
                this.setState({isLoading: false});

                return data;
            })
            .then(response => response.json())
            .then(responseData => {
                this.setState({respuestaDeleteProducto: responseData.respuesta});
                if (this.state.respuestaDeleteProducto === true) {
                    swal(
                        "¡Borrado!",
                        "¡El Producto ha sido borrado satisfactoriamente!",
                        "success"
                    );
                }
            })
            .catch(error => this.setState({error, isLoading: false}));

        this.onFilterProds(this.state.filterParams);
    }

    handleOnDeleteProducto(IdProducto) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Desea borrar éste producto?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete)
                await this.deleteProducto(IdProducto);

            this.setState({
                IdProducto: IdProducto
            });
        };

    }

    handleSavePDF() {
        const mmPageSize = [760, 450];
        const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
        let doc = new jsPDF({
            orientation: "landscape",
            format: ptPageSize
        });
        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.setFontType("bold");
        doc.text("REPORTE DE PRODUCTOS", 115, 10);
        doc.setFontType("normal");
        //(this.state.data)
        //
        //Calcular los precios sin igv y con igv
        doc.autoTable({
            startY: 15,
            body: this.state.data,
            theme: "plain",
            styles: {lineWidth: 0.5},

            head: [

                [
                    {
                        content:
                            "CODIGO",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    },
                    {
                        content:
                            "NOMBRE",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    },
                    {
                        content: "PRECIOS",
                        colSpan: 7,
                        rowSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    // {
                    //   content:
                    //     "UNIDAD",
                    //   colSpan: 1,
                    //   rowSpan : 2,
                    //   styles: { halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50 }
                    // },
                    {
                        content:
                            "MONEDA",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    },
                    {
                        content:
                            "MEDIDA",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    },
                    {
                        content:
                            "TIPO DE AFECTACIÓN",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    },
                    {
                        content:
                            "EXISTENCIA",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    },
                    {
                        content:
                            "STOCK SUNAT",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    },
                    {
                        content:
                            "STOCK",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    }
                ],
                [

                    {
                        content: "P. COSTO SIN IGV",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "P. COSTO CON IGV",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "P. MENOR SIN IGV",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "P. MENOR CON IGV",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "P. MAYOR SIN IGV",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "P. MAYOR CON IGV",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "P. MAYOR CANTIDAD",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    // {
                    //   content: "P. VALE",
                    //   colSpan: 1,
                    //   styles: { halign: "center", valign: "middle" }
                    // },
                    // {
                    //   content: "P. FAMILIAR",
                    //   colSpan: 1,
                    //   styles: { halign: "center", valign: "middle" }
                    // },
                    // {
                    //   content: "P. ESPECIAL",
                    //   colSpan: 1,
                    //   styles: { halign: "center", valign: "middle" }
                    // },


                ]
            ],
            //************************************************************** */
            columnStyles: {
                text: {
                    cellWidth: "wrap",
                    overflow: "linebreak",
                    halign: "right",
                    valign: "middle"
                },
                codigo: {
                    cellWidth: 20,
                    halign: "center",
                    valign: "middle",
                    overflow: "linebreak"
                },
                nombre: {
                    cellWidth: 50,
                    halign: "left",
                    valign: "middle",
                    overflow: "linebreak"
                },
                PrecioCosto: {
                    cellWidth: 5,
                    halign: "right"
                },
                PrecioCostoConIgv: {
                    cellWidth: 5,
                    halign: "right"
                },
                Precio: {
                    cellWidth: 5,
                    halign: "right"
                },
                PrecioConIgv: {
                    cellWidth: 5,
                    halign: "right"
                },
                PrecioMayor: {
                    cellWidth: 5,
                    halign: "right"
                },
                PrecioMayorConIgv: {
                    cellWidth: 5,
                    halign: "right"
                },
                PrecioMayorCantidad: {
                    cellWidth: 5,
                    halign: "right"
                },
                // PrecioVale: {
                //   cellWidth: 20,
                //   halign: "right"
                // },
                // PrecioFamiliar: {
                //   cellWidth: 20,
                //   halign: "right"
                // },
                // Simbolo: {
                //   cellWidth: 20,
                //   halign: "right"
                // },
                Abreviatura: { //Moneda
                    cellWidth: 15,
                    halign: "center",
                    valign: "middle",
                    overflow: "linebreak"
                },
                unidad_de_medida: {
                    cellWidth: 30,
                    halign: "center",
                    valign: "middle",
                    overflow: "linebreak"
                },
                tipo_de_afectacion_IGV: {
                    cellWidth: 40,
                    halign: "center",
                    valign: "middle",
                    overflow: "linebreak"
                },
                tipo_de_existencia: {
                    cellWidth: 30,
                    halign: "center",
                    valign: "middle",
                    overflow: "linebreak"
                },
                StockDisponibleConComprobante: {
                    cellWidth: 30,
                    halign: "center",
                    valign: "middle",
                    overflow: "linebreak"
                },
                StockDisponibleSinComprobante: {
                    cellWidth: 30,
                    halign: "center",
                    valign: "middle",
                    overflow: "linebreak"
                }
            },
            //************************************************************ */
            columns: [
                {header: 'CÓDIGO', dataKey: 'codigo'},
                {header: 'NOMBRE', dataKey: 'nombre'},


                {header: 'P. COSTO ', dataKey: 'PrecioCosto'}, //Precio Costo
                {header: 'P. COSTO ConIgv ', dataKey: 'PrecioCostoConIgv'}, //Precio Costo con igv
                {header: 'P. MENOR ', dataKey: 'Precio'}, //Precio Menor
                {header: 'P. MENOR ConIgv ', dataKey: 'PrecioConIgv'}, //Precio Menor Con Igv
                {header: 'P. MAYOR ', dataKey: 'PrecioMayor'}, //Precio Mayor
                {header: 'P. MAYOR ConIgv ', dataKey: 'PrecioMayorConIgv'}, //Precio Mayor ConIgv
                {header: 'P. MAYOR CANTIDAD', dataKey: 'PrecioMayorCantidad'}, //Precio Mayor Cantidad
                // {header: 'P. VALE', dataKey: 'PrecioVale'}, //Precio Vale
                // {header: 'P. FAMILIAR', dataKey: 'PrecioFamiliar'}, //Precio Familiar
                // {header: 'UNIDAD', dataKey: 'Simbolo'}, //Precio Especial
                {header: 'MONEDA', dataKey: 'Abreviatura'},
                {header: 'MEDIDA', dataKey: 'unidad_de_medida'},
                {header: 'AFECTACIÓN', dataKey: 'tipo_de_afectacion_IGV'},
                {header: 'EXISTENCIA', dataKey: 'tipo_de_existencia'},
                {header: 'STOCK SUNAT', dataKey: 'StockDisponibleConComprobante'},
                {header: 'STOCK', dataKey: 'StockDisponibleSinComprobante'}
            ]
        });
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("reporte_productos.pdf");
    }

    async onFilterProds(state, instance) {
        this.setTableState({isLoading: true});
        const params = {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }
        this.setState({data: [], filterParams: params});
        let prods = await this.prodService.filtrarProds(params)
        const prodsClean = prods.productos.filter(x => x)
        if (prodsClean) {
            this.setState({data: prodsClean})
            this.setTableState({pages: prods.pages})
        } else
            notificarError("No se ha podido filtrar los productos")
        this.setTableState({isLoading: false});
    }

    setTableState(newTableState) {
        this.setState((localState, props) => {
            return {
                table: {
                    ...localState.table,
                    ...newTableState,
                }
            }
        })
    }

    render() {
        const {isLoading, error} = this.state;
        if (isLoading) {
            return <PageLoading/>;
        }
        if (error) {
            return <PageError error={this.state.error}/>;
        }
        return (
            <div id="productosContainer">
                <ProductosUI
                    onFilterProds={this.onFilterProds}
                    tableInfo={this.state.table}
                    data={this.state.data}
                    handleOnClickProducto={this.handleOnClickProducto}
                    IdProducto={this.state.IdProducto}
                    handleKeyUp={this.handleKeyUp}
                    onCloseModal={this.handleOnCloseModal}
                    statusModal={this.state.statusModal}
                    history={this.props.history}
                    handleOnDeleteProducto={this.handleOnDeleteProducto}
                    handleOnCloseModal={this.handleOnCloseModal}
                    pdfViewer={this.handleSavePDF}
                />
            </div>
        );
    }
}

export default Productos;
