import React from 'react';

import LinkButton from "../../componentes/LinkButton";
import { Link } from 'react-router-dom';
import PageLoading from '../PageLoading.js';
import jsPDF from "jspdf";
import TransportistasDeEmpresaList from '../Transportistas/TransportistasDeEmpresaList.js'
import { BotonGuardarLetras, BotonAdd, BotonCancelar, BotonPDF, BotonExcel, BotonAddAgregar } from "../Plantillas/Botones";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Transportistas extends React.Component {

  constructor() {
    super()
    this.state = {
      Estado: 'activo',
      checkEstado: true,
      mostrarButtonAdd: true,
      dataTransportistas: {
        Transportistas: []
      },
      IdEmpresa: '',
      dataEmpresas: [],
      loading: false,
      error: null,
    }
    this.handleSavePDF = this.handleSavePDF.bind(this);

  }

  componentDidMount = async () => {
    await Promise.all([
      await this.getEmpresas()
    ]).then(() => this.obtenerTransportistas(0))

    // this.intervalo=setInterval(this.obtenerTransportistas,1000)

  }

  componentWillUnmount() {
    clearInterval(this.intervalo)
  }

  getEmpresas = async () => {
    // this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/empresas");
      const data = await response.json();
      const empresas = data.empresas.filter(act => {
        return act.Estado == "Activo";
      });
      this.setState({
        loading: true,
        dataEmpresas: empresas
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };


  obtenerTransportistas = async (IdEmpresa) => {
    // this.setState({loading:true, error:null})
    try {
      const response = await fetch(`/api/transportistas/porEmpresa/${IdEmpresa}`);
      const data = await response.json();
      this.setState({
        // loading: false,
        dataTransportistas: data
      })
      // console.log(data)
    } catch (error) {
      this.setState({ loading: false, error: error })
    }
  }
  EstadoChange = (e) => {
    this.setState({
      Estado: e.target.value
    });
  }
  EmpresaChange = async (e) => {
    // console.log(e.target.value)
    if (e.target.value != "Seleccionar Empresa") {
      this.setState({
        mostrarButtonAdd: false,
        [e.target.name]: e.target.value,
      })
      await this.obtenerTransportistas(e.target.value);
    } else {
      this.setState({
        mostrarButtonAdd: true,
      })
      await this.obtenerTransportistas(0);
    }
  }
  EstadoChange = (e) => {
    if (this.state.Estado == 'activo') {
      this.setState({
        Estado: 'inactivo',
        checkEstado: false
      });
    } else if (this.state.Estado == 'inactivo') {
      this.setState({
        Estado: 'activo',
        checkEstado: true
      });
    }

  }
  handleSavePDF(e) {
    e.preventDefault();
    let doc = new jsPDF({
      orientation: "landscape"
    });

    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.setFontSize(12);
    doc.text("RELACION DE CONDUCTORES", 110, 10);
    // console.log(this.state.data.GuiasRemision)
    doc.autoTable({
      body: this.state.dataTransportistas.Transportistas,
      columns: [
        { header: 'EMPLEADO', dataKey: 'Empleado' },
        { header: 'DNI', dataKey: 'DNI' },
        { header: 'CATEGORIA', dataKey: 'Categoria' },
        { header: 'NUMERO DE LICENCIA', dataKey: 'NumeroLicencia' },
        { header: 'CELULAR', dataKey: 'Celular' },
        { header: 'ESTADO', dataKey: 'Estado' },
      ],
      startY: 20,
      margin: 5,
      styles: {
        overflow: "linebreak",
        lineWidth: 0.1,
        tableWidth: "auto",
        columnWidth: "auto",
        halign: "center"
      },
      theme: "plain"
    }).setFontSize(11);
    doc.setFontSize(10);
    doc.setFontType("italic");
    doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

    doc.save("relación_Conductores.pdf");
  }
  getDataExportExcel(dataSet) {
    return [
      {
        columns: [
          {
            title: `RELACION DE CONDUCTORES`,
            width: { wpx: 80 }
          }
        ],
        data: []
      },
      {
        columns: [
          {
            title: `EMPLEADO`,
            width: { wpx: 150 }
          },
          {
            title: `CATEGORIA`,
            width: { wpx: 150 }
          },
          {
            title: `NUMERO DE LICENCIA`,
            width: { wpx: 150 }
          },
          {
            title: `ESTADO`,
            width: { wpx: 150 }
          },
          {
            title: `DNI`,
            width: { wpx: 80 }
          },
          {
            title: `CELULAR`,
            width: { wpx: 150 }
          }
        ],
        data: dataSet.map(row => {
          return [
            {
              value: row.Empleado,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Categoria,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NumeroLicencia,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Estado,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.DNI,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Celular,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            }
          ];
        })
      }
    ];
  }

  render() {
    // if(this.state.loading === true && this.state.dataTransportistas=== undefined){
    //     return <PageLoading/>;
    // }


    if (this.state.loading == false) {
      return <PageLoading />;
    } else {
      const transportistasFiltrados = this.state.dataTransportistas.Transportistas.filter((transportista) => {
        return transportista.Estado.includes(this.state.Estado)
      })
      const dataExport = this.getDataExportExcel(transportistasFiltrados)

      if (this.state.dataTransportistas.error) {
        return `Error: ${this.state.error.message}`;
      }
      return (
        <React.Fragment>
          <div className="container mt-4 mb-4">
            <BotonCancelar to="/empresas" />
          </div>
          <form className="d-flex justify-content-center">
            <div className="container">
              <div className="almacenDiv">
                <div className="caja-fondo-blanco-m">
                  <b className="labelAlmacen mr-3">Empresa</b>
                  <select
                    required
                    className="input__linea"
                    aria-haspopup="true"
                    name="IdEmpresa"
                    value={this.state.buscarEmpresa}
                    onChange={this.EmpresaChange}
                  >
                    <option>Seleccionar Empresa</option>
                    {this.state.dataEmpresas.map(empresa => {
                      return (
                        <option
                          key={empresa.ID}
                          value={empresa.ID}
                        >
                          {empresa.RazonSocial}
                        </option>
                      );
                    })}
                  </select>
                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    {/* <label>FALTA SELECCIONAR LA EMPRESA Y QUE SEAN ACTIVOS (PARECIDO A EMPLEADOS - REVISAR SI ES NECESARIO CAMBIAR EL BACK Y EN LUGAR DE MANDARLO DEL USER, MANDARLO DEL SELECCIONADO  )</label> */}
                    {/* <LinkButton hidden={this.state.mostrarButtonAdd} title="Nuevo Transportista" className="btn btn-outline-primary ml-1 mr-1" state={this.state} to={`/transportistas/addPorEmpresa/${this.state.IdEmpresa}`}><i className="ml-1 fa fa-plus-square" ></i></LinkButton> */}
                    <BotonPDF
                      hidden={this.state.mostrarButtonAdd}
                      title="Guardar PDF"
                      onClick={this.handleSavePDF}
                      className="ml-3"
                    />
                    <ExcelFile
                      element={
                        <BotonExcel
                          hidden={this.state.mostrarButtonAdd}
                          className="ml-3"
                          type="button"
                          title="Guardar Excel"
                          style={{ display: `${this.state.displayButton}` }} />
                      }
                      filename={`Relacion_Conductores`}
                    >
                      <ExcelSheet

                        dataSet={dataExport}
                        name="ConductoresFile"
                      >
                      </ExcelSheet>
                    </ExcelFile>
                    <BotonAddAgregar
                      hidden={this.state.mostrarButtonAdd} title="Nuevo Transportista" className="ml-3" state={this.state} to={`/transportistas/addPorEmpresa/${this.state.IdEmpresa}`}
                    />
                    {/* <button title="Guardar PDF" className="btn btn-outline-danger ml-1 mr-1" onClick={this.handleSavePDF}> <i className="fas fa-file-pdf" ></i></button> */}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <b>Activos</b>
                  <div className="input__Almacen">
                    <label className="checkbox-toggle">
                      <div className="toggle">
                        <input
                          type="checkbox"
                          name="Estado"
                          className="toggle-state"
                          onChange={this.EstadoChange}
                          defaultChecked={this.state.Estado}
                        />
                        <div className="toggle-inner">
                          <div className="indicator" />
                        </div>
                        <div className="active-bg" />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <TransportistasDeEmpresaList
                state={this.state}
                transportistasFiltrados={transportistasFiltrados}
              />
            </div>
          </form>
          {/* <div align ="right" >
                                    <label>Estado de Transportistas</label>
                                        <select onChange={this.EstadoChange} className="btn btn-outline-dark dropdown-toggle m-5" aria-haspopup="true" aria-expanded="false">
                                            <option value="activo">Activos</option>
                                            <option value="inactivo">Inactivos</option>
                                        </select>
                                    </div> */}
        </React.Fragment>
      )
    }
  }

}

export default Transportistas;