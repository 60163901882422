import React from 'react';
import SelectEmpresa from './EmpresasLista';
import SelectSucursal from './SucursalesLista';
import Modal from '../clientes/ClienteModal';
import PuntoVentaNuevo from './PuntoVentaNuevo';
import PuntoVentaEditar from './PuntoVentaEditar';
import ConfigComprobantePuntoVenta from './ConfigComprobantePuntoVenta';
import ReactTable from "react-table";
import PageLoading from '../PageLoading';

class ConfigPuntoVenta extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataEmpresas: [],
            dataSucursales: [],
            dataPuntosVenta: [],

            Llama: "",
            idEmpresa: 0,
            idSucursal: 0,
            loading: true,
            error: null,

            ModalIsOpen: false,
            itemPV: {
                IdPuntoVenta: null,
                Nombre: '',
            }
        }
    }

    componentDidMount() {
        this.TraerEmpresas();
        // this.intervalId = setInterval(this.SucursalChange,5000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    TraerEmpresas = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch("/api/empresas");
            const data = await response.json();
            const empresas = data.empresas.filter(act => {
                return act.Estado == "Activo";
            });
            this.setState({
                loading: false,
                dataEmpresas: empresas
            });

        } catch (error) {
            this.setState({loading: false, error: error});
        }
        // console.log('Empresas: ',this.state.dataEmpresas)
    }

    EmpresaChange = e => {
        this.setState({
            idSucursal: 0,
            idEmpresa: parseInt(e.target.value),
        });
        this.TraerSucursales(e.target.value);
        this.TraerPuntosVenta(-1);
    }
    SucursalChange = e => {
        // console.log(e.target.value);
        this.setState({
            idSucursal: parseInt(e.target.value),
        })
        this.TraerPuntosVenta(e.target.value);
    }

    TraerSucursales = async (idemp) => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/sucursales`);
            const dataSuc = await response.json();
            //console.log(response.headers);
            this.setState({
                loading: false,
                dataSucursales: dataSuc.sucursales,
            });

            const dataS = await this.state.dataSucursales.filter((sucursal) => {
                return sucursal.IdEmpresa === parseInt(idemp)
            });
            // console.log(dataS);
            this.setState({
                dataSucursales: dataS,
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
        // console.log('Sucursales: ',this.state.dataSucursales);
    };


    TraerPuntosVenta = async (idsuc) => {
        // console.log('sucursal ',idsuc);
        // if(idsuc > 0){
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/puntosVenta/sucursal/${idsuc}`);
            const dataPV = await response.json();
            // console.log('PV',dataPV[0]);
            this.setState({
                loading: false,
                dataPuntosVenta: dataPV[0],
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
        // }
    };

    handleOpenModalNuevo = (e) => {
        this.setState({
            modalIsOpen: true,
            Llama: 'Nuevo',
        })
    }

    handleOpenModalEditar = (e) => {
        this.setState({
            modalIsOpen: true,
            itemPV: {
                ...this.state.itemPV,
                IdPuntoVenta: e.target.id,
                Nombre: e.target.value,
            },
            Llama: 'Editar',
        })
    }

    handleOpenModalComprobante = (e) => {
        console.log("e.target: ", e.target.idcaja);
        this.setState({
            modalIsOpen: true,
            itemPV: {
                ...this.state.itemPV,
                IdPuntoVenta: e.target.id,
                Nombre: e.target.value,
            },
            Llama: 'Comprobante',
        })
    }

    OpenModalComprobante = (idpv, nombre, idcaja) => {
        this.setState({
            modalIsOpen: true,
            itemPV: {
                ...this.state.itemPV,
                IdPuntoVenta: idpv,
                Nombre: nombre,
                IdCaja: idcaja,
            },
            Llama: 'Comprobante',
        })
    }


    handleCloseModal = (e) => {
        this.setState({
            modalIsOpen: false,
            itemPV: {
                ...this.state.itemPV,
                IdPuntoVenta: null,
                Nombre: '',
            }
        })
    }
    hola = (nom) => {
        alert('Hola ' + nom);
    }

    render() {
        /* SI HUBO ERROR AL CARGAR LA DATA MOSTRAMOS EL ERROR */
        /* ================================================== */
        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }

        /* MOSTRAMOS UN PRE-LOAD AL CARGAAR LA DATA */
        /* ================================================== */
        if (this.state.loading === true && this.state.dataPuntosVenta === undefined) {
            return <PageLoading/>;
        }

        // if(this.state.dataPuntosVenta.length === 0 || this.state.dataPuntosVenta=== undefined){
        return (
            <section className='ventas-fpay-section-vh cienvh'>
            <div className='container pt-5'
            >
                <div className="">
                    <div className="caja-fondo-blanco-m-2">
                        <label htmlFor="cbEmpresa">Empresa:</label>
                        <SelectEmpresa
                            id="cbEmpresa"
                            dataEmpresas={this.state.dataEmpresas}
                            EmpresaChange={this.EmpresaChange}
                        />
                    </div>
                    <div className="caja-fondo-blanco-m-2">
                        <label htmlFor="cbSucursal">Sucursal:</label>
                        <SelectSucursal
                            id={this.state.idSucursal}
                            dataSucursales={this.state.dataSucursales}
                            SucursalChange={this.SucursalChange}
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-end" >
                    <div className="">
                        <button className="btn btn-verde-mfp mt-3 mb-3"
                                title="Nuevo Punto de Venta"
                                disabled={this.state.idSucursal <= 0 ? true : false}
                                onClick={this.handleOpenModalNuevo}>
                                <a className="agregar-text" />    
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ReactTable
                            data={this.state.dataPuntosVenta}
                            columns={[
                                {
                                    // Header: "Lista Puntos de Venta",
                                    columns: [
                                        {
                                            Header: "Acciones",
                                            accessor: "IdPuntoVenta",
                                            width: 100,
                                            Cell: (f) => {

                                                return (
                                                    <div align="center">
                                                        <div>
                                                            <button
                                                                id={f.value}
                                                                className="btn btn-outline-primary fas fa-edit mr-1"
                                                                title="Editar"
                                                                value={f.original.Nombre}
                                                                onClick={this.handleOpenModalEditar}
                                                            />
                                                            <button
                                                                id={f.value}
                                                                className="btn btn-outline-success fas fa-file-alt mr-1"
                                                                title="Comprobantes de Pago"
                                                                value={f.original.Nombre}
                                                                idcaja={f.original.IdCaja}
                                                                onClick={this.handleOpenModalComprobante}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        },
                                        // {
                                        //     Header:"Id",
                                        //     accessor:"IdPuntoVenta",
                                        //     width:50,
                                        // },
                                        {
                                            Header: "Nombre / Alias",
                                            accessor: "Nombre",
                                            width: 500,
                                        },
                                        {
                                            Header: "MAC",
                                            accessor: "MAC",
                                            width: 200,
                                        },
                                        {
                                            Header: "Función",
                                            accessor: "Funcion",
                                            width: 100,
                                        },
                                        {
                                            Header: "Estado",
                                            accessor: "Estado",
                                            width: 100,
                                        },
                                    ]
                                }
                            ]}
                            defaultPageSize={10}
                            //pageSize={10}
                            className="-striped -highlight"
                            // Text de paginación
                            previousText='<<'
                            nextText='>>'
                            loadingText='Cargando...'
                            noDataText='No se encontraron registros'
                            pageText='Pág.'
                            ofText='de'
                            rowsText='filas'
                            width='90%'
                            pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}

                        />
                    </div>

                </div>
                {
                    this.state.Llama === 'Nuevo' &&
                    <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                           title="NUEVO Punto de Venta---------------------------->"
                    >
                        <PuntoVentaNuevo
                            onClose={this.handleCloseModal}
                            idSucursal={this.state.idSucursal}
                            listarPuntosVenta={this.TraerPuntosVenta}
                        />
                    </Modal>
                }
                {
                    this.state.Llama === 'Editar' &&
                    <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                           title="MODIFICAR Punto de Venta---------------------------->"
                    >
                        <PuntoVentaEditar
                            onClose={this.handleCloseModal}
                            idSucursal={this.state.idSucursal}
                            listarPuntosVenta={this.TraerPuntosVenta}
                            itemPV={this.state.itemPV}

                        />
                    </Modal>
                }
                {
                    this.state.Llama === 'Comprobante' &&
                    <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                           title={"COMPROBANTE DE PUNTO DE VENTA => " + this.state.itemPV.Nombre}
                    >
                        <ConfigComprobantePuntoVenta
                            onClose={this.handleCloseModal}
                            itemPV={this.state.itemPV}
                        />
                    </Modal>
                }
            </div>
            </section>
        );

    }
}

export default ConfigPuntoVenta;
