import React, { useEffect, useState } from "react";
import { BotonSearchCliente2, BotonAddCliente2, BotonCancelarLetras } from "../../Plantillas/Botones";
import Modal from "../../Modal";
import ModalBuscarCliente from "../../Preventas/ModalBuscarCliente";
import ModalCliente from '../../clientes/ClienteModal';
import ClienteNuevo from "../../clientes/ClientesNuevo";
import {notificarError} from "../../Almacenes/AlmacenNotify";


const DNI_DOCUMENT_LENTH = 8;
const RUC_DOCUMENT_LENTH = 11;


export default function (props) {
  const [document, setDocument] = useState('');
  const [client, setClient] = useState({
    name: '',
    address: '',
  })
  const [clientData, setClientData] = useState(null);
  const [canContinue, setCanContinue] = useState(false);
  const [showSearchClient, setShowSearchClient] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);

  useEffect(() => {
    props.onChangeClient(clientData)
  }, [clientData])

  useEffect(() => {
    if (document.trim().length !== DNI_DOCUMENT_LENTH && document.trim().length !== RUC_DOCUMENT_LENTH) {
      return;
    }
    
    setClientData(null);

    const value = document.trim();
    const onlyNumbers = /^\d+$/;
    const isValid = onlyNumbers.test(value);

    if (!isValid) {
      return;
    }

    const fetchClient = async (doc) => {
      setClient({
        name: '',
        address: ''
      })

      const req = await fetch(`/api/preVentas/traerCliente/${doc}`);
      const data = await req.json();

      if (!Array.isArray(data)) {
        return;
      }

      const [clientData, ] = data;

      if (!clientData) {
        notificarError('No se encontró algún cliente registrado con el documento ingresado.')
        return;
      }

      setClientData(clientData);
      
      setClient({
        name: clientData.RazonSocial !== "" ? clientData.RazonSocial : clientData.PrimerNombre + " " + clientData.ApellidoPaterno,
        address: clientData.Direccion
      })
    }

    fetchClient(value);

  }, [document])

  useEffect(() => {
    if (client.name !== "") {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [client]);

  const onChooseClient = (client) => {
    setDocument(client.NroTipoDocumento)
    setClientData(client);
    setShowSearchClient(false);
  }

  return (
    <>
      <div className="row">
        <div className="form-group col-6">
          <label className="font-weight-bold">
            Nro. Documento
          </label>
          <div className="input-group">
            <input type="text" className="form-control" placeholder="RUC/DNI" value={document} onChange={e => setDocument(e.target.value)} />
            <div className="input-group-append">
              <BotonAddCliente2
                  onClick={() => setShowAddClient(true)}
                  className="ml-3 px-3 botones-agregar-buscar"
                  title="Nuevo Cliente"
              />
              <BotonSearchCliente2
                className="ml-3 px-3 botones-agregar-buscar"
                onClick={() => setShowSearchClient(true)}
                title="Buscar Cliente en la lista"
              />
            </div>
          </div>
        </div>
        <div className="form-group col-6">
          <label className="font-weight-bold">
            Razón Social
          </label>
          <input type="text" value={client.name} className="form-control" disabled/>
        </div>

        <div className="form-group col-12">
          <label className="font-weight-bold">
            Dirección
          </label>
          <textarea className="form-control" cols="30" rows="3" value={client.address} disabled></textarea>
        </div>
      </div>

      <Modal title="BUSCAR CLIENTE" isOpen={showSearchClient} onClose={() => setShowSearchClient(false)}>
          <ModalBuscarCliente GetCliente={(e) => onChooseClient(e)} />
      </Modal>

      <ModalCliente
          isOpen={showAddClient}
          onClose={() => setShowAddClient(false)}
          title="NUEVO CLIENTE">
          <ClienteNuevo onClose={() => setShowAddClient(false)}
              AsignarValorDoc={(e) => onChooseClient(e)}
          />
      </ModalCliente>
      
      {canContinue && <button type="button" className="btn btn-outline-primary btn-verde-mfp btn-block" onClick={() => props.nextStep()}>Continuar</button>}

      <BotonCancelarLetras
          onClick={() => props.onCancel()}
          className="mb-2 mt-2 btn-block"
      />
    </>
  );
}
