import React from "react";
import "./styles/Header.css";

class Header extends React.Component {
  render() {
    return (
      <div className="d-flex justify-content-start mb-4">
        <div className="parent">
          <img className="parent_icon" src={this.props.parent_icon} alt="icono parent"/>
          <h5 className="parent_font">{this.props.parent}</h5>
        </div>
        <div className="child">
        <img className="child_icon" src={this.props.child_icon} alt="icono child"/>
          <h3 className="child_font">{this.props.child}</h3>
        </div>
      </div>
    );
  }
}

export default Header;
