import React from "react";
import { BotonBuscar, BotonGuardarLetras } from "../Plantillas/Botones";

class EmpleadoForm extends React.Component {
  render() {
    return (
      <React.Fragment>

        <form onSubmit={this.props.onSubmit} className="d-flex justify-content-center">
          <div className="container mt-5">

            {this.props.Empresa ? this.props.Empresa : null}

            <div className="">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*DNI</b>

                <input
                  className="input__linea mr-2"
                  name="DNI"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.DNI}
                  placeholder="DNI"
                  required
                  autoFocus
                />
                <div className="d-flex justify-content-end mt-3">
                  <BotonBuscar
                    type="button"
                    onClick={this.props.onSubmitDNI}
                    title="Buscar datos en RENIEC (Puede demorar)"
                  ></BotonBuscar>
                </div>
              </div>
              <div className="labelValidEmpleado ml-4">
                {this.props.state.Errores.DNI.length > 0 && (
                  <span>{this.props.state.Errores.DNI}</span>
                )}
              </div>
            </div>


            <div className="caja-fondo-blanco-m-2">
              <b className="">*Nombres</b>
              <input
                className="input__linea"
                name="Nombres"
                type="text"
                value={this.props.state.Nombres}
                required
                onChange={this.props.onChange}
                placeholder="Nombres"
              />
            </div>
            <div className="labelValidEmpleado">
              {this.props.state.Errores.Nombres.length > 0 && (
                <span>{this.props.state.Errores.Nombres}</span>
              )}
            </div>



            <div className="caja-fondo-blanco-m-2">
              <b className="">*Apellidos</b>
              <input
                className="input__linea"
                name="Apellidos"
                onChange={this.props.onChange}
                type="text"
                value={this.props.state.Apellidos}
                required
                placeholder="Apellidos"
              />
            </div>
            <div className="labelValidEmpleado">
              {this.props.state.Errores.Apellidos.length > 0 && (
                <span>{this.props.state.Errores.Apellidos}</span>
              )}
            </div>



            <div className="caja-fondo-blanco-m-2">
              <b className="">Teléfono</b>
              <input
                className="input__linea"
                name="Telefono"
                onChange={this.props.onChange}
                type="text"
                value={this.props.state.Telefono}
                placeholder="Teléfono"
              />
            </div>
            <div className="labelValidEmpleado">
              {this.props.state.Errores.Telefono.length > 0 && (
                <span>{this.props.state.Errores.Telefono}</span>
              )}
            </div>



            <div className="caja-fondo-blanco-m-2">
              <b className="">*Celular</b>
              <input
                className="input__linea"
                name="Celular"
                onChange={this.props.onChange}
                type="text"
                value={this.props.state.Celular}
                required
                placeholder="Celular"
              />
            </div>
            <div className="labelValidEmpleado">
              {this.props.state.Errores.Celular.length > 0 && (
                <span>{this.props.state.Errores.Celular}</span>
              )}
            </div>



            <div className="caja-fondo-blanco-m-2">
              <b className="">*Correo</b>
              <input
                className="input__linea"
                name="Correo"
                onChange={this.props.onChange}
                type="email"
                value={this.props.state.Correo}
                required
                placeholder="Correo"
              />
            </div>
            <div className="labelValidEmpleado">
              {this.props.state.Errores.Correo.length > 0 && (
                <span>{this.props.state.Errores.Correo}</span>
              )}
            </div>



            <div className="caja-fondo-blanco-m-2">
              <b className="">*Dirección</b>
              <input
                className="input__linea"
                name="Direccion"
                onChange={this.props.onChange}
                type="text"
                value={this.props.state.Direccion}
                required
                placeholder="Dirección"
              />
            </div>



            <div className="caja-fondo-blanco-m-2">
              <b className="">*Fecha de Ingreso</b>
              <input
                className="input__linea"
                name="FechaIngreso"
                onChange={this.props.onChange}
                type="date"
                value={this.props.state.FechaIngreso}
                required
              />
            </div>
            <div className="labelValidEmpleado">
              {this.props.state.Errores.FechaIngreso.length > 0 && (
                <span>{this.props.state.Errores.FechaIngreso}</span>
              )}
            </div>


            <div className="d-flex justify-content-end container mb-3">
              {this.props.CambiarEstadoEmpleado ? this.props.CambiarEstadoEmpleado : null}
              <BotonGuardarLetras type="submit" className="ml-2" />
            </div>

          </div>
        </form>


      </React.Fragment>
    );
  }
}

export default EmpleadoForm;
