import { io } from 'socket.io-client';
import Noty from "noty";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

export const KILOGRAMO_UNIT_DESCRIPTION = "KILOGRAMO"

function notificar(type, message) {
    new Noty({
        type,
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: message,
        timeout: 1500
    }).show();
}

let instance;

let sourceIsRunning = false;
let scaleEnabled = false;
let scaleStatus = "NOT_READY";
let codeOfWeightOwner = undefined;
let addWeight = false;

class Listener {
    constructor() {
        if (instance) {
            throw new Error("Only a single instance of this listener is allowed.")
        }

        this.subscribers = [];
        this.checkAvailability();
        instance = this;
    }

    async checkAvailability() {
        const reqAddons = await fetch(`/api/preVentas/addons/magellan`);

        if (!reqAddons.ok) {
            return;
        }

        try {
            this.emitter = io('http://localhost:3000');

            this.emitter.on("connect_error", () => {
                const task = async () => {
                    sourceIsRunning = false;

                    const configReq = await fetch("/api/pre-invoice/config");

                    if (configReq.ok) {
                        const config = await configReq.json()
        
                        if ('canSellWithoutScale' in config && !config.canSellWithoutScale) {
                            sourceIsRunning = true;
                        }
                        
                    }

                    this.onChangeAvailability();
                    this.emitter.close();
                }

                task();
                
            })
            
            this.emitter.on('connection_status', (msg) => {
                if (msg === 'RUNNING') {
                    sourceIsRunning = true;
                }

                this.onChangeAvailability();
            });

            this.emitter.on('scale_enabled', (msg) => {
                scaleEnabled = !!(+msg)
            })

            this.emitter.on('scale_status', (msg) => {
                scaleStatus = msg;
                this.notifyScaleStatus();
            })

            this.emitter.on('weight_req', (msg) => {
                if (msg === 'COMMAND_SENDED') {
                    notificar('info', "Coloque el producto encima de la balanza.")
                } else {
                    notificar('warning', "No se ha detectado un peso válido.")
                }
            });

            this.emitter.on('weight', this.onRecieveWeight.bind(this))
            this.emitter.on('code', this.onRecieveCode.bind(this))
        } catch (e) {
            console.error(e)
        }
    }

    addSubscriber(subscriber) {
        this.subscribers.push(subscriber);
    }

    sourceIsRunning() {
        return sourceIsRunning;
    }

    scaleEnabled() {
        return scaleEnabled;
    }

    currentStatus() {
        return scaleStatus;
    }

    notifyScaleStatus() {
        const container = document.querySelector('.preventa__container');

        if (!container) {
            return;
        }

        if (scaleStatus === "NOT_READY") {
            notificar('error', "La balanza no está lista para su uso.")
        } else if (scaleStatus === "TOO_HEAVY") {
            notificar('error', "El producto sobre pasa el limitie de peso permitido.")
        } else if (scaleStatus === "NON_ZERO") {
            notificar('warning', "Retire el producto actual para cotinuar.")
        }
    }

    onChangeAvailability() {
        this.subscribers.forEach((s) => {
            if ('onMagellanChangeAvailability' in s) {
                s.onMagellanChangeAvailability(sourceIsRunning);
            }
        })
    }

    onRecieveWeight(weight) {
        this.subscribers.forEach((s) => {
            if (codeOfWeightOwner && 'onRecieveWeight' in s) {
                s.onRecieveWeight(weight, codeOfWeightOwner, addWeight);
            }
        });

        codeOfWeightOwner = undefined;
        addWeight = false;
    }
    
    onRecieveCode(code) {
        this.subscribers.forEach((s) => {
            if ('onRecieveCode' in s) {
                s.onRecieveCode(code);
            }
        })
    }

    requestWeight(code, add) {
        codeOfWeightOwner = code;
        addWeight = add;
        this.notifyScaleStatus();
        this.emitter.emit('weight_req');
    }

    close() {
        this.emitter.close();
    }
}

const singletonListener = new Listener();

export default singletonListener;
