import React from "react";
import moment from "moment";
import SucursalPicker from "./SucursalPicker";
import ProductoPicker from "./ProductoPicker";
import TipoDocMovPicker from "./TipoDocMovPicker";
import TipoMovPicker from "./TipoMovPicker";

export default class FiltroReporteMovAlmacen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {
                fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
                fechaFin: moment(new Date()).format('YYYY-MM-DD'),
            }
        }
    }

    setProduct(payload, e) {
        if (!e) {
            return
        }
        this.setState({
            payload: {...payload, idProducto: e.value, _prod: e}
        })
    }

    render() {
        const {payload} = this.state;
        return (
            <>
                <div className="justify-content-center caja-fondo-blanco-m-2 contenedor-fecha-fpay">
                    <span className="align-self-center letra-fecha-fpay">
                        Desde:</span>
                    <input className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay" type="date"
                           value={payload.fechaInicio}
                           onChange={e => this.setState({
                               payload: {...payload, fechaInicio: e.target.value},
                           })}/>

                    <span className="align-self-center letra-fecha-fpay">
                        Hasta:</span>
                    <input className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay" type="date"
                           value={payload.fechaFin}
                           onChange={e => this.setState({
                               payload: {...payload, fechaFin: e.target.value}
                           })}/>

                </div>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-sm">
                            <SucursalPicker onChangeSucursal={id => this.setState({
                                payload: {...payload, idSucursal: id}
                            })}/>
                        </div>
                        {payload.idSucursal ? (
                            <div className="col-sm">
                                <ProductoPicker initialLoad={false} idSucursal={payload.idSucursal}
                                                onChangeProducto={e =>
                                                    this.setProduct(payload, e)
                                                }
                                />
                                )
                            </div>
                        ) : null}

                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <TipoMovPicker onChangeTipoMov={e => this.setState({
                                payload: {...payload, idsTipoMov: e}
                            })}/>
                        </div>
                        <div className="col-sm">
                            <TipoDocMovPicker onChangeTipoDoc={e => this.setState({
                                payload: {...payload, idsTipoDoc: e}
                            })}/>
                        </div>
                    </div>
                    <div className="">
                        <div className="seccion-btn-verde-mfp">
                            <button disabled={this.props.isLoading} className="btn btn-outline-primary btn-verde-mfp"
                                    onClick={() => this.props.onFiltrarPressed(payload)}>
                                Filtrar
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
