import {IVAP} from "../../Global";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import calcularVenta from "../../helpers/calcularVenta";


export const getVentaCalculada = (preventa, detalles) => {
    const TasaIGV = preventa.TasaIGV;
    const TasaICBPER = preventa.TasaICBPER;
    const TasaIVAP = IVAP;

    let datosParaCalculo = {
        Descuento: 0,
        TasaIGV,
        TasaICBPER,
        TasaIVAP,
        Items: detalles.map((detalle) => {
            return {
                ValorUnitario: detalle.ValorUnitario,
                Cantidad: detalle.Cantidad,
                Descuento: detalle.Descuento,
                Tributos: JSON.parse(detalle.Tributos),
                TasaISC: detalle.TasaIsc,
                IdStock: detalle.IdStock,
                PrecioReferencial: detalle.PrecioReferencial,
                IdAfectacionIgv: detalle.IdAfectacionIgv,
                IdPresentacion: detalle.IdPresentacion,
                TipoCambio: detalle.TipoCambio,
                Gratuito: detalle.Gratuito,
                PrecioVenta: detalle.PrecioVenta,
            };
        }),
    };

    let tmpVenta = calcularVenta(datosParaCalculo);
    tmpVenta.Items = tmpVenta.Items.map(d => {
        return {
            ...d,
            PrecioVenta: detalles.find(x => String(x.IdPresentacion) === String(d.IdPresentacion)).PrecioVenta,
        }
    })
    return tmpVenta;
}


export const getPreventaFromDB = async (_id) => {
    try {
        const req = await fetch(`/api/gestionPreventas/preventa/${_id}`);
        if (!req.ok) {
            throw new Error("Ocurrió un error al obtener datos de preventa.")
        }

        const { respuesta: [data] } = await req.json();

        return data;
    } catch (e) {
        notificarError(e.message)
    }
}

export const getDetallesPreventaFromDB = async (_id) => {
    try {
        const req = await fetch(`/api/gestionPreventas/detallepreventa/${_id}`);

        if (!req.ok) {
            throw new Error("Ocurrió un error al obtener los detalles de preventa.")
        }

        const { respuesta: detalles } = await req.json();

        return detalles;
    } catch (e) {
        notificarError(e.message)
    }
}