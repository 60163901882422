import React from "react";
import EfectivoLogo from "../images/TarjetasLogo/LogoEfectivo.png";
import MasterCardLogo from "../images/TarjetasLogo/MasterCardLogoPequeño.png";
import VisaLogo from "../images/TarjetasLogo/Visa-LogoPequeño.png";

import AmericanLogo from "../images/TarjetasLogo/AmericanExpressLogoPequeño.png";
import otrasTarjetas from "../images/TarjetasLogo/otrasTarjetas.png";
import credito from "../../pages/RegistroVentas/images/credito.png";
import FpayLogo from "../../pages/RegistroVentas/images/fpy.jpeg"
import YapeLogo from "../../pages/RegistroVentas/images/yape.png"
import TunkiLogo from "../../pages/RegistroVentas/images/tunki logo.png"
import RappiLogo from "../../pages/RegistroVentas/images/rappi logo.png"
import PlinLogo from "../../pages/RegistroVentas/images/plin logo.png"
import {
    AMERICAN,
    decimalAdjust,
    EFECTIVO,
    ESTADO_CE,
    ESTADO_VALE,
    ESTADO_VENTA,
    MASTER,
    OTROS,
    PAGO_CREDITO, PLIN, RAPPI, TUNKI,
    VISA, YAPE
} from "../../Global";
import {BotonCerrarCajaLetras} from "../Plantillas/Botones";
import _ from 'lodash';
import Notify from "../Notify";
import {handleSavePDF} from "./printDataCaja";
import {calcularMovsCaja, getTotalRedondeo} from "../../helpers/utils";
import FpayService from "../../services/FpayService";
import moment from "moment";
import VentaService from "../../services/VentaService";

export default class TurnoCierre extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            MontoFinalEfectivoFisico: 0,
            MontoFinalEfectivoSistema: 0,
            MontoFinalTarjetaMasterCardFisico: 0,
            MontoFinalTarjetaVisaFisico: 0,
            MontoFinalTarjetaAmericanFisico: 0,
            MontoFinalFpay: 0,
            MontoFinalYape: 0,
            MontoFinalTunki: 0,
            MontoFinalRappi: 0,
            MontoFinalPlin: 0,
            MontoFinalOtraTarjetaFisico: 0,
            redondeoRVAceptados: 0,
            redondeoRVAnulados: 0,
            dataPdf: {},
            MontoFinalTarjetaMasterCardSistema: 0,
            MontoFinalTarjetaVisaSistema: 0,
            MontoFinalTarjetaAmericanSistema: 0,
            MontoFinalOtraTarjetaSistema: 0,
            MontoFinalCreditoSistema: 0,
            Saldo: 0,
            totales: {},
            isLoading: false,
            verMontosCaja: false,
            comprobantesEmitidos:false,
            IdSucursal: 0,
            fechaActual: moment(new Date()).format('YYYY-MM-DD'),
            fechaDB:"",
            configuration: {},
        }
        this.handleChange = this.handleChange.bind(this);
        this.CerrarTurno = this.CerrarTurno.bind(this);
        this.getListFpay = this.getListFpay.bind(this);
        this.verificarMontosCaja = this.verificarMontosCaja.bind(this);
        this.traerFechaBase = this.traerFechaBase.bind(this);
        this.DatosUser = this.DatosUser.bind(this);
        this.getVentas = this.getVentas.bind(this);
        this.ventaService = new VentaService();
        this.fpayService = new FpayService()
    }

    async getTotalByComprobante(idsTipoPago, estadoVenta, estadoVale) {
        let queryParams =
            `idsTipoPago=${idsTipoPago}&
            idTurnoCaja=${this.props.idTurno}&
            estadoVenta=${estadoVenta}&
            estadoVale=${estadoVale}            
            `.replace(/\s/g, "");
        let response = await fetch(`/api/turnos/total-ventas?${queryParams}`);
        let resJson = await response.json();
        return Number(resJson.total);
    }

    async componentDidMount() {
        this.setState({isLoading: true})
        await this.fpayService.getUserFpay()
        await this.getConfiguration();
        await this.calcularTotales();
        await this.verificarMontosCaja();
        await this.DatosUser();
        await this.traerFechaBase();
        await this.getVentas();
        this.setState({isLoading: false})
    }

    async getConfiguration() {
        try {
            const req = await fetch(`/api/config/emp`);
            const res = await req.json();
            this.setState({ configuration: {...res} });
        } catch (e) {
            console.error(e)
        }
    }

    async verificarMontosCaja() {
        try {
            const responseValidarMontos = await fetch(`/api/cajas/verMontosCajas/validar`);
            const data = await responseValidarMontos.json();
            this.setState({verMontosCaja: true})
        } catch (e) {
            this.setState({verMontosCaja: false})
        }
    }

    traerFechaBase = async () => {
        try {
            const response = await fetch(`/api/ventas/traerFecha/Base`);
            const fechaBusqueda = await response.json();
            this.setState({
                fechaDB: fechaBusqueda.respuesta[0].fechaBusqueda
            })
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async getVentas(){
        const params = {
            page: 1,
            pageSize: 10,
            sorted: [],
            filtered: []
        }
        let data = String(this.state.IdSucursal) === "0" ? {ventas: []} : await this.ventaService.filterVentasCe(params,
            {idSucursal: this.state.IdSucursal || "0", fecha: this.state.fechaDB})
        let estadoCe = false;
        data.ventas.forEach(venta => {
            if(venta.EstadoCE == null && venta.EstadoCE == ""){
                estadoCe = true;
            }
        })
        this.setState({comprobantesEmitidos:estadoCe})
    }



    async DatosUser() {
        try {

            const response = await fetch(`/api/configFactElectronica/User`);
            const data = await response.json();

            this.setState({
                IdSucursal: data.objuser.IdSucursal,
                extImg: data.extImg,
                isActiveTurn: data.isActiveTurn
            })

            await this.ValidarRol()
            await this.onFilterVentas(this.state.table)

        } catch (error) {
        }
    }

    async CerrarTurno(e) {
        e.preventDefault();

        let total = Number(this.props.montoInicial) + Number(this.state.MontoFinalEfectivoSistema) +
            Number(this.state.MontoFinalTarjetaMasterCardSistema) +
            Number(this.state.MontoFinalTarjetaVisaSistema) +
            Number(this.state.MontoFinalTarjetaAmericanSistema) +
            Number(this.state.MontoFinalFpay) +
            Number(this.state.MontoFinalYape) +
            Number(this.state.MontoFinalTunki) +
            Number(this.state.MontoFinalRappi) +
            Number(this.state.MontoFinalPlin) +
            Number(this.state.MontoFinalOtraTarjetaSistema);


        if (this.state.MontoFinalEfectivoFisico === 0 && total > 0)
            return Notify("El monto físico no puede ser cero, por favor ingresar la cantidad solicitada", "warning", 3000)
        this.setState({isLoading: true})
        await handleSavePDF({
            ...this.state,
            idTurno: this.props.idTurno,
            movCajas: this.props.movsCaja,
            MontoInicial: this.props.montoInicial,
            extImg: this.props.extImg
        });
        try {
            const res = await fetch(`/api/cajas/cierre/turno`, {
                method: 'PUT',
                body: JSON.stringify({
                    MontoFinalEfectivoSistema: this.state.MontoFinalEfectivoSistema,
                    MontoFinalTarjetaMasterCardSistema: this.state.MontoFinalTarjetaMasterCardSistema,
                    MontoFinalTarjetaVisaSistema: this.state.MontoFinalTarjetaVisaSistema,
                    MontoFinalTarjetaAmericanSistema: this.state.MontoFinalTarjetaAmericanSistema,
                    MontoFinalOtraTarjetaSistema: this.state.MontoFinalOtraTarjetaSistema,

                    MontoFinalEfectivoFisico: this.state.MontoFinalEfectivoFisico,
                    MontoFinalTarjetaMasterCardFisico: this.state.MontoFinalTarjetaMasterCardFisico,
                    MontoFinalTarjetaVisaFisico: this.state.MontoFinalTarjetaVisaFisico,
                    MontoFinalTarjetaAmericanFisico: this.state.MontoFinalTarjetaAmericanFisico,
                    MontoFinalYape: this.state.MontoFinalYape,
                    MontoFinalTunki: this.state.MontoFinalTunki,
                    MontoFinalRappi: this.state.MontoFinalRappi,
                    MontoFinalPlin: this.state.MontoFinalPlin,
                    MontoFinalFpay: this.state.MontoFinalFpay,
                    MontoFinalOtraTarjetaFisico: this.state.MontoFinalOtraTarjetaFisico,
                    MontoFinalCreditoSistema: this.state.MontoFinalCreditoSistema,

                    billetes200: 0,
                    billetes100: 0,
                    billetes50: 0,
                    billetes20: 0,
                    billetes10: 0,
                    monedas5: 0,
                    monedas2: 0,
                    monedas1: 0,
                    monedas05: 0,
                    monedas02: 0,
                    monedas01: 0,

                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        } catch (err) {
            console.log(err)
        }
        this.setState({isLoading: false});

        if (this.props.onCierreTurnoCompletado) {
            this.props.onCierreTurnoCompletado();
        }
    }

    async getListFpay() {
        return await this.fpayService.getFpayListCaja(this.props.idCaja, this.props.idTurno)
    }

    async calcularTotales() {
        let [entradas, salidas, salidasComprobante, salidasNormales] = calcularMovsCaja(this.props.movsCaja);
        let [totalOkVisa, totalBadVisa] = await this.calcularTotalVisa();
        let [totalOkAmerican, totalBadAmerican] = await this.calcularTotalAmerican();
        let [totalOkOtros, totalBadOtros] = await this.calcularTotalOtros();
        let [totalOkYape, totalBadYape] = await this.calcularTotalYape();
        let [totalOkTunki, totalBadTunki] = await this.calcularTotalTunki();
        let [totalOkRappi, totalBadRappi] = await this.calcularTotalRappi();
        let [totalOkPlin, totalBadPlin] = await this.calcularTotalPlin();
        let [totalOkMaster, totalBadMaster] = await this.calcularTotalMaster();
        let [totalOkFpay, totalBadFpay, totalFpayCancel] = await this.calcularTotalFpay();
        let [totalOkCredito, totalBadCredito] = await this.calcularCreditos();

        // let totalAnuladosTarjetas = totalBadMaster + totalBadVisa + totalBadAmerican;
        let totalAnuladosTarjetas = 0;
        let [totalEfectivoVentas, totalEfectOk, totalEfectBad] = await this.calularTotalEfectivo(totalAnuladosTarjetas, entradas, salidasNormales);
        let redondeoOk = await getTotalRedondeo(`${ESTADO_CE.ACEPTADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.PENDIENTE}`)
        let redondeoBad = await getTotalRedondeo(`${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`)

        const ignoreInitialAmount = 'ignoreInitialAmount' in this.state.configuration ? this.state.configuration.ignoreInitialAmount : false;

        this.setState({
            MontoFinalEfectivoSistema: Number(decimalAdjust('floor', ignoreInitialAmount ? totalEfectivoVentas : (totalEfectivoVentas + +this.props.montoInicial), -2)),
            MontoFinalEfectivoSistemaTemp: Number(decimalAdjust('floor', ignoreInitialAmount ? totalEfectivoVentas : (totalEfectivoVentas + +this.props.montoInicial), -2)),
            MontoFinalTarjetaMasterCardSistema: Number(decimalAdjust('floor', totalOkMaster - totalBadMaster, -2)),
            MontoFinalTarjetaVisaSistema: Number(decimalAdjust('floor', totalOkVisa - totalBadVisa, -2)),
            MontoFinalTarjetaAmericanSistema: Number(decimalAdjust('floor', totalOkAmerican - totalBadAmerican, -2)),
            MontoFinalOtraTarjetaSistema: Number(decimalAdjust('floor', totalOkOtros - totalBadOtros, -2)),
            MontoFinalCreditoSistema: Number(decimalAdjust('floor', totalOkCredito - totalBadCredito, -2)),
            MontoFinalFpaySistema: Number(decimalAdjust('floor', totalOkFpay, -2)),
            MontoFinalYapeSistema: Number(decimalAdjust('floor', totalOkYape - totalBadYape, -2)),
            MontoFinalTunkiSistema: Number(decimalAdjust('floor', totalOkTunki - totalBadTunki, -2)),
            MontoFinalRappiSistema: Number(decimalAdjust('floor', totalOkRappi - totalBadRappi, -2)),
            MontoFinalPlinSistema: Number(decimalAdjust('floor', totalOkPlin - totalBadPlin, -2)),
            Saldo: Number(decimalAdjust('floor', totalEfectivoVentas, -2)),
            redondeoRVAceptados: redondeoOk,
            redondeoRVAnulados: redondeoBad,
            totales: {
                entradas: decimalAdjust('floor', entradas, -2),
                salidas: decimalAdjust('floor', salidas, -2),
                totalOkMaster: decimalAdjust('floor', totalOkMaster, -2),
                totalBadMaster: decimalAdjust('floor', totalBadMaster, -2),
                totalOkVisa: decimalAdjust('floor', totalOkVisa, -2),
                totalBadVisa: decimalAdjust('floor', totalBadVisa, -2),
                totalOkAmerican: decimalAdjust('floor', totalOkAmerican, -2),
                totalBadAmerican: decimalAdjust('floor', totalBadAmerican, -2),
                totalOkOtros: decimalAdjust('floor', totalOkOtros, -2),
                totalBadOtros: decimalAdjust('floor', totalBadOtros, -2),
                totalOkCredito: decimalAdjust('floor', totalOkCredito, -2),
                totalBadCredito: decimalAdjust('floor', totalBadCredito, -2),
                totalOkFpay: decimalAdjust('floor', totalOkFpay, -2),
                totalBadFpay: decimalAdjust('floor', totalBadFpay, -2),
                totalOkYape: decimalAdjust('floor', totalOkYape, -2),
                totalBadYape: decimalAdjust('floor', totalBadYape, -2),
                totalOkTunki: decimalAdjust('floor', totalOkTunki, -2),
                totalBadTunki: decimalAdjust('floor', totalBadTunki, -2),
                totalOkRappi: decimalAdjust('floor', totalOkRappi, -2),
                totalBadRappi: decimalAdjust('floor', totalBadRappi, -2),
                totalOkPlin: decimalAdjust('floor', totalOkPlin, -2),
                totalBadPlin: decimalAdjust('floor', totalBadPlin, -2),
                totalCancelFpay: decimalAdjust('floor', totalFpayCancel, -2),
                totalEfectOk: decimalAdjust('floor', totalEfectOk, -2),
                totalEfectBad: decimalAdjust('floor', totalEfectBad, -2),
                salidasComprobante: salidasComprobante,
                salidasNormales: salidasNormales
            }
        })
    }

    async calularTotalEfectivo(anuladosTarjetas, totalIngresos, salidasNormales) {
        const totalEfectOk = await this.getTotalByComprobante(EFECTIVO, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalEfectBad = await this.getTotalByComprobante(EFECTIVO, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [(totalEfectOk - totalEfectBad) + totalIngresos - salidasNormales - anuladosTarjetas, totalEfectOk, totalEfectBad];
    }

    async calcularTotalMaster() {
        const totalMasterOk = await this.getTotalByComprobante(MASTER, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalMasterBad = await this.getTotalByComprobante(MASTER, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalMasterOk, totalMasterBad];
    }

    async calcularTotalVisa() {
        const totalVisaOk = await this.getTotalByComprobante(VISA, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalVisaBad = await this.getTotalByComprobante(VISA, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalVisaOk, totalVisaBad];
    }

    async calcularTotalAmerican() {
        const totalAmericanOk = await this.getTotalByComprobante(AMERICAN, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalAmericanBad = await this.getTotalByComprobante(AMERICAN, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalAmericanOk, totalAmericanBad];
    }

    async calcularTotalOtros() {
        const totalOtrosOk = await this.getTotalByComprobante(OTROS, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalOtrosBad = await this.getTotalByComprobante(OTROS, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalOtrosOk, totalOtrosBad];
    }

    async calcularTotalYape() {
        const totalOtrosOk = await this.getTotalByComprobante(YAPE, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalOtrosBad = await this.getTotalByComprobante(YAPE, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalOtrosOk, totalOtrosBad];
    }

    async calcularTotalTunki() {
        const totalOtrosOk = await this.getTotalByComprobante(TUNKI, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalOtrosBad = await this.getTotalByComprobante(TUNKI, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalOtrosOk, totalOtrosBad];
    }

    async calcularTotalRappi() {
        const totalOtrosOk = await this.getTotalByComprobante(RAPPI, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalOtrosBad = await this.getTotalByComprobante(RAPPI, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalOtrosOk, totalOtrosBad];
    }

    async calcularTotalPlin() {
        const totalOtrosOk = await this.getTotalByComprobante(PLIN, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalOtrosBad = await this.getTotalByComprobante(PLIN, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalOtrosOk, totalOtrosBad];
    }


    async calcularTotalFpay() {
        const response = await this.getListFpay()
        const fpayList = await response.json();
        const totalFpayOk = Number(Number(fpayList.filter(d => d.Estado == 'PAGADO').map(d => d.Monto).reduce((a, b) => a + b, 0)).toFixed(6))
        const totalFpayBad = Number(Number(fpayList.filter(d => d.Estado == 'ANULADO').map(d => d.Monto).reduce((a, b) => a + b, 0)).toFixed(6))
        const totalFpayCancel = Number(Number(fpayList.filter(d => d.Estado == 'RECHAZADO').map(d => d.Monto).reduce((a, b) => a + b, 0)).toFixed(6))
        return [totalFpayOk, totalFpayBad, totalFpayCancel];
    }

    async calcularCreditos() {
        const totalCreditosOk = await this.getTotalByComprobante(PAGO_CREDITO, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalCreditosBad = await this.getTotalByComprobante(PAGO_CREDITO, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalCreditosOk, totalCreditosBad];
    }


    handleChange(e) {
        const {name, value} = e.target
        this.setState({
            [name]: e.target.getAttribute('type') === 'number' ? (_.isNumber(Number(value)) ? value : 0) : value
        })
    }


    render() {

        let totales = {
            mtoTotalEfecSis: this.state.MontoFinalEfectivoSistema,
            mtoTotalMasterSis: this.state.MontoFinalTarjetaMasterCardSistema,
            mtoTotalVisaSis: this.state.MontoFinalTarjetaVisaSistema,
            mtoTotalAmericanSis: this.state.MontoFinalTarjetaAmericanSistema,
            mtoTotalOtroSis: this.state.MontoFinalOtraTarjetaSistema,
            mtoTotalFpay: this.state.MontoFinalFpaySistema? this.state.MontoFinalFpaySistema:0,
            mtoTotalYape: this.state.MontoFinalYapeSistema? this.state.MontoFinalYapeSistema:0,
            mtoTotalTunki: this.state.MontoFinalTunkiSistema? this.state.MontoFinalTunkiSistema:0,
            mtoTotalRappi: this.state.MontoFinalRappiSistema? this.state.MontoFinalRappiSistema:0,
            mtoTotalPlin: this.state.MontoFinalPlinSistema? this.state.MontoFinalPlinSistema:0,
            mtoTotalCreditoSis: this.state.MontoFinalCreditoSistema,
            saldo: this.state.Saldo,
            salidas: this.state.totales.salidas,
            salidasComprobante: this.state.totales.salidasComprobante
        };


        const totalSistema = totales.mtoTotalEfecSis + totales.mtoTotalMasterSis + totales.mtoTotalVisaSis +
            totales.mtoTotalAmericanSis + totales.mtoTotalOtroSis + totales.mtoTotalCreditoSis + totales.mtoTotalFpay +
            totales.mtoTotalYape + totales.mtoTotalTunki + totales.mtoTotalRappi + totales.mtoTotalPlin;

        const data = this.state.totales;
        const totalAnulados = data.totalBadOtros + data.totalBadAmerican + data.totalBadVisa + data.totalBadMaster
            + data.totalBadCredito + data.totalEfectBad + data.totalBadPlin + data.totalBadTunki + data.totalBadRappi + data.totalBadYape;
        const totalFisico =
            Number(this.state.MontoFinalEfectivoFisico) +
            Number(this.state.MontoFinalTarjetaMasterCardFisico) +
            Number(this.state.MontoFinalFpay) +
            Number(this.state.MontoFinalYape) +
            Number(this.state.MontoFinalRappi) +
            Number(this.state.MontoFinalPlin) +
            Number(this.state.MontoFinalTunki) +
            Number(this.state.MontoFinalTarjetaVisaFisico) +
            Number(this.state.MontoFinalTarjetaAmericanFisico) +
            Number(this.state.MontoFinalOtraTarjetaFisico);

        const totalEntregar = totales.mtoTotalEfecSis
        
        if (this.state.isLoading)
            return (
                <div className="row d-flex justify-content-center mt-5 mb-5 w-100">
                    <div className="d-flex">
                        <div className="loader mr-3"></div>
                        <div className="texto-carga mt-2">
                            <span className="texto-1 texto-dinamico">Cerrando Caja</span>
                            <span className="texto-2 texto-dinamico">Cargando Montos</span>
                        </div>
                    </div>
                </div>
            )
        return (
            <React.Fragment>
                <form onSubmit={this.CerrarTurno}>
                    <ul className="nav nav-tabs mt-1" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active text-secondary" id="pills-Montos-tab" data-toggle="pill"
                               href="#pills-montos" role="tab" aria-controls="pills-montos" aria-selected="true">
                                Ingreso de montos
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-montos" role="tabpanel"
                             aria-labelledby="pills-Montos-tab">

                            <div align="center">
                                <b>Monto Apertura: {this.props.montoInicial} </b>
                            </div>
                            <table className="table table-hover">
                                <thead className="text-center">
                                <tr>
                                    <th text="center" align="center" scope="col-6">Tipo de monto</th>
                                    <th scope="col-2" align="center">{this.state.verMontosCaja? "Monto Sistema" : ""}</th>
                                    <th scope="col-1"> Monto Físico</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><img src={EfectivoLogo} alt="efectivo"/>Efectivo</td>
                                    <td align="center">{this.state.verMontosCaja? "S/." + totales.mtoTotalEfecSis : ""}</td>
                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.1"
                                               placeholder="Monto Físico" name="MontoFinalEfectivoFisico"
                                               value={this.state.MontoFinalEfectivoFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img width={"110px"} height={"75px"} src={YapeLogo} alt="yape"/> Yape</td>
                                    <td align="center">{"S/." + totales.mtoTotalYape}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Fpay" name="MontoFinalYape"
                                               defaultValue={this.state.MontoFinalYape}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={VisaLogo} alt="visa"/>Tarjeta Visa</td>
                                    <td align="center">{this.state.verMontosCaja? "S/." + totales.mtoTotalVisaSis : ""}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Visa" name="MontoFinalTarjetaVisaFisico"
                                               defaultValue={this.state.MontoFinalTarjetaVisaFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={MasterCardLogo} alt="mastercard"/>Tarjeta MasterCard</td>
                                    <td align="center">{this.state.verMontosCaja? "S/." + totales.mtoTotalMasterSis : ""}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="MasterCard" name="MontoFinalTarjetaMasterCardFisico"
                                               defaultValue={this.state.MontoFinalTarjetaMasterCardFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={AmericanLogo} alt="american express"/>Tarjeta American Express</td>
                                    <td align="center" >{this.state.verMontosCaja? "S/." + totales.mtoTotalAmericanSis : ""}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="American Express" name="MontoFinalTarjetaAmericanFisico"
                                               defaultValue={this.state.MontoFinalTarjetaAmericanFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={otrasTarjetas} alt="tarjeta"/>Otra Tarjeta</td>
                                    <td align="center">{this.state.verMontosCaja? "S/." + totales.mtoTotalOtroSis : ""}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Otra Tarjeta" name="MontoFinalOtraTarjetaFisico"
                                               defaultValue={this.state.MontoFinalOtraTarjetaFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img width={"110px"} height={"75px"} src={FpayLogo} alt="fpay"/> Fpay</td>
                                    <td align="center">{"S/." + totales.mtoTotalFpay}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Fpay" name="MontoFinalFpay"
                                               defaultValue={this.state.MontoFinalFpay}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img width={"110px"} height={"75px"} src={TunkiLogo} alt="tunki"/> Tunki</td>
                                    <td align="center">{"S/." + totales.mtoTotalTunki}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Fpay" name="MontoFinalTunki"
                                               defaultValue={this.state.MontoFinalTunki}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img width={"110px"} height={"75px"} src={RappiLogo} alt="rappi"/> Rappi</td>
                                    <td align="center">{"S/." + totales.mtoTotalRappi}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Fpay" name="MontoFinalRappi"
                                               defaultValue={this.state.MontoFinalRappi}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img width={"110px"} height={"75px"} src={PlinLogo} alt="plin"/> Plin</td>
                                    <td align="center">{"S/." + totales.mtoTotalPlin}</td>

                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Fpay" name="MontoFinalPlin"
                                               defaultValue={this.state.MontoFinalPlin}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={credito} alt="credito"/>Ventas a Crèdito</td>
                                    <td align="center">{this.state.verMontosCaja? "S/." + totales.mtoTotalCreditoSis : ""}</td>

                                </tr>
                                <tr>
                                    <td><i className="fas fa-percent"/> Redondeo Comprobante Aceptados</td>
                                    <td align="center">S/. {decimalAdjust('floor', this.state.redondeoRVAceptados, -3)}</td>
                                </tr>
                                <tr>
                                    <td><i className="fas fa-percent"/> Redondeo Comprobante Anulados</td>
                                    <td align="center">S/. {decimalAdjust('floor', this.state.redondeoRVAnulados, -3)}</td>
                                </tr>
                                <tr>
                                    <td>Total Anulados</td>
                                    <td align="center">S/. {decimalAdjust('floor', 0 || totalAnulados ? totalAnulados : 0, -6)}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div align="center">
                                <label>Monto Final Total
                                    Sistema: {decimalAdjust('floor', totalSistema ? totalSistema : 0, -2)} </label>
                            </div>
                            <div align="center">
                                <label>Monto Final Total Físico: {totalFisico}
                                </label>
                            </div>
                            <div align="center">
                                <label style={{color: "#01a59c"}}>
                                    Total a entregar efectivo: {decimalAdjust('floor', totalEntregar, -6)}
                                </label>
                            </div>
                            <div className="col-3"/>
                            <div align="center" className="mb-4">
                                <BotonCerrarCajaLetras type="submit" disabled={this.state.comprobantesEmitidos}/>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}
