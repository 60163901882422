import React, { useEffect, useState, useCallback } from "react";
import {
  decimalAdjust,
  calcularTotales,
  calcTotal,
  TIPO_STOCK,
} from "../../../Global";
import { setPreciosYDescuento } from "../../../helpers/calcularVenta";
import { debounce, isNumber, set } from "lodash";
import { BotonCancelarLetras } from "../../Plantillas/Botones";
import {
  FindPrecioEspecial,
  FindPrecioFamiliar,
  FindPrecioMenor,
  FindPrecioPorMayor,
  GetPrecioCosto,
  getPrecioPorMayor,
} from "../../Preventas/PreciosPreventa";
import PreventaModalDescuentos from "../../Preventas/PreventaModalDescuentos";
import Modal from "../../Modal";

const ListaProductosStock = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProductos("");
  }, []);

  const fetchProductos = async (q) => {
    const req = await fetch(
      `/api/preVentas/?search=${q}&idTipoStock=${TIPO_STOCK.CON_COMPROBANTE}`
    );
    const data = await req.json();

    const products = data.productos.map((d) => {
      const itemCalculado = setPreciosYDescuento(d);
      return {
        ...d,
        Precio: itemCalculado.PrecioVenta,
        PrecioReferencial: itemCalculado.PrecioVenta,
        Gratuito: parseInt(d.Gratuito),
      };
    });

    setProducts(products);
  };

  const debounceSearch = useCallback(debounce(fetchProductos, 500), []);

  const handleSearch = (e) => {
    const value = e.target.value.trim();

    setSearchTerm(value);
    debounceSearch(value);
  };

  const onChooseProduct = (p) => props.onChooseProduct(p);

  return (
    <>
      <div className="PreVBuscarProducto row input-producto mb-2">
        <div className="w-100 col-12 mt-1">
          <input
            type="text"
            placeholder="Buscar Producto"
            className="mr-2 w-100 DocProductoPreventa borde-15"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="lista_productos table-responsive">
        <div
          className="lista_productos__item"
          style={{ maxHeight: "51vh", overflowY: "scroll" }}
        >
          <table
            hidden={props.load}
            className="table table-hover"
            style={{ maxHeight: "50vh" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    borderTopLeftRadius: "10px",
                    backgroundColor: "#034b2f",
                    color: "white",
                  }}
                >
                  <span>Producto / </span>
                  <span>Presentación</span>
                </th>
                <th style={{ backgroundColor: "#034b2f", color: "white" }}>
                  Precio
                </th>
                <th
                  style={{
                    borderTopRightRadius: "0px",
                    backgroundColor: "#034b2f",
                    color: "white",
                  }}
                >
                  Stock
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map((p) => {
                return (
                  <tr
                    onClick={() => onChooseProduct(p)}
                    className="prodPreventaTr"
                    style={{ cursor: "pointer" }}
                    key={`prod-${p.IdProducto}-${p.IdPresentacion}`}
                    title={`${p.descripcion}-${p.unidadMedida}`}
                    id={`prod-${p.IdProducto}-${p.IdPresentacion}`}
                  >
                    <td style={{}}>
                      {p.descripcion} {p.unidadMedida}
                    </td>
                    <td bgcolor={"#01a59c"} style={{ padding: "5px 0" }}>
                      <label style={{ color: "white" }}>{p.moneda}</label>

                      <div className="">
                        <div style={{ color: "white", textAlign: "end" }}>
                          {decimalAdjust("floor", p.precio, -2)}
                        </div>
                      </div>
                    </td>
                    <td
                      style={{
                        backgroundColor: "rgb(53,96,90,0.18)",
                        borderTop: "1px solid white",
                      }}
                    >
                      <div style={{ textAlign: "end" }}>
                        {decimalAdjust("floor", p.stock, -2)}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default function (props) {
  const [items, setItems] = useState([]);
  const [totales, setTotales] = useState({});
  const [showModalDescuento, setShowModalDescuento] = useState(false);
  const [currentItem, setCurrentItem] = useState(undefined);
  const [affectsIgv, setAffectsIgv] = useState([]);
  const [canApplyDiscount, setCanApplyDiscount] = useState(false);
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    checkPermissionsApplyDiscount();
  }, [])

  useEffect(() => {
    const _calculated = calcularTotales(items);

    setTotales({
      Gravado: _calculated.gravados,
      Inafecto: _calculated.inafectos,
      Exonerado: _calculated.exonerados,
      IGVPreventa: _calculated.gravados * 0.18,
      Gratuitas: _calculated.gratuitos,
      totalMonto: _calculated.total,
      ICBPERPreventa: _calculated.icbper,
      redondeo: _calculated.redondeo,
    });

    setCanContinue(!!items.length)
    props.onChangeItems([...items]);
  }, [items]);

  const onChooseProduct = (product) => {
    let currentItems = [...items];
    const productPosition = currentItems.indexOfObject(
      product,
      "IdPresentacion"
    );

    let descuentoPersonal = 0;

    if (props.client && "DescuentoPersonal" in props.client) {
      descuentoPersonal = props.client["DescuentoPersonal"];
    }

    if (productPosition !== -1) {
      currentItems[productPosition].Cantidad++;
      FindPrecioPorMayor([], currentItems[productPosition]);
      currentItems[productPosition].Total =
        calcTotal(currentItems[productPosition]) -
        currentItems[productPosition].Descuento;
    } else {
      FindPrecioPorMayor([], product);
      product.Descuento = product.Descuento + descuentoPersonal;

      currentItems.push({
        ...product,
        Cantidad: 1,
        PrecioVenta: product.PrecioVenta,
        Total: product.PrecioVenta,
        PrecioEspecial: FindPrecioEspecial([], product),
        PrecioFamiliar: FindPrecioFamiliar([], product),
        PrecioCosto: GetPrecioCosto([], product),
        PrecioMenor: FindPrecioMenor([], product),
        precioMayor: getPrecioPorMayor([], product),
        checked: isNumber(product.checked) ? product.checked : 0,
        oldPrecios: [product.PrecioVenta],
        initialAfectGrat: product.IdAfectacionIgv,
        oldCants: [1],
        NombreProducto: product.descripcion,
        Descuento: 0
      });
    }

    setItems([...currentItems]);
  };

  const fetchAffects = async (idAfect) => {
    const req = await fetch(`/api/preVentas/free-afects?idGrupoIgv=${idAfect}`);
    const data = await req.json();
    setAffectsIgv(data);
  }

  const onUpdateQuantity = (index, value) => {
    const currentItems = [...items];

    currentItems[index].Cantidad = Number(value);
    currentItems[index].Total = calcTotal(currentItems[index]) - currentItems[index].Descuento
    setItems([...currentItems]);
  }

  const checkPermissionsApplyDiscount = async () => {
      try {
          const responseVal = await fetch('/api/usuarios/descuentoItem/validar');
          const data = await responseVal.json();
          setCanApplyDiscount(true)
      } catch (e) {
          setCanApplyDiscount(false)
      }
  }

  const onShowModalDescuento = (item) => {
    setShowModalDescuento(true);
    setCurrentItem(item);
    fetchAffects(item.IdGrupoClasificacionIgv);
  }

  const onChangeDiscount = (e, item) => {
    const currentItems = [...items];

    const i = currentItems.indexOfObject(item, 'IdPresentacion')
    const val = e.target.value;
    
    if (Number(items[i].Gratuito) === 0) {
        items[i].Descuento = val
        items[i].Total = calcTotal(items[i]) - items[i].Descuento
        setItems([...currentItems]);
        setCurrentItem(items[i]);
    }
  }

  const onChangeAfectFree = (e, item) => {
    const currentItems = [...items];

    let i = items.indexOfObject(item, 'IdPresentacion');
    const val = e.target.value

    const checked = String(val) !== "-1";
    items[i].checked = checked
    items[i].Gratuito = checked ? 1 : 0

    if (checked) {
        items[i].Descuento = 0
        items[i].IdAfectacionIgv = val
    } else
        items[i].IdAfectacionIgv = items[i].initialAfectGrat

    setItems([...currentItems]);
    setCurrentItem(items[i]);
  }

  const onRemoveItem = (index) => {
    const currentItems = [...items];

    currentItems.splice(index, 1);
    setItems([...currentItems]);
  };

  return (
    <>
      <div className="row">
        <div className="col-7">
          <ListaProductosStock
            onChooseProduct={(p) => onChooseProduct(p)}
            StockDisponible={console.log}
            AddProductoInList={console.log}
          />
        </div>
        <div className="col-5">
          <div
            id="tableDetProdPreventa"
            className="preventa__carrito mt-2"
            style={{ maxHeight: "53vh", overflowY: "scroll" }}
          >
            <table className="table table-hover" style={{ overflow: "hidden" }}>
              <thead>
                <tr>
                  <th style={{ color: "white", background: "#01A59C", width: '40%' }}>
                    CANTIDAD
                  </th>
                  <th style={{ color: "white", background: "#01A59C", width: '40%' }}>
                    PRECIO
                  </th>
                  <th style={{ color: "white", background: "#01A59C", width: '40%' }}>TOTAL</th>
                  <th style={{ background: "#01A59C", width: '10%' }}></th>
                </tr>
              </thead>
              <tbody id="IdDetProductoCarrito">
                {items.map((item, index) => {
                  return (
                    <>
                      <tr
                        id={`producto-title-${item.IdPresentacion}`}
                        key={`det-title-${item.IdPresentacion}`}
                        className="my-2"
                      >
                        <td colSpan={3} style={{ fontSize: "14px" }}>
                          {`${item.descripcion} ${item.unidadMedida}`}
                        </td>
                        <td>
                          <button type="button" className="btn btn-danger" onClick={() => onRemoveItem(index)}>X</button>
                        </td>
                      </tr>
                      <tr
                        id={`producto-${item.IdPresentacion}`}
                        key={`det-${item.IdPresentacion}`}
                        className="my-2"
                      >
                        <td style={{ fontSize: "14px", width: '40%' }}>
                          <input type="number" className="form-control form-control-sm" value={item.Cantidad} onChange={(e) => onUpdateQuantity(index, e.target.value)}/>
                        </td>
                        <td style={{ fontSize: "14px", width: '30%' }}>
                          <span className="mt-2">{decimalAdjust("round", item.Precio, -2)}</span>
                        </td>
                        <td style={{ fontSize: "14px", width: '30%' }}>
                          <span className="mt-2">{decimalAdjust("round", item.Total, -2)}</span>
                        </td>
                        <td>
                          <button type="button" className="btn text-white" style={{background: "#01A59C"}} onClick={() => onShowModalDescuento(item)}><i className="fas fa-arrow-down"></i></button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {
        decimalAdjust('round', totales.totalMonto, -1) > 0 &&
        <div className="row bg-verde-mfp">
          { parseFloat(totales.Gravado) > 0 && <div className="col my-2">
            <strong>Gravado:</strong> {Number(totales.Gravado).toFixed(2)}
          </div> }

          { parseFloat(totales.Inafecto) > 0 && <div className="col my-2">
            <strong>Gravado:</strong> {decimalAdjust('floor', totales.Inafecto, -2)}
          </div> }

          { parseFloat(totales.Exonerado) > 0 && <div className="col my-2">
            <strong>Exonerado:</strong>{decimalAdjust('floor', totales.Exonerado, -2)}
          </div> }

          { parseFloat(totales.Gratuitas) > 0 && <div className="col my-2">
            <strong>Gratuitas:</strong>{decimalAdjust('floor', totales.Gratuitas, -2)}
          </div> }

          { parseFloat(totales.IGVPreventa) > 0 && <div className="col my-2">
            <strong>IGV:</strong> {decimalAdjust('floor', totales.IGVPreventa, -2)}
          </div> }
          
          <div className="col-2 my-2">
            <strong>Total:</strong> {decimalAdjust('round', totales.totalMonto, -1)}
          </div>
        </div>
      }

      {canContinue && <button type="button" className="mt-2 btn btn-outline-primary btn-verde-mfp btn-block" onClick={() => props.nextStep()}>Continuar</button>}

      <BotonCancelarLetras
          onClick={() => props.onCancel()}
          className="mb-2 mt-2 btn-block"
      />

      <Modal title="Realizar descuento" isOpen={showModalDescuento}
              onClose={() => setShowModalDescuento(false)}> 
          <PreventaModalDescuentos
            ItemDescuento={currentItem}
            DescuentoChange={onChangeDiscount}
            onBlurDescuento={() => {
            }}
            afectsIgv={affectsIgv}
            onChangeCheckBoxGratuito={onChangeAfectFree}
          />
      </Modal>
    </>
  );
}
