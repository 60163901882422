import React from "react";
import PageLoading from "../PageLoading.js";
import "../../pages/styles/SelectSearch.css";
import "react-moment";
import moment from "moment";
import ReactExport from "react-data-export";
import {BotonExcel, BotonLetras, BotonPDF, BotonCancelar} from "../Plantillas/Botones.js";
import {notificationKardexProductoNull, notificationKardexFechas} from "./KardexNotify.js";
import '../styles/Kardex.css';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import TablaPDF from "./TablaPDF.js";
import {getDataExportExcel} from "./TablaKardexExcel.js";
//sunat img
import sunat from "../images/sunat.png"
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {notificarError} from "../Almacenes/AlmacenNotify";
import {calculateKardex} from "contatrib-utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class ExportOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    async handleSavePDF() {
        const doc = new jsPDF({
            orientation: 'landscape'
        })
        doc.setFontSize(10);
        doc.text('FORMATO 13.1: "REGISTRO DE INVENTARIO PERMANENTE VALORIZADO - DETALLE DEL INVENTARIO VALORIZADO"', 10, 10)
        doc.text(`PERIODO: ${moment(this.props.FechaInicio).format("DD/MM/YYYY")} - ${moment(this.props.FechaFin).format("DD/MM/YYYY")}`, 10, 20)
        doc.text(`RUC: ${this.props.RUC}`, 10, 25)
        doc.text(`APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL: ${this.props.RazonSocial}`, 10, 30)
        doc.text(`ESTABLECIMIENTO: ${this.props.Establecimiento}`, 10, 35)
        doc.text(`CÓDIGO DE LA EXISTENCIA:  ${this.props.productInfo[this.props.product.id].CodigoProducto}`, 10, 40)
        doc.text(`TIPO: (TABLA 5): ${this.props.productInfo[this.props.product.id].CodigoExistencia} ${this.props.productInfo[this.props.product.id].Descripcion}`, 10, 45)
        doc.text(`DESCRIPCIÓN: ${this.props.product.label}`, 10, 50)
        doc.text(`CÓDIGO DE LA UNIDAD DE MEDIDA: ${this.props.productInfo[this.props.product.id].CodigoUnidad}`, 10, 55)
        doc.text(`MÉTODO DE EVALUACIÓN: `, 10, 60)
        //doc.setFontSize(5);
        doc.autoTable({
            html: '#tableKardex',
            startY: 70,
            styles: {
                overflow: "linebreak",
                lineWidth: 0.1,
                tableWidth: "auto",
                columnWidth: "auto",
                halign: "center",
                fontSize: 8
            },
            theme: "plain"
        })
        doc.text(`(1) Dirección del Establecimiento o Código según el Registro Único de Contribuyentes.`, 10, doc.autoTable.previous.finalY + 10)
        doc.save(`${moment(this.props.FechaInicio).format("DD/MM/YYYY")} - ${moment(this.props.FechaFin).format("DD/MM/YYYY")}.pdf`)
    }

    render() {
        const dataExport = getDataExportExcel({ ...this.props.product, ...this.props, ...this.props.productInfo[this.props.product.id], nombreProducto: this.props.product.label }, `FORMATO 13.1: "REGISTRO DE INVENTARIO PERMANENTE VALORIZADO - DETALLE DEL INVENTARIO VALORIZADO"`)

        return <React.Fragment>
            <li class="list-group-item d-flex">
                <div className="flex-grow-1">
                    { this.props.product.label }
                </div>
                <div>
                    <BotonPDF
                        className="ml-2 mr-2"
                        title="Guardar PDF"
                        onClick={() => this.handleSavePDF()}
                        style={{display: `${this.props.displayButton}`}}
                    />

                    <ExcelFile
                        element={
                            <BotonExcel
                                type="button"
                                title="Guardar Excel"
                                style={{display: `${this.props.displayButton}`}}/>
                        }
                        filename={`${this.props.product.label} / ${moment(this.props.FechaInicio).format("DD/MM/YYYY")} - ${moment(this.props.FechaFin).format("DD/MM/YYYY")}`}
                    >
                        <ExcelSheet
                            dataSet={dataExport}
                            name="kardexFile"
                        >
                        </ExcelSheet>
                    </ExcelFile>
                </div>
            </li>


            <TablaPDF
                dataKardex={this.props.product.dataKardex}
            />
        </React.Fragment>
    }
}

class KardexMultiProducto extends React.Component {
    constructor(props) {
        super(props)
        const now = moment(new Date()).format("YYYY-MM-DD")
        this.state = {
            dataProductos: [],
            isLoading: false,
            error: null,
            IdProducto: null,
            nombreProducto: "",
            FechaInicio: now,
            FechaFin: now,
            dataVentas: {
                items: []
            },
            RUC: "",
            RazonSocial: "",
            Establecimiento: "",
            CodigoExistencia: "",
            CodigoUnidad: "",
            Descripcion: "",
            CodigoProducto: "",
            TotalCantidadEntradas: 0,
            TotalCostoUnitarioEntradas: 0,
            TotalCostoTotalEntradas: 0,
            TotalCantidadSalidas: 0,
            TotalCostoUnitarioSalidas: 0,
            TotalCostoTotalSalidas: 0,
            TotalCantidadSaldo: 0,
            TotalCostoUnitarioSaldo: 0,
            TotalCostoTotalSaldo: 0,
            displayButton: "none",
            productos: [],
            hasStockInicial: false,
            dataKardex: {items: []},
            canDownload: false,
            productInfo: {}
        }

        this.FechaChange = this.FechaChange.bind(this)
        this.handleProductoChange = this.handleProductoChange.bind(this)
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getDataEmpresa()
    }

    searchProductos(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/productos?search=${query}`)
                .then(response => response.json())
                .then(json => json.productos)
                .then(prods => {
                    const mappedProds = prods.map(p => {
                        return {
                            ...p,
                            label: p.nombre,
                            value: p.id
                        }
                    })
                    resolve(mappedProds)
                }).catch(reject);
        })
    }

    async getDataEmpresa() {
        this.setState({loading: true, error: null});
        try {
            await fetch(`/api/kardex/InfoEmpresa`)
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        isLoading: false,
                        RUC: data[0].Ruc,
                        RazonSocial: data[0].RazonSocial,
                        Establecimiento: data[0].NombreUbigeo.concat(`- ${data[0].Direccion}`)
                    })
                });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
            console.log(error)
        }
    }

    async getProductosById(IdProducto) {
        this.setState({isLoading: true, error: null});
        try {
            const req = await fetch(`/api/kardex/InfoProducto/${IdProducto}`);

            if (!req.ok) {
                throw new Error('No es posible obtener la información del producto')
            }

            return await req.json();
        } catch (error) {
            this.setState({
                isLoading: false,
                error: error,
            });
            console.log(error)
        }
    }

    async handleProductoChange(prods) {
        this.setState({canDownload: false, isLoading: true, error: null});

        for await (let [index, prod] of prods.entries()) {
            try {
                const res = await this.getProductosById(prod.id);

                if (!res.hasStockInicial) {
                    notificarError("El producto no tiene compras registradas o stock inicial")
                    return;
                }

                this.setState({ productInfo: {...this.state.productInfo, [prod.id]: {
                    CodigoExistencia: res.CodigoExistencia,
                    CodigoUnidad: res.CodigoUnidad,
                    Descripcion: res.Descripcion,
                    CodigoProducto: res.CodigoProducto,
                    hasStockInicial: res.hasStockInicial
                } }});
            } catch (e) {
                notificarError('No es posible obtener la información de algunos productos. Intente nuevamente');
                return;
            }
        }

        this.setState({ isLoading: false, productos: prods, displayButton: "none" });
    }

    FechaChange(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
            displayButton: "none"
        });
    }

    async GetKardex() {
        if (this.state.productos.length == 0) {
            notificationKardexProductoNull()
            return
        }

        if (this.state.FechaInicio > this.state.FechaFin) {
            notificationKardexFechas()
            return;
        }

        this.setState({isLoading: true, error: null});

        for await (let [index, prod] of this.state.productos.entries()) {

            let items = [];

            try {
                const response = await fetch(
                    `/api/kardex/getProductoKardex/IdProducto=${prod.id}&FechaInicio=${this.state.FechaInicio}&FechaFin=${this.state.FechaFin}&useFechaInicio=1`
                )

                if (!response.ok) {
                    throw new Error(response.text);
                }

                items = await response.json();
            } catch (e) {
                console.error(e);
            }
            
            const data = calculateKardex(items.items);

            const producto = {...this.state.productos[index]};

            const { productos, ..._state } = this.state;

            this.state.productos[index] = {
                ...producto,
                ..._state,
                dataVentas: items,
                dataKardex: data,
            }

            this.setState({displayButton: "inline",})
        }

        this.setState({isLoading: false, canDownload: true });
    }

    render() {
        if (this.state.isLoading === true)
            return (
                <React.Fragment>
                    <div>
                        <PageLoading/>
                    </div>
                </React.Fragment>
            )
        else
            return (
                <React.Fragment>

                    <div className="ProcesarBoton mt-1">
                        <BotonCancelar
                            onClick={this.props.history.goBack}
                            className="ml-4"
                        />

                        <div className="ProcesarBotones">
                            

                            <BotonLetras
                                className="mr-4 ml-2"
                                type="button"
                                disabled={this.state.productos.length == 0 || this.state.productos.some(x => !this.state.productInfo[x.id].hasStockInicial)}
                                onClick={() => this.GetKardex()}
                            >
                                Cargar
                            </BotonLetras>
                        </div>
                    </div>

                    <div className="imgcomprobante">
                        <img src={sunat} alt="sunat"/>
                    </div>

                    <div className="kardex__container">
                        <div className="kardex">

                            <div className="reporteVentasProd">
                                <div className="KardexDocumento mb-4">
                                    <b className="ml-4">*Fecha Inicio</b>
                                    <div className="input__KardexDivSmall">
                                        <input
                                            className="FechaFin input__linea"
                                            name="FechaInicio"
                                            onChange={this.FechaChange}
                                            type="date"
                                            value={this.state.FechaInicio}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="KardexDocumento mb-4">
                                    <b className="ml-4">*Fecha Fin</b>
                                    <div className="input__KardexDivSmall">
                                        <input
                                            className="FechaFin input__linea"
                                            name="FechaFin"
                                            onChange={this.FechaChange}
                                            type="date"
                                            value={this.state.FechaFin}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="KardexDocumento">
                                <AsyncSelect
                                    defaultOptions={true}
                                    isMulti={true}
                                    onChange={this.handleProductoChange}
                                    value={this.state.productos}
                                    loadOptions={this.searchProductos}
                                />
                            </div>

                        </div>
                    </div>

                    {this.state.canDownload && 
                        <div className="container mt-3">
                            <ul class="list-group">
                                {
                                    this.state.productos.map((p) => <ExportOptions
                                        RUC={this.state.RUC}
                                        Establecimiento={this.state.Establecimiento}
                                        RazonSocial={this.state.RazonSocial}
                                        fechaInicio={this.state.FechaInicio}
                                        fechaFin={this.state.FechaFin}
                                        displayButton={this.state.displayButton}
                                        productInfo={this.state.productInfo}
                                        product={p}/>)
                                }
                            </ul>
                        </div>
                    }

                </React.Fragment>
            )

    }
}

export default KardexMultiProducto;
