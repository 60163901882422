import React from "react";
import AsyncSelect from "react-select/async/dist/react-select.esm";

export default class TipoDocMovPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.searchDocs = this.searchDocs.bind(this);
        this.onChangeTipoDoc = this.onChangeTipoDoc.bind(this);
    }

    searchDocs(query) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true})
            fetch(`/api/planesSunat/tipo-docs/?query=${query}`)
                .then(r => r.json())
                .then(docs => {
                    resolve(docs.map(d => {
                        return {...d, value: d.IdTipoDocumentoSunat, label: d.Descripcion}
                    }))
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        });
    }

    onChangeTipoDoc(e) {
        let ids = e ? e.map(x => x.value) : [];
        this.props.onChangeTipoDoc(ids);
    }

    render() {
        return (
            <div className="caja-fondo-blanco-m-2">
                <label className="sucursal-text-color">Seleccione documentos (Si no se selecciona, son todos)</label>
                <AsyncSelect
                    defaultOptions={true}
                    onChange={this.onChangeTipoDoc}
                    loadOptions={this.searchDocs}
                    isClearable={true}
                    isLoading={this.state.load}
                    placeholder={"Seleccione documentos"}
                    isMulti
                />
            </div>
        )
    }
}
