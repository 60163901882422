import React, {Component} from "react";
import "react-moment";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import SelectSearch from "react-select-search";
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
import {Redirect} from "react-router-dom";
import {decimalAdjust, ESTADO_CE} from "../../Global";
import logoFpay from "../../componentes/Preventas/BilleterasDigitales/Fpay/fpaylogo.png";
import FpayService from "../../services/FpayService";
import {notificarError, notificarMsg} from "../../componentes/Almacenes/AlmacenNotify";
import swal from "sweetalert";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ReporteVentasFpay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ReporteVentasRango: [],
            ReporteTotalVentas: null,
            ReporteTotalPagadoVentas: null,
            ReporteTotalRedondeos: null,

            ReporteVentasTurnoRango: [],
            ReporteVentasModalidadPago: [],
            IdCliente: null,
            ModalidadPago: null,
            FechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            FechaFin: moment(new Date()).format('YYYY-MM-DD'),
            TipoReporte: "Sucursal",
            Clientes: [],
            Credito: false,
            Contado: true,
            listaSucursales: [],
            listaCajas: [],
            listaSucursalCajas: [],
            listaUsuarios: [],
            ReporteVentasSucursal: [],
            IdSucursal: -1,
            FiltrarSucursal: false,
            user: {},
            totalAceptados: 0,
            totalAnulados: 0,
            totalRechazados: 0,
            tokenFpay: "",
            IdTurnoCaja: 0,
            IdCaja: 0,
            IdUsuario: 0,
            IdCajaSelector: 0,
            hasChangeSucursal: null,
            hasListCaja: null
        };
        this.fpayService = new FpayService();
        this.getReporteTurnoRango = this.getReporteTurnoRango.bind(this);
        this.getReporteTotalVentas = this.getReporteTotalVentas.bind(this);
        this.getReporteVentasClienteRango = this.getReporteVentasClienteRango.bind(this);
        this.getReporteVentasModalidadPago = this.getReporteVentasModalidadPago.bind(this);
        this.handleTipoReporte = this.handleTipoReporte.bind(this);
        this.getClientes = this.getClientes.bind(this);
        this.listClientes = this.listClientes.bind(this);
        this.handleSelectCliente = this.handleSelectCliente.bind(this);
        this.handleFechaInicio = this.handleFechaInicio.bind(this);
        this.handleFechaFin = this.handleFechaFin.bind(this);
        this.handleCredito = this.handleCredito.bind(this);
        this.handleContado = this.handleContado.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.getDataExportExcel = this.getDataExportExcel.bind(this);
        this.getSucursales = this.getSucursales.bind(this);
        this.getReporteVentasSucursal = this.getReporteVentasSucursal.bind(this);
        this.handleSelectSucursal = this.handleSelectSucursal.bind(this);
        this.handleSelectCaja = this.handleSelectCaja.bind(this);
        this.handleSelectUsuario = this.handleSelectUsuario.bind(this);
        this.listSucursales = this.listSucursales.bind(this);
        this.ValidarRol = this.ValidarRol.bind(this);
        this.DatosUser = this.DatosUser.bind(this);
        this.onSearchVentas = this.onSearchVentas.bind(this);
        this.fetchSucursalesPerm = this.fetchSucursalesPerm.bind(this);
        this.OpcionesCaja = this.OpcionesCaja.bind(this);
    }

    async componentWillMount() {
        await this.verificarTurno();
        await this.fpayService.getUserFpay()
        const response = await this.fpayService.authToken()
        this.setState({tokenFpay: response})
    }

    componentDidMount() {
        this.DatosUser();
        this.getClientes();
    }

    async fetchSucursalesPerm() {
        let res = await fetch('/api/ventas/has-perms/fpay/reporte')
        if (res.ok) {
            const data = await res.json()
            this.setState({hasChangeSucursal: data.hasChangeSucursal, hasListCaja: data.hasListCaja})
        }
    }

    async DatosUser() {
        try {
            const response = await fetch(`/api/configFactElectronica/User`);
            const data = await response.json();
            this.setState({
                IdSucursal: data.objuser.IdSucursal,
                user: data.objuser
            })
            await this.ValidarRol()
        } catch (error) {
        }
    }

    async ValidarRol() {
        try {
            const response = await fetch(`/api/configFactElectronica/ChangeSucursal`);
            const data = await response.json();
            this.setState({
                FiltrarSucursal: true
            })
        } catch (error) {
            this.setState({
                FiltrarSucursal: false
            })
        }
        await this.getSucursales();
        await this.getCajas();
        await this.getUsuarios();
        this.OpcionesCaja();
    }

    async getSucursales() {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/configFactElectronica/SucursalEmpresa`);
            const data = await response.json();
            this.setState({
                loading: false,
                listaSucursales: data
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async getCajas() {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/turnos/cajas/`);
            const data = await response.json();
            this.setState({
                loading: false,
                listaCajas: data
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }


    async getUsuarios() {
        const response = await fetch(`/api/turnos/usuarios`); //Trae turnos por Sucursal
        const data = await response.json();
        this.setState({
            isLoading: false,
            listaUsuarios: data.usuariosSucursal
        })
    }

    listSucursales(sucursales) {
        return sucursales.map(sucursal => {
            return {
                name: sucursal.Nombre,
                value: sucursal.IdSucursal
            };
        });
    }

    listCajas(cajas) {
        return cajas.map(caja => {
            return {
                name: caja.Nombre,
                value: caja.IdCaja
            };
        });
    }

    listUser(users) {
        return users.map(user => {
            return {
                name: user.Usuario,
                value: user.IdUsuario
            };
        });
    }

    handleSelectSucursal(e) {
        this.setState({IdSucursal: e.value});
        this.OpcionesCaja();
    }

    handleSelectCaja(e) {
        this.setState({IdCajaSelector: e.value});
    }

    handleSelectUsuario(e) {
        this.setState({IdUsuario: e.value});
    }

    OpcionesCaja() {
        const cajasSucursal = this.state.listaCajas.cajasSucursal.filter(x => x.IdSucursal == this.state.IdSucursal)
        this.setState({listaSucursalCajas: cajasSucursal});
    }

    async getReporteVentasSucursal(Inicio, Fin, IdSucursal) {
        try {
            const response = await fetch(
                `/api/ventas/reporte/sucursalFpay/fecha/${Inicio}/${Fin}/${IdSucursal}`
            );
            const ReporteVentasSucursal = await response.json();
            const datos = ReporteVentasSucursal.respuesta;
            this.setState({
                ReporteVentasSucursal: datos.map(d => {
                    return {...d, _tipoPagos: this.extractTiposPagos(d.tipoPagos)}
                }),
                isLoading: false,
            });
            await this.getReporteTotalVentas(ReporteVentasSucursal.respuesta);
            return ReporteVentasSucursal;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }


    async getReporteTurnoRango(Inicio, Fin, IdSucursal) {
        //TODO: avance
        const idCaja = this.state.IdCaja
        const idTurnoCaja = this.state.IdCajaSelector
        const idUsuario = this.state.IdUsuario
        let queryParams = `idCaja=${idCaja}&idTurnoCaja=${idTurnoCaja}&idUsuario=${idUsuario}&idSucursal=${IdSucursal}`
        const urlPerm = `/api/ventas/reporte/caja/fpay/perm/fecha/${Inicio}/${Fin}?${queryParams}}`
        const urlNoPerm = `/api/ventas/reporte/caja/fpay/fecha/${Inicio}/${Fin}?${queryParams}}`
        let url = this.state.listaSucursales ? urlPerm : urlNoPerm
        try {
            const response = await fetch(url);
            const ReporteVentasTurnoRango = await response.json();

            this.setState({
                ReporteVentasTurnoRango: ReporteVentasTurnoRango,
                isLoading: false,
            });

            await this.getReporteTotalVentas(ReporteVentasTurnoRango.respuesta);

            return ReporteVentasTurnoRango;
        } catch (error) {
            this.setState({error, isLoading: false});
        }

    }

    async getReporteVentasClienteRango(Inicio, Fin, IdCliente) {
        let idSucursal = this.getIdSucursal();
        try {
            const response = await fetch(
                `/api/ventas/reporte/cliente/fecha/${Inicio}/${Fin}/${IdCliente || "0"}?${idSucursal ? `idSucursal=${idSucursal}` : ''}`
            );
            const ReporteVentasClienteRango = await response.json();
            this.setState({
                ReporteVentasClienteRango: ReporteVentasClienteRango.respuesta.map(d => {
                    return {...d, _tipoPagos: this.extractTiposPagos(d.tipoPagos)}
                }),
                isLoading: false,
            });

            await this.getReporteTotalVentas(ReporteVentasClienteRango.respuesta);
            return ReporteVentasClienteRango;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReporteVentasModalidadPago(Inicio, Fin, IdModalidadPago) {
        let idSucursal = this.getIdSucursal();
        try {
            const response = await fetch(
                `/api/ventas/reporte/modalidad/fecha/${Inicio}/${Fin}/${IdModalidadPago}?${idSucursal ? `idSucursal=${idSucursal}` : ''}`
            );
            const ReporteVentasModalidadPago = await response.json();
            this.setState({
                ReporteVentasModalidadPago: ReporteVentasModalidadPago.respuesta.map(d => {
                    return {...d, _tipoPagos: this.extractTiposPagos(d.tipoPagos)}
                }),
                isLoading: false,
            });

            await this.getReporteTotalVentas(ReporteVentasModalidadPago.respuesta);
            return ReporteVentasModalidadPago;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReporteTotalVentas(Ventas) {
        try {
            let ReporteTotalVentas = 0;
            let ReporteTotalRedondeos = 0;
            let ReporteTotalPagadoVentas = 0;

            Ventas.forEach(element => {
                ReporteTotalVentas += element.Monto; //Sumatoria de todos los montos totales (sin redondeo)
                ReporteTotalRedondeos += element.Redondeo; //Sumatoria de todos los redondeos
                ReporteTotalPagadoVentas += element.TotalRedondeo; //Sumatoria de pago real (Incluye redondeo)
            });

            this.setState({
                ReporteTotalVentas: Number(Number(ReporteTotalVentas).toFixed(6)),
                ReporteTotalRedondeos: Number(Number(ReporteTotalRedondeos).toFixed(6)),
                ReporteTotalPagadoVentas: Number(Number(ReporteTotalPagadoVentas).toFixed(6)),
                totalAnulados: Number(Number(Ventas.filter(d => d.Estado == 'ANULADO').map(d => d.Monto).reduce((a, b) => a + b, 0)).toFixed(6)),
                totalRechazados: Number(Number(Ventas.filter(d => d.Estado == 'RECHAZADO').map(d => d.Monto).reduce((a, b) => a + b, 0)).toFixed(6)),
                totalAceptados: Number(Number(Ventas.filter(d => d.Estado == 'PAGADO').map(d => d.Monto).reduce((a, b) => a + b, 0)).toFixed(6)),
            });
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    verificarTurno = async () => {
        this.setState({isLoading: true, error: null});
        try {
            const response = await fetch(`/api/cajas/verificar/Turno`);
            const data = await response.json();
            this.setState({
                IdTurnoCaja: data.Turno ? data.Turno[0][0].IdTurnoCaja : null,
                IdCaja: data.Turno ? data.Turno[0][0].IdCaja : null,
            });
            if (this.state.IdTurno === null) {
                this.props.history.push(`/gestionpreventas`);
            }
        } catch (error) {
            this.setState({isLoading: false, error: error});
        }
    };

    async getClientes() {
        try {
            const response = await fetch(
                `/api/clientes/emp/`
            );
            const Clientes = await response.json();
            this.setState({
                Clientes: Clientes[1],
                isLoading: false
            });
            return Clientes;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    listClientes(Clientes) {
        return Clientes.map(cliente => {
            return {
                name: cliente.NombreCliente.concat("-", cliente.NroTipoDocumento),
                value: cliente.IdCliente
            };
        });
    }

    handleSelectCliente(e) {
        this.setState({IdCliente: e.value});
    }

    handleFechaInicio(e) {
        this.setState({FechaInicio: e.target.value});
    }

    handleFechaFin(e) {
        this.setState({FechaFin: e.target.value});
    }

    handleTipoReporte(TipoReporte) {
        this.setState({
            TipoReporte: TipoReporte
        });
    }

    async handleContado() {
        this.setState({
            Contado: true,
            Credito: false
        });
    }

    async handleCredito(e) {
        this.setState({
            Credito: true,
            Contado: false
        });
    }

    handleSavePDF(Reporte) {
        let Inicio = moment(this.state.FechaInicio).format("YYYY-MM-DD");
        let Fin = moment(this.state.FechaFin).format("YYYY-MM-DD");

        const mmPageSize = [700, 450];
        const ptPageSize = mmPageSize.map(coord => coord * 2.83465);

        let doc = new jsPDF({
            orientation: "landscape",
            format: ptPageSize
        });
        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text(
            `REPORTE DE VENTAS`

            , 80, 10);

        Inicio == Fin ?
            doc.text(
                `PERIODO: ${Inicio}`

                , 40, 20) :
            doc.text(
                `PERIODO: ${Inicio} - ${Fin}`

                , 40, 20);
        // console.log(this.state.ReporteVentasClienteRango[0].RazonSocial)
        // //
        this.state.TipoReporte == "Cliente" && this.state.IdCliente != null && this.state.ReporteVentasClienteRango.length > 0 ?
            doc.text(
                `CLIENTE: ${this.state.ReporteVentasClienteRango[0].RazonSocial}`

                , 130, 20) :
            doc.text(
                ``

                , 130, 20)

        this.state.TipoReporte == "Modalidad" ?
            this.state.Credito == true ? doc.text(
                `MODALIDAD DE PAGO: CRÉDITO`

                , 130, 20) : doc.text(
                `MODALIDAD DE PAGO: CONTADO`

                , 130, 20)
            : doc.text(
                ``

                , 130, 20)
        // console.log(this.state.ReporteVentasSucursal[0])
        // //
        this.state.TipoReporte == "Sucursal" && this.state.IdSucursal != null ?
            doc.text(
                `SUCURSAL: ${this.state.ReporteVentasSucursal[0].Sucursal}`

                , 130, 20) :
            doc.text(
                ``

                , 130, 20)

        doc.autoTable({
            startY: 30,
            body: Reporte,
            theme: "plain",
            styles: {lineWidth: 0.5},

            head: [
                [
                    {
                        content: "FECHA DE EMISIÓN",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle", cellWidth: 32}
                    },
                    {
                        content: "ESTADO VENTA",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle", cellWidth: 32}
                    },
                    {
                        content: "TIPO DE COMPROBANTE",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "SERIE",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "NUMERO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "TIPO CLIENTE",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "NRO. CLIENTE",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "RAZÓN SOCIAL",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "MODALIDAD DE PAGO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "CANTIDAD DE DÍAS",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "FECHA DE PAGO DEL CRÉDITO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "ESTADO DEL COMPROBANTE",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "TIPOS DE PAGO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "EXPORTACIÓN",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "GRAVADAS",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "EXONERADAS",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "INAFECTAS",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "ISC",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "IGV Y/O IPM",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "ICBPER",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "TOTAL",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle", cellWidth: 32}
                    },
                    {
                        content: "REDONDEO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle", cellWidth: 32}
                    },
                    {
                        content: "TOTAL REDONDEO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle", cellWidth: 32}
                    },
                    {
                        content: "TIPO DE CAMBIO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "FECHA COMPROBANTE MODIFICADO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "CODIGO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "SERIE",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "NUMERO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                ]
            ],

            columnStyles: {
                text: {
                    cellWidth: "wrap",
                    overflow: "linebreak",
                    halign: "right",
                    valign: "middle"
                },
                FechaEmision: {
                    cellWidth: 20,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                state: {
                    cellWidth: 25,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                TipoComprobante: {
                    cellWidth: 20,
                    halign: "right"
                },
                Serie: {
                    cellWidth: 15,
                    halign: "right"
                },
                NumeroComprobante: {
                    cellWidth: 20,
                    halign: "right"
                },
                TipoDocumentoCliente: {
                    cellWidth: 20,
                    halign: "right"
                },
                NroTipoDocumentoCliente: {
                    cellWidth: 30,
                    halign: "right"
                },
                RazonSocial: {
                    cellWidth: 80,
                    halign: "right"
                },

                IdModalidadPago: {
                    cellWidth: 25,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                CantidadDiasCredito: {
                    cellWidth: 20,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                FechaPago: {
                    cellWidth: 25,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                EstadoCE: {
                    cellWidth: 25,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                tipoPagos: {
                    cellWidth: 40,
                    halign: "left",
                    // valign: "middle",
                    overflow: "linebreak"
                },

                Exportacion: {
                    cellWidth: 20,
                    halign: "right"
                },
                Gravadas: {
                    cellWidth: 20,
                    halign: "right"
                },
                Exoneradas: {
                    cellWidth: 20,
                    halign: "right"
                },
                Inafectas: {
                    cellWidth: 20,
                    halign: "right"
                },
                ISC: {
                    cellWidth: 20,
                    halign: "right"
                },
                IGV: {
                    cellWidth: 20,
                    halign: "right"
                },
                ICBPER: {
                    cellWidth: 20,
                    halign: "right"
                },
                Total: {
                    cellWidth: 15,
                    halign: "right"
                },
                Redondeo: {
                    cellWidth: 15,
                    halign: "right"
                },
                TotalRedondeo: {
                    cellWidth: 15,
                    halign: "right"
                },
                TipoCambio: {
                    cellWidth: 15,
                    halign: "right"
                },
                FechaComprobanteModificado: {
                    cellWidth: 30,
                    halign: "right"
                },
                CodigoTipoComprobanteModificado: {
                    cellWidth: 20,
                    halign: "right"
                },
                SerieComprobanteModificado: {
                    cellWidth: 20,
                    halign: "right"
                },
                NumeroComprobanteModificado: {
                    cellWidth: 30,
                    halign: "right"
                }
            },
            columns: [
                {header: "EMISIÓN", dataKey: "FechaEmision"},
                {header: "TIPO COMPROB.", dataKey: "TipoComprobante"},
                {header: "ESTADO VENTA", dataKey: "state"},
                {header: "SERIE", dataKey: "Serie"},
                {header: "NÚMERO", dataKey: "NumeroComprobante"},
                {header: "TIPO CLIENTE", dataKey: "TipoDocumentoCliente"},
                {
                    header: "NRO. DOCUMENTO",
                    dataKey: "NroTipoDocumentoCliente"
                },
                {header: "RAZÓN SOCIAL", dataKey: "RazonSocial"},
                {header: "MODALIDAD DE PAGO", dataKey: "IdModalidadPago"},
                {header: "DIAS DE CREDITO", dataKey: "CantidadDiasCredito"},
                {header: "FECHA DE PAGO DEL CREDITO", dataKey: "FechaPago"},
                {header: "ESTADO DEL COMPROBANTE", dataKey: "EstadoCE"},
                {header: "TIPOS DE PAGO", dataKey: "tipoPagos"},
                {header: "EXPORTACIÓN", dataKey: "Exportacion"},
                {header: "GRAVADAS", dataKey: "Gravadas"},
                {header: "EXONERADAS", dataKey: "Exoneradas"},
                {header: "INAFECTAS", dataKey: "Inafectas"},
                {header: "ISC", dataKey: "ISC"},
                {header: "IGV", dataKey: "IGV"},
                {header: "ICBPER", dataKey: "ICBPER"},
                {header: "TOTAL", dataKey: "Total"},
                {header: "REDONDEO", dataKey: "Redondeo"},
                {header: "TOTAL REDONDEO", dataKey: "TotalRedondeo"},
                {header: "TIPO CAMBIO", dataKey: "TipoCambio"},
                {
                    header: "FECHA COMPROBANTE MODIFICADO",
                    dataKey: "FechaComprobanteModificado"
                },
                {
                    header: "CODIGO",
                    dataKey: "CodigoTipoComprobanteModificado"
                },
                {
                    header: "SERIE",
                    dataKey: "SerieComprobanteModificado"
                },
                {
                    header: "NUMERO",
                    dataKey: "NumeroComprobanteModificado"
                }
            ]
        });
        let finalTable = doc.autoTable.previous.finalY;
        doc.setFontSize(12);
        doc.setFontStyle("bold")
        doc.setFontType("bold");
        doc.text(
            `TOTAL DE VENTAS:         S/. ${this.state.ReporteTotalVentas}`

            , 15, finalTable + 10) //pageWidth -90
        doc.text(
            `TOTAL PAGADO:              S/. ${this.state.ReporteTotalPagadoVentas}`

            , 15, finalTable + 20) //pageWidth -90
        doc.text(
            `TOTAL DE REDONDEO:   S/.   ${this.state.ReporteTotalRedondeos}`

            , 15, finalTable + 30) //pageWidth -90
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("ReporteVentas.pdf");
    }

    getDataExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: "Fecha Emision",
                        width: {wpx: 100}
                    },
                    {
                        title: "Tipo Comprobante",
                        width: {wpx: 100}
                    },
                    {
                        title: "Serie",
                        width: {wpx: 60}
                    },
                    {
                        title: "Numero Comprobante",
                        width: {wpx: 100}
                    },
                    {
                        title: "Nro Tipo Documento Cliente",
                        width: {wpx: 120}
                    },
                    {
                        title: "RazonSocial",
                        width: {wpx: 300}
                    },
                    {
                        title: "Estado",
                        width: {wpx: 300}
                    },
                    {
                        title: "Tipos de pago",
                        width: {wpx: 300}
                    },
                    {
                        title: "Modalidad",
                        width: {wpx: 300}
                    },
                    {
                        title: "Días crédito",
                        width: {wpx: 300}
                    },
                    {
                        title: "Fecha de Pago",
                        width: {wpx: 300}
                    },
                    {
                        title: "Exportacion",
                        width: {wpx: 100}
                    },
                    {
                        title: "Gravadas",
                        width: {wpx: 100}
                    },
                    {
                        title: "Exoneradas",
                        width: {wpx: 100}
                    },
                    {
                        title: "Inafectas",
                        width: {wpx: 100}
                    },
                    {
                        title: "ISC",
                        width: {wpx: 100}
                    },
                    {
                        title: "IGV",
                        width: {wpx: 100}
                    },
                    {
                        title: "ICBPER",
                        width: {wpx: 100}
                    },
                    {
                        title: "Total",
                        width: {wpx: 100}
                    },
                    {
                        title: "Redondeo",
                        width: {wpx: 100}
                    },
                    {
                        title: "Total Redondeado",
                        width: {wpx: 100}
                    },
                ],

                data: dataSet.map(row => {
                    return [
                        {
                            value: row.FechaEmision,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.TipoComprobante,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Serie,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NumeroComprobante || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NroTipoDocumento || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.RazonSocial || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.EstadoCE || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.tipoPagos || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.IdModalidadPago || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.CantidadDiasCredito || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.FechaPago || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Exportacion || 0.0,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: decimalAdjust('round', row.Gravadas || 0.0, -2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: decimalAdjust('round', row.Exoneradas || 0.0, -2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: decimalAdjust('round', row.Inafectas || 0.0, -2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: decimalAdjust('round', row.ISC || 0.0, -2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: decimalAdjust('round', row.IGV || 0.0, -2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.ICBPER || 0.0,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: decimalAdjust('round', row.Total || 0.0, -2),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Redondeo,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.TotalRedondeo,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            },
            {
                columns: [
                    {
                        title: "TOTAL DE VENTAS",
                        width: {wpx: 100}
                    },
                    {
                        title: "TOTAL PAGADO",
                        width: {wpx: 100}
                    },
                    {
                        title: "REDONDEO",
                        width: {wpx: 60}
                    }
                ],

                data: [
                    [
                        {
                            value: this.state.ReporteTotalVentas,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: this.state.ReporteTotalPagadoVentas,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: this.state.ReporteTotalRedondeos,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ],

                ]
            }
        ];
    }

    onClickDetalleVenta(props) {
        this.props.history.push(
            `/detalleregistroventas/${props.original.IdRegistroVenta}`
        );
        return <Redirect to={

            `/detalleregistroventas/${props.original.IdRegistroVenta}`

        }/>
    }

    async anularVenta(props) {
        const {original} = props
        // const res = await this.anularVentaCaja(original)
        const resp = await this.anularVentaFpay(original)
        let Inicio = moment(this.state.FechaInicio).format("YYYY-MM-DD");
        let Fin = moment(this.state.FechaFin).format("YYYY-MM-DD");
        let IdSucursal = this.state.IdSucursal;
        this.getReporteVentasSucursal(Inicio, Fin, IdSucursal);
    }

    async anularVentaFpay(data) {
        try {
            let isOK = await swal({
                title: 'Cuidado...! ',
                text: `Desea anular la operación, ID:` + data.IdRegistroVentasFpay,
                icon: 'error',
                buttons: [
                    'No',
                    'Si'
                ],
            })
            if (isOK) {
                const infoPago = JSON.parse(data.Info)
                const detailPagos = infoPago ? infoPago : {}
                await this.fpayService.refundIntentionPay(this.state.tokenFpay, detailPagos)
            }
        } catch (e) {
            notificarError("No se puede anular esta venta")
        }
    }

    async anularVentaCaja(data) {
        await fetch(`/api/cajas/addMov/anulacionMov`, {
            method: 'POST',
            body: JSON.stringify({
                IdRegistroVenta: data.IdRegistroVenta,
                Observacion: "Anulación del comprobante: " + data.Serie + " - " + data.NroComprobante
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }

    getIdSucursal() {
        if (this.state.FiltrarSucursal)
            return null;
        return this.state.IdSucursal;
    }

    onSearchVentas() {
        let Inicio = moment(this.state.FechaInicio).format("YYYY-MM-DD");
        let Fin = moment(this.state.FechaFin).format("YYYY-MM-DD");
        let TipoReporte = this.state.TipoReporte;
        this.setState({
            isLoading: true
        })

        if (TipoReporte === "Turno") {
            console.log("Entra turno")
            let IdSucursal = this.state.IdSucursal;
            this.getReporteTurnoRango(Inicio, Fin, IdSucursal);
        }
        if (TipoReporte === "Cliente") {
            let IdCliente = this.state.IdCliente;
            this.getReporteVentasClienteRango(Inicio, Fin, IdCliente);
        }
        if (TipoReporte === "Modalidad") {
            let IdModalidadPago = this.state.Contado === true ? 1 : 2;
            this.getReporteVentasModalidadPago(Inicio, Fin, IdModalidadPago);
        }
        if (TipoReporte === "Sucursal") {
            let IdSucursal = this.state.IdSucursal;
            this.getReporteVentasSucursal(Inicio, Fin, IdSucursal);
        }
    }

    extractTiposPagos(tipoPagos) {
        tipoPagos = tipoPagos && tipoPagos.length ? tipoPagos.split(",") : ""
        const tipos = []
        for (let i = 0; i < tipoPagos.length; i += 2) {
            tipos.push({
                tipo: tipoPagos[i],
                monto: tipoPagos[i + 1]
            })
        }
        return tipos
    }

    render() {
        let OpcionFiltro = null;

        OpcionFiltro = this.state.TipoReporte;

        let Reporte = null;
        let ReporteTotalPagado = this.state.ReporteTotalPagadoVentas;
        let ReporteTotalRedondeo = this.state.ReporteTotalRedondeos;

        if (OpcionFiltro === "Turno") {
            console.log("Entro turno render")
            Reporte = this.state.ReporteVentasTurnoRango;
        }
        if (OpcionFiltro === "VentaRango") {
            console.log("Entro VentaRango render")
            Reporte = this.state.ReporteVentasRango;
        }
        if (OpcionFiltro === "Modalidad") {
            Reporte = this.state.ReporteVentasModalidadPago;
        }
        if (OpcionFiltro === "Sucursal") {
            Reporte = this.state.ReporteVentasSucursal;
        }
        if (Reporte) {
            var dataExport = this.getDataExportExcel(Reporte);
        }
        const styleCalendar = {
            border: "0.5px solid #acacac",
            borderRadius: "25px",
            outline: "none",
            flexBasis: "50%",
            alignSelf: "center"
        };
        return (
            <>
                <section className="ventas-fpay-section">
                    <div className="row w-100 m-auto">
                        <div className="titulo-ventas-pay col-12 col-sm-10 np" align="center">
                            <h4 className="ventas-fpay-titulo">Selecciona las opciones del reporte personalizado de
                                Ventas de Fpay</h4>
                        </div>
                        <div className="titulo-ventas-pay col-12 col-sm-2">
                            <img src={logoFpay} className="ventas-fpay-logo"/>
                        </div>
                    </div>
                    <div
                        className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2"
                    >
                        <span className="align-self-center letra-fecha-fpay">Desde: </span>
                        <input
                            type="date"
                            onChange={this.handleFechaInicio}
                            value={this.state.FechaInicio}
                            className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                            style={styleCalendar}
                        />
                        <span className="align-self-center letra-fecha-fpay">Hasta: </span>
                        <input
                            type="date"
                            onChange={this.handleFechaFin}
                            value={this.state.FechaFin}
                            className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                            style={styleCalendar}
                        />
                    </div>
                    <div
                        style={{width: "100%"}}
                        className="d-flex justify-content-center"
                    >
                        <div className=" pagos-comprobante">
                            {this.state.FiltrarSucursal ? (
                                <button
                                    type="button"
                                    className={
                                        this.state.TipoReporte === "Turno"
                                            ? "pagos__tipo--comprobante--item--active pestaña-hvr"
                                            : "pagos__tipo--comprobante--item pago-comprobante-border-l pestaña-hvr"
                                    }
                                    value={this.state.TipoReporte}
                                    onClick={() => {
                                        this.handleTipoReporte("Turno");
                                    }}
                                >
                                    Por Turnos
                                </button>
                            ) : null}

                            <button
                                type="button"
                                className={
                                    this.state.TipoReporte === "Sucursal"
                                        ? "pagos__tipo--comprobante--item--active pestaña-hvr"
                                        : "pagos__tipo--comprobante--item pestaña-hvr"
                                }
                                value={this.state.TipoReporte}
                                onClick={() => {
                                    this.handleTipoReporte("Sucursal");
                                }}
                            >
                                Por Sucursal
                            </button>
                            <button
                                type="button"
                                className={"pagos__tipo--comprobante--item pago-comprobante-border-r pestaña-hvr"}
                                onClick={() => {
                                    this.props.history.push("/registrosventas/reportes/sucursales");
                                }}
                            >
                                General de sucursales
                            </button>
                        </div>
                    </div>

                    {Reporte ? (
                        <div className="container mt-5">

                            {OpcionFiltro === "Turno" && (
                                <>
                                    <div className="caja-fondo-blanco-m-2">
                                        <h4 className="sucursal-text-color pad-15-fpay">Selecciona la Sucursal</h4>
                                        {this.state.FiltrarSucursal && this.state.listaSucursales.length ?
                                            <SelectSearch
                                                name="IdSucursal"
                                                value={this.state.IdSucursal}
                                                onChange={this.handleSelectSucursal}
                                                options={this.listSucursales(this.state.listaSucursales)}
                                                className="select-search-box"
                                                placeholder="Selecciona"
                                                key={2}
                                                required
                                            /> :
                                            <label>{this.state.user && this.state.user.RazonSocial ? this.state.user.RazonSocial : "Sucursal del usuario en sesión"}</label>}
                                    </div>

                                    <div className="caja-fondo-blanco-m-2">
                                        <h4 className="sucursal-text-color pad-15-fpay">Selecciona la Caja</h4>
                                        {this.state.FiltrarSucursal && this.state.listaSucursales.length ?
                                            <SelectSearch
                                                name="IdCaja"
                                                value={this.state.IdCajaSelector}
                                                onChange={this.handleSelectCaja}
                                                options={this.listCajas(this.state.listaSucursalCajas)}
                                                className="select-search-box"
                                                placeholder="Selecciona"
                                                key={10}
                                                required
                                            /> :
                                            <label></label>}
                                    </div>
                                    <div className="caja-fondo-blanco-m-2">
                                        <h4 className="sucursal-text-color pad-15-fpay">Selecciona el Usuario</h4>
                                        {this.state.FiltrarSucursal && this.state.listaSucursales.length ?
                                            <SelectSearch
                                                name="IdSucursal"
                                                value={this.state.IdUsuario}
                                                onChange={this.handleSelectUsuario}
                                                options={this.listUser(this.state.listaUsuarios)}
                                                className="select-search-box"
                                                placeholder="Selecciona"
                                                key={5}
                                                required
                                            /> :
                                            <label></label>}
                                    </div>
                                </>
                            )}

                            {OpcionFiltro === "Sucursal" && (
                                <>
                                    <div className="caja-fondo-blanco-m-2">
                                        <h4 className="sucursal-text-color pad-15-fpay">Selecciona la Sucursal</h4>
                                        {this.state.FiltrarSucursal && this.state.listaSucursales.length ?
                                            <SelectSearch
                                                name="IdSucursal"
                                                value={this.state.IdSucursal}
                                                onChange={this.handleSelectSucursal}
                                                options={this.listSucursales(this.state.listaSucursales)}
                                                className="select-search-box"
                                                placeholder="Selecciona"
                                                key={4}
                                                required
                                            /> :
                                            <label>{this.state.user && this.state.user.RazonSocial ? this.state.user.RazonSocial : "Sucursal del usuario en sesión"}</label>}
                                    </div>

                                </>
                            )}


                            {Reporte.length > 0 && (
                                <>
                                    <div className="justify-content-between caja-fondo-blanco-m-2 no-marg">
                                        <div className="row justify-content-center">
                                            <h4 className="d-flex justify-content-center col-12 col-sm-4">
                                                {" "}
                                                Total Aceptados: {decimalAdjust('floor', this.state.totalAceptados, -4)}
                                            </h4>
                                            <h4 className="d-flex justify-content-center col-12 col-sm-4">
                                                {" "}
                                                Total Anulados: {decimalAdjust('floor', this.state.totalAnulados, -4)}
                                            </h4>
                                            <h4 className="d-flex justify-content-center col-12 col-sm-4">
                                                {" "}
                                                Total
                                                Rechazados: {decimalAdjust('floor', this.state.totalRechazados, -4)}
                                            </h4>
                                            {/*<h4 className="align-self-center col-12 col-sm-3">*/}
                                            {/*    {" "}*/}
                                            {/*    Total Pagado: {ReporteTotalPagado}*/}
                                            {/*</h4>*/}
                                            {/*<h4 className="align-self-center col-12 col-sm-3">*/}
                                            {/*    {" "}*/}
                                            {/*    Total Redondeos: {ReporteTotalRedondeo}*/}
                                            {/*</h4>*/}
                                        </div>

                                        {/* {!this.state.isLoading &&
                                            <div className="align-self-center mb-2 seccion-btn-verde-mfp">
                                                <button
                                                    className="boton__Rojo"
                                                    onClick={() => this.handleSavePDF(Reporte)}
                                                    title="Descarga reporte mensual de Ventas en PDF"
                                                >
                                                    <i className="fas fa-file-pdf"/>
                                                </button>
                                                <ExcelFile
                                                    element={
                                                        <button
                                                            className="boton__verdeOscuro ml-2"
                                                            title="Descarga reporte de Ventas en EXCEL"
                                                        >
                                                            <i className="fas fa-file-excel"/>
                                                        </button>
                                                    }
                                                    filename="ReporteVentas"
                                                >
                                                    <ExcelSheet
                                                        dataSet={dataExport}
                                                        name="Ventas"
                                                    />
                                                </ExcelFile>
                                            </div>} */}
                                    </div>
                                </>
                            )}

                            <div className="seccion-btn-verde-mfp">
                                {Reporte.length > 0 && (
                                    <>
                                        {!this.state.isLoading &&
                                            <div className="align-self-center seccion-btn-verde-mfp">
                                                <button
                                                    className="boton__Rojo"
                                                    style={{height: "49px"}}
                                                    onClick={() => this.handleSavePDF(Reporte)}
                                                    title="Descarga reporte mensual de Ventas en PDF"
                                                >
                                                    <i className="fas fa-file-pdf"/>
                                                </button>
                                                <ExcelFile
                                                    element={
                                                        <button
                                                            className="boton__verdeOscuro ml-2 mr-2"
                                                            title="Descarga reporte de Ventas en EXCEL"
                                                        >
                                                            <i className="fas fa-file-excel"/>
                                                        </button>
                                                    }
                                                    filename="ReporteVentas"
                                                >
                                                    <ExcelSheet
                                                        dataSet={dataExport}
                                                        name="Ventas"
                                                    />
                                                </ExcelFile>
                                            </div>}
                                    </>
                                )}
                                <button onClick={this.onSearchVentas} disabled={this.state.isLoading}
                                        className="btn btn-outline-primary btn-verde-mfp">
                                    Generar
                                </button>
                            </div>

                            <div className="mt-3">
                                <ReactTable
                                    id="ventas"
                                    data={Reporte}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value
                                    }
                                    onFilteredChange={(filters) => {
                                        let tmpData = [...Reporte]
                                        for (let i = 0; i < filters.length; i++) {
                                            if (filters[i].id === "Serie") {
                                                let query = String(filters[i].value).split(',').map(x => x.toLowerCase())
                                                tmpData = tmpData.filter(x => query.includes(String(x[filters[i].id]).toLowerCase()))
                                            } else
                                                tmpData = tmpData.filter(x => String(x[filters[i].id]).toLowerCase().includes(String(filters[i].value).toLowerCase()))
                                        }
                                        this.getReporteTotalVentas(tmpData);
                                    }}
                                    columns={
                                        [
                                            {
                                                Header: "Acción",
                                                id: "TipoPago",
                                                width: 80,
                                                Cell: row => {
                                                    const tipoPagos = row.original.tipoPagos ? true : false
                                                    return (
                                                        <div>
                                                            <button
                                                                className="btn btn-danger fas fa-folder-minus ml-1 mr-1"
                                                                title="Anular Venta/ Devolución"
                                                                hidden={!(row.original.Estado === "PAGADO")}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.anularVenta(row);
                                                                }}
                                                            >
                                                            </button>
                                                            {tipoPagos &&
                                                                <button
                                                                    className="btn-tipopagos btn btn-info"
                                                                    type="button"
                                                                >
                                                                    <span>{tipoPagos}</span>
                                                                </button>
                                                            }
                                                        </div>
                                                    );
                                                }
                                            },
                                            {
                                                columns: [
                                                    // {
                                                    //     Header: "Cliente",
                                                    //     id: "RazonSocial",
                                                    //     width: 250,
                                                    //     accessor: d => d.RazonSocial,
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["RazonSocial"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANLADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    {
                                                        Header: "Fecha Emisión",
                                                        id: "FechaEmision",
                                                        width: 160,
                                                        show: true,
                                                        accessor: d => d.FechaEmision,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["FechaEmision"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANUADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },
                                                    {
                                                        Header: "Fecha Inicio Turno",
                                                        id: "FechaInicio",
                                                        width: 160,
                                                        show: OpcionFiltro == "Turno",
                                                        accessor: d => d.TurnoFechaInicio,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["TurnoFechaInicio"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANUADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },
                                                    {
                                                        Header: "Fecha Fin Turno",
                                                        id: "TurnoFechaFin",
                                                        width: 160,
                                                        show: OpcionFiltro == "Turno",
                                                        accessor: d => d.TurnoFechaFin,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["TurnoFechaFin"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANUADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },
                                                    {
                                                        Header: "Fecha Modificación",
                                                        id: "FechaModificacion",
                                                        accessor: d => d.FechaModificacion,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["FechaModificacion"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANUADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },

                                                    {
                                                        Header: "Estado",
                                                        id: "Estado",
                                                        accessor: d => d.Estado,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["Estado"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    textAlign: "right",
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANULADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },

                                                    {
                                                        Header: "Usuario",
                                                        id: "Usuario",
                                                        show: OpcionFiltro == "Turno",
                                                        accessor: d => d.Usuario,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["Usuario"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    textAlign: "right",
                                                                    background:
                                                                        rowInfo && rowInfo.row.Usuario === "ANULADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },
                                                    {
                                                        Header: "Terminal",
                                                        id: "TerminalId",
                                                        width: 50,
                                                        accessor: d => d.TerminalId,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["TerminalId"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    textAlign: "right",
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANULADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },
                                                    {
                                                        Header: "Store",
                                                        id: "StoreId",
                                                        width: 50,
                                                        accessor: d => d.StoreId,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["StoreId"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    textAlign: "right",
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANULADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },
                                                    {
                                                        Header: "Monto",
                                                        id: "Monto",
                                                        accessor: d =>
                                                            d.Monto.toLocaleString("en", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }),
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["Monto"]
                                                            }),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    textAlign: "right",
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANULADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },
                                                    // {
                                                    //     Header: "Sucursal",
                                                    //     id: "Sucursal",
                                                    //     accessor: d => d.Sucursal,
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {keys: ["Sucursal"]}),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },

                                                    //   {
                                                    //       Header: "Tipo Pago",
                                                    //       id: "tipoPagos",
                                                    //       accessor: d => d._tipoPagos.map(d => d.tipo).join(","),
                                                    //       filterMethod: (filter, rows) =>
                                                    //           matchSorter(rows, filter.value, {
                                                    //               keys: ["tipoPagos"]
                                                    //           }),
                                                    //       filterAll: true,
                                                    //       getProps: (state, rowInfo, column) => {
                                                    //           return {
                                                    //               style: {
                                                    //                   background:
                                                    //                       rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                           ? "#ffcccc"
                                                    //                           : null
                                                    //               }
                                                    //           };
                                                    //       }
                                                    //   },

                                                    {
                                                        Header: "Caja",
                                                        id: "IdCaja",
                                                        width: 50,
                                                        accessor: d => d.IdCaja,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {keys: ["IdCaja"]}),
                                                        filterAll: true,
                                                        getProps: (state, rowInfo, column) => {
                                                            return {
                                                                style: {
                                                                    textAlign: "right",
                                                                    background:
                                                                        rowInfo && rowInfo.row.Estado === "ANULADA"
                                                                            ? "#ffcccc"
                                                                            : null
                                                                }
                                                            };
                                                        }
                                                    },
                                                    // {
                                                    //     Header: "EstadoCE",
                                                    //     id: "EstadoCE",
                                                    //     accessor: d => d.EstadoCE,
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["Estado"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Modalidad Pago",
                                                    //     id: "IdModalidadPago",
                                                    //     accessor: d => d.IdModalidadPago,
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["IdModalidadPago"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Días Crédito",
                                                    //     id: "CantidadDiasCredito",
                                                    //     width: 120,
                                                    //     accessor: d => d.CantidadDiasCredito,
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {keys: ["CantidadDiasCredito"]}),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Fecha de Pago",
                                                    //     id: "FechaPago",
                                                    //     width: 120,
                                                    //     accessor: d => d.FechaPago !== '-' ? d.FechaPago : '-',
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {keys: ["FechaPago"]}),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Tipo Comprobante",
                                                    //     id: "TipoComprobante",
                                                    //     accessor: d => d.TipoComprobante,
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["TipoComprobante"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Moneda",
                                                    //     id: "Abreviatura",
                                                    //     accessor: d => d.Abreviatura,
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["Abreviatura"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Redondeo",
                                                    //     id: "Redondeo",
                                                    //     accessor: d => d.Redondeo.toLocaleString("en", {
                                                    //         minimumFractionDigits: 2,
                                                    //         maximumFractionDigits: 2
                                                    //     }),
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["Redondeo"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 textAlign: "right",
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "TotalRedondeo",
                                                    //     id: "TotalRedondeo",
                                                    //     accessor: d => d.TotalRedondeo.toLocaleString("en", {
                                                    //         minimumFractionDigits: 2,
                                                    //         maximumFractionDigits: 2
                                                    //     }) ? d.TotalRedondeo.toLocaleString("en", {
                                                    //         minimumFractionDigits: 2,
                                                    //         maximumFractionDigits: 2
                                                    //     }) : 0.0,
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["TotalRedondeo"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 textAlign: "right",
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Gravadas",
                                                    //     id: "Gravadas",
                                                    //     accessor: d =>
                                                    //         d.Gravadas.toLocaleString("en", {
                                                    //             minimumFractionDigits: 2,
                                                    //             maximumFractionDigits: 2
                                                    //         }),
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["Gravadas"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 textAlign: "right",
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Exoneradas",
                                                    //     id: "Exoneradas",
                                                    //     accessor: d =>
                                                    //         d.Exoneradas.toLocaleString("en", {
                                                    //             minimumFractionDigits: 2,
                                                    //             maximumFractionDigits: 2
                                                    //         }),
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["Exoneradas"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 textAlign: "right",
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Inafectas",
                                                    //     id: "Inafectas",
                                                    //     accessor: d =>
                                                    //         d.Inafectas.toLocaleString("en", {
                                                    //             minimumFractionDigits: 2,
                                                    //             maximumFractionDigits: 2
                                                    //         }),
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["Inafectas"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 textAlign: "right",
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "IGV",
                                                    //     id: "IGV",
                                                    //     accessor: d =>
                                                    //         d.IGV.toLocaleString("en", {
                                                    //             minimumFractionDigits: 2,
                                                    //             maximumFractionDigits: 2
                                                    //         }),
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["IGV"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 textAlign: "right",
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "ICBPER",
                                                    //     id: "ICBPER",
                                                    //     accessor: d =>
                                                    //         d.ICBPER.toLocaleString("en", {
                                                    //             minimumFractionDigits: 2,
                                                    //             maximumFractionDigits: 2
                                                    //         }),
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["ICBPER"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 textAlign: "right",
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // },
                                                    // {
                                                    //     Header: "Descuento",
                                                    //     id: "DescuentoTotal",
                                                    //     accessor: d => {
                                                    //         return d.DescuentoTotal ? d.DescuentoTotal.toLocaleString("en", {
                                                    //             minimumFractionDigits: 2,
                                                    //             maximumFractionDigits: 2
                                                    //         }) : "0";
                                                    //     },
                                                    //     filterMethod: (filter, rows) =>
                                                    //         matchSorter(rows, filter.value, {
                                                    //             keys: ["DescuentoTotal"]
                                                    //         }),
                                                    //     filterAll: true,
                                                    //     getProps: (state, rowInfo, column) => {
                                                    //         return {
                                                    //             style: {
                                                    //                 textAlign: "right",
                                                    //                 background:
                                                    //                     rowInfo && rowInfo.row.Estado === "ANULADA"
                                                    //                         ? "#ffcccc"
                                                    //                         : null
                                                    //             }
                                                    //         };
                                                    //     }
                                                    // }
                                                ]
                                            }
                                        ]}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    previousText="Anterior"
                                    nextText="Siguiente"
                                    noDataText="No se encontraron registros"
                                    loadingText="Cargando..."
                                    ofText="de"
                                    rowsText="filas"
                                    pageText="Página"
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    SubComponent={row => {
                                        const tipoPagos = row.original._tipoPagos
                                        return (
                                            <>
                                                Pagos
                                                <ul className={tipoPagos}>
                                                    {(() => {
                                                        if (tipoPagos) {
                                                            return (
                                                                tipoPagos.map((t, i) =>
                                                                    <li key={i}>{t.tipo}: {decimalAdjust('floor', t.monto, -6)} S./</li>)
                                                            )
                                                        } else {
                                                            return (<li>No se ha realizad pago</li>)
                                                        }
                                                    })()}
                                                </ul>
                                            </>
                                        )
                                    }}
                                />
                            </div>

                        </div>
                    ) : (
                        <div>Cargando Ventas</div>
                    )}
                </section>
            </>
        );
    }
}

export default ReporteVentasFpay;
