import React from 'react';
import FpayLogo from "../../../../pages/RegistroVentas/images/fpy.jpeg"
import FpayQR from "../../../../pages/RegistroVentas/images/loader.gif"
import FpayClock from "../../../../pages/RegistroVentas/images/icons8-clock.gif"
import "./ModalFpay.css"
import FpayService from "../../../../services/FpayService";
import {decimalAdjust} from "../../../../Global";
import {notificarError, notificarMsg} from "../../../Almacenes/AlmacenNotify";

const MONTO_MANUAL = "MONTO MANUAL"
const MONTO_UNICO = "MONTO UNICO"
const SEGUNDOS = 60
const INICIO_TIMER = '00:00'

// const BASE_TIMER = '00:0'

export class ModalFpay extends React.Component {
    constructor(props) {
        super(props);
        const {pagoUnico, productos, montoActual, onAgregarPagoFpay, onClose, docInfo} = this.props;
        this.state = {
            monto: decimalAdjust("round", pagoUnico - montoActual, -2),
            productos: productos,
            informaciónPago: "",
            status: false,
            montoManual: true,
            textoBotonMonto: MONTO_MANUAL,
            timer: '00:00',
            token: "",
            gifTimer: "",
            deshabilitarBoton: false,
            deshabilitarProcesarPago: true,
            deshabilitarBotonActivar: false,
            qr: FpayQR,
            infoFpay: this.searchFpayInfo(docInfo),
            branchInfo: this.getBranchInfo(docInfo),
            intentionFpay: {},
        }
        this.productos = productos
        this.total = pagoUnico
        this.tipoPago = "ESTATICO"
        this.fpayService = new FpayService();
        this.qr = ""
        this.links = {}
        this.fpayIntention = {}
        this.merchantId = this.generateQuickGuid()
        this.changeValue = this.changeValue.bind(this);
        this.toggleMontoManual = this.toggleMontoManual.bind(this);
        this.activarPago = this.activarPago.bind(this);
        this.onAgregarPagoFpay = this.onAgregarPagoFpay.bind(this);
        this.cerrarBoton = this.cerrarBoton.bind(this)
        this.anularPago = this.anularPago.bind(this)
        this.searchFpayInfo = this.searchFpayInfo.bind(this)
        this.getBranchInfo = this.getBranchInfo.bind(this)
    }

    searchFpayInfo(data) {
        const billeterasInfo = data ? data.docBilleteras : {}
        const fpayInfo = billeterasInfo.find(i => i.IdTipoDocumentoSunat == 54)
        if (fpayInfo) {
            return fpayInfo.metadata
        }
        this.cerrarModal()
        return {}
    }

    getBranchInfo(data) {
        const billeterasInfo = data ? data.docBilleteras : {}
        const branchInfo = billeterasInfo.find(i => i.IdTipoDocumentoSunat == 54)
        if (branchInfo) {
            const {
                CorreoElectronico,
                Direccion,
                Nombre,
                Telefono,
                VentasFpay,
                NombreUbigeo,
                IdCaja,
                NombreComercial,
                Codigo
            } = branchInfo
            return {
                CorreoElectronico,
                Direccion,
                Nombre,
                Telefono,
                VentasFpay,
                NombreUbigeo,
                IdCaja,
                NombreComercial,
                Codigo
            }
        }
        this.cerrarModal()
        return {}

    }

    generateQuickGuid() {
        return Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
    }

    onAgregarPagoFpay() {
        this.props.onAgregarPagoFpay({...this.state})
    }

    cerrarModal() {
        this.props.onClose()
    }

    async cerrarBoton() {
        if (this.links) {
            const cancel = await this.anularPago()
        }
        this.cerrarModal()
    }

    async componentWillMount() {
        const log = await  this.fpayService.getUserFpay()
        const response = await this.fpayService.authToken()
        this.setState({token: response})
        if(this.state.infoFpay !== {}){
            let infoFpay = {...JSON.parse(this.state.infoFpay)}
            infoFpay = infoFpay[0]
            this.setState({qr: infoFpay.link})
        } else {
            this.cerrarModal()
            notificarError("Su caja no tiene configuración para usar Fpay")
        }
        // infoFpay.link = "https://sunat-ose.mifacturaperu.com/datos/20607074578/Img/logo_20607074578.png"
        // const fpayQR = await this.fpayService.getImage(infoFpay.link)
    }

    changeValue(e) {
        this.setState({
            monto: e.target.value
        });
    }

    toggleMontoManual() {
        this.setState({montoManual: !this.state.montoManual}, () => {
            if (this.state.montoManual) {
                this.setState({monto: this.props.pagoUnico})
                this.setState({textoBotonMonto: MONTO_MANUAL})
            } else {
                this.setState({textoBotonMonto: MONTO_UNICO})
            }
        })
    }

    async activarPago() {
        const tiempo = SEGUNDOS
        this.setState({gifTimer: FpayClock})
        this.setState({deshabilitarBoton: true})
        this.setState({deshabilitarBotonActivar: true})
        let infoFpay = {...JSON.parse(this.state.infoFpay)}
        infoFpay = infoFpay[0]
        const detail = {
            items: this.state.productos,
            total: this.state.monto,
            merchantId: this.merchantId,
            infoPay: infoFpay,
            branchInfo: this.state.branchInfo
        }
        const intentionFpay = await this.fpayService.intentionPay(this.state.token, detail)
        this.setState({intentionFpay: intentionFpay})
        this.links = intentionFpay ? this.fpayService.getLinks(intentionFpay.links) : null
        if (!this.links) {
            this.cerrarModal()
            return;
        }
        this.fpayService.nuevoRegistroFpay(detail, intentionFpay)

        if (this.tipoPago == "DINAMICO") {
            const qr = await this.fpayService.getQR(this.state.token, this.links.qr_code.href)
            this.setState({qr: qr})
        }

        this.setState({timer: '00:00'}, () => {
            this.cuentaRegresiva(tiempo)
        })
    }

    async cuentaRegresiva(tiempo) {
        if (tiempo % 5 == 0) {
            const status = await this.fpayService.getStatus(this.state.token, this.links.self.href)
            this.setState({status: status})
        }
        if (this.state.status == "paid") {
            const resp = await this.fpayService.updateStatus(this.merchantId, "PAGADO")
            this.onAgregarPagoFpay()
            return setTimeout(() => {
                this.cerrarModal()
            }, 5000)
        }

        if (this.state.status == "rejected") {
            const resp = await this.fpayService.updateStatus(this.merchantId, "RECHAZADO")
            return setTimeout(() => {
                this.cerrarModal()
            }, 5000)

            return null
        }

        if (tiempo == 0 && this.state.status != "paid") {
            const cancel = await this.anularPago()
            const status = await this.fpayService.getStatus(this.state.token, this.links.self.href)
            if(status == 'reversed'){
                notificarMsg("Fue Cancelado el proceso de pago, intente nuevamente")
            }
            this.cerrarModal()

        }

        if (tiempo >= 0) {
            const value = tiempo > 10 ? '00:' + tiempo : '00:0' + tiempo
            return setTimeout(() => {
                this.setState({timer: value}, () => {
                    this.cuentaRegresiva(--tiempo)
                })
            }, 1000)

        }


        this.setState({status: true})
        this.setState({gifTimer: ""})
        this.cerrarModal()
        return this.setState({timer: INICIO_TIMER})
    }

    async anularPago() {
        const refused = await this.fpayService.reversedlIntetionPay(this.state.token, this.merchantId)
        const resp = await this.fpayService.updateStatus(this.merchantId, "INVERTIDO")
    }

    render() {
        return (
            <>
                <div className="container-formulario-fpay-modal">
                    <div className="row h-25">
                        <div className="d-flex align-items-center col-8">
                            <div
                                className="d-flex justify-content-center align-items-center align-content-center w-100">
                                <label className="monto-label font-weight-bold mb-0">MONTO A PAGAR S/: &nbsp;</label>
                                <input id="monto-value"
                                       className="w-50 form-control border-bottom border-dark border-top-0 border-left-0 border-right-0"
                                       type="number"
                                       value={decimalAdjust("round", this.state.monto, -2)} onChange={this.changeValue}
                                       disabled={this.state.montoManual}/>
                            </div>
                        </div>
                        <div className="metodo-pago h-100 col-4" key={"Fpay-logo"}>
                            <div className="fondo-fpay">
                                <img alt={"fpay-logo-img"} title={"Fpay"} className="logo-metodo-pago" src={FpayLogo}/>
                            </div>
                        </div>
                    </div>
                    <div className="row h-50 margin-bot">
                        <div className="col-8 h-100">
                            <div className="rounded w-100 h-100 fpay-bg">

                                {(
                                    () => {
                                    if (this.state.status == "paid") {
                                        return (
                                            <div className="margen-top">
                                                <div
                                                    className="panel-status d-flex flex-column justify-content-center align-content-center align-items-center h-75">
                                                    <h3 className="panel-status-title">monto pagado</h3>
                                                </div>
                                            </div>)
                                    } else if (this.state.status == "rejected") {
                                        return (
                                            <div className="margen-top">
                                                <div
                                                    className="panel-status d-flex flex-column justify-content-center align-content-center align-items-center h-75">
                                                    <h3 className="panel-status-recjected-title">PAGO RECHAZADO</h3>
                                                </div>
                                            </div>)
                                    } else {
                                        return (
                                            <div
                                                className="rounded w-100 h-100 d-flex justify-content-center align-items-center align-content-center">
                                                <img className={"qr-fpay-metodo-pago"} src={this.state.qr} alt="fpay pago"/>
                                            </div>
                                        )
                                    }
                                    // <div
                                    //     className="rounded w-100 h-100 d-flex justify-content-center align-items-center align-content-center"
                                    //     dangerouslySetInnerHTML={{__html: this.state.qr}}>
                                    // </div>
                                })()}
                            </div>
                        </div>
                        <div className="col-4 h-100">
                            <div className="d-flex flex-column-reverse justify-content-end   h-75">
                                <button className="billetera-button my-1 h-25"
                                        disabled={this.state.deshabilitarBotonActivar}
                                        onClick={this.activarPago}>ACTIVAR
                                </button>
                                <button className="billetera-button my-1 h-25"
                                        onClick={this.toggleMontoManual}
                                        disabled={this.state.deshabilitarBoton}>{this.state.textoBotonMonto}</button>
                            </div>
                        </div>
                    </div>
                    <div className="row no-flex">
                        <div className="d-flex justify-content-around w-100 col-8">
                            {(() => {
                                if (this.state.status == "paid") {
                                    return (<div
                                        className="d-flex justify-content-center align-items-center align-content-center w-100 timer-content">
                                        <div>
                                            <label className="title-end-time monto-pagado pb-4">Su monto ha sido
                                                pagado</label>
                                        </div>
                                        <div>
                                            <label className="title-rejected-time pt-4"> El modal se cerrará en
                                                los proximos 5 segundos</label>
                                            <img src={this.state.gifTimer} alt="timer"></img>
                                        </div>
                                    </div>)
                                } else if (this.state.status == "rejected") {
                                    return (<div
                                        className="justify-content-center align-items-center align-content-center w-100 timer-content">
                                        <div>
                                            <label className="title-rejected-time mx-1"> El modal se cerrará en
                                                los proximos 5 segundos</label>
                                            <img src={this.state.gifTimer} alt="timer gif"></img>
                                        </div>
                                    </div>)
                                } else {
                                    return (
                                        <div
                                            className="justify-content-center align-items-center align-content-center w-100 timer-content">
                                            <div className='end-time-text'>
                                                <p>Tiene 60 segundos para escanear el codigo QR luego de activar el
                                                    pago</p>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center align-items-center align-content-center">
                                                <img src={this.state.gifTimer} alt="timer gif"></img>
                                                <label className="title-end-time mx-1">TIEMPO RESTANTE: </label>
                                                <label className="title-time">{this.state.timer}</label>
                                            </div>
                                        </div>
                                    )
                                }

                            })()}

                        </div>
                        <div className="row no-flex inline">
                            {/* <div className=" justify-content-end flex-column w-100 ">
                                <button
                                   onClick={this.onAgregarPagoFpay}
                                   disabled={this.state.deshabilitarProcesarPago}
                                    className="billetera-procesa-button">AGREGAR PAGO
                                </button>
                            </div> */}
                        {(() => {
                            if (this.state.status == "paid") {
                                return(
                                    <div></div>
                                )
                            }else {  
                                return(
                                    <div className=" justify-content-end flex-column w-100 ">
                                    <button
                                        className="cancelar-button"
                                        onClick={this.cerrarBoton}
                                        disabled={!this.state.deshabilitarProcesarPago}
                                    >CANCELAR
                                    </button>
                                </div>
                                )
                            }
                        })()}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}