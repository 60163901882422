import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import {
  decimalAdjust,
  calcularTotales,
  calcTotal,
} from "../../../Global";
import { setPreciosYDescuento } from "../../../helpers/calcularVenta";
import { debounce, isNumber } from "lodash";
import { BotonCancelarLetras } from "../../Plantillas/Botones";
import {
  FindPrecioEspecial,
  FindPrecioFamiliar,
  FindPrecioMenor,
  FindPrecioPorMayor,
  GetPrecioCosto,
  getPrecioPorMayor,
} from "../../Preventas/PreciosPreventa";
import { notificarError } from "../../Almacenes/AlmacenNotify";
import GananciaIndicator from "../GananciaIndicator";


const ProductoStock = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const product = useMemo(() => props.product, [props.product]);
  const buttonRef = useRef(null);

  const onChooseProduct = (e, p) => {    
    e.preventDefault();
    if (buttonRef.current && buttonRef.current.contains(e.target)) return;

    props.onChooseProduct(p)
  };

  if (!isOpen) {
    return <tr
        onClick={(e) => onChooseProduct(e, product)}
        className="prodPreventaTr"
        style={{ cursor: "pointer" }}
        title={`${product.descripcion}-${product.unidadMedida}`}
      >
        <td>
          <button ref={buttonRef} type="button" style={{ width: '40px' }} className={'btn btn-light btn-sm mr-2'} onClick={() => setIsOpen(!isOpen)}>
            <i className="fas fa-chevron-right"></i>
          </button>

          {product.descripcion} {product.unidadMedida}
        </td>
        <td
          style={{
            backgroundColor: "rgb(53,96,90,0.18)",
            borderTop: "1px solid white",
          }}
        >
          <div style={{ textAlign: "end" }}>
            {decimalAdjust("floor", product.stock, -2)}
          </div>
        </td>
        <td bgcolor={"#01a59c"} style={{ padding: "5px 0" }}>
          <label style={{ color: "white" }}>{product.moneda}</label>

          <div className="">
            <div style={{ color: "white", textAlign: "end" }}>
              {decimalAdjust("floor", product.precio, -2)}
            </div>
          </div>
        </td>
      </tr>
  }

  return <>
    <tr
      onClick={(e) => onChooseProduct(e, product)}
      className="prodPreventaTr"
      style={{ cursor: "pointer" }}
      title={`${product.descripcion}-${product.unidadMedida}`}
    >
      <td>
        <button ref={buttonRef} type="button" style={{ width: '40px' }} className={'btn btn-light btn-sm mr-2'} onClick={() => setIsOpen(!isOpen)}>
          <i className="fas fa-chevron-down"></i>
        </button>

        {product.descripcion} {product.unidadMedida}
      </td>
      <td style={{
        backgroundColor: "rgb(53,96,90,0.18)",
        borderTop: "1px solid white",
        textAlign: "end"
      }}>{decimalAdjust("floor", product.stock, -2)}</td>
      <td bgcolor={"#01a59c"} style={{ padding: "5px 0" }} rowSpan={product.stocks.length + 1}>
        <label style={{ color: "white" }}>{product.moneda}</label>

        <div className="">
          <div style={{ color: "white", textAlign: "end" }}>
            {decimalAdjust("floor", product.precio, -2)}
          </div>
        </div>
      </td>
    </tr>
    {
      product.stocks.map((stock) => {
        return (
          <tr
            onClick={(e) => onChooseProduct(e, product)}
            style={{ cursor: "pointer" }}
            key={`stock-${product.IdProducto}-${product.IdPresentacion}-${stock.IdStock}`}            
            title={`${product.descripcion}-${product.unidadMedida}`}
            id={`stock-${product.IdProducto}-${product.IdPresentacion}-${stock.IdStock}`}
          >
            <td style={{ paddingLeft: '30px' }}>{stock.Nombre}</td>
            <td style={{
                backgroundColor: "rgb(53,96,90,0.18)",
                borderTop: "1px solid white",
                textAlign: "end"
            }}>{decimalAdjust("floor", stock.stock, -2)}</td>
          </tr>
        );
      })
    }
  </>
}

const ListaProductosStock = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);

  useEffect(() => {
    fetchProductos("");
  }, []);

  const fetchProductos = async (q) => {
    const req = await fetch(
      `/api/nota-pedidos/productos?search=${q}`
    );
    const data = await req.json();

    const products = data.productos.map((d) => {
      const itemCalculado = setPreciosYDescuento(d);
      return {
        ...d,
        Precio: itemCalculado.PrecioVenta,
        PrecioReferencial: itemCalculado.PrecioVenta,
        Gratuito: parseInt(d.Gratuito),
      };
    });

    setProducts(products);
    setStockTypes(data.tiposStock);
  };

  const debounceSearch = useCallback(debounce(fetchProductos, 500), []);

  const handleSearch = (e) => {
    const value = e.target.value.trim();

    setSearchTerm(value);
    debounceSearch(value);
  };

  const onChooseProduct = (p) => props.onChooseProduct(p);

  return (
    <>
      <div className="PreVBuscarProducto row input-producto mb-2">
        <div className="w-100 col-12 mt-1">
          <input
            type="text"
            placeholder="Buscar Producto"
            className="mr-2 w-100 DocProductoPreventa borde-15"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="lista_productos table-responsive">
        <div
          className="lista_productos__item"
          style={{ maxHeight: "51vh", overflowY: "scroll" }}
        >
          <table
            hidden={props.load}
            className="table table-hover"
            style={{ maxHeight: "50vh" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    borderTopLeftRadius: "10px",
                    backgroundColor: "#034b2f",
                    color: "white",
                    position: 'sticky',
                    top: 0 
                  }}
                >
                  <span>Producto / </span>
                  <span>Presentación</span>
                </th>
                <th
                  style={{
                    borderTopRightRadius: "0px",
                    backgroundColor: "#034b2f",
                    color: "white",
                    position: 'sticky',
                    top: 0 
                  }}
                >
                  Stock
                </th>
                <th style={{ backgroundColor: "#034b2f", color: "white", position: 'sticky', top: 0 }}>
                  Precio
                </th>
              </tr>
            </thead>
            <tbody>
              { products.map((p) => <ProductoStock product={p} key={`prod-${p.IdProducto}-${p.IdPresentacion}`} onChooseProduct={onChooseProduct}/>) }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};


const ItemSeleccionado = (props) => {
  const escalaGanancia = useMemo(() => props.escalaGanancia, [props.escalaGanancia]);
  const precioMin = useMemo(() => {
    const _min = props.item.PrecioCosto * (1 + escalaGanancia.Rojo / 100);
    return decimalAdjust('round', _min, -2);
  }, [props.item.Precio]);

  useEffect(() => {
    onUpdatePrice(props.item.PrecioVenta)
  }, []);

  const onUpdatePrice = (value) => {
    if (props.conf.canUpdatePrice && value < precioMin) {
      value = precioMin;
    }

    props.onUpdatePrice(props.index, value);
  };

  return <>
    <tr
      id={`producto-title-${props.item.IdPresentacion}`}
      key={`det-title-${props.item.IdPresentacion}`}
      className="my-2"
    >
      <td colSpan={3} style={{ fontSize: "14px" }}>
        {`${props.item.descripcion} ${props.item.unidadMedida}`}
      </td>
      <td>
        <button type="button" className="btn btn-danger" onClick={() => props.onRemoveItem(props.index)}>X</button>
      </td>
    </tr>
    <tr
      id={`producto-${props.item.IdPresentacion}`}
      key={`det-${props.item.IdPresentacion}`}
      className="my-2"
    >
      <td style={{ fontSize: "14px", width: '40%' }}>
        <input type="number" className="form-control form-control-sm" value={props.item.Cantidad} onChange={(e) => props.onUpdateQuantity(props.index, e.target.value)}/>
      </td>
      <td style={{ fontSize: "14px", width: '30%' }}>
        { props.conf.canUpdatePrice ? 
          <input type="number" className="form-control form-control-sm" value={props.item.Precio} onChange={(e) => onUpdatePrice(e.target.value)}/>
          : <span className="mt-2">{decimalAdjust("round", props.item.Precio, -2)}</span>}
      </td>
      <td colSpan={2} style={{ fontSize: "14px", width: '30%' }}>
        <span className="mt-2">{decimalAdjust("round", props.item.Total, -2)}</span>
        <GananciaIndicator escalaGanancia={escalaGanancia} items={[props.item]}/>
      </td>
    </tr>
  </>
}


export default function (props) {
  const [items, setItems] = useState([]);
  const [totales, setTotales] = useState({});
  const [currentItem, setCurrentItem] = useState(undefined);
  const [affectsIgv, setAffectsIgv] = useState([]);
  const [canApplyDiscount, setCanApplyDiscount] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [conf, setConf] = useState({
    canUpdatePrice: false
  });
  const [escalaGanancia, setEscalaGanancia] = useState([]);

  useEffect(() => {
    checkPermissionsApplyDiscount();
    fetchConfiguration();
    fetchEscala();
  }, [])

  useEffect(() => {
    const _calculated = calcularTotales(items);

    setTotales({
      Gravado: _calculated.gravados,
      Inafecto: _calculated.inafectos,
      Exonerado: _calculated.exonerados,
      IGVPreventa: _calculated.gravados * 0.18,
      Gratuitas: _calculated.gratuitos,
      totalMonto: _calculated.total,
      ICBPERPreventa: _calculated.icbper,
      redondeo: _calculated.redondeo,
    });

    setCanContinue(!!items.length)
    props.onChangeItems([...items]);
  }, [items]);

  const onChooseProduct = (product) => {
    let currentItems = [...items];
    const productPosition = currentItems.indexOfObject(
      product,
      "IdPresentacion"
    );

    let descuentoPersonal = 0;

    if (props.client && "DescuentoPersonal" in props.client) {
      descuentoPersonal = props.client["DescuentoPersonal"];
    }

    if (productPosition !== -1) {
      currentItems[productPosition].Cantidad++;
      FindPrecioPorMayor([], currentItems[productPosition]);
      currentItems[productPosition].Total =
        calcTotal(currentItems[productPosition]) -
        currentItems[productPosition].Descuento;
    } else {
      FindPrecioPorMayor([], product);
      product.Descuento = product.Descuento + descuentoPersonal;

      currentItems.push({
        ...product,
        Cantidad: 1,
        PrecioVenta: product.PrecioVenta,
        Total: product.PrecioVenta,
        PrecioEspecial: FindPrecioEspecial([], product),
        PrecioFamiliar: FindPrecioFamiliar([], product),
        PrecioCosto: GetPrecioCosto([], product),
        PrecioMenor: FindPrecioMenor([], product),
        precioMayor: getPrecioPorMayor([], product),
        checked: isNumber(product.checked) ? product.checked : 0,
        oldPrecios: [product.PrecioVenta],
        initialAfectGrat: product.IdAfectacionIgv,
        oldCants: [1],
        NombreProducto: product.descripcion,
        Descuento: 0
      });
    }

    setItems([...currentItems]);
  };

  const fetchConfiguration = async () => {
    try {
        const response = await fetch('/api/pre-invoice/config');
        const data = await response.json();
        setConf(data)
    } catch (e) {
        setConf({ canUpdatePrice: false })
    }
  }

  const fetchEscala = async () => {
    try {
      const req = await fetch(`/api/nota-pedidos/escala`);

      if (!req.ok) {
        throw new Error('Error al obtener la escala');
      }

      setEscalaGanancia(await req.json());
    } catch (e) {
      notificarError('Error al obtener configuración para precios. Intente nuevamente');
      props.onCancel();
    }
  }

  const onUpdateQuantity = (index, value) => {
    const currentItems = [...items];

    currentItems[index].Cantidad = Number(value);
    currentItems[index].Total = calcTotal(currentItems[index]) - currentItems[index].Descuento
    setItems([...currentItems]);
  }

  const onUpdatePrice = (index, value) => {
    const currentItems = [...items];
    const price = Number(value);

    currentItems[index].Precio = price;
    currentItems[index].PrecioVenta = price;

    currentItems[index].Total = calcTotal(currentItems[index]) - currentItems[index].Descuento
    setItems([...currentItems]);
  }

  const checkPermissionsApplyDiscount = async () => {
      try {
          const responseVal = await fetch('/api/usuarios/descuentoItem/validar');
          const data = await responseVal.json();
          setCanApplyDiscount(true)
      } catch (e) {
          setCanApplyDiscount(false)
      }
  }

  const onRemoveItem = (index) => {
    const currentItems = [...items];

    currentItems.splice(index, 1);
    setItems([...currentItems]);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-7">
          <ListaProductosStock
            onChooseProduct={(p) => onChooseProduct(p)}
            StockDisponible={console.log}
            AddProductoInList={console.log}
          />
        </div>
        <div className="col-12 col-lg-5">
          <div
            id="tableDetProdPreventa"
            className="preventa__carrito mt-2"
            style={{ maxHeight: "53vh", overflowY: "scroll" }}
          >
            <table className="table table-hover" style={{ overflow: "hidden" }}>
              <thead>
                <tr>
                  <th style={{ color: "white", background: "#01A59C", width: '40%' }}>
                    CANTIDAD
                  </th>
                  <th style={{ color: "white", background: "#01A59C", width: '40%' }}>
                    PRECIO
                  </th>
                  <th style={{ color: "white", background: "#01A59C", width: '40%' }}>TOTAL</th>
                  <th style={{ background: "#01A59C", width: '10%' }}></th>
                </tr>
              </thead>
              <tbody id="IdDetProductoCarrito">
                {items.map((item, index) => 
                  <ItemSeleccionado index={index} item={item} conf={conf} onUpdateQuantity={onUpdateQuantity} onUpdatePrice={onUpdatePrice} onRemoveItem={onRemoveItem} escalaGanancia={escalaGanancia}/>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {
        decimalAdjust('round', totales.totalMonto, -1) > 0 &&
        <div className="row bg-verde-mfp">
          { parseFloat(totales.Gravado) > 0 && <div className="col my-2">
            <strong>Gravado:</strong> {Number(totales.Gravado).toFixed(2)}
          </div> }

          { parseFloat(totales.Inafecto) > 0 && <div className="col my-2">
            <strong>Gravado:</strong> {decimalAdjust('floor', totales.Inafecto, -2)}
          </div> }

          { parseFloat(totales.Exonerado) > 0 && <div className="col my-2">
            <strong>Exonerado:</strong>{decimalAdjust('floor', totales.Exonerado, -2)}
          </div> }

          { parseFloat(totales.Gratuitas) > 0 && <div className="col my-2">
            <strong>Gratuitas:</strong>{decimalAdjust('floor', totales.Gratuitas, -2)}
          </div> }

          { parseFloat(totales.IGVPreventa) > 0 && <div className="col my-2">
            <strong>IGV:</strong> {decimalAdjust('floor', totales.IGVPreventa, -2)}
          </div> }
          
          <div className="col-2 my-2">
            <strong>Total:</strong> {decimalAdjust('round', totales.totalMonto, -1)}
          </div>
        </div>
      }

      {canContinue && <button type="button" className="mt-2 btn btn-outline-primary btn-verde-mfp btn-block" onClick={() => props.nextStep()}>Continuar</button>}

      <BotonCancelarLetras
          onClick={() => props.onCancel()}
          className="mb-2 mt-2 btn-block"
      />
    </>
  );
}
