import React from 'react';
import PageLoading from '../PageLoading';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import Modal from '../clientes/ClienteModal';
import OrdenCompraEditar from './OrdenCompraEditar';
import {
    BotonEditar,
    BotonDelete,
    BotonAdd,
    BotonLetras,
    BotonPDFpequeno,
    BotonAddOC
} from '../Plantillas/Botones';
import swal from "sweetalert";
import {pdf} from "@react-pdf/renderer";
import ModalPDF from './Reportes/modalPDF';
import VisorPDF from './Reportes/visorPDF'
import ReporteOC from './Reportes/RptOC'
import "../Plantillas/Estilos.css"
import {notificarError} from "../Almacenes/AlmacenNotify";

class OrdenCompraLista extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            buscar: "",
            modalIsOpen: false,
            modalIsOpenPDF: false,
            accion: '',
            nroOC_Seleccionado: '',

            loading: true,
            error: null,
            data: [],
            itemordencompra: {
                idordencompra: null,
                nroserie: '',
            },


            itemproveedor: {
                nomproveedor: '',
                idproveedor: null,
            },

            caboc: {},
            detoc: [],
            extImg: 'png',
        }
    }

    componentDidMount() {
        this.listaOrdenesCompraPorSucursal();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    handleClickNuevo = async (e) => {
        this.setState({
            modalIsOpen: true,
            swPrincipal: 1,
            accion: 'Nuevo'
        })

    }

    cambiarAccion = (a, idOrdenCompra = null) => {
        this.setState({
            accion: a,
            itemordencompra: idOrdenCompra ? {
                ...this.state.itemordencompra,
                idordencompra: idOrdenCompra
            } : {...this.state.itemordencompra}
        })
    }

    handleClickEditar = async (reg) => {
        ('ingreso')
        this.setState({
            modalIsOpen: true,
            swPrincipal: 1,
            accion: 'Editar',
            nroOC_Seleccionado: reg.Numero,

            itemordencompra: {
                idordencompra: reg.IdOrdenCompra,
                nroserie: reg.Numero,
            },
        })
    }

    crearOrdenCompra = async () => {
        try {
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdOrdenCompra: '1',
                    FechaRegistro: null,
                    FechaEmision: null,
                    FechaEntrega: null,
                    Serie: null,
                    Numero: null,
                    IdMoneda: null,
                    TipoCambio: null,
                    IdUsuario: null,
                    IdSucursal: '1',
                    Estado: 'Por Procesar',
                    IdAlmacen: null,
                    Glosa: null,
                    IdProveedor: null,
                })
            };
            //
            const response = await fetch(`/api/ordenescompra/add/`, config);
            const rpta = await response.json();
            this.setState({
                itemordencompra: {
                    idordencompra: rpta.respuesta
                }
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    listaOrdenesCompraPorSucursal = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/ordenescompra/list/suc`);
            const data = await response.json();
            this.setState({
                loading: false,
                data: data,
                extImg: data.extImg
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    handleInput = (e) => {
        this.setState({
            buscar: e.target.value,
            // [e.target.name]: e.target.value,
        });
    }

    handleClickEliminarOC = (reg) => {
        swal({
            title: 'Cuidado...! ',
            text: `Desea Eliminar la Orden de Compra Nro: ${reg.Numero}?`,
            icon: 'error',
            buttons: [
                'No',
                'Si'
            ],
        }).then((isConfirm) => {
            if (isConfirm) {
                this.eliminarOC(reg);

            } else {
            }
        })
    }

    eliminarOC = async (reg) => {
        var id = reg.IdOrdenCompra

        try {
            if (id) {
                const response = await fetch(`/api/ordenescompra/delete/${id}`, {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
                const rpta = await response.json();

                if (rpta) {
                    swal({
                        title: 'Atención...! ',
                        text: `Orden de Compra eliminada correctamente...`,
                        icon: 'success'
                    })
                    this.listaOrdenesCompraPorSucursal();
                }
                //
                console.log(rpta);
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }

    }

    handleClickProcesarOC = (reg) => {
        swal({
            title: 'Atención...! ',
            text: `Está seguro de Procesar la OC?`,
            icon: 'info',
            buttons: [
                'No',
                'Si'
            ],
        }).then((isConfirm) => {
            if (isConfirm) {
                this.ProcesarOC(reg)
                swal({
                    title: 'Orde de Compra Procesada'
                })
            } else {
                swal({
                    title: 'No hacer nada'
                })
            }
        })
    }

    ProcesarOC = (reg) => {

    }

    handleOpenModal = (e) => {
        console.log("Nombre Proveedor: ", e.target.value);
        this.setState({
            modalIsOpen: true,
            accion: 'Nuevo',
            itemproveedor: {
                ...this.state.itemproveedor,
                nomproveedor: e.target.value,
                idproveedor: e.target.id,
            }
        })
    }

    handleCloseModal = (e) => {
        //
        this.setState({
            modalIsOpen: false,
            itemordencompra: {
                idordencompra: null,
                nroserie: '',
            }
        })
    }

    handleCloseModalPDF = (e) => {
        //
        this.setState({
            modalIsOpenPDF: false
        })
    }

    handleOpenModalPDF = async (reg) => {
        await this.cabeceraOC(reg.IdOrdenCompra)
        await this.detalleOC(reg.IdOrdenCompra)

        this.setState({
            modalIsOpenPDF: true,
            nroOC_Seleccionado: reg.Numero,
            itemordencompra: {
                idordencompra: reg.IdOrdenCompra,
                nroserie: reg.Numero,

            },
        })
    }

    acciones(f) {
        return (
            <div align="center">
                <div className='mr-1'>
                    <button id={f.value} value={f.original.RazonSocial} onClick={this.handleOpenModal}
                            className="btn btn-outline-primary fas fa-eye"
                            title="Ver"/>

                </div>
                <div className='mr-1'>
                    <button id={f.value} value={f.original.RazonSocial} onClick={this.handleOpenModal}
                            className="btn btn-outline-danger fas fa-eye"
                            title="Anular"
                    />
                </div>
            </div>
        )
    }

    cabeceraOC = async (idoc) => {
        try {
            // //
            const response = await fetch(`/api/ordenescompra/item/${idoc}`);
            const regoc = await response.json();
            this.setState({
                caboc: regoc[0][0]
            })
            console.log("cabecera", this.state.caboc)

        } catch (error) {
            console.error(error);
        }
    }

    detalleOC = async (idoc) => {
        try {
            // //
            const response = await fetch(`/api/ordenescompra/detalleoc/${idoc}`);
            const data = await response.json();
            console.log("detalle", data[1])
            this.setState({
                detoc: data[1]
            })

        } catch (error) {
            console.error(error);
            return null
        }
    }

    async handleEnviarCorreo(data) {
        console.log(data)
        if (data.CorreoProveedor) {
            let rpta = await this.sendemail(data);
            //
            if (rpta) {
                swal({
                    title: 'El correo fue enviado con éxito...! ',
                    text: `Cuenta destino: ${data.CorreoProveedor}`,
                    icon: 'success'
                })
            } else {
                swal({
                    title: 'Correo no enviado, intente luego... ',
                    text: `Cuenta destino: ${data.CorreoProveedor}`,
                    icon: 'warning'
                })
            }
        } else {
            swal({
                title: 'Atención...! ',
                text: `No cuenta con una cuenta de correo`,
                icon: 'info'
            })
        }
    }

    async crearPDFOC() {
        return <ReporteOC extImg={this.state.extImg} itemordencompra={this.state.itemordencompra}
                          caboc={this.state.caboc}
                          detoc={this.state.detoc}/>
    }

    async sendemail(data) {
        try {
            console.log("Data", data);
            //;
            await this.cabeceraOC(data.IdOrdenCompra)
            await this.detalleOC(data.IdOrdenCompra)

            //crear el pdf
            var doc = await this.crearPDFOC()
            const blob = await pdf(doc).toBlob();
            // var blob = doc.output('blob');

            // enviar blob
            let form = new FormData();
            form.append('pdf', blob, 'mi.pdf');
            form.append('idOrden', data.IdOrdenCompra);
            form.append('email', data.CorreoProveedor);
            let config = {
                method: 'POST',
                body: form
            }

            const res = await fetch(`/api/ordenescompra/send`, config);

            if (res.ok) {
                console.log("correo enviado safisfactoriamente...")
                return true;
            } else {
                notificarError("No se pudo enviar el correo")
                return false;
            }

        } catch (error) {
            this.setState({error, isLoading: false});
            return false;
        }
    }

    render() {

        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }

        if (this.state.loading === true && this.state.data[1] === undefined) {
            return <PageLoading/>;
        }

        if (this.state.data[1] && (this.state.data[1].length === 0 || this.state.data[1] === undefined)) {
            return (
                <div className="card text-center">
                    <div className="body p-4">
                        <p className="card-text">¡Aún no has creado tu primera orden de compra...!</p>
                        <BotonLetras type="button" onClick={this.handleClickNuevo}>+ Nueva Orden de Compra</BotonLetras>
                    </div>
                    <div className="card-footer text-muted">
                        <p className="card-text">Notas de Ayuda...</p>
                    </div>
                    <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                           title="NUEVA ORDEN DE COMPRA">
                        <OrdenCompraEditar
                            onClose={this.handleCloseModal}
                            listaOrdenesCompraPorSucursal={this.listaOrdenesCompraPorSucursal}
                            accion={this.state.accion}
                            itemordencompra={this.state.itemordencompra}
                        />
                    </Modal>
                </div>
            );
        } else {
            return (
                <div className="container mt-5">
                    <div align="right" className="mb-3">
                        {/* <Link className="btn btn-primary " to= "/nuevocliente">+ Nuevo Cliente</Link> */}
                        <BotonAddOC
                            title="Nueva Orden de Compra"
                            type="button"
                            onClick={this.handleClickNuevo}>
                        </BotonAddOC>
                    </div>
                    <div>
                        <ReactTable
                            data={this.state.data.ordenesCompra[1]}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Acciones",
                                            accessor: "IdOrdenCompra",
                                            // headerClassName:'card-header bg-dark text-white',
                                            width: 400,
                                            filterable: false,
                                            Cell: (f) => {
                                                if (!f) {
                                                    return null
                                                }
                                                return (
                                                    <div align='rigth'>
                                                        <BotonEditar
                                                            className="mr-2"
                                                            id={f.value}
                                                            value={f.original.RazonSocial}
                                                            hidden={f.original.Estado === 'Atendida' || f.original.Estado === 'Parcial' || f.original.Estado === 'Anulada'}
                                                            onClick={
                                                                () => {
                                                                    this.handleClickEditar(f.original)
                                                                }
                                                            }
                                                            title="Editar"
                                                        />

                                                        <BotonPDFpequeno
                                                            className="mr-2"
                                                            onClick={
                                                                () => {
                                                                    this.handleOpenModalPDF(f.original)
                                                                }
                                                            }
                                                            title="Descargar PDF"
                                                        />
                                                        <button
                                                            className="btn-correo-mfp mr-2"
                                                            title="Enviar Correo"
                                                            // hidden={f.original.EstadoCE === "Pendiente"? "hidden" : ""}
                                                            onClick={
                                                                () => {
                                                                    this.handleEnviarCorreo(f.original)
                                                                }
                                                            }
                                                        >
                                                            Enviar Correo
                                                        </button>

                                                        <BotonDelete
                                                            className="mr-2"
                                                            id={f.value}
                                                            value={f.original.RazonSocial}
                                                            hidden={f.original.Estado === 'Atendida' || f.original.Estado === 'Parcial' || f.original.Estado === 'Anulada'}
                                                            onClick={
                                                                () => {
                                                                    this.handleClickEliminarOC(f.original)
                                                                }
                                                            }
                                                            title="Anular"
                                                        />

                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            Header: "Estado",
                                            id: "Estado",
                                            accessor: "Estado",
                                            // headerClassName:'card-header bg-dark text-white',
                                            width: '125',
                                            Cell: row => <div style={{textAlign: "center"}}>{row.value}</div>,

                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                            filterMethod: (filter, row) => {
                                                if (filter.value === 'Todos') {
                                                    return true;
                                                }
                                                if (filter.value === "Sin Generar") {
                                                    return row[filter.id] === 'Sin Procesar';
                                                }
                                                if (filter.value === "Generada") {
                                                    return row[filter.id] === 'Generada';
                                                }
                                                if (filter.value === "Atendida") {
                                                    return row[filter.id] === 'Atendida';
                                                }
                                                if (filter.value === "Parcial") {
                                                    return row[filter.id] === 'Parcial';
                                                }
                                                if (filter.value === "Anulada") {
                                                    return row[filter.id] === 'Anulada';
                                                }
                                            },
                                            Filter: ({filter, onChange}) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{width: "100%"}}
                                                    value={filter ? filter.value : "Todos"}

                                                >
                                                    <option value="Todos">Todos</option>
                                                    <option value="Sin Generar">Sin Generar</option>
                                                    <option value="Generada">Generada</option>
                                                    <option value="Atendida">Atendida</option>
                                                    <option value="Parcial">Parcial</option>
                                                    <option value="Anulada">Anulada</option>
                                                </select>

                                            // filterAll: true
                                        },

                                        {
                                            Header: "Código",
                                            id: "Numero",
                                            // accessor: "NombreCliente",
                                            width: '130',
                                            accessor: d => d.Numero,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Numero"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                        },
                                        {
                                            Header: "RUC Proveedor",
                                            id: "Ruc",
                                            // accessor: "NroTipoDocumento",
                                            width: '130',
                                            accessor: d => d.RucProveedor,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Ruc"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "center"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Proveedor",
                                            id: "NombreProveedor",
                                            // accessor: "NombreCliente",
                                            width: '250',
                                            accessor: d => d.NombreProveedor,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["NombreProveedor"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                        },

                                        // {
                                        //     Header: "Serie",
                                        //     id:"Serie",
                                        //     // accessor: "NombreCliente",
                                        //     width:'90',
                                        //     accessor: d => d.Serie,
                                        //     filterMethod: (filter, rows) =>
                                        //         matchSorter(rows, filter.value, { keys: ["Serie"] }),
                                        //     filterAll: true,
                                        //     getProps: (state, rowInfo, column) => {
                                        //         return {
                                        //             style: {
                                        //                 background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                        //             },
                                        //         };
                                        //     },
                                        // },


                                        {
                                            Header: "Fecha Emisión",
                                            id: "FechaEmision",
                                            // accessor: "NombreCliente",
                                            width: '120',
                                            accessor: d => d.FechaEmision,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "center"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Fecha Entrega",
                                            id: "FechaEntrega",
                                            // accessor: "NombreCliente",
                                            width: '120',
                                            accessor: d => d.FechaEntrega,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["FechaEntrega"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "center"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "SubTotal",
                                            id: "SubTotal",
                                            // accessor: "NombreCliente",
                                            width: '120',
                                            align: 'center',
                                            accessor: d => d.SubTotal,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["SubTotal"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "right"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "IGV",
                                            id: "IGV",
                                            // accessor: "NombreCliente",
                                            width: '100',
                                            align: 'center',
                                            accessor: d => d.IGV,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["IGV"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "right"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Total",
                                            id: "Total",
                                            // accessor: "NombreCliente",
                                            // width: '120',
                                            align: 'center',
                                            accessor: d => d.Total,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Total"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "right"
                                                    },
                                                };
                                            },
                                        },


                                    ]
                                },
                            ]}

                            defaultPageSize={10}
                            // showPagination={false}
                            //pageSize={10}
                            className="-striped -highlight"
                            // Text de paginación
                            showPageJump={false}
                            previousText='Anterior'
                            nextText='Siguiente'
                            loadingText='Cargando...'
                            noDataText='No se encontraron registros'
                            pageText='Página'
                            ofText='de'
                            rowsText='filas'
                            // width='100%'
                            style={{
                                width: "100%",
                                fontSize: "15px"
                            }}
                            pageSizeOptions={[5, 10, 20, 25, 50, 100]}

                        />
                        {
                            this.state.modalIsOpen && this.state.accion === 'Editar' ?
                                <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal} width='900px'
                                       title={`MODIFICAR: ${this.state.nroOC_Seleccionado}`}>
                                    <OrdenCompraEditar
                                        idproveedor={this.state.itemproveedor.idproveedor}
                                        onClose={this.handleCloseModal}
                                        cambiarAccion={this.cambiarAccion}
                                        accion={this.state.accion}
                                        listaOrdenesCompraPorSucursal={this.listaOrdenesCompraPorSucursal}
                                        itemordencompra={this.state.itemordencompra}
                                    />
                                </Modal>
                                :
                                <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal} width='900px'
                                       title="NUEVA ORDEN DE COMPRA">
                                    <OrdenCompraEditar
                                        onClose={this.handleCloseModal}
                                        cambiarAccion={this.cambiarAccion}
                                        listaOrdenesCompraPorSucursal={this.listaOrdenesCompraPorSucursal}
                                        accion={this.state.accion}
                                        itemordencompra={this.state.itemordencompra}
                                    />
                                </Modal>

                        }
                        {
                            this.state.modalIsOpenPDF &&
                            <ModalPDF isOpen={this.state.modalIsOpenPDF} onClose={this.handleCloseModalPDF}
                                      width='900px'
                                      title="Visor PDF">
                                <VisorPDF fileName={`${this.state.nroOC_Seleccionado}.pdf`}>
                                    <ReporteOC itemordencompra={this.state.itemordencompra} caboc={this.state.caboc}
                                               extImg={this.state.extImg}
                                               detoc={this.state.detoc}/>
                                </VisorPDF>
                            </ModalPDF>
                        }
                    </div>


                </div>
            );
        }

    }
}

export default OrdenCompraLista;
