import React, {useEffect, useState} from 'react';
import Menu from './componentes/Menu';
import Navbar from './componentes/Navbar';
import "./componentes/styles/Menu.css";
import useFetch from './helpers/useFetch';
import convertMenuListToMenuTree from './helpers/convertMenuListToMenuTree';
import {comprobarPermisos} from "contatrib-utils"

function Layout({children, sesion}) {

    const menuList = useFetch("/api/menus");
    const [menuTree, setMenuTree] = useState([]);

    // Al montarse el componente convertir *menuList* en un objecto jerárquico
    useEffect(() => {
        if (menuList === null) return
        const menuListFiltered = menuList.filter(({IdMenu}) => comprobarPermisos([{IdMenu}], sesion.RolesPermisos, sesion.IdEmpresa));
        const menuTree = convertMenuListToMenuTree(menuListFiltered);
        setMenuTree(menuTree);
    }, [menuList])

    return (
        <>
            <Navbar {...{menuList}} />
            <div className="d-flex justify-content-start h-100">
                <div id="Menu" className="pt-3 pb-3 pl-3 pr-1 align-self-stretch vh-height-overflow collapse">
                    <div className="menuCompleto">
                        <div className="menuDesplegable">
                            <Menu menu={menuTree}/>
                        </div>
                        <div className="menuFijo align-items-center">
                            <div className="menu__nombre p-0 align-items-center">

                                <h5><i className="fas fa-book"> </i> <a
                                    href="https://mifacturaperu.atlassian.net/wiki/spaces/MT/overview">Manual</a></h5>
                            </div>
                            <div className="menu__nombre p-0 align-items-center">

                                <h5><i className="fas fa-headset"> </i> <a
                                    href="https://mifacturaperu.atlassian.net/wiki/spaces/MT/overview">Soporte</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 vh-height-overflow ventas-fpay-section">
                    {children}
                </div>
            </div>
        </>
    );
}

export default Layout;
