import React, { useEffect, useState } from "react";
import "../Plantillas/Estilos.css";
import Modal from "../clientes/ClienteModal";
import { REGISTRAR_ACTION, EDITAR_ACTION, UNDEFINED_ACTION } from "./constants";
import CompraExternaForm from "./CompraExternaForm";
import moment from "moment";
import { BotonDelete, BotonEditar } from '../Plantillas/Botones';
import matchSorter from 'match-sorter';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { notificarError, notificarMsg } from "../Almacenes/AlmacenNotify";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const CALENDAR_STYLE = {
  border: "0.5px solid #acacac",
  borderRadius: "25px",
  outline: "none",
  flexBasis: "50%",
  alignSelf: "center",
};

export default function ComprasExternasLista(props) {
  const [action, setAction] = useState(UNDEFINED_ACTION);
  const [modalTitle, setModalTitle] = useState("");
  const [currentId, setCurrentId] = useState(undefined);
  const [desde, setDesde] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [hasta, setHasta] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [comprasExternas, setComprasExternas] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [sucursalInfo, setSucursalInfo] = useState({
    Nombre: "Sucursal del usuario en sesión"
  });
  const [currentSucursalId, setCurrentSucursalId] = useState(undefined);
  const [currentAlmacenId, setCurrentAlmacenId] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [extImg, setExtImg] = useState('png');
  const [canChangeSucursal, setCanChangeSucursal] = useState(false);
  const [excelSheet, setExcelSheet] = useState([])

  useEffect(() => {
    fetchUserData();
  }, [])

  useEffect(() => {
    if (canChangeSucursal) {
      fetchSucursales();
    }
  }, [canChangeSucursal]);

  useEffect(() => {
    if (!currentSucursalId) {
      return;
    }

    fetchAlmacen(currentAlmacenId);
  }, [currentSucursalId])

  useEffect(() => {
    if (!currentAlmacenId) {
      return;
    }
    fetchComprasExternas();
  }, [currentAlmacenId, desde, hasta]);

  useEffect(() => {
    setExcelSheet([
      {
        columns: [
          {
            title: "Código",
            width: {wpx: 100}
          },
          {
            title: "Fue validado",
            width: {wpx: 100}
          },
          {
            title: "Fecha de movimiento",
            width: {wpx: 100}
          },
          {
            title: "Número Doc.",
            width: {wpx: 100}
          },
          {
            title: "Tipo de Doc.",
            width: {wpx: 100}
          },
          {
            title: "Proveedor",
            width: {wpx: 100}
          },
          {
            title: "Total",
            width: {wpx: 100}
          },
          {
            title: "Descripción",
            width: {wpx: 300}
          }
        ],

        data: comprasExternas.map(compra => {
          return [
            {
              value: compra.Numero || "",
              style: {
                  fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
              }
            },
            {
              value: compra.FueValidado ? "Sí" : "No",
              style: {
                  fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
              }
            },
            {
              value: compra.FechaMovimiento || "",
              style: {
                  fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
              }
            },
            {
              value: compra.NroDocumentoSunat || "",
              style: {
                  fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
              }
            },
            {
              value: compra.DescTipoDocSunat || "",
              style: {
                  fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
              }
            },
            {
              value: compra.RazonSocial || "",
              style: {
                  fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
              }
            },
            {
              value: compra.Total,
              style: {
                  fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
              }
            },
            {
              value: compra.Glosa || "",
              style: {
                  fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
              }
            }
          ];
        })
      }
  ])
  }, [comprasExternas])

  const fetchUserData = async () => {
    try {
      const response = await fetch(`/api/configFactElectronica/User`);
      const data = await response.json();

      const {objuser: {sucursal, ...user}, extImg } = data;
      
      setUser({...user});
      setSucursalInfo(sucursal);
      setExtImg(extImg);
      setCurrentSucursalId(user.IdSucursal);
      fetchRoleValidation();
    } catch (e) {
      console.error(e)
    }
  }

  const fetchRoleValidation = async () => {
    const response = await fetch(`/api/configFactElectronica/ChangeSucursal`);
    setCanChangeSucursal(response.ok);
  }

  const fetchSucursales = async () => {
    const response = await fetch("/api/configFactElectronica/SucursalEmpresa");
    const sucursales = await response.json();
    setSucursales([...sucursales]);
  }

  const fetchAlmacen = async () => {
    const response = await fetch(`/api/almacenes/AlmacenBySucursal?idSucursal=${currentSucursalId}`);
    const {results, } = await response.json();
    const { IdAlmacen } = results[0];
    setCurrentAlmacenId(IdAlmacen);
  }

  const fetchComprasExternas = async () => {
    setComprasExternas([]);

    try {
      const response = await fetch(
        `/api/comprasexternas/items?almacen=${currentAlmacenId}&desde=${desde}&hasta=${hasta}`
      );
      const { items } = await response.json();

      setComprasExternas(items.map(x => {
        return {
          ...x,
          canDelete: x.FechaRegistro === moment(new Date()).format("DD-MM-YYYY")
        }
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const onCreate = () => {
    setCurrentId(undefined);
    setAction(REGISTRAR_ACTION);
    setModalTitle("Nueva compra externa");
  };

  const onDelete = async (id) => {
    try {
      const response = await fetch(`/api/comprasexternas/items/${id}`, {
        method: "DELETE"
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      notificarMsg("La compra se ha eliminado correctamente.");

      fetchComprasExternas();
    } catch (e) {
      notificarError("Ha ocurrido un error al momento de eliminar la compra. Intente de nuevo en unos minutos.")
      console.error(e)
    }
  }

  const onEdit = (compraExternaId) => {
    setCurrentId(compraExternaId);
    setAction(EDITAR_ACTION);
    setModalTitle(`Editar compra externa: ${compraExternaId}`);
  };

  const onCloseForm = () => {
    setCurrentId(undefined);
    setAction(UNDEFINED_ACTION);
    fetchComprasExternas();
  };

  return (
    <div className="ventas-fpay-section mb-5">
      <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2 mt-3">
        <span className="align-self-center letra-fecha-fpay">Desde: </span>
        <input
          type="date"
          onChange={(e) => setDesde(e.target.value)}
          value={desde}
          className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
          style={CALENDAR_STYLE}
        />
        <span className="align-self-center letra-fecha-fpay">Hasta: </span>
        <input
          type="date"
          onChange={(e) => setHasta(e.target.value)}
          defaultValue={hasta}
          className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
          style={CALENDAR_STYLE}
        />
      </div>

      <div className="pt-3 container">
        <div className={`col-12 no-pad ${canChangeSucursal ? '' : 'caja-fondo-blanco-m-2'}`}>
            {canChangeSucursal ? 
              <div className="caja-fondo-blanco-m-2">
                  <label className="sucursal-text-color" htmlFor="sucursales">Seleccione una sucursal</label>
                  <select value={currentSucursalId} name="sucursales" id="sucursales"
                          className="w-100 select-search-box__search"
                          onChange={e => setCurrentSucursalId(e.target.value)}>
                    {sucursales.map((s, i) => <option key={i} value={s.IdSucursal}>{s.Nombre}</option>)}
                  </select>
              </div> : <label className="sucursal-text-color container">{sucursalInfo.Nombre}</label>}
        </div>
      </div>

      <div className="mt-3 container">
        <div className="d-flex justify-content-end">
          <ExcelFile
              element={
                  <button className="btn btn-outline-primary btn-excel-mfp">
                      <a className="">Descargar Excel</a>
                  </button>
              }
              filename="ComprasExternas"
          >
              <ExcelSheet dataSet={excelSheet} name="ComprasExternas"/>
          </ExcelFile>
          <button 
            className="ml-3 btn btn-outline-primary btn-verde-mfp mb-2"
            type="button"
            onClick={() => onCreate()}
          >
            Registrar compra externa
          </button>
        </div>
      </div>

      <div className="mt-3 container">
        <ReactTable
          data={comprasExternas}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              columns: [
                {
                  Header: "Acciones",
                  accessor: "IdMovimientoAlmacen",
                  // headerClassName:'card-header bg-dark text-white',
                  width: "150",
                  filterable: false,
                  Cell: (f) => {
                    if (!f) {
                      return null;
                    }
                    return (
                      <div align="rigth"> 
                        <BotonEditar
                          className={`mr-2 ${f.original.canDelete ? '' : 'd-none'}`}
                          id={`${f.value}-edit`}
                          onClick={() => onEdit(f.original.IdCompraExterna)}
                          title="Editar"
                        />
                        <BotonDelete
                          id={`${f.value}-delete`}
                          className="mr-2"
                          hidden={!f.original.canDelete}
                          onClick={() => onDelete(f.original.IdCompraExterna)}
                          title="Anular"
                        />
                      </div>
                    );
                  },
                },

                {
                  Header: "Código",
                  id: "Numero",
                  width: "150",
                  accessor: (d) => d.Numero,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["Numero"] }),
                  filterAll: true,
                  Cell: props => {
                    const icon = props.original.FueValidado ? <i className="fas fa-check text-success mr-3"></i> : <i className="fas fa-times text-danger mr-3"></i>

                    return <span>{icon} {props.value}</span>
                  }
                },

                {
                  Header: "Fecha Movimiento",
                  id: "FechaMovimiento",
                  // accessor: "NombreCliente",
                  width: "90",
                  accessor: (d) => d.FechaMovimiento,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["FechaMovimiento"],
                    }),
                  filterAll: true,
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        textAlign: "center",
                      },
                    };
                  },
                },

                {
                  Header: "Número doc.",
                  id: "NroDocumentoSunat",
                  width: "100",
                  accessor: (d) => d.NroDocumentoSunat,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["NroDocumentoSunat"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Tipo doc.",
                  id: "DescTipoDocSunat",
                  width: "100",
                  accessor: (d) => d.DescTipoDocSunat,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["DescTipoDocSunat"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Proveedor",
                  id: "RazonSocial",
                  width: "150",
                  align: "center",
                  accessor: (d) => d.RazonSocial,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["RazonSocial"] }),
                  filterAll: true,
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        background:
                          rowInfo && rowInfo.row.Estado === "Por Procesar"
                            ? "#FEEBEB"
                            : null,
                        textAlign: "right",
                      },
                    };
                  },
                },
                {
                  Header: "Total",
                  id: "Total",
                  // accessor: "NombreCliente",
                  width: "100",
                  align: "center",
                  accessor: (d) => d.Total,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["Total"] }),
                  filterAll: true,
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        background:
                          rowInfo && rowInfo.row.Estado === "Por Procesar"
                            ? "#FEEBEB"
                            : null,
                        textAlign: "right",
                      },
                    };
                  },
                },
                {
                  Header: "Descripción",
                  id: "Glosa",
                  // accessor: "NombreCliente",
                  width: "200",
                  accessor: (d) => d.Glosa,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["Glosa"] }),
                  filterAll: true,
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        background:
                          rowInfo && rowInfo.row.Estado === "Por Procesar"
                            ? "#FEEBEB"
                            : null,
                        textAlign: "center",
                      },
                    };
                  },
                },
              ],
            },
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
          showPageJump={false}
          previousText="Anterior"
          nextText="Siguiente"
          loadingText="Cargando..."
          noDataText="No se encontraron registros"
          pageText="Página"
          ofText="de"
          rowsText="filas"
          style={{
            width: "100%",
            fontSize: "15px",
          }}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        />
      </div>

      {action !== UNDEFINED_ACTION && (
        <Modal isOpen={true} title={modalTitle} onClose={onCloseForm}>
          <CompraExternaForm
            action={action}
            currentId={currentId}
            currentSucursalId={currentSucursalId}
            currentAlmacenId={currentAlmacenId}
            onChangeCurrentId={(e) => setCurrentId(e)}
            onCancel={onCloseForm}
          />
        </Modal>
      )}
    </div>
  );
}
