import React, {Component} from "react";
// import logo from "../componentes/images/mifacturaperu.png";
import "./styles/Dashboard.css";
import "react-moment";
import moment from "moment";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TotalVentas: null,
            TotalProductosAgotarStock: null,
            TotalProductosVales: null,
            ReporteVentasRango: [],
            dataVentasDia: [],
            dataSemanas: [],
            dataVentasPorMeses: [],
            FechaInicio: moment().add(-30, 'days'),
            FechaFin: new Date(),
            MesActual: new Date().getMonth()
        };

        this.getTotalVentasTurnoEmpresa = this.getTotalVentasTurnoEmpresa.bind(
            this
        );
        this.getTotalProductosValesEmpresa = this.getTotalProductosValesEmpresa.bind(
            this
        );
        this.getTotalProductosPorAgotarStockEmpresa = this.getTotalProductosPorAgotarStockEmpresa.bind(
            this
        );

        this.handleVerProductosAgotarStock = this.handleVerProductosAgotarStock.bind(
            this
        );

        this.handleIrProcesarVales = this.handleIrProcesarVales.bind(
            this
        );

        this.handleVerVentasTurnosActivosEmpresa = this.handleVerVentasTurnosActivosEmpresa.bind(
            this
        );

        this.handleVerProductosAgotarStock = this.handleVerProductosAgotarStock.bind(
            this
        );
        this.handleIrProductosMasVendidos = this.handleIrProductosMasVendidos.bind(
            this
        );
        this.handleReportesStock = this.handleReportesStock.bind(
            this
        );
        this.getReporteVentasRango = this.getReporteVentasRango.bind(this);
        this.getVentasDelDia = this.getVentasDelDia.bind(this);
        this.getVentasPorMeses = this.getVentasPorMeses.bind(this);
        this.getVentasPorSemanas = this.getVentasPorSemanas.bind(this);
    }

    componentDidMount() {
        let Inicio = moment(this.state.FechaInicio).format("YYYY-MM-DD");
        let Fin = moment(this.state.FechaFin).format("YYYY-MM-DD");
        this.mesLetras(Fin)
        this.getVentasDelDia(Fin);
        this.getVentasPorSemanas(Fin)
        this.getVentasPorMeses(Fin)

        this.getReporteVentasRango(Inicio, Fin);

        this.getTotalVentasTurnoEmpresa();
        this.getTotalProductosValesEmpresa();
        this.getTotalProductosPorAgotarStockEmpresa();
    }

    mesLetras = () => {
        const month = [
            "ENERO",
            "FEBRERO",
            "MARZO",
            "ABRIL",
            "MAYO",
            "JUNIO",
            "JULIO",
            "AGOSTO",
            "SETIEMBRE",
            "OCTUBRE",
            "NOVIEMBRE",
            "DICIEMBRE"
        ];
        this.setState({
            MesActual: month[this.state.MesActual]
        });
    }
    

    getVentasDelDia = async (Hoy) => {
        try {
            const response = await fetch(
                `/api/dashboard/get/ventasDia/${Hoy}`
            );
            const ReporteVentasDia = await response.json();
            this.setState({
                dataVentasDia: ReporteVentasDia.respuesta,
                isLoading: false
            });

            return ReporteVentasDia;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    getVentasPorSemanas = async (Hoy) => {
        try {
            const response = await fetch(
                `/api/dashboard/get/ventasSemanas/${Hoy}`
            );
            const ReporteVentasSemana = await response.json();
            this.setState({
                dataSemanas: ReporteVentasSemana.respuesta,
                isLoading: false
            });

            return ReporteVentasSemana;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    getVentasPorMeses = async (Hoy) => {
        try {
            // const year = moment(Hoy).format("YYYY")
            const response = await fetch(
                `/api/dashboard/get/ventasMeses/${Hoy}`
            );
            const ReporteVentasPorMeses = await response.json();
            this.setState({
                dataVentasPorMeses: ReporteVentasPorMeses.respuesta,
                isLoading: false
            });

            return ReporteVentasPorMeses;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReporteVentasRango(Inicio, Fin) {
        try {
            const response = await fetch(
                `/api/ventas/reporte/ventas/fecha/${Inicio}/${Fin}`
            );
            const ReporteVentasRango = await response.json();
            console.log(ReporteVentasRango)
            this.setState({
                ReporteVentasRango: ReporteVentasRango.respuesta,
                isLoading: false
            });

            return ReporteVentasRango;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getTotalVentasTurnoEmpresa() {
        try {
            const response = await fetch(`/api/dashboard/get/totalventas`);
            const TotalVentas = await response.json();
            this.setState({
                TotalVentas: TotalVentas.respuesta[0].Total,
                isLoading: false
            });
            return TotalVentas;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getTotalProductosValesEmpresa() {
        try {
            const response = await fetch(`/api/dashboard/get/totalproductosvales`);
            const TotalProductosVales = await response.json();
            this.setState({
                TotalProductosVales: TotalProductosVales.respuesta[0].Total,
                isLoading: false
            });
            return TotalProductosVales;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getTotalProductosPorAgotarStockEmpresa() {
        try {
            const response = await fetch(
                `/api/dashboard/get/totalproductosagotarstock`
            );
            const TotalProductosAgotarStock = await response.json();
            this.setState({
                TotalProductosAgotarStock: TotalProductosAgotarStock.respuesta[0].Total,
                isLoading: false
            });
            return TotalProductosAgotarStock;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    handleVerProductosAgotarStock() {
        this.props.history.push("/dashboard/productosporagotarstock/")
    }

    handleIrProcesarVales() {
        this.props.history.push("/procesarvales/")
    }

    handleVerVentasTurnosActivosEmpresa() {
        this.props.history.push("/dashboard/ventas/turnosactivos/empresa")
    }

    handleIrProductosMasVendidos() {
        this.props.history.push("/dashborad/productosmasvendidos")
    }

    handleReportesStock() {
        this.props.history.push("/dashborad/reportesstock")
    }


    render() {


        const reporteDia = this.state.dataVentasDia.map(venta => {
            return {name: 'Ventas del Dia', Facturas: venta.Facturas, Boletas: venta.Boletas, Vales: venta.Vales,}
        });

        const reporteSemana = this.state.dataSemanas.map(venta => {
            return {name: venta.Semana, Ventas: venta.Ventas, Compras: venta.Compras}
        });
        
        const reporteMeses = this.state.dataVentasPorMeses.map(venta => {
            return {name: venta.Mes, Compras: venta.Compras, Ventas: venta.Total, Vales: venta.Vales,}
        });

          
           const renderBarChartVentasDia = (
            
               <div className="row seccion-ventas-mes justify-content-evenly">
                 {this.state.dataVentasDia.map((venta, index) => (
                   <div key={index} className="cuadro-dashboard-1 mt-2 mb-3 col-12 col-md-3">
                     <div className="name-data">Facturas: {venta.Facturas}</div>
                     <div className="ventas-data">Boletas: {venta.Boletas}</div>
                     <div className="compras-data">Vales: {venta.Vales}</div>
                   </div>
                 ))}
               </div>
             );
            
          
           const renderLineChartVentaMes = (
            
            <div className="row seccion-ventas-mes justify-content-evenly">
            {this.state.dataSemanas.map((venta, index) => (
              <div key={index}  className="cuadro-dashboard-1 mt-2 mb-3 col-12 col-md-2">
                <div className="name-data">{venta.Semana}</div>
                <div className="ventas-data">Ventas: {venta.Ventas}</div>
                <div className="compras-data">Compras: {venta.Compras}</div>
              </div>
            ))}
          </div>
             );      


          const renderBarChartMeses = (
            
            <div className="row seccion-ventas-mes justify-content-evenly">
            {this.state.dataVentasPorMeses.map((venta, index) => (
              <div key={index}  className="cuadro-dashboard-1 mt-2 mb-3 col-12 col-md-3">
                <div className="name-data">{venta.Mes}</div>              
                <div className="vales-data">Vales: {venta.Vales}</div>
                <div className="compras-data-2">Compras: {venta.Compras}</div>
                <div className="total-data">Total: {venta.Total}</div>
              </div>
            ))}
          </div>
             );            


        return (
            <section className="ventas-fpay-section-vh">
            <div className="dashboard">
                <div className="row w-100 m-0 mt-5">
                    <div className=" col-12  no-pad dashboard-sec-1">
                        <div className="">
                            <div className="row justify-content-center" style={{margin: "auto"}}>
                                <div className="col-12  mt-3 mb-3">
                                    <div className="" style={{textAlign:"center"}}>
                                        <h4 className="mt-2">VENTAS DEL DIA</h4>
                                        {renderBarChartVentasDia}
                                    </div>
                                </div>
                                <div className="col-12  mt-3 mb-3">
                                    <div className="" style={{textAlign:"center"}}>
                                        <h4 className="mt-2">{this.state.MesActual}</h4>
                                            {renderLineChartVentaMes}
                                    </div>
                                </div>
                                <div className="col-12  mt-3 mb-3">
                                    <div className="" style={{textAlign:"center"}}>
                                        <h4 className="mt-2" style={{textAlign:"center"}}>VENTAS 3 ÚLTIMOS MESES </h4>
                                        {renderBarChartMeses}
                                    </div>
                                </div>
                            </div>
                            {/* <h4 className="align-self-center mt-3">VENTAS 3 ÚLTIMOS MESES </h4>
                            <div className="cardGraphics">
                            {renderBarChartMeses}
                            </div> */}
                        </div>
                    </div>
                    <div className="col-12  pt-5">
                        <h4 className="d-flex justify-content-center">REPORTES PERSONALIZADOS</h4>
                        <div className="row w-100 m-0 mt-3 d-flex justify-content-center mb-5">
                            <div type="button" className="dashboard__content--item-g col-12 mt-5" title="Haz clic para ver detalles"
                                onClick={this.handleVerVentasTurnosActivosEmpresa}>
                                <div className="d-flex">
                                    <i className="fas fa-cash-register"></i>
                                    <span className="align-self-center">Ventas de los turnos activos</span>
                                </div>
                                <span className="d-flex justify-content-end">
                                    {this.state.TotalVentas &&
                                        "S/ " + this.state.TotalVentas.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                                </span>
                            </div>

                            <div type="button" className="dashboard__content--item-d col-12 mt-5" title="Haz click para ver detalles"
                                onClick={this.handleVerProductosAgotarStock}>
                                <div>
                                    <i className="fas fa-boxes"></i>
                                    Productos Agotados o Próximos en Agotar Stock
                                </div>
                                <span className="d-flex justify-content-end">
                                    {this.state.TotalProductosAgotarStock &&
                                        this.state.TotalProductosAgotarStock}
                                </span>
                            </div>
                            <div hidden className="dashboard__content--item-g col-12 mt-5" type="button"
                                title="Haz click para ir a Procesar Vales" onClick={this.handleIrProcesarVales}>
                                <div>
                                    <i className="fas fa-hourglass-end"></i>
                                    Productos Pendientes en Procesar Vales
                                </div>
                                <span className="d-flex justify-content-end"> 
                                    {this.state.TotalProductosVales &&
                                        this.state.TotalProductosVales}
                                </span>
                            </div>

                            <div type="button" className="dashboard__content--item-d col-12 mt-5" title="Haz click para ver detalles"
                                onClick={this.handleIrProductosMasVendidos}>
                                <div>
                                    <i className="fas fa-barcode"></i>
                                    Productos más Vendidos
                                </div>

                            </div>

                            <div type="button" className="dashboard__content--item-g col-12 mt-5" title="Haz click para ver detalles"
                                onClick={this.handleReportesStock}>
                                <div>
                                    <i className="fas fa-boxes"></i>
                                    Reportes de Stocks
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        );
    }
}

export default Dashboard;
