import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
import "react-moment";
import { BotonCancelar } from "../componentes/Plantillas/Botones";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ReportesStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StockDisponibleCompleto: null,
      StockDisponibleConComprobante: null,
      StockDisponibleSinComprobante: null,
      StockComprometidoCompleto: null,
      StockComprometidoConComprobante: null,
      StockComprometidoSinComprobante: null,
      StockComprometidoVale: null
    };

    this.getStockDisponibleCompleto = this.getStockDisponibleCompleto.bind(
      this
    );
    this.getStockDisponibleConComprobante = this.getStockDisponibleConComprobante.bind(
      this
    );
    this.getStockDisponibleSinComprobante = this.getStockDisponibleSinComprobante.bind(
      this
    );
    this.getStockComprometidoCompleto = this.getStockComprometidoCompleto.bind(
      this
    );
    this.getStockComprometidoConComprobante = this.getStockComprometidoConComprobante.bind(
      this
    );
    this.getStockComprometidoSinComprobante = this.getStockComprometidoSinComprobante.bind(
      this
    );
    this.getStockComprometidoVale = this.getStockComprometidoVale.bind(
      this
    );
    this.handleSavePDF = this.handleSavePDF.bind(this);
  }

  componentDidMount() {
    this.getStockDisponibleCompleto();
    this.getStockDisponibleConComprobante();
    this.getStockDisponibleSinComprobante();
    this.getStockComprometidoCompleto();
    this.getStockComprometidoConComprobante();
    this.getStockComprometidoSinComprobante();
    this.getStockComprometidoVale();
    this.setState({ isLoading: true });
  }

  async getStockDisponibleCompleto() {
    try {
      const response = await fetch(
        `/api/dashboard/get/stock/disponiblecompleto`
      );
      const StockDisponibleCompleto = await response.json();
      this.setState({
        StockDisponibleCompleto: StockDisponibleCompleto.respuesta,
        isLoading: false
      });
      return StockDisponibleCompleto;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getStockDisponibleConComprobante() {
    try {
      const response = await fetch(
        `/api/dashboard/get/stock/disponibleconcomprobante`
      );
      const StockDisponibleConComprobante = await response.json();
      this.setState({
        StockDisponibleConComprobante: StockDisponibleConComprobante.respuesta,
        isLoading: false
      });
      return StockDisponibleConComprobante;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getStockDisponibleSinComprobante() {
    try {
      const response = await fetch(
        `/api/dashboard/get/stock/disponiblesincomprobante`
      );
      const StockDisponibleSinComprobante = await response.json();
      this.setState({
        StockDisponibleSinComprobante: StockDisponibleSinComprobante.respuesta,
        isLoading: false
      });
      return StockDisponibleSinComprobante;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getStockComprometidoCompleto() {
    try {
      const response = await fetch(
        `/api/dashboard/get/stock/comprometidocompleto`
      );
      const StockComprometidoCompleto = await response.json();
      this.setState({
        StockComprometidoCompleto: StockComprometidoCompleto.respuesta,
        isLoading: false
      });
      return StockComprometidoCompleto;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getStockComprometidoConComprobante() {
    try {
      const response = await fetch(
        `/api/dashboard/get/stock/comprometidoconcomprobante`
      );
      const StockComprometidoConComprobante = await response.json();
      this.setState({
        StockComprometidoConComprobante: StockComprometidoConComprobante.respuesta,
        isLoading: false
      });
      return StockComprometidoConComprobante;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getStockComprometidoSinComprobante() {
    try {
      const response = await fetch(
        `/api/dashboard/get/stock/comprometidosincomprobante`
      );
      const StockComprometidoSinComprobante = await response.json();
      this.setState({
        StockComprometidoSinComprobante: StockComprometidoSinComprobante.respuesta,
        isLoading: false
      });
      return StockComprometidoSinComprobante;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getStockComprometidoVale() {
    try {
      const response = await fetch(
        `/api/dashboard/get/stock/comprometidovale`
      );
      const StockComprometidoVale = await response.json();
      this.setState({
        StockComprometidoVale: StockComprometidoVale.respuesta,
        isLoading: false
      });
      return StockComprometidoVale;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  getDataProductosExportExcel(dataSet) {
    return [
      {
        columns: [
          {
            title: "Código",
            width: { wpx: 90 }
          },
          {
            title: "Nombre Producto",
            width: { wpx: 400 }
          },
          {
            title: "Descripción",
            width: { wpx: 300 }
          },
          {
            title: "Stock",
            width: { wpx: 100 }
          },
          {
            title: "Nombre Almacen",
            width: { wpx: 250 }
          },
          {
            title: "Nombre Sucursal",
            width: { wpx: 250 }
          }
        ],

        data: dataSet.map(row => {
          return [
            {
              value: row.Codigo,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreProducto,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Descripcion || "",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Stock,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreAlmacen,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreSucursal,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            }
          ];
        })
      }
    ];
  }
  handleSavePDF(reporte) {
    let doc = new jsPDF({
      orientation: "landscape",
      format: "a4"
    });

    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.setFontSize(14);
    doc.text("REPORTE DE STOCK", 80, 10);
    doc.autoTable({
      body: reporte,
      theme: "grid",
      columnStyles: {
        text: {
          columnWidth: "wrap",
          overflow: "linebreak",
          halign: "right",
          valign: "middle"
        },
        Codigo: {
          columnWidth: 25,
          halign: "right",
          valign: "middle",
          overflow: "linebreak"
        },
        NombreProducto: {
          columnWidth: 30,
          halign: "left",
          valign: "middle",
          overflow: "linebreak"
        },
        Descripcion: {
          columnWidth: 30,
          halign: "left",
          valign: "middle",
          overflow: "linebreak"
        },
        Stock: {
          columnWidth: 20,
          halign: "right"
        },
        NombreAlmacen: {
          columnWidth: 30,
          halign: "left"
        },
        NombreSucursal: {
          columnWidth: 30,
          halign: "left"
        }
      },
      columns: [
        { header: "CÓDIGO", dataKey: "Codigo" },
        { header: "NOMBRE PRODUCTO", dataKey: "NombreProducto" },
        { header: "DESCRIPCION", dataKey: "Descripcion" },
        { header: "STOCK", dataKey: "Stock" },
        { header: "NOMBRE ALMACEN", dataKey: "NombreAlmacen" },
        { header: "NOMBRE SUCURSAL", dataKey: "NombreSucursal" }
      ]
    });
    doc.setFontSize(10);
    doc.setFontType("italic");
    doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

    doc.save("ResporteStock.pdf");
  }

  render() {
    let stockDisponibleCompleto = null;
    if (this.state.StockDisponibleCompleto) {
      stockDisponibleCompleto = this.getDataProductosExportExcel(
        this.state.StockDisponibleCompleto
      );
    }

    let stockDisponibleConComprobante = null;
    if (this.state.StockDisponibleConComprobante) {
      stockDisponibleConComprobante = this.getDataProductosExportExcel(
        this.state.StockDisponibleConComprobante
      );
    }

    let stockDisponibleSinComprobante = null;
    if (this.state.StockDisponibleSinComprobante) {
      stockDisponibleSinComprobante = this.getDataProductosExportExcel(
        this.state.StockDisponibleSinComprobante
      );
    }
    
    let stockComprometidoCompleto = null;
    if (this.state.StockComprometidoCompleto) {
      stockComprometidoCompleto = this.getDataProductosExportExcel(
        this.state.StockComprometidoCompleto
      );
    }
    
    let stockComprometidoConComprobante = null;
    if (this.state.StockComprometidoConComprobante) {
      stockComprometidoConComprobante = this.getDataProductosExportExcel(
        this.state.StockComprometidoConComprobante
      );
    }
    
    let stockComprometidoSinComprobante = null;
    if (this.state.StockComprometidoSinComprobante) {
      stockComprometidoSinComprobante = this.getDataProductosExportExcel(
        this.state.StockComprometidoSinComprobante
      );
    }
    
    let stockComprometidoVale = null;
    if (this.state.StockComprometidoVale) {
      stockComprometidoVale = this.getDataProductosExportExcel(
        this.state.StockComprometidoVale
      );
    }




    return (
      <section className="ventas-fpay-section cienvh-resp">
        <div className="container">
        <div className="mb-2 pt-3 pb-4">
        </div>
          <h4 className="ml-3 mb-4">Selecciona el Tipo de Stock a Consultar</h4>
          <div class="accordion pb-5" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button
                    class="btn text-secondary"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Stock Disponible Completo (con o sin comprobante)
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  {stockDisponibleCompleto ? (
                    <div>
                      <div className="container">
                        <div className="d-flex  justify-content-between align-items-center mb-2 mt-2">
                          <h3 className="">
                            Detalle de Productos del Stock Disponible Completo
                          </h3>
                        </div>
                        <div className="d-flex justify-content-end mb-2">
                            <button
                              className="btn-pdf-mfp mr-2"
                              onClick={() =>
                                this.handleSavePDF(
                                  this.state.StockDisponibleCompleto
                                )
                              }
                            >
                              Descargar pdf
                            </button>
                            <ExcelFile
                              element={
                                <button className="btn-excel-mfp">
                                  Descargar excel
                                </button>
                              }
                              filename="ReporteProductosStock"
                            >
                              <ExcelSheet
                                dataSet={stockDisponibleCompleto}
                                name="Productos"
                              ></ExcelSheet>
                            </ExcelFile>
                          </div>
                        <ReactTable
                          id="table-productos-to-xls"
                          data={this.state.StockDisponibleCompleto}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                          }
                          columns={[
                            {
                              columns: [
                                {
                                  Header: "Código",
                                  id: "Codigo",
                                  accessor: d => d.Codigo,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Codigo"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Sucursal",
                                  id: "NombreSucursal",
                                  accessor: d => d.NombreSucursal,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreSucursal"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Almacen",
                                  id: "NombreAlmacen",
                                  accessor: d => d.NombreAlmacen,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreAlmacen"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Producto",
                                  id: "NombreProducto",
                                  accessor: d => d.NombreProducto,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreProducto"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Descripcion",
                                  id: "Descripcion",
                                  accessor: d => d.Descripcion,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Descripcion"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Stock",
                                  id: "Stock",
                                  accessor: d => d.Stock,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Stock"]
                                    }),
                                  filterAll: true,
                                  getProps: (state, rowInfo, column) => {
                                    return {
                                      style: {
                                        textAlign: "right"
                                      }
                                    };
                                  }
                                }
                              ]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                          previousText="Anterior"
                          nextText="Siguiente"
                          noDataText="No se encontraron registros"
                          loadingText="Cargando..."
                          ofText="de"
                          rowsText="filas"
                          pageText="Página"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>Cargando Productos</div>
                  )}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                  <button
                    class="btn text-secondary collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Stock Disponible Con Comprobante
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                {stockDisponibleConComprobante ? (
                    <div>
                      <div className="container">
                        <div className="d-flex  justify-content-between align-items-center mb-2 mt-2">
                          <h3 className="">
                            Detalle de Productos del Stock Disponible Con Comprobante
                          </h3>
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <button
                              className="boton__Rojo mr-2"
                              onClick={() =>
                                this.handleSavePDF(
                                  this.state.StockDisponibleConComprobante
                                )
                              }
                            >
                              {<i className="fas fa-file-pdf"></i>}
                            </button>
                            <ExcelFile
                              element={
                                <button className="boton__verdeOscuro">
                                  <i className="fas fa-file-excel"></i>
                                </button>
                              }
                              filename="ReporteProductosStock"
                            >
                              <ExcelSheet
                                dataSet={stockDisponibleConComprobante}
                                name="Productos"
                              ></ExcelSheet>
                            </ExcelFile>
                          </div>
                        <ReactTable
                          id="table-productos-to-xls"
                          data={this.state.StockDisponibleConComprobante}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                          }
                          columns={[
                            {
                              columns: [
                                {
                                  Header: "Código",
                                  id: "Codigo",
                                  accessor: d => d.Codigo,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Codigo"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Sucursal",
                                  id: "NombreSucursal",
                                  accessor: d => d.NombreSucursal,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreSucursal"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Almacen",
                                  id: "NombreAlmacen",
                                  accessor: d => d.NombreAlmacen,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreAlmacen"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Producto",
                                  id: "NombreProducto",
                                  accessor: d => d.NombreProducto,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreProducto"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Descripcion",
                                  id: "Descripcion",
                                  accessor: d => d.Descripcion,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Descripcion"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Stock",
                                  id: "Stock",
                                  accessor: d => d.Stock,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Stock"]
                                    }),
                                  filterAll: true,
                                  getProps: (state, rowInfo, column) => {
                                    return {
                                      style: {
                                        textAlign: "right"
                                      }
                                    };
                                  }
                                }
                              ]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                          previousText="Anterior"
                          nextText="Siguiente"
                          noDataText="No se encontraron registros"
                          loadingText="Cargando..."
                          ofText="de"
                          rowsText="filas"
                          pageText="Página"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>Cargando Productos</div>
                  )}
                </div>
              </div>
            </div>


            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn text-secondary collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Stock Disponible Sin Comprobante
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                {stockDisponibleSinComprobante ? (
                    <div>
                      <div className="container">
                        <div className="d-flex  justify-content-between align-items-center mb-2 mt-2">
                          <h3 className="">
                            Detalle de Productos del Stock Disponible Sin Comprobante
                          </h3>
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <button
                              className="boton__Rojo mr-2"
                              onClick={() =>
                                this.handleSavePDF(
                                  this.state.StockDisponibleSinComprobante
                                )
                              }
                            >
                              {<i className="fas fa-file-pdf"></i>}
                            </button>
                            <ExcelFile
                              element={
                                <button className="boton__verdeOscuro">
                                  <i className="fas fa-file-excel"></i>
                                </button>
                              }
                              filename="ReporteProductosStock"
                            >
                              <ExcelSheet
                                dataSet={stockDisponibleSinComprobante}
                                name="Productos"
                              ></ExcelSheet>
                            </ExcelFile>
                          </div>
                        <ReactTable
                          id="table-productos-to-xls"
                          data={this.state.StockDisponibleSinComprobante}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                          }
                          columns={[
                            {
                              columns: [
                                {
                                  Header: "Código",
                                  id: "Codigo",
                                  accessor: d => d.Codigo,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Codigo"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Sucursal",
                                  id: "NombreSucursal",
                                  accessor: d => d.NombreSucursal,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreSucursal"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Almacen",
                                  id: "NombreAlmacen",
                                  accessor: d => d.NombreAlmacen,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreAlmacen"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Producto",
                                  id: "NombreProducto",
                                  accessor: d => d.NombreProducto,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreProducto"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Descripcion",
                                  id: "Descripcion",
                                  accessor: d => d.Descripcion,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Descripcion"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Stock",
                                  id: "Stock",
                                  accessor: d => d.Stock,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Stock"]
                                    }),
                                  filterAll: true,
                                  getProps: (state, rowInfo, column) => {
                                    return {
                                      style: {
                                        textAlign: "right"
                                      }
                                    };
                                  }
                                }
                              ]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                          previousText="Anterior"
                          nextText="Siguiente"
                          noDataText="No se encontraron registros"
                          loadingText="Cargando..."
                          ofText="de"
                          rowsText="filas"
                          pageText="Página"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>Cargando Productos</div>
                  )}


                </div>
              </div>
            </div>


            <div class="card">
              <div class="card-header" id="headingFour">
                <h2 class="mb-0">
                  <button
                    class="btn text-secondary collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Stock Comprometido Completo (con o sin comprobante)
                  </button>
                </h2>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionExample"
              >
                <div class="card-body">
            
                {stockComprometidoCompleto ? (
                    <div>
                      <div className="container">
                        <div className="d-flex  justify-content-between align-items-center mb-2 mt-2">
                          <h3 className="">
                            Detalle de Productos del Stock Comprometido Completo
                          </h3>                          
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <button
                              className="boton__Rojo mr-2"
                              onClick={() =>
                                this.handleSavePDF(
                                  this.state.StockComprometidoCompleto
                                )
                              }
                            >
                              {<i className="fas fa-file-pdf"></i>}
                            </button>
                            <ExcelFile
                              element={
                                <button className="boton__verdeOscuro">
                                  <i className="fas fa-file-excel"></i>
                                </button>
                              }
                              filename="ReporteProductosStock"
                            >
                              <ExcelSheet
                                dataSet={stockComprometidoCompleto}
                                name="Productos"
                              ></ExcelSheet>
                            </ExcelFile>
                          </div>
                        <ReactTable
                          id="table-productos-to-xls"
                          data={this.state.StockComprometidoCompleto}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                          }
                          columns={[
                            {
                              columns: [
                                {
                                  Header: "Código",
                                  id: "Codigo",
                                  accessor: d => d.Codigo,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Codigo"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Sucursal",
                                  id: "NombreSucursal",
                                  accessor: d => d.NombreSucursal,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreSucursal"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Almacen",
                                  id: "NombreAlmacen",
                                  accessor: d => d.NombreAlmacen,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreAlmacen"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Producto",
                                  id: "NombreProducto",
                                  accessor: d => d.NombreProducto,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreProducto"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Descripcion",
                                  id: "Descripcion",
                                  accessor: d => d.Descripcion,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Descripcion"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Stock",
                                  id: "Stock",
                                  accessor: d => d.Stock,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Stock"]
                                    }),
                                  filterAll: true,
                                  getProps: (state, rowInfo, column) => {
                                    return {
                                      style: {
                                        textAlign: "right"
                                      }
                                    };
                                  }
                                }
                              ]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                          previousText="Anterior"
                          nextText="Siguiente"
                          noDataText="No se encontraron registros"
                          loadingText="Cargando..."
                          ofText="de"
                          rowsText="filas"
                          pageText="Página"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>Cargando Productos</div>
                  )}

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <h2 class="mb-0">
                  <button
                    class="btn text-secondary collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Stock Comprometido Con Comprobante
                  </button>
                </h2>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordionExample"
              >
                <div class="card-body">
            
                {stockComprometidoConComprobante ? (
                    <div>
                      <div className="container">
                        <div className="d-flex  justify-content-between align-items-center mb-2 mt-2">
                          <h3 className="">
                            Detalle de Productos del Stock Comprometido con Comprobante
                          </h3>
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <button
                              className="boton__Rojo mr-2"
                              onClick={() =>
                                this.handleSavePDF(
                                  this.state.StockComprometidoConComprobante
                                )
                              }
                            >
                              {<i className="fas fa-file-pdf"></i>}
                            </button>
                            <ExcelFile
                              element={
                                <button className="boton__verdeOscuro">
                                  <i className="fas fa-file-excel"></i>
                                </button>
                              }
                              filename="ReporteProductosStock"
                            >
                              <ExcelSheet
                                dataSet={stockComprometidoConComprobante}
                                name="Productos"
                              ></ExcelSheet>
                            </ExcelFile>
                          </div>
                        <ReactTable
                          id="table-productos-to-xls"
                          data={this.state.StockComprometidoConComprobante}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                          }
                          columns={[
                            {
                              columns: [
                                {
                                  Header: "Código",
                                  id: "Codigo",
                                  accessor: d => d.Codigo,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Codigo"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Sucursal",
                                  id: "NombreSucursal",
                                  accessor: d => d.NombreSucursal,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreSucursal"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Almacen",
                                  id: "NombreAlmacen",
                                  accessor: d => d.NombreAlmacen,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreAlmacen"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Producto",
                                  id: "NombreProducto",
                                  accessor: d => d.NombreProducto,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreProducto"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Descripcion",
                                  id: "Descripcion",
                                  accessor: d => d.Descripcion,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Descripcion"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Stock",
                                  id: "Stock",
                                  accessor: d => d.Stock,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Stock"]
                                    }),
                                  filterAll: true,
                                  getProps: (state, rowInfo, column) => {
                                    return {
                                      style: {
                                        textAlign: "right"
                                      }
                                    };
                                  }
                                }
                              ]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                          previousText="Anterior"
                          nextText="Siguiente"
                          noDataText="No se encontraron registros"
                          loadingText="Cargando..."
                          ofText="de"
                          rowsText="filas"
                          pageText="Página"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>Cargando Productos</div>
                  )}

                </div>
              </div>
            </div>


            <div class="card">
              <div class="card-header" id="headingSix">
                <h2 class="mb-0">
                  <button
                    class="btn text-secondary collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Stock Comprometido Sin Comprobante
                  </button>
                </h2>
              </div>
              <div
                id="collapseSix"
                class="collapse"
                aria-labelledby="headingSix"
                data-parent="#accordionExample"
              >
                <div class="card-body">
            
                {stockComprometidoSinComprobante ? (
                    <div>
                      <div className="container">
                        <div className="d-flex  justify-content-between align-items-center mb-2 mt-2">
                          <h3 className="">
                            Detalle de Productos del Stock Comprometido Sin Comprobante
                          </h3>
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <button
                              className="boton__Rojo mr-2"
                              onClick={() =>
                                this.handleSavePDF(
                                  this.state.StockComprometidoSinComprobante
                                )
                              }
                            >
                              {<i className="fas fa-file-pdf"></i>}
                            </button>
                            <ExcelFile
                              element={
                                <button className="boton__verdeOscuro">
                                  <i className="fas fa-file-excel"></i>
                                </button>
                              }
                              filename="ReporteProductosStock"
                            >
                              <ExcelSheet
                                dataSet={stockComprometidoSinComprobante}
                                name="Productos"
                              ></ExcelSheet>
                            </ExcelFile>
                          </div>
                        <ReactTable
                          id="table-productos-to-xls"
                          data={this.state.StockComprometidoSinComprobante}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                          }
                          columns={[
                            {
                              columns: [
                                {
                                  Header: "Código",
                                  id: "Codigo",
                                  accessor: d => d.Codigo,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Codigo"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Sucursal",
                                  id: "NombreSucursal",
                                  accessor: d => d.NombreSucursal,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreSucursal"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Almacen",
                                  id: "NombreAlmacen",
                                  accessor: d => d.NombreAlmacen,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreAlmacen"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Producto",
                                  id: "NombreProducto",
                                  accessor: d => d.NombreProducto,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreProducto"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Descripcion",
                                  id: "Descripcion",
                                  accessor: d => d.Descripcion,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Descripcion"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Stock",
                                  id: "Stock",
                                  accessor: d => d.Stock,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Stock"]
                                    }),
                                  filterAll: true,
                                  getProps: (state, rowInfo, column) => {
                                    return {
                                      style: {
                                        textAlign: "right"
                                      }
                                    };
                                  }
                                }
                              ]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                          previousText="Anterior"
                          nextText="Siguiente"
                          noDataText="No se encontraron registros"
                          loadingText="Cargando..."
                          ofText="de"
                          rowsText="filas"
                          pageText="Página"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>Cargando Productos</div>
                  )}

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingSeven">
                <h2 class="mb-0">
                  <button
                    class="btn text-secondary collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Stock Comprometido de Vales
                  </button>
                </h2>
              </div>
              <div
                id="collapseSeven"
                class="collapse"
                aria-labelledby="headingSeven"
                data-parent="#accordionExample"
              >
                <div class="card-body">
            
                {stockComprometidoVale ? (
                    <div>
                      <div className="container">
                        <div className="d-flex  justify-content-between align-items-center mb-2 mt-2">
                          <h3 className="">
                            Detalle de Productos del Stock Comprometido Vale
                          </h3>                      
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <button
                              className="boton__Rojo mr-2"
                              onClick={() =>
                                this.handleSavePDF(
                                  this.state.StockComprometidoVale
                                )
                              }
                            >
                              {<i className="fas fa-file-pdf"></i>}
                            </button>
                            <ExcelFile
                              element={
                                <button className="boton__verdeOscuro">
                                  <i className="fas fa-file-excel"></i>
                                </button>
                              }
                              filename="ReporteProductosStock"
                            >
                              <ExcelSheet
                                dataSet={stockComprometidoVale}
                                name="Productos"
                              ></ExcelSheet>
                            </ExcelFile>
                          </div>
                        <ReactTable
                          id="table-productos-to-xls"
                          data={this.state.StockComprometidoVale}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                          }
                          columns={[
                            {
                              columns: [
                                {
                                  Header: "Código",
                                  id: "Codigo",
                                  accessor: d => d.Codigo,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Codigo"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Sucursal",
                                  id: "NombreSucursal",
                                  accessor: d => d.NombreSucursal,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreSucursal"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Almacen",
                                  id: "NombreAlmacen",
                                  accessor: d => d.NombreAlmacen,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreAlmacen"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Producto",
                                  id: "NombreProducto",
                                  accessor: d => d.NombreProducto,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["NombreProducto"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Descripcion",
                                  id: "Descripcion",
                                  accessor: d => d.Descripcion,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Descripcion"]
                                    }),
                                  filterAll: true
                                },
                                {
                                  Header: "Stock",
                                  id: "Stock",
                                  accessor: d => d.Stock,
                                  filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {
                                      keys: ["Stock"]
                                    }),
                                  filterAll: true,
                                  getProps: (state, rowInfo, column) => {
                                    return {
                                      style: {
                                        textAlign: "right"
                                      }
                                    };
                                  }
                                }
                              ]
                            }
                          ]}
                          defaultPageSize={10}
                          className="-striped -highlight"
                          previousText="Anterior"
                          nextText="Siguiente"
                          noDataText="No se encontraron registros"
                          loadingText="Cargando..."
                          ofText="de"
                          rowsText="filas"
                          pageText="Página"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>Cargando Productos</div>
                  )}

                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    );
  }
}

export default ReportesStock;
