import React from "react";
import '../styles/Sucursal.css';
import { BotonCancelar, BotonGuardarLetras } from "../Plantillas/Botones";

class SucursalForm extends React.Component {
  render() {
    return (
      <section className="ventas-fpay-section">

        <div className="container pt-4">
          <BotonCancelar to="/sucursales" />
        </div>

        <form onSubmit={this.props.onSubmit} className="d-flex justify-content-center">
          <div className="container mt-5">
            <div className="sucursalDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Empresa</b>
                <select
                  required
                  className="input__linea "
                  aria-haspopup="true"
                  name="empresa"
                  value={this.props.state.IdEmpresa}
                  onChange={this.props.EmpresaChange}
                >
                  <option value="">--Seleccionar Empresa--</option>
                  {this.props.state.dataEmpresas.map(empresa => {
                    return (
                      <option key={empresa.ID} value={empresa.ID}>
                        {empresa.RazonSocial}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="sucursalDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">Responsable</b>
                <select
                  className="input__linea "
                  aria-haspopup="true"
                  name="empleado"
                  value={this.props.state.IdEmpleado}
                  onChange={this.props.EmpleadoChange}
                >
                  {this.props.state.empleadosPorEmpresa.map(empleado => {
                    return (
                      <option
                        key={empleado.IdEmpleado}
                        value={empleado.IdEmpleado}
                      >
                        {empleado.Nombres} {empleado.Apellidos}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="sucursalDiv">
              <div className="caja-fondo-blanco-m-2">
                <b className="">*Ubicación</b>
                <div className="mt-3 row pl-3 pr-3">
                  <select
                    required
                    className="col-12 col-sm-4"
                    aria-haspopup="true"
                    aria-expanded="false"
                    name="region"
                    value={this.props.state.valueRegion}
                    onChange={this.props.RegionChange}
                  >
                    {this.props.state.ubigeoReg.map(obj => {
                      return (
                        <option key={obj.IdUbigeo} value={obj.CodRegion}>
                          {obj.NombreUbigeo}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    required
                    className="col-12 col-sm-4"
                    aria-haspopup="true"
                    aria-expanded="false"
                    name="provincia"
                    value={this.props.state.valueProvincia}
                    onChange={this.props.ProvinciaChange}
                  >
                    {this.props.state.ubigeoProv.map(obj => {
                      return (
                        <option key={obj.IdUbigeo} value={obj.CodProvincia}>
                          {obj.NombreUbigeo}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    required
                    className="col-12 col-sm-4"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="distrito"
                    value={this.props.state.valueDistrito}
                    name="IdUbigeo"
                    onChange={this.props.DistritoChange}
                  >
                    {this.props.state.ubigeoDis.map(obj => {
                      return (
                        <option key={obj.IdUbigeo} value={obj.IdUbigeo}>
                          {obj.NombreUbigeo}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="sucursalDiv">
            <div className="caja-fondo-blanco-m-2">
              <b className="">*Nombre Localidad</b>
                <input
                  className="input__linea"
                  name="Nombre"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Nombre}
                  placeholder="Nombre..."
                  required
                />
              </div>
            </div>

            <div className="sucursalDiv">
            <div className="caja-fondo-blanco-m-2">
              <b className="">*Dirección</b>
                <input
                  className="input__linea"
                  name="Direccion"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Direccion}
                  placeholder="Dirección..."
                  required
                />
              </div>
            </div>

            <div className="sucursalDiv">
            <div className="caja-fondo-blanco-m-2">
              <b className="">*Teléfono</b>
                <input
                  className="input__linea"
                  name="Telefono"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Telefono}
                  placeholder="Teléfono..."
                  required
                />

              </div>
              <div className="labelValidSucursal">
                {this.props.state.Errores.Telefono.length > 0 && (
                  <span>{this.props.state.Errores.Telefono}</span>
                )}
              </div>
            </div>

            <div className="sucursalDiv">
            <div className="caja-fondo-blanco-m-2">
              <b className="">*Celular</b>
                <input
                  className="input__linea"
                  name="Celular"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Celular}
                  placeholder="Celular..."
                  required
                />
              </div>
              <div className="labelValidSucursal">
                {this.props.state.Errores.Celular.length > 0 && (
                  <span>{this.props.state.Errores.Celular}</span>
                )}
              </div>
            </div>

            <div className="sucursalDiv">
            <div className="caja-fondo-blanco-m-2">
              <b className="">*Correo Electrónico</b>
                <input
                  className="input__linea"
                  name="CorreoElectronico"
                  onChange={this.props.onChange}
                  type="email"
                  value={this.props.state.CorreoElectronico}
                  placeholder="Correo Electronico..."
                  required
                />
              </div>
              <div className="labelValidSucursal">
                {this.props.state.Errores.CorreoElectronico.length > 0 && (
                  <span>{this.props.state.Errores.CorreoElectronico}</span>
                )}
              </div>
            </div>

            <div className="sucursalDiv">
            <div className="caja-fondo-blanco-m-2">
              <b className="">Ubigeo:</b>
                <b className="">{this.props.state.ubigeo}</b>
              </div>
            </div>

            <div className="sucursalDiv">
            <div className="caja-fondo-blanco-m-2">
              <b className="">*Código Establecimiento</b>
                <input
                  className="input__linea"
                  name="Codigo"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Codigo}
                  placeholder="Codigo..."
                  required
                />
              </div>
            </div>

            <div className="sucursalBoton mb-3 d-flex justify-content-end">
              {this.props.CambiarEstadoSede ? this.props.CambiarEstadoSede : null}
              <BotonGuardarLetras type="submit" className="ml-2" />
            </div>

          </div>
        </form>
      </section>
    );
  }
}

export default SucursalForm;
