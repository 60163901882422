import React, {useState, useEffect} from "react";
import LinkButton from "../componentes/LinkButton";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {borrar_sesion} from "../redux/actions";

import "./styles/Navbar.css";
import logo from "./images/mifacturaperublanco.png";
import opciones from "./images/icons8-menú-en-círculos-1002.png";
import menu_hamburguesa from "./images/icons8-menu-100.png";

const ProfileView = ({Usuario, Nombres, RazonSocial, idSucursal}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sucursal, setSucursal] = useState({});

    useEffect(() => {
        async function loadSucursal() {
            let res = await fetch(`/api/menus/sucursal?idSucursal=${idSucursal}`)
            if (res.ok) {
                let data = await res.json();
                setSucursal(data.sucursal);
            }
        }

        loadSucursal();
    }, [])

    const handleLogOut = async () => {
        const respuesta = await fetch("/api/auth/logout", {method: "POST"});

        switch (respuesta.status) {
            case 401:
                console.log("No existe sesión");
                break;
            case 200: {
                window.localStorage.clear();
            }
                break;
            default:
                console.log(
                    "Hubo un error al cerrar sesión. Status:" + respuesta.status
                );
        }

        window.localStorage.removeItem('extraConf')
        dispatch(borrar_sesion());
        history.push("/login");
    };

    return (
        <>
            <span className="navbar_titulo" src="">
                <strong>{Usuario}</strong>
                <br/>
                {`${sucursal.Nombre ? sucursal.Nombre : RazonSocial}` + " - " + `${Nombres}`}
            </span>
            <div className="dropdown">
                <button
                    className="link-button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <img className="mx-2" src={opciones} alt="Opciones"/>
                </button>

                <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuLink"
                >
                    <button className="dropdown-item" onClick={handleLogOut}>
                        Cerrar Sesión
                    </button>
                </div>
            </div>
        </>
    );
};

const LoginButton = () => {
    const history = useHistory();
    return (
        <LinkButton to="/login" state={{from: history.location.pathname}}
                    className="boton__verdeOscuro--letrasLogin mr-3" type="button">
            Iniciar Sesión
        </LinkButton>
    );
};

const Navbar = ({menuList}) => {
    const [titulo, setTitulo] = useState("")
    const {isLoggedIn, Usuario, Nombres, RazonSocial, IdSucursal} = useSelector(state => state.sesion);
    const {pathname} = useLocation();


    useEffect(() => {
        if (menuList === null) return
        const menuActive = menuList.find(menu => pathname.startsWith(menu.Ruta));
        if (menuActive) setTitulo(menuActive.Nombre)
    }, [menuList, pathname])

    return (
        <>
            <div className="navbar__container">
                <div className="navbar___logo">
                    <button
                        role="button"
                        className="link-button"
                        href="#Menu"
                        data-toggle="collapse"
                        aria-expanded="true"
                    >
                        <img
                            className="menu_hamburguesa"
                            src={menu_hamburguesa}
                            alt="MenuHamburguesa"
                        />
                    </button>
                    <img className="navar___log--img logo-responsive" src={logo} alt="LogoMFP"/>
                </div>
                <div className="navbar___content">
                    <div className="navbar___titulo">
                        {titulo}
                    </div>
                    <div className="navbar__login">
                        {isLoggedIn ? (
                            <ProfileView Usuario={Usuario} Nombres={Nombres} RazonSocial={RazonSocial}
                                         idSucursal={IdSucursal}/>
                        ) : (
                            <LoginButton/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
