import React, {Component} from "react";
import AsyncSelect from 'react-select/async';
import moment from "moment";
import Noty from "noty";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import Modal from "../componentes/Modal";
import FormPagoComprobante from "../componentes/Creditos/FormPagoComprobante";
import {decimalAdjust} from "../Global";
import DetalleDeudaComponent from "../componentes/Creditos/DetalleDeudaComponent";

export default class Creditos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            fechaFin: moment(new Date()).format('YYYY-MM-DD'),
            cliente: {},
            isLoading: false,
            deudas: [],
            isOpenPagoModal: false,
            selectedDeuda: null,
            isOpenDetalleModal: false,
            totalPagado: 0,
            totalDeuda: 0
        }
        this.searchClients = this.searchClients.bind(this);
        this.onChangeCliente = this.onChangeCliente.bind(this);
        this.onChangeFecha = this.onChangeFecha.bind(this);
        this.buscarDeudas = this.buscarDeudas.bind(this);
        this.onRealizarPagoHandler = this.onRealizarPagoHandler.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    searchClients(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/clientes/buscar/?query=${query}`)
                .then(r => r.json())
                .then(clientes => {
                    const mappedClientes = clientes.map(c => {
                        return {
                            label: `${c.PrimerNombre} ${c.SegundoNombre || ""} - ${c.RazonSocial || ""} ${c.NroTipoDocumento || ""}`,
                            value: c.IdCliente
                        }
                    });
                    resolve(mappedClientes)
                }).catch(reject)
        })
    }

    onChangeCliente(test) {
        this.setState({
            cliente: test
        })
    }

    onChangeFecha(e) {
        const elem = e.target;
        this.setState((state, props) => {
            let newState = {...state};
            newState[elem.getAttribute('name')] = elem.value
            return newState;
        })
    }

    async buscarDeudas() {
        const isValid = this.state.fechaInicio.length && this.state.fechaInicio.length;
        if (isValid)
            await this.fetchDeudas();
        else
            new Noty({
                type: "warning",
                theme: "bootstrap-v4",
                layout: "topCenter",
                text: `Los datos ingresados son inválidos`,
                timeout: 1500
            }).show();
    }

    async fetchDeudas() {
        this.setState({isLoading: true})
        let queryClient = this.state.cliente && this.state.cliente.value ? `&idCliente=${this.state.cliente.value}` : '';
        const query = `fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}${queryClient}`;
        let resDeudas = await fetch(`/api/creditos/boletas-facturas?${query}`);
        let deudas = await resDeudas.json();
        const totalDeuda = deudas.map(x => x.saldo).reduce((a, b) => a + b, 0)
        const totalImportes = deudas.map(x => x.Total).reduce((a, b) => a + b, 0)
        this.setState({
            deudas,
            totalDeuda,
            isLoading: false,
            totalPagado: totalImportes - totalDeuda
        })
    }

    onRealizarPagoHandler(deuda) {
        this.setState({isOpenPagoModal: true, selectedDeuda: deuda})
    }

    onCloseModal() {
        this.setState({isOpenPagoModal: false, isOpenDetalleModal: false})
        this.fetchDeudas();
    }

    onDetallePagosHandler(deuda) {
        this.setState({isOpenDetalleModal: true, selectedDeuda: deuda})
    }

    render() {
        return (
            <section className="ventas-fpay-section pt-5">
                <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                    <span className="align-self-center letra-fecha-fpay">
                        Desde:
                    </span>

                    <input type="date" onChange={this.onChangeFecha} name='fechaInicio' className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                        value={this.state.fechaInicio} />

                    <span className="align-self-center letra-fecha-fpay">
                        Hasta:
                    </span>

                    <input type="date" onChange={this.onChangeFecha} name='fechaFin' className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                        value={this.state.fechaFin} />

                </div>

                <div className="container mt-3">
                    <div className="caja-fondo-blanco-m-2">
                        <h5 className="sucursal-text-color">
                            Cliente
                        </h5>
                        <div className="col-sm np">
                            <AsyncSelect
                                isClearable={true}
                                defaultOptions={true}
                                onChange={this.onChangeCliente}
                                value={this.state.cliente}
                                loadOptions={this.searchClients}
                            />
                        </div>
                    </div>
                    <div className="seccion-btn-verde-mfp">
                        <button disabled={this.state.isLoading} onClick={this.buscarDeudas}
                                className="btn btn-outline-primary btn-verde-mfp">
                            Buscar
                        </button>
                    </div>
                </div>

                <div className="mt-3 container">
                    <div className="row">
                        <div className="col-sm-auto">
                            <h4>Total deuda: {decimalAdjust('floor', this.state.totalDeuda, -6)}</h4>
                        </div>
                        <div className="col-sm-auto">
                            <h4>Total pagado: {decimalAdjust("floor", this.state.totalPagado, -6)}</h4>
                        </div>
                    </div>
                </div>

                <div className="mt-3 container no-pad">
                    <div className="col-sm-12">
                        <ReactTable
                            loading={this.state.isLoading}
                            data={this.state.deudas}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase())
                            }
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Código",
                                            id: "IdRegistroVenta",
                                            width: "80",
                                            accessor: d => d.IdRegistroVenta,
                                            filterable: true
                                        },
                                        {
                                            Header: "Razón social",
                                            id: "datos",
                                            width: "180",
                                            accessor: d => d.datos,
                                            filterable: true
                                        },
                                        {
                                            Header: "Serie-Correlativo",
                                            id: "numSerie",
                                            width: "130",
                                            accessor: d => d.numSerie,
                                            filterable: true
                                        },
                                        {
                                            Header: "Importe total",
                                            id: "Total",
                                            width: "100",
                                            filterable: true,
                                            Cell: (d) => decimalAdjust('floor', d.original.Total, -2)
                                        },
                                        {
                                            Header: "Cant. Cuotas",
                                            id: "cantPagos",
                                            width: "100",
                                            accessor: d => d.cantPagos,
                                            filterable: true
                                        },
                                        {
                                            Header: "Saldo",
                                            id: "saldo",
                                            width: "100",
                                            filterable: true,
                                            accessor: d => d.saldo,
                                            Cell: d => decimalAdjust('floor', d.original.saldo, -2)
                                        },
                                        {
                                            Header: "Estado",
                                            id: "estado",
                                            width: "100",
                                            accessor: d => d.estado,
                                            filterable: true
                                        },
                                        {
                                            Header: "Ultima fecha de pago",
                                            id: "ultimaFecha",
                                            width: "100",
                                            accessor: d => d.ultimaFecha,
                                            filterable: true
                                        },
                                        {
                                            Header: "Dias de retraso",
                                            id: "dias_retraso",
                                            width: "50",
                                            accessor: d => d.dias_retraso > 0 ? d.dias_retraso : 0,
                                            filterable: true
                                        },
                                        {
                                            Header: "Acciones",
                                            width: "300",
                                            filterable: false,
                                            Cell: (d) => {
                                                if (d.original.estado === "Anulado" || d.original.estado === "Pagado")
                                                    return (
                                                        <div>
                                                            <button className="btn btn-outline-info"
                                                                    onClick={() => this.onDetallePagosHandler(d.original)}
                                                                    disabled={this.state.isLoading}>
                                                                Pagos y NC
                                                            </button>
                                                        </div>
                                                    )
                                                return (
                                                    <div>
                                                        <button onClick={() => this.onRealizarPagoHandler(d.original)}
                                                                className="btn btn-outline-secondary"
                                                                disabled={this.state.isLoading}>
                                                            Realizar pago
                                                        </button>
                                                        <button className="btn btn-outline-info"
                                                                onClick={() => this.onDetallePagosHandler(d.original)}
                                                                disabled={this.state.isLoading}>
                                                            Pagos y NC
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>

                <Modal title="Realizar pagos" isOpen={this.state.isOpenPagoModal} onClose={this.onCloseModal}>
                    <FormPagoComprobante deuda={this.state.selectedDeuda}/>
                </Modal>

                <Modal title="Detalles de pagos y créditos" isOpen={this.state.isOpenDetalleModal}
                       onClose={this.onCloseModal}>
                    <DetalleDeudaComponent deuda={this.state.selectedDeuda}/>
                </Modal>

            </section>
        );
    }
}
