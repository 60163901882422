import React, {useState, useEffect} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import apiFetch from "../helpers/apiFetch"
import Modal from "../componentes/Modal";
import UsuarioForm from "../componentes/UsuarioForm";
import Notify from '../componentes/Notify';
import {BotonLetras, BotonEditar, BotonDelete, BotonCheck, BotonAperturar} from '../componentes/Plantillas/Botones';
import {simpleReactTableColumn} from '../helpers/funcionesGlobales';

const Usuarios = () => {

    const [usuarios, setUsuarios] = useState(null);
    // usuario que se va a mostrar en el formulario en el modal. Si está null el modal se mantendrá cerrado, de lo contrario abre
    const [usuario, setUsuario] = useState(null);

    // Obtener usuarios cuando se cargue el componente
    useEffect(() => {
        const getUsuarios = async () => {
            const {data_tables, error} = await apiFetch("/api/usuarios", ["_estado_usuarios_list"]);
            if (error)
                return;
            const [usuariosList] = data_tables;
            // Concatenar "Nombres" con "Apellidos" y "RazonSocial" con "Sucursal"
            const usuarios = usuariosList.map(({
                                                   IdUsuario,
                                                   IdEmpleado,
                                                   IdSucursal,
                                                   Usuario,
                                                   Estado,
                                                   Roles,
                                                   Nombres,
                                                   Apellidos,
                                                   Sucursal,
                                                   RazonSocial
                                               }) => {
                return {
                    IdUsuario,
                    IdEmpleado,
                    IdSucursal,
                    Usuario,
                    Estado,
                    Roles: [...new Set(JSON.parse(Roles))],
                    Empleado: `${Nombres} ${Apellidos}`,
                    Sucursal: `${RazonSocial} - ${Sucursal}`
                }
            });
            setUsuarios(usuarios);
        }
        getUsuarios();
    }, [usuario]);

    const desactivarUsuario = async (IdUsuario, estado, msg) => {

        if (!IdUsuario)
            return Notify(`Error, id es '${IdUsuario}'`);
        const url = `/api/usuarios/${IdUsuario}`;
        const data = {Estado: estado};
        const {error} = await apiFetch(url, ["_estado_usuario_update"], {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
        if (error)
            return
        Notify(msg);
    }

    const columns = [
        simpleReactTableColumn("Usuario"),
        simpleReactTableColumn("Empleado"),
        simpleReactTableColumn("Sucursal"),
        simpleReactTableColumn("Estado"),

        {
            Header: "Opciones",
            filterable: false,
            Cell: (f) => {
                const usuario = f.original
                return (
                    <div align="center">
                        <BotonEditar className="mr-3" onClick={() => setUsuario(usuario)}/>
                        {
                            usuario.Estado === "inactivo" ? (
                                    <BotonCheck
                                        onClick={() => desactivarUsuario(usuario.IdUsuario, 'activo', "El usuario se activó correctamente.")}/>) :
                                (<BotonDelete
                                    onClick={() => desactivarUsuario(usuario.IdUsuario, 'inactivo', "Usuario inhabilitado exitosamente.")}/>)
                        }
                    </div>
                );
            }
        },
    ]

    return (
        <section className='ventas-fpay-section'>
            <div className="container py-4">
                <div className="d-flex justify-content-end mb-2">
                    <BotonLetras onClick={() => setUsuario({Roles: []})}>
                        Crear
                    </BotonLetras>
                </div>

                {usuarios &&
                <ReactTable
                    data={usuarios}
                    columns={columns}
                    filterable
                />
                }
            </div>

            <Modal
                isOpen={usuario !== null}
                onClose={() => setUsuario(null)}
                title="Nuevo Usuario"
            >
                <UsuarioForm
                    closeModal={() => setUsuario(null)}
                    Usuario={usuario}
                />
            </Modal>
        </section>
    )
}


export default Usuarios;
