import React from "react";
import {decimalAdjust, TIPO_STOCK} from "../../Global";

class ListaProductosPreventa extends React.Component {
    handleClick = (producto) => {
        const rowId = `prod-${producto.IdProducto}-${producto.IdPresentacion}`;
        const row = document.getElementById(rowId);
        if (row) {
            row.scrollIntoView({ behavior: 'smooth' });
            console.log({productoClick: producto})
            this.props.AddProductoInList(producto);
        }
    }

    render() {
        const showDefaultColor = Boolean(this.props.showDefaultColor);
        const stockKey = this.props.stockKey
        const showStock = 'showStock' in this.props ? this.props.showStock : true;
        const style = this.props.disableHeight ? {} : { height: '100%' };

        return (
            <React.Fragment>
                <div id="tableProdPreventa" className="lista_productos table-responsive" style={style}>
                    <div className="lista_productos__item">
                        <table hidden={this.props.load} className="table table-hover">
                            <thead>
                            <tr>
                                <th style={{borderTopLeftRadius: "10px", backgroundColor: "#034b2f", color:"white"}}><span>Producto / </span><span>Presentación</span></th>
                                <th style={{backgroundColor: "#034b2f", color:"white"}}>Precio</th>
                                {showStock ? <th style={{borderTopRightRadius: "0px",backgroundColor: "#034b2f", color:"white"}}>Stock</th> : null}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.productos.map(p => {
                                    let stock = 0
                                    if (stockKey) {
                                        stock = p[stockKey]
                                    } else {
                                        const preVentaStock = p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
                                            p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE_PREVENTA).Stock :
                                            p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE_PREVENTA).Stock;

                                        const currentStock = p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
                                            p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE).Stock :
                                            p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE).Stock;

                                        const valeCurrentStock = p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.STOCK_COMPROMETIDO_VALE).Stock

                                        stock = (currentStock) / p.TipoCambio;
                                    }

                                    let color = showDefaultColor ? "" : (p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ? "" : "#f78b2d")

                                    return (
                                        <tr onClick={() => this.handleClick(p)}
                                            className="prodPreventaTr"
                                            style={{cursor: "pointer"}}
                                            key={`prod-${p.IdProducto}-${p.IdPresentacion}`}
                                            title={`${p.descripcion}-${p.unidadMedida}`}
                                            id={`prod-${p.IdProducto}-${p.IdPresentacion}`}
                                        >
                                            <td style={{}}
                                                bgcolor={color}>
                                                {p.descripcion} {p.unidadMedida}
                                            </td>
                                            <td bgcolor={"#01a59c"}
                                                style={{padding: '5px 0'}}>
                                                <label
                                                    style={{color: "white"}}>{p.moneda}</label>

                                                <div className="">
                                                    <div style={{color: "white", textAlign: "end"}}>
                                                        {decimalAdjust('floor', p.precio, -2)}
                                                    </div>
                                                </div>
                                            </td>
                                            {showStock ? (
                                                <td style={{
                                                    backgroundColor: "rgb(53,96,90,0.18)",
                                                    borderTop: "1px solid white"
                                                }}>
                                                    <div style={{textAlign: "end"}}>
                                                        {
                                                            decimalAdjust('floor', stock, -2)
                                                        }
                                                    </div>
                                                </td>
                                            ) : null}
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ListaProductosPreventa;
