import React from "react";
import {BotonAddCliente2, BotonSearchCliente2, BotonCancelar} from "../Plantillas/Botones";
import _ from 'lodash';

class BuscarClientePorDoc extends React.Component {
    render() {
        return (

            <div className="preventa__cliente row overflow-movil ml-0 mr-0" style={this.props.style}>
                {/* <div className="col-12 mb-3">
                        <BotonCancelar
                            hidden={_.isUndefined(this.props.hideGoBack) ? false : (this.props.hideGoBack)}
                            onClick={this.props.goBack}
                            className="mr-2"
                        />
                    </div> */}
                    <div className="p-0 col-12 col-md-4">
                        <div className="p-0 col-12 m-auto">
                            <div className="">
                                <BotonAddCliente2
                                    hidden={_.isUndefined(this.props.hideAddCliente) ? false : (this.props.hideAddCliente)}
                                    onClick={this.props.handleClickNuevo}
                                    title="Nuevo Cliente" style={{ width: "100%", height: "35px",borderRadius: "15px" }} className="mt-3"
                                />
                            </div>
                            <div className="">
                                <BotonSearchCliente2
                                    hidden={_.isUndefined(this.props.hideSearchCliente) ? false : (this.props.hideSearchCliente)}
                                    style={{ width: "100%", height: "35px",borderRadius: "15px" }}
                                    onClick={this.props.handleClickBuscar}
                                    title="Buscar Cliente en la lista"
                                    className="mt-3"
                                />
                            </div>
                        </div>
                    
                    </div>
                    <div className="col-12 col-md-4 mt-3 pl-3" style={{ position: "relative", padding: "0" }}>
                        <input
                            className="letra-movil"
                            style={{ width: "100%", height: "47px", boxShadow: "none", borderRadius: "15px 15px 15px 15px", paddingTop: "10px", paddingBottom: "10px", border:"none" }}
                            name="Documento"
                            onChange={this.props.DocChange}
                            type="text"
                            value={this.props.Documento}
                            placeholder="DNI/RUC"
                            required
                            onFocus={(e) => this.props.setActiveInputDoc(e, "Documento")}
                        />
                    </div>
                    <div className="col-12 col-md-4 mt-3  pl-3">
                        <input
                            hidden={_.isUndefined(this.props.hideAlias) ? false : (this.props.hideAlias)}
                            className={this.props.aliasClass || "inputAlias"}
                            name="Alias"
                            onChange={this.props.AliasChange}
                            type="text"
                            value={this.props.Alias}
                            style={this.props.aliasStyle || {}}
                            placeholder={this.props.placeholder || "Alias (opcional)"}
                        />
                        {/* <input
                            className="letra-movil"
                            style={{ width: "100%", height: "47px", boxShadow: "none", borderRadius: "15px 15px 15px 15px", paddingTop: "10px", paddingBottom: "10px", border:"none" }}
                            name="Documento"
                            onChange={this.props.DocChange}
                            type="text"
                            value={this.props.Documento}
                            placeholder="DNI/RUC"
                            required
                            onFocus={(e) => this.props.setActiveInputDoc(e, "Documento")}
                        /> */}
                    </div>
            </div>

        );
    }
}

export default BuscarClientePorDoc;
