import React from "react";
import "./styles/DetallePago.css";
import {
    EFECTIVO,
    MASTER,
    VISA,
    AMERICAN,
    OTROS,
    PAGO_CREDITO,
    FPAY, decimalAdjust, PLIN, RAPPI, TUNKI, YAPE
} from "../../Global";
import EfectivoImg from "./images/efectivo.png";
import Mastercard from "./images/mastercard.png";
import Visa from "./images/visa.png";
import American from "./images/american.png";
import OtrosPagos from "./images/otros.png";
import Credito from "./images/credito.png";
import Fpay from "./images/fpy.jpeg";
import Yape from "./images/yape.png";
import Plin from "./images/plin logo.png";
import Rappi from "./images/rappi logo.png";
import Tunki from "./images/tunki logo.png";
import moment from "moment";

function getDatosMetodoPago(name){

}


const DetallePagoUI = props => {

    return (

        <>
            <div>
                <div className="detalle__pago">
                    <div className="items__detalle__pago botones-desk">
                        {props.IdTipoPago === EFECTIVO && (
                            <input
                                type="image"
                                src={EfectivoImg}
                                className="btn btn-outline-success item__detalle__pago--img"
                                alt="Tipo pago Efectivo"
                            />
                        )}
                        {props.IdTipoPago === MASTER && (
                            <input
                                type="image"
                                src={Mastercard}
                                className="btn btn-outline-danger item__detalle__pago--img"
                                alt="Tipo pago Master Card"
                            />
                        )}
                        {props.IdTipoPago === VISA && (
                            <input
                                type="image"
                                src={Visa}
                                className="btn btn-outline-primary item__detalle__pago--img"
                                alt="Tipo pago Visa"
                            />
                        )}
                        {props.IdTipoPago === AMERICAN && (
                            <input
                                type="image"
                                src={American}
                                className="btn btn-outline-info item__detalle__pago--img"
                                alt="Tipo pago American"
                            />
                        )}
                        {props.IdTipoPago === OTROS && (
                            <input
                                type="image"
                                src={OtrosPagos}
                                className="btn btn-outline-warning item__detalle__pago--img"
                                alt="Tipo pago Otros"
                            />
                        )}
                        {props.IdTipoPago === PAGO_CREDITO && (
                            <div>
                                <input
                                    type="image"
                                    src={Credito}
                                    className="btn btn-outline-danger item__detalle__pago--img"
                                    alt="Tipo pago Crédito"
                                />
                            </div>
                        )}
                        {props.IdTipoPago === FPAY && (
                            <div>
                                <input
                                    type="image"
                                    src={Fpay}
                                    className="btn btn-outline-danger item__detalle__pago--img"
                                    alt="Tipo pago Fpay"
                                />
                            </div>
                        )}
                        {props.IdTipoPago === PLIN && (
                            <div>
                                <input
                                    type="image"
                                    src={Plin}
                                    className="btn btn-outline-info item__detalle__pago--img"
                                    alt="Tipo pago Plin"
                                />
                            </div>
                        )}
                        {props.IdTipoPago === RAPPI&& (
                            <div>
                                <input
                                    type="image"
                                    src={Rappi}
                                    className="btn btn-outline-warning item__detalle__pago--img"
                                    alt="Tipo pago Rappi"
                                />
                            </div>
                        )}
                        {props.IdTipoPago === TUNKI && (
                            <div>
                                <input
                                    type="image"
                                    src={Tunki}
                                    className="btn btn-outline-danger item__detalle__pago--img"
                                    alt="Tipo pago Tunki"
                                />
                            </div>
                        )}
                        {props.IdTipoPago === YAPE && (
                            <div>
                                <input
                                    type="image"
                                    src={Yape}
                                    className="btn btn-outline-danger item__detalle__pago--img"
                                    alt="Tipo pago Yape"
                                />
                            </div>
                        )}
                        <input
                            className="item__detalle__pago"
                            type="number"
                            autoFocus
                            onFocus={props.handleFocus}
                            placeholder="Monto"
                            value={decimalAdjust('round', props.Monto, -2)}
                            onChange={props.handleMontoPago}
                            // disabled={props.IdTipoPago === FPAY}
                            tabIndex="2"
                            onKeyDown={props.handlePagoEnterKeyUp}
                            style={{width: "25%"}}
                        />

                        {props.IdTipoPago !== PAGO_CREDITO ? (
                            <input
                                type="text"
                                className="item__detalle__pago"
                                placeholder="Ref."
                                value={props.Referencia}
                                onChange={props.handleReferenciaPago}
                                style={{width: "25%"}}
                            />
                        ) : (
                            <div>
                                <input
                                    type="date"
                                    className="item__detalle__pago"
                                    value={moment(props.fechaPago).format("YYYY-MM-DD")}
                                    onChange={props.handleSelectPlazosCredito}
                                    style={{width: "25%"}}
                                />
                            </div>
                        )}

                        {props.IdTipoPago === FPAY ? (
                            <div className="invisible">
                                <button
                                    type="button"
                                    className="boton__outline-pequeno--r"
                                    onClick={props.handleRemovePago}
                                >
                                    {<i className="fas fa-trash-alt"/>}
                                </button>
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="boton__outline-pequeno--r"
                                onClick={props.handleRemovePago}
                            >
                                {<i className="fas fa-trash-alt"/>}
                            </button>
                        )}
                    </div>
                    <div className="items__detalle__pago botones-resp">
                        {props.IdTipoPago === EFECTIVO && (
                            <input
                                type="image"
                                style={{margin: "1.5rem 20px 0 0"}}
                                src={EfectivoImg}
                                className="btn btn-outline-success item__detalle__pago--img"
                                alt="Tipo pago Efecto"
                            />
                        )}
                        {props.IdTipoPago === MASTER && (
                            <input
                                type="image"
                                style={{margin: "1.5rem 20px 0 0"}}
                                src={Mastercard}
                                className="btn btn-outline-danger item__detalle__pago--img"
                                alt="Tipo pago Master Card"
                            />
                        )}
                        {props.IdTipoPago === VISA && (
                            <input
                                type="image"
                                style={{margin: "1.5rem 20px 0 0"}}
                                src={Visa}
                                className="btn btn-outline-primary item__detalle__pago--img"
                                alt="Tipo pago Visa"
                            />
                        )}
                        {props.IdTipoPago === AMERICAN && (
                            <input
                                type="image"
                                style={{margin: "1.5rem 20px 0 0"}}
                                src={American}
                                className="btn btn-outline-info item__detalle__pago--img"
                                alt="Tipo pago American"
                            />
                        )}
                        {props.IdTipoPago === OTROS && (
                            <input
                                type="image"
                                style={{margin: "1.5rem 20px 0 0"}}
                                src={OtrosPagos}
                                className="btn btn-outline-warning item__detalle__pago--img"
                                alt="Tipo pago Otros"
                            />
                        )}
                        {props.IdTipoPago === PAGO_CREDITO && (
                            <div>
                                <input
                                    type="image"
                                    style={{margin: "1.5rem 20px 0 0"}}
                                    src={Credito}
                                    className="btn btn-outline-danger item__detalle__pago--img"
                                    alt="Tipo pago Crédito"
                                />
                            </div>
                        )}
                        {props.IdTipoPago === FPAY && (
                            <div>
                                <input
                                    type="image"
                                    style={{margin: "1.5rem 20px 0 0"}}
                                    src={Fpay}
                                    className="btn btn-outline-danger item__detalle__pago--img"
                                    alt="Tipo pago FPay"
                                />
                            </div>
                        )}
                        <div className="card" style={{padding: "15px"}}>
                            <div className="row">
                                <div className="col-8">
                                    <input
                                        className="item__detalle__pago w-100"
                                        style={{margin: "0"}}
                                        type="number"
                                        autoFocus
                                        onFocus={props.handleFocus}
                                        placeholder="Monto"
                                        value={decimalAdjust('round', props.Monto, -2)}
                                        onChange={props.handleMontoPago}
                                        // disabled={props.IdTipoPago === FPAY}
                                        tabIndex="2"
                                        onKeyDown={props.handlePagoEnterKeyUp}
                                    />
                                </div>
                                <div className="col-4 pt-1">
                                    {props.IdTipoPago === FPAY ? (
                                        <div className="invisible">
                                            <button
                                                type="button"
                                                className="boton__outline-pequeno--r"
                                                onClick={props.handleRemovePago}
                                            >
                                                {<i className="fas fa-trash-alt"/>}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="boton__outline-pequeno--r"
                                                onClick={props.handleRemovePago}
                                            >
                                                {<i className="fas fa-trash-alt"/>}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default DetallePagoUI;
