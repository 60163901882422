import React, {Component} from "react";
import PresentacionesUI from "./PresentacionesUI";
import parent_icon_header from "../componentes/images/icons8-barcode-52.png";
import child_icon_header from "../componentes/images/icons8-clipboard-52.png";
import PageError from "../componentes/PageError";
import PageLoading from "../componentes/PageLoading";
import swal from "sweetalert";
import jsPDF from "jspdf";

import Header from "../componentes/Header";
import {ticketProductoPDF} from "../componentes/Caja/printDataCaja";
import {log} from "qrcode/lib/core/galois-field";

class Presentaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            presentaciones: [],
            idempresa: 1, // Pendiente pasar parámetro de empresa
            nombre: "",
            descripcion: "",
            tipodecambio: "",
            unidades: [],
            lotes: [],
            respuesta: [],
            statusModal: false,
            IdPresentacion: null,
            isLoading: false,
            error: null,
            respuestaDeletePresentacion: null,
            mensajedeanewpresentacion: null,
            IdProducto: null,
            CodigoBarras: null
        };

        this.handleNombreChange = this.handleNombreChange.bind(this);
        this.handleDescripcionChange = this.handleDescripcionChange.bind(this);
        this.handleTipoCambioChange = this.handleTipoCambioChange.bind(this);
        this.handleUnidadChange = this.handleUnidadChange.bind(this);
        this.handleLoteChange = this.handleLoteChange.bind(this);
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleSubmitNewPresentacion = this.handleSubmitNewPresentacion.bind(
            this
        );
        this.handleGetPresentaciones = this.handleGetPresentaciones.bind(this);
        this.handleGetPrecios = this.handleGetPrecios.bind(this);
        this.handleOnDeletePresentacion = this.handleOnDeletePresentacion.bind(
            this
        );
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.handleCodigoBarras = this.handleCodigoBarras.bind(this);
        this.handleOnPrint = this.handleOnPrint.bind(this);
    }

    componentDidMount() {

        this.getPresentaciones(this.props.IdProducto);
        this.getUnidades();
        this.getLotes(this.props.IdProducto);
        this.setState({
            isLoading: true,
            IdProducto: this.props.IdProducto
        });

    }

    handleNombreChange(e) {
        this.setState({nombre: e.target.value});
    }

    handleDescripcionChange(e) {
        this.setState({descripcion: e.target.value});
    }

    handleCodigoBarras(e) {
        this.setState({CodigoBarras: e.target.value})
    }

    handleTipoCambioChange(e) {
        this.setState({tipodecambio: e.target.value});
    }

    handleUnidadChange(e) {
        this.setState({idunidad: e.value});
    }

    getUnidades() {
        fetch("/api/productos/unidades")
            .then(response => response.json())
            .then(unidades =>
                this.setState({unidades: unidades.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    handleLoteChange(e) {
        this.setState({idlote: e.value});
    }

    getLotes(IdProducto) {
        fetch(`/api/productos/lotes/${IdProducto}`)
            .then(response => response.json())
            .then(lotes =>
                this.setState({lotes: lotes.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getPresentaciones(IdProducto) {
        fetch(`/api/presentaciones/${IdProducto}`)
            .then(response => response.json())
            .then(presentaciones =>
                this.setState({
                    presentaciones: presentaciones.respuesta,
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getPrecios(IdPresentacion) {
        return fetch(`/api/presentaciones/precios/${IdPresentacion}`)
            .then(response => response.json())
            .then(precios => {
                    return precios.respuesta;
                }
            ).catch(error => this.setState({error, isLoading: false}));
    }

    addNewPresentacion() {
        fetch("/api/presentaciones/new", {
            method: "POST",
            body: JSON.stringify({
                IdProducto: this.state.IdProducto,
                IdLote: this.state.idlote,
                IdUnidad: this.state.idunidad,
                Nombre: this.state.nombre,
                Descripcion: this.state.descripcion,
                TipoCambio: this.state.tipodecambio,
                CodigoBarras: this.state.CodigoBarras
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            // .then(this.setState({ isLoading: false }))
            .then(response => response.json())
            .then(responseData => {
                const [[table_data], [header]] = responseData;
                let error = false;
                if (header._estado) error = header._estado;
                else if (header._estadoInsertarPresentacion !== true)
                    error = header._estadoInsertarPresentacion;
                if (error) {
                    swal("ERROR", error, "error");
                } else {
                    this.setState({respuesta: table_data.IdPresentacion});
                    this.setState({
                        mensajedeanewpresentacion: "Se ha guardado correctamente"
                    });
                    setTimeout(() => {
                        this.setState({
                            mensajedeanewpresentacion: ""
                        });
                    }, 5000);
                    this.clearInputsPresentaciones();
                    this.getPresentaciones(this.state.IdProducto);
                }
            })
            .catch(error => this.setState({error, isLoading: false}));
    }

    handleSubmitNewPresentacion = async e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        try {
            await this.addNewPresentacion();
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    };

    handleOnCloseModal() {
        this.setState({
            statusModal: false
        });
    }

    handleOnClick(IdPresentacion) {
        return () => {
            this.setState({
                statusModal: true,
                IdPresentacion: IdPresentacion
            });
        };
    }

    handleKeyUp(event) {
        if (event.key === "Escape") {
            this.setState({
                statusModal: false
            });
        }
    }

    handleGetPresentaciones() {
        return () => {
            this.getPresentaciones(this.state.IdProducto);
        };
    }

    handleGetPrecios(idPresentacion) {
        return async () => {
            this.getPrecios(idPresentacion);
        };
    }

    clearInputsPresentaciones() {
        this.setState({
            idlote: "",
            idunidad: "",
            nombre: "",
            descripcion: "",
            tipodecambio: "",
            CodigoBarras: ""
        });
    }

    async deletePresentacion(IdPresentacion) {
        await fetch(`/api/presentaciones/delete/${IdPresentacion}`, {
            method: "POST",
            body: JSON.stringify({
                IdPresentacion: IdPresentacion
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(data => {
                this.setState({isLoading: false});
                return data;
            })
            .then(response => response.json())
            .then(responseData => {
                this.setState({respuestaDeletePresentacion: responseData.respuesta});
                if (this.state.respuestaDeletePresentacion === true) {
                    swal(
                        "¡Borrado!",
                        "¡La Presentación ha sido borrado satisfactoriamente!",
                        "success"
                    );
                }
            })
            .catch(error => this.setState({error, isLoading: false}));
        await this.getPresentaciones(this.state.IdProducto);
    }

    handleOnPrint(presentacion) {
        return async () => {
            console.log({presentacion})
            const precio = await this.getPrecios(presentacion.IdPresentacion)
            console.log({precio})
            const precioIgv =  precio == null || precio == []? 0: precio[0].PrecioIGV
            const descripcion = presentacion.DescripcionPresentacion == null ? "" : presentacion.DescripcionPresentacion
            const producto = {
                nombre: presentacion.NombreProd + " " + descripcion,
                PrecioConIgv: precioIgv
            }
            await ticketProductoPDF(producto)
        };
    }

    handleOnDeletePresentacion(IdPresentacion) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Desea borrar ésta Presentación?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete) {
                await this.deletePresentacion(IdPresentacion);
            }

            this.setState({
                IdPresentacion: IdPresentacion
            });
        };
    }

    handleSavePDF(nombreProducto) {
        return () => {
            let doc = new jsPDF();
            var pageHeight =
                doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var pageWidth =
                doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            doc.setFontSize(14);
            doc.text("PRESENTACIONES - " + nombreProducto, 14, 10);
            doc.autoTable({
                body: this.state.presentaciones,
                columns: [
                    {header: "PRESENTACIÓN", dataKey: "NombrePres"},
                    {header: "UNIDAD DE MEDIDAS", dataKey: "Descripcion"}
                ]
            });
            doc.setFontSize(10);
            doc.setFontType("italic");
            doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

            doc.save(`reporte_presentaciones_${nombreProducto}.pdf`);
        };
    }

    render() {
        const {isLoading, error} = this.state;
        if (isLoading) {
            return <PageLoading/>;
        }
        if (error) {
            return <PageError error={this.state.error}/>;
        }
        return (
            <div>
                {/* <Header
          parent_icon={parent_icon_header}
          child_icon={child_icon_header}
          parent="Presentaciones"
          child="Gestión"
        /> */}
                <PresentacionesUI
                    data={this.state.presentaciones}
                    unidades={this.state.unidades}
                    IdUnidad={this.state.idunidad}
                    lotes={this.state.lotes}
                    IdLote={this.state.idlote}
                    seleccionarunidad={this.handleUnidadChange}
                    TipoCambio={this.state.tipodecambio}
                    Nombre={this.state.nombre}
                    Descripcion={this.state.descripcion}
                    escribirtipodecambio={this.handleTipoCambioChange}
                    escribirnombre={this.handleNombreChange}
                    escribirdescripcion={this.handleDescripcionChange}
                    seleccionarlote={this.handleLoteChange}
                    envionewpresentacion={this.handleSubmitNewPresentacion}
                    onCloseModal={this.handleOnCloseModal}
                    statusModal={this.state.statusModal}
                    handleOnClick={this.handleOnClick}
                    handleKeyUp={this.handleKeyUp}
                    IdPresentacion={this.state.IdPresentacion}
                    IdProducto={this.state.IdProducto}
                    mensajedenewpresentacion={this.state.mensajedeanewpresentacion}
                    getPresentaciones={this.handleGetPresentaciones}
                    getPrecios={this.handleGetPrecios}
                    IdEmpresa={this.state.idempresa}
                    handleOnDeletePresentacion={this.handleOnDeletePresentacion}
                    handleOnPrint={this.handleOnPrint}
                    pdfViewer={this.handleSavePDF}
                    escribirCodigoBarras={this.handleCodigoBarras}
                    CodigoBarras={this.CodigoBarras}

                />
            </div>
        );
    }
}

export default Presentaciones;
