import React, { useEffect, useState } from "react";
import { notificarError } from "../Almacenes/AlmacenNotify";


export function EmpresaTransportistaAddForm (props) {
    const [payload, setPayload] = useState({
        NroDocumento: "",
        RazonSocial: "",
    });

    const register = async () => {
        try {
            const req = await fetch('/api/guiaRemision/empresas-transportistas/', {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (!req.ok) {
                throw new Error("Error al registrar empresa transportista");
            }

            const res = await req.json();

            props.onSaveEmpresaTransportista({
                IdEmpresaTransportista: res.IdEmpresaTransportista,
                NroDocumento: payload.NroDocumento,
                RazonSocial: payload.RazonSocial,
            });
        } catch (e) {
            notificarError("No se pudo registrar la empresa transportista")
        }
    }

    return <div className="pt-3 container">
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label><strong>Nro. Documento</strong></label>
                    <input type="text" className="form-control" value={payload.NroDocumento} onChange={e => {
                        setPayload({
                            ...payload,
                            NroDocumento: e.target.value,
                        })
                    }}/>
                </div>
                <div className="form-group">
                    <label><strong>Razon Social</strong></label>
                    <input type="text" className="form-control" value={payload.RazonSocial} onChange={e => {
                        setPayload({
                            ...payload,
                            RazonSocial: e.target.value,
                        })
                    }}/>
                </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
                <div className="seccion-btn-verde-mfp pb-3">
                    <button onClick={register} className="btn-verde-mfp btn btn-outline-primary px-3">
                        Registrar
                    </button>
                </div>
            </div>
        </div>
    
    </div>
}

