import React from "react";
import AsyncSelect from "react-select/async/dist/react-select.esm";

export default class AsyncSucursalPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.onChangeSucursales = this.onChangeSucursales.bind(this)
        this.searchSucursales = this.searchSucursales.bind(this)
    }

    searchSucursales(query) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true});
            const filterAll = Boolean(this.props.filterAll)
            fetch(`/api/reportes/sucursales?search=${query}${filterAll ? `&filterAll=1` : ""}`)
                .then(r => r.json())
                .then(docs => {
                    resolve(docs.map(d => {
                        return {...d, value: d.IdSucursal, label: `${d.Nombre}`}
                    }))
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        });
    }

    onChangeSucursales(e) {
        this.props.onChangeSucursales(e)
    }

    render() {
        return (
            <div className={this.props.divClass || "form-group caja-fondo-blanco-m-2"}>
                <label>Seleccione sucursales</label>
                <AsyncSelect
                    defaultOptions={true}
                    onChange={this.onChangeSucursales}
                    loadOptions={this.searchSucursales}
                    isClearable={true}
                    isLoading={this.state.isLoading}
                    isMulti={Boolean(this.props.isMulti)}
                />
            </div>
        );
    }

}
