import React from "react";
import {BOLETA, FACTURA, VALE} from "../../Global";

const _docs = [
    {key: FACTURA, name: "Factura"},
    {key: BOLETA, name: "Boleta"},
    {key: VALE, name: "Vale"}
]

export default class DocTypeSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            docs: _docs
        }
        this.onSelect = this.onSelect.bind(this)
        this.setDefaultValue = this.setDefaultValue.bind(this)
    }

    componentDidMount() {
        const hideKeys = this.props.hideDocKeys || []
        if (hideKeys && hideKeys.length)
            this.setState({docs: _docs.filter(d => !hideKeys.includes(d.key))}, this.setDefaultValue)
        else
            this.setDefaultValue()
    }

    setDefaultValue() {
        if (this.props.value)
            this.props.onChange(this.state.docs.find(d => String(d.key) === String(this.props.value)))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (String(prevProps.value) !== String(this.props.value))
            this.props.onChange(this.state.docs.find(d => String(d.key) === String(this.props.value)))
    }

    onSelect(e) {
        const value = e.target.getAttribute('value')
        const tmpDocs = this.state.docs
        this.props.onChange(tmpDocs.find(d => String(d.key) === String(value)))
    }

    render() {
        const docs = this.state.docs
        const value = this.props.value;

        return (
            <>
            {/* <div className="preventa__tipo--comprobante modo-desk">
                 {docs.map(d =>
                     <button
                        key={d.key}
                         type="button"
                        className={
                             (String(value) === String(d.key))
                                 ? "preventa__tipo--comprobante--item--active2"
                                 : "preventa__tipo--comprobante--item2"
                         }
                         value={d.key}
                         onClick={this.onSelect}
                     >
                         {d.name}
                     </button>
                 )}
            </div> */}
                <div className="preventa__tipo--comprobante modo-desk">
                    {docs.map((d, index) => (
                        <button
                            key={d.key}
                            type="button"
                            className={
                                (String(value) === String(d.key))
                                    ? (index === 0)
                                        ? "preventa__tipo--comprobante--item--active2 primer-btn"
                                        : (index === 1)
                                            ? "preventa__tipo--comprobante--item--active2 segundo-btn"
                                            : (index === 2)
                                                ? "preventa__tipo--comprobante--item--active2 tercer-btn"
                                                : "preventa__tipo--comprobante--item2"
                                    : (index === 0)
                                        ? "preventa__tipo--comprobante--item2 primer-btn"
                                        : (index === 1)
                                            ? "preventa__tipo--comprobante--item2 segundo-btn"
                                            : (index === 2)
                                                ? "preventa__tipo--comprobante--item2 tercer-btn"
                                                : "preventa__tipo--comprobante--item2"
                            }
                            value={d.key}
                            onClick={this.onSelect}
                        >
                            {d.name}
                        </button>
                    ))}
                </div>
            <div className="preventa__tipo--comprobante modo-resp mt-3">
                <select
                    value={value}
                    onChange={this.onSelect}
                    className="preventa__tipo--comprobante--select"
                >
                    {docs.map(d =>
                        <option
                            key={d.key}
                            value={d.key}
                            className="preventa__tipo--comprobante--option"
                        >
                            {d.name}
                        </option>
                    )}
                </select>
            </div>
            </>
        );
    }
}
