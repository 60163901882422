import React from 'react';
import '../styles/Sucursal.css';
import PageLoading from "../PageLoading.js";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
import { BotonEditar, BotonExcel, BotonPDF, BotonAdd, BotonCancelar, BotonAddAlmacen } from '../Plantillas/Botones';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Sucursales extends React.Component {

  constructor() {
    super()
    this.state = {
      Activo: true,
      buscarEstado: 'Activo',
      buscar: "",
      buscarEmpresa: "",
      data: {
        sucursales: []
      },
      loading: true,
      error: null,
      dataEmpresas: [],
      nombreEmpresa: ""
    }
    this.getEmpresas = this.getEmpresas.bind(this)
    this.getSucursales = this.getSucursales.bind(this)
    this.EstadoChange = this.EstadoChange.bind(this)
    this.showPrincipal = this.showPrincipal.bind(this)
    this.handleSavePDF = this.handleSavePDF.bind(this)
  }

  componentDidMount() {
    this.getEmpresas();
    this.getSucursales();
    this.intervalo = setInterval(this.getSucursales, 3000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalo)
  }

  getEmpresas = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/empresas");
      const data = await response.json();
      const empresas = data.empresas.filter(act => {
        return act.Estado == 'Activo'
      });

      this.setState({
        loading: false,
        dataEmpresas: empresas
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
    //console.log(this.state.dataEmpresas)
  };

  getSucursales = async () => {
    this.setState({ loading: true, error: null })
    try {
      const response = await fetch('/api/sucursales');
      const data = await response.json();
      this.setState({
        loading: false,
        data: data
      })
    } catch (error) {
      this.setState({ loading: false, error: error })
    }
    //console.log(this.state.data)
  }

  handleChange(e) {

  }

  EstadoChange(e) {
    const { checked } = e.target;
    //console.log(checked)
    if (checked === false) {
      this.setState({
        buscarEstado: "Inactivo",
        Activo: checked
      });
    } else {
      this.setState({
        buscarEstado: "Activo",
        Activo: checked
      });
    }
  }

  EmpresaChange = e => {
    this.setState({
      buscarEmpresa: e.target.value,
      nombreEmpresa: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
    });
  };

  handleSavePDF(e) {
    e.preventDefault();
    let doc = new jsPDF({
      orientation: "landscape"
    });

    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.setFontSize(14);
    doc.text(`RELACION DE SUCURSALES ${this.state.buscarEstado.toUpperCase()}S- ${this.state.nombreEmpresa} -`, 10, 10);
    doc.autoTable({
      body: this.state.data.sucursales.filter((sucursales) => {
        return sucursales.Nombre.concat(sucursales.Codigo).toLowerCase().includes(this.state.buscar.toLowerCase())
          && sucursales.Estado === this.state.buscarEstado &&
          sucursales.RazonSocial === this.state.buscarEmpresa
      }),
      columns: [
        { header: 'NOMBRE', dataKey: 'Nombre' },
        { header: 'UBIGEO', dataKey: 'NombreUbigeo' },
        { header: 'TELÉFONO', dataKey: 'Telefono' },
        { header: 'CELULAR', dataKey: 'Celular' },
        { header: 'DIRECCIÓN', dataKey: 'Direccion' },
        { header: 'CÓDIGO DE ESTABLECIMIENTO', dataKey: `Codigo` },
        { header: 'CORREO ELECTRÓNICO', dataKey: 'CorreoElectronico' }

      ],
      styles: {
        fontSize: 11,
      },
    }).setFontSize(11);
    doc.setFontSize(10);
    doc.setFontType("italic");
    doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

    doc.save(`relación_Sucursales_${this.state.nombreEmpresa}.pdf`);
  }

  getDataExportExcel(dataSet) {
    return [
      {
        columns: [
          {
            title: `RELACION DE SUCURSALES ${this.state.buscarEstado.toUpperCase()}S- ${this.state.nombreEmpresa} -`,
            width: { wpx: 80 }
          }
        ],
        data: []
      },
      {
        columns: [
          {
            title: `NOMBRE`,
            width: { wpx: 150 }
          },
          {
            title: `UBIGEO `,
            width: { wpx: 150 }
          },
          {
            title: `TELÉFONO `,
            width: { wpx: 80 }
          },
          {
            title: `CELULAR  `,
            width: { wpx: 150 }
          },
          {
            title: `DIRECCIÓN`,
            width: { wpx: 150 }
          },
          {
            title: `CÓDIGO DE ESTABLECIMIENTO`,
            width: { wpx: 150 }
          },
          {
            title: `CORREO ELECTRÓNICO`,
            width: { wpx: 150 }
          }
        ],
        data: dataSet.map(row => {
          return [
            {
              value: row.Nombre,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreUbigeo,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Telefono,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Celular,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Direccion,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Codigo,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.CorreoElectronico,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            }
          ];
        })
      }
    ];
  }

  //para mostrar si es principal 1 = Si , 0 = No
  showPrincipal(principal) {
    if (principal === 1) {
      return "Principal";
    } else {
      return "Secundario";
    }

  }

  render() {

    const sucursales = this.state.data.sucursales.filter((sucursales) => {
      return sucursales.Nombre.concat(sucursales.Codigo).toLowerCase().includes(this.state.buscar.toLowerCase())
        && sucursales.Estado === this.state.buscarEstado &&
        sucursales.RazonSocial === this.state.buscarEmpresa
    })
    const dataExport = this.getDataExportExcel(sucursales)

    if (this.state.loading === true && this.state.data === undefined) {
      return (
        <React.Fragment>
          <div className="d-flex justify-content-center">
            <label>Cargando...</label>
            <div className="spinner-border text-primary ml-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </React.Fragment>
      )
    }

    if (this.state.data.sucursales.length === 0) {
      return (
        <React.Fragment>
          <div className="p-5">
            <div className="form-group">
              <BotonCancelar
                onClick={this.props.history.goBack}
                className="mr-2"
              />
              <label className="mr-3">Buscar por Empresa</label>
              <select
                required
                className="dropdown-toggle input__lineaMediana"
                aria-haspopup="true"
                name="empresa"
                value={this.state.buscarEmpresa}
                onChange={this.EmpresaChange}
              >
                <option>Seleccionar Empresa</option>
                {this.state.dataEmpresas.map(empresa => {
                  return (
                    <option
                      key={empresa.RazonSocial}
                      value={empresa.RazonSocial}
                    >
                      {empresa.RazonSocial}
                    </option>
                  );
                })}
              </select>

              <label className="mr-3 ml-3">Activos</label>
              <label className="checkbox-toggle">
                <div className="toggle">
                  <input
                    type="checkbox"
                    name="Valorizacion"
                    className="toggle-state"
                    onChange={this.EstadoChange}
                    checked={this.state.Activo}
                  />
                  <div className="toggle-inner">
                    <div className="indicator" />
                  </div>
                  <div className="active-bg" />
                </div>
              </label>
              <BotonAdd
                className="ml-2"
                to="/sucursales/add/"
                title="Nueva Sede/Sucursal"
              />
              <BotonPDF
                className="ml-2 mr-2"
                title="Guardar PDF"
                onClick={this.handleSavePDF}
              />
              <ExcelFile
                element={
                  <BotonExcel type="button" title="Guardar Excel" />
                }
                filename={`relación_Sucursales_${this.state.nombreEmpresa}`}
              >
                <ExcelSheet
                  dataSet={dataExport}
                  name="SucursalesFile"
                >
                </ExcelSheet>
              </ExcelFile>
            </div>
            <div>
              <PageLoading />
            </div>
          </div>
        </React.Fragment>
      )
    } else {
      const filtrarSucursales = this.state.data.sucursales.filter((sucursales) => {
        return sucursales.Nombre.concat(sucursales.Codigo).toLowerCase().includes(this.state.buscar.toLowerCase())
          && sucursales.Estado === this.state.buscarEstado &&
          sucursales.RazonSocial === this.state.buscarEmpresa

      })

      if (this.state.error) {
        return `Error: ${this.state.error.message}`;
      }

      if (filtrarSucursales.length === 0) {
        return (
          <section className="ventas-fpay-section-vh cienvh">
            <div className="pt-5 container">
              <div className="form-group d-flex justify-content-between">
                <BotonCancelar
                  onClick={this.props.history.goBack}
                  className="mr-2"
                />
                <div>
                  <label className="mr-3 ml-3 mt-3">Activos</label>
                  <label className="checkbox-toggle">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        name="Valorizacion"
                        className="toggle-state"
                        onChange={this.EstadoChange}
                        checked={this.state.Activo}
                      />
                      <div className="toggle-inner">
                        <div className="indicator" />
                      </div>
                      <div className="active-bg" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="caja-fondo-blanco-m-2">
                <label className="mr-3">Buscar por Empresa</label>
                <select
                  required
                  className="dropdown-toggle input__lineaMediana w-100"
                  aria-haspopup="true"
                  name="empresa"
                  value={this.state.buscarEmpresa}
                  onChange={this.EmpresaChange}
                >
                  <option>Seleccionar Empresa</option>
                  {this.state.dataEmpresas.map(empresa => {
                    return (
                      <option
                        key={empresa.RazonSocial}
                        value={empresa.RazonSocial}
                      >
                        {empresa.RazonSocial}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="d-flex justify-content-end">
                <BotonPDF
                  className="ml-2 mr-2"
                  title="Guardar PDF"
                  onClick={this.handleSavePDF}
                />

                <ExcelFile
                  element={
                    <BotonExcel type="button" title="Guardar Excel" />
                  }
                  filename={`relación_Sucursales_${this.state.nombreEmpresa}`}
                >
                  <ExcelSheet
                    dataSet={dataExport}
                    name="SucursalesFile"
                  >
                  </ExcelSheet>
                </ExcelFile>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <BotonAddAlmacen
                  className="ml-2"
                  to="/sucursales/add"
                  title="Nueva Sede/Sucursal"
                />
              </div>
              <div>
                <h3>No se encontraron Sedes</h3>
              </div>
            </div>
          </section>
        )
      }

      return (
        <section className="ventas-fpay-section-vh cienvh">
          <div className="pt-5 container">
            <div className="form-group d-flex justify-content-between">
              <BotonCancelar
                onClick={this.props.history.goBack}
                className="mr-2"
              />
              <div>
                <label className="mr-3 ml-3 mt-3">Activos</label>
                <label className="checkbox-toggle">
                  <div className="toggle">
                    <input
                      type="checkbox"
                      name="Valorizacion"
                      className="toggle-state"
                      onChange={this.EstadoChange}
                      checked={this.state.Activo}
                    />
                    <div className="toggle-inner">
                      <div className="indicator" />
                    </div>
                    <div className="active-bg" />
                  </div>
                </label>
              </div>
            </div>
            <div className="caja-fondo-blanco-m-2">
              <label className="mr-3">Buscar por Empresa</label>
              <select
                required
                className="dropdown-toggle input__lineaMediana w-100"
                aria-haspopup="true"
                name="empresa"
                value={this.state.buscarEmpresa}
                onChange={this.EmpresaChange}
              >
                <option>Seleccionar Empresa</option>
                {this.state.dataEmpresas.map(empresa => {
                  return (
                    <option
                      key={empresa.RazonSocial}
                      value={empresa.RazonSocial}
                    >
                      {empresa.RazonSocial}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex justify-content-end">
              <BotonPDF
                className="ml-2 mr-2"
                title="Guardar PDF"
                onClick={this.handleSavePDF}
              />

              <ExcelFile
                element={
                  <BotonExcel type="button" title="Guardar Excel" />
                }
                filename={`relación_Sucursales_${this.state.nombreEmpresa}`}
              >
                <ExcelSheet
                  dataSet={dataExport}
                  name="SucursalesFile"
                >
                </ExcelSheet>
              </ExcelFile>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <BotonAddAlmacen
                className="ml-2"
                to="/sucursales/add/"
                title="Nueva Sede/Sucursal"
              />
            </div>
            <div className="pt-3">
              <ReactTable
                data={filtrarSucursales}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    columns: [
                      {
                        Header: "Acción",
                        filterable: false,
                        headerClassName: "",
                        width: 80,
                        Cell: original => {
                          return (
                            <BotonEditar
                              to={"/sucursales/edit/" + original.original.IdSucursal}
                            />
                          );
                        }
                      },
                      {
                        Header: "Nombre Localidad",
                        id: "Nombre",
                        // accessor: "Nombre",
                        headerClassName: "",
                        width: 300,
                        accessor: d => d.Nombre,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["Nombre"] }),
                        filterAll: true
                      },
                      {
                        Header: "Ubigeo - Dirección",
                        id: "Direccion",
                        // accessor: "Direccion",
                        headerClassName: "",
                        width: 300,
                        accessor: d => d.NombreUbigeo.concat(" - ", d.Direccion),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Direccion"]
                          }),
                        filterAll: true
                      },
                      {
                        Header: "Principal",
                        id: "EsPrincipal",
                        //accessor: "EsPrincipal",
                        headerClassName: "",
                        Cell: original => {
                          return this.showPrincipal(
                            original.original.EsPrincipal
                          );
                        },
                        accessor: d => this.showPrincipal(d.EsPrincipal),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["EsPrincipal"]
                          }),
                        filterAll: true
                      },
                      {
                        Header: "Codigo",
                        id: "Codigo",
                        // accessor: "Codigo",
                        headerClassName: "",
                        width: 70,
                        accessor: d => d.Codigo,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["Codigo"] }),
                        filterAll: true
                      }

                    ]
                  }
                ]}
                defaultPageSize={5}
                //pageSize={10}
                className="-striped -highlight"
                // Text de paginación
                previousText="Anterior"
                nextText="Siguiente"
                loadingText="Cargando..."
                noDataText="No se encontraron registros"
                pageText="Página"
                ofText="de"
                rowsText="filas"
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              />
            </div>
          </div>
        </section>
      )
    }
  }
}

export default Sucursales;