import React, { useEffect, useState } from 'react';
import { notificarError, notificarMsg } from '../Almacenes/AlmacenNotify';


const MAX_ERROR_COUNT = 20;


export function EnvioOperadorMasivo(props) {
    const [invoices, setInvoices] = useState([]);
    const [counter, setCounter] = useState(-1);
    const [errorCounter, setErrorCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setInvoices([]);

        if (isNaN(+props.idSucursal) || +props.idSucursal == 0) {
            return;
        }
        
        const task = async () => {
            try {
                const req = await fetch(`/api/ventas/filter-fe?idSucursal=${props.idSucursal}&fecha=${props.fechaBusqueda}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "page": 0,
                        "pageSize": 10000,
                        "sorted": [],
                        "filtered": []
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!req.ok) {
                    throw new Error();
                }

                const data = await req.json();

                const shuffle = (_invoices) => {
                    let currentIndex = _invoices.length;

                    while (currentIndex != 0) {
                        let randomIndex = Math.floor(Math.random() * currentIndex);
                        currentIndex--;
                        [_invoices[currentIndex], _invoices[randomIndex]] = [_invoices[randomIndex], _invoices[currentIndex]];
                      }
                }
                
                const _invoices = data.ventas.filter(x => ['Pendiente', 'Rechazado'].includes(x.EstadoCE))
                
                shuffle(_invoices);

                setInvoices(_invoices)
            } catch (e) {
                console.error(e);
                notificarError("Ocurrió un error al verificar los documentos en estado pendiente.")
            }
        }

        task();
    }, [props.attempt, props.idSucursal, props.fechaBusqueda])

    useEffect(() => {
        if (invoices.length === 0) {
            setCounter(-1);
            setIsLoading(false);
            setErrorCounter(0);
            return;
        }

        if (counter < 0) {
            return;
        }

        if (errorCounter >= MAX_ERROR_COUNT) {
            setCounter(-1);
            setIsLoading(false);
            notificarError("En este momento no es posible enviar los comprobantes al operador. Intente luego.")

            props.onCompleted();
            return;
        }

        if (counter >= invoices.length) {
            setCounter(-1);
            setIsLoading(false);
            
            notificarMsg(`Los comprobantes han sido enviados. Enviados: ${invoices.length - errorCounter}, Con error: ${errorCounter} `)

            props.onCompleted();
            return;
        }

        sendOnce(invoices[counter]);
    }, [counter]);

    const delay = (t) => new Promise(resolve => setTimeout(() => resolve(), t));

    const start = async () => {
        setCounter(0);
        setErrorCounter(0);
        setIsLoading(true);
    }

    const sendOnce = async (invoice) => {
        let timeForDelay = 150;

        try {
            const req = fetch(`/api/configFactElectronica/ce/${invoice.IdRegistroVenta}`)

            if (!req.ok) {
                throw new Error(`Ocurrió un error al enviar el comprobante: ${invoice.Serie}-${invoice.NumeroComprobante}`)
            }

            const { ok, timeout, error } = await req.json();

            if (timeout) {
                throw new Error(`El envio del comprobante: ${invoice.Serie}-${invoice.NumeroComprobante}, ha tardado más de lo esperado.`)
            }

            if (!ok) {
                throw new Error(`No se pudo enviar el comprobante: ${invoice.Serie}-${invoice.NumeroComprobante}.`)
            }
        } catch (e) {
            notificarError(e.message);
            timeForDelay = 1_500;
            setErrorCounter(errorCounter => errorCounter + 1);
        }
        
        await delay(timeForDelay);
        setCounter(counter => counter + 1);
    }

    if (invoices.length == 0) {
        return null;
    }

    return <>
        <button disabled={isLoading} onClick={start} className={`btn btn-outline-secondary btn-verde-mfp ${props.className}`}>
            {isLoading ? `Enviando ${counter + 1}/${invoices.length}` : 'Enviar a operador'}
        </button>
    </>
}
