import React from "react";
import $ from "jquery";
import {BotonAddPequeño, BotonDown, BotonMenosPequeño, BotonRemovePequeño, BotonPesar} from "../Plantillas/Botones";
import {decimalAdjust, TIPO_STOCK} from "../../Global";
import _ from 'lodash';
import MagellanListener, { KILOGRAMO_UNIT_DESCRIPTION } from "../../helpers/MagellanListener";


export default class DetallesProductoPreventa extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cantidadInput: 1,
            verPrecios: true,
            magellanListenerIsAvailable: false,
        }
        this.verificarPrecioCosto = this.verificarPrecioCosto.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let divCarrito = $("#tableDetProdPreventa");
        let rows = $("#IdDetProductoCarrito").find('tr');

        if (rows.length >= 6)
            divCarrito.scrollTop(divCarrito.prop('scrollHeight'))
    }

    async verificarPrecioCosto() {
        try {
            const responseVal =await fetch(`/api/prices/precioCosto/validar`);
            const data = await responseVal.json();
            this.setState({verPrecios: true})
        } catch (e) {
            this.setState({verPrecios: false})
        }
    }

    async componentDidMount() {
        MagellanListener.addSubscriber(this);
        this.setState({ magellanListenerIsAvailable: MagellanListener.sourceIsRunning() });
        await this.verificarPrecioCosto()
    }

    requestWeight(detalle) {
        const add = this.props.detalles.some(p => p.IdProducto === detalle.IdProducto);
        MagellanListener.requestWeight(detalle.IdProducto, add);
    }

    onRecieveWeight(weight, owner, addWeight) {
        const detalle = this.props.detalles.find(p => p.IdProducto === owner);

        if (!detalle) {
            return;
        }

        this.props.InputCantidadDetalleChange({
            target: {
                value: addWeight ? (detalle.Cantidad + weight) - 1 : weight
            }
        }, detalle);
    }

    onMagellanChangeAvailability(availability) {
        this.setState({ magellanListenerIsAvailable: availability });
    }

    render() {
        let canUpdatePrice = !_.isUndefined(this.props.canUpdatePrice) ? this.props.canUpdatePrice : true;
        let canIncrementProductPrice = !!this.props.incrementarPrecioProd ? this.props.incrementarPrecioProd : false;

        return (
            <div id="tableDetProdPreventa" className="preventa__carrito mt-2">
                <table className="table table-hover" style={{overflow: "hidden"}}>
                    <thead>
                    <tr>

                        <th className="tdDetallePreventa cabecera-tabla"
                            style={{borderRadius: '15px 15px 0 0', backgroundColor: "#01A59C"}}>
                            <div style={{color: "white"}} className='inputDet'>CANTIDAD&nbsp;</div>
                            <div style={{color: "white"}} className='inputDet'>/ PRECIO</div>
                            <div style={{color: "white"}} className='inputDet'>/ TOTAL</div>
                            <div className='inputDet no-visible-resp'/>
                        </th>
                    </tr>
                    </thead>
                    <tbody id="IdDetProductoCarrito">
                    {
                        this.props.detalles.map((det, i) => {
                            return (
                                <tr
                                    // id={`det-${i}`}
                                    id={`producto-${det.IdPresentacion}`}
                                    key={`det-${det.IdPresentacion}`}
                                    bgcolor={this.props.defaultBgInProductDetail ? null : det.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ? "" : "#f78b2d"}
                                    className="trDetallePreventa"
                                    onKeyUp={(e) => this.props.onSecretKeyPress(e, det)}
                                >
                                    <td className="pt-3 pl-3">
                                        <div>
                                            <div style={{fontSize: "14px"}} className="itemNombre">
                                                <div>
                                                    {
                                                        String(det.EsFacturable) === "1" ? `${det.descripcion} ${det.unidadMedida}`
                                                            :
                                                            (
                                                                <strong>
                                                                    <p className="fw-bolder"
                                                                       style={{color: "#01a59c"}}>
                                                                        {det.descripcion} {det.unidadMedida}
                                                                    </p>
                                                                </strong>
                                                            )
                                                    }

                                                </div>
                                                <div className="botones-desk">
                                                    {this.props.venderConListaPrecios === 1 ?
                                                        (

                                                            <select
                                                                required
                                                                disabled={this.props.load}
                                                                className="mt-1 mr-1"
                                                                value={det.Precio}
                                                                onChange={(e) => this.props.PrecioChange(e, det)}
                                                            >
                                                                <option value={det.PrecioMenor}>
                                                                    Menor/Lista
                                                                </option>
                                                                <option value={det.PrecioFamiliar}>
                                                                    Familiar
                                                                </option>
                                                                <option value={det.PrecioEspecial}>
                                                                    Especial
                                                                </option>
                                                            </select>
                                                        ) : null
                                                    }
                                                    {this.state.verPrecios ?
                                                        (
                                                            <div>
                                                                Precio Costo:
                                                                    {det.PrecioCosto
                                                                        .toLocaleString("en-GB", {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })}
                                                            </div>
                                                        ) :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tdDetallePreventa-desk" key={`div-det-${det.id}`}>

                                            <div className="inputDet">
                                                { (this.state.magellanListenerIsAvailable && 'unidadMedida' in det && det.unidadMedida === KILOGRAMO_UNIT_DESCRIPTION)
                                                    ? <input type="number" style={{backgroundColor: "rgb(53,96,90,0.18)"}} className="inputCantidadDet" name="Cantidad" value={det.Cantidad} readOnly/> : 
                                                    <input
                                                        onClick={(e) => {
                                                            e.target.select()
                                                        }}
                                                        readOnly={this.props.load}
                                                        type="number"
                                                        style={{backgroundColor: "rgb(53,96,90,0.18)"}}
                                                        step="1"
                                                        min="1"
                                                        name="Cantidad"
                                                        value={det.Cantidad}
                                                        className="inputCantidadDet"
                                                        onChange={e => this.props.InputCantidadDetalleChange(e, det)}
                                                        onBlur={e => this.props.onBlurInputCantidad(e, det)}
                                                    />
                                                }
                                                
                                            </div>
                                            <div className="inputDet">
                                                {canUpdatePrice ||  canIncrementProductPrice ? (

                                                    <input
                                                        onClick={(e) => {
                                                            e.target.select()
                                                        }}
                                                        readOnly={this.props.load}
                                                        type="number"
                                                        style={{backgroundColor: "#01a59c", color: "white"}}
                                                        step="1"
                                                        min="0"
                                                        value={det.PrecioVenta}
                                                        onChange={e => this.props.InputPrecioVentaChange(e, det)}
                                                        name="PrecioVenta"
                                                        className="inputPrecioVentaDet"
                                                        onBlur={e => this.props.onBlurPrecioVenta(e, det)}
                                                    />

                                                ) : (

                                                    <input
                                                        onClick={(e) => {
                                                            e.target.select()
                                                        }}
                                                        readOnly={true}
                                                        type="number"
                                                        style={{backgroundColor: "#01a59c", color: "white"}}
                                                        step="1"
                                                        min="0"
                                                        disabled={true}
                                                        value={det.PrecioVenta}
                                                        name="PrecioVenta"
                                                        className="inputPrecioVentaDet"
                                                    />
                                                )}
                                            </div>


                                            <div className="inputDet">
                                                {/*{this.props.canUpdateTotal ?*/}
                                                    <input type="number"
                                                           onChange={e => this.props.onChangeTotal(e, det)}
                                                           onBlur={e => this.props.onBlurTotal(e, det)}
                                                           onClick={e => e.target.select()}
                                                           readOnly={!this.props.canUpdateTotal}
                                                           style={{backgroundColor: "#034b2f", color: "white"}}
                                                           value={decimalAdjust('round', det.Total - (det.Total * this.props.descuentoPorCliente/100), -2)}
                                                           className="inputPrecioVentaDet"/>
                                                    {/*// decimalAdjust('round',det.Total - (det.Total * this.props.descuentoPorCliente/100), -2)*/}
                                                {/*}*/}
                                            </div>

                                            <div>

                                                <div>
                                                { (this.state.magellanListenerIsAvailable && 'unidadMedida' in det && det.unidadMedida === KILOGRAMO_UNIT_DESCRIPTION) ?
                                                    <BotonPesar 
                                                        disabled={this.props.load}
                                                        className="ml-2"
                                                        type="button"
                                                        title="Pesar"
                                                        onClick={() => this.requestWeight(det)}
                                                    /> :
                                                    <>
                                                        <BotonMenosPequeño
                                                            disabled={this.props.load}
                                                            className="ml-2"
                                                            type="button"
                                                            title="Disminuir en 1"
                                                            onClick={() =>
                                                                this.props.RestarCantidadDet(det)
                                                            }
                                                        />

                                                        <BotonAddPequeño
                                                            disabled={this.props.load}
                                                            className="ml-2"
                                                            type="button"
                                                            title="Aumentar en 1"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                this.props.AgregarCantidadDet(det)
                                                            }}
                                                        />
                                                    </>
                                                }
                                                    

                                                    <BotonRemovePequeño
                                                        disabled={this.props.load}
                                                        className="ml-2"
                                                        type="button"
                                                        title="Quitar item"
                                                        onClick={() =>
                                                            this.props.RemoveProductoInList(det)
                                                        }
                                                    />

                                                    <BotonDown
                                                        disabled={this.props.load}
                                                        className="ml-2"
                                                        onClick={() => this.props.handleOpenModalDescuento(det)}
                                                        title="Descuento"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tdDetallePreventa-resp " key={`div-det-${det.id}`}
                                             style={{height: "75px", padding: "20px 0px 0px 0px"}}>
                                            <div className="">
                                                <div className="d-flex">
                                                    <div className="no-pad">
                                                        <input
                                                            onClick={(e) => {
                                                                e.target.select()
                                                            }}
                                                            readOnly={this.props.load}
                                                            type="number"
                                                            style={{backgroundColor: "rgb(53,96,90,0.18)"}}
                                                            step="1"
                                                            min="1"
                                                            name="Cantidad"
                                                            value={det.Cantidad}
                                                            className="inputCantidadDet"
                                                            onChange={e => this.props.InputCantidadDetalleChange(e, det)}
                                                            onBlur={e => this.props.onBlurInputCantidad(e, det)}
                                                        />
                                                    </div>
                                                    <div className="no-pad">
                                                        {canUpdatePrice ? (

                                                            <input
                                                                onClick={(e) => {
                                                                    e.target.select()
                                                                }}
                                                                readOnly={this.props.load}
                                                                type="number"
                                                                style={{backgroundColor: "#01a59c", color: "white"}}
                                                                step="1"
                                                                min="0"
                                                                value={det.PrecioVenta}
                                                                onChange={e => this.props.InputPrecioVentaChange(e, det)}
                                                                name="PrecioVenta"
                                                                className="inputPrecioVentaDet"
                                                                onBlur={e => this.props.onBlurPrecioVenta(e, det)}
                                                            />

                                                        ) : (

                                                            <input
                                                                onClick={(e) => {
                                                                    e.target.select()
                                                                }}
                                                                readOnly={true}
                                                                type="number"
                                                                style={{backgroundColor: "#01a59c", color: "white"}}
                                                                step="1"
                                                                min="0"
                                                                disabled={true}
                                                                value={det.PrecioVenta}
                                                                name="PrecioVenta"
                                                                className="inputPrecioVentaDet"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="no-pad">
                                                        {this.props.canUpdateTotal ?
                                                            <input type="number"
                                                                   onChange={e => this.props.onChangeTotal(e, det)}
                                                                   onBlur={e => this.props.onBlurTotal(e, det)}
                                                                   onClick={e => e.target.select()}
                                                                   readOnly={this.props.load}
                                                                   style={{backgroundColor: "#034b2f", color: "white"}}
                                                                   value={decimalAdjust('round', det.Total, -2)}
                                                                   className="inputPrecioVentaDet"/> :
                                                            decimalAdjust('round', det.Total, -2)
                                                        }
                                                    </div>
                                                    <div className="dropdown drop-resp ml-2 mr-2">
                                                        <button className="btn options-ventas" type="button"
                                                                data-toggle="dropdown" aria-expanded="false">

                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item">
                                                                <BotonMenosPequeño
                                                                    disabled={this.props.load}
                                                                    className="ml-2"
                                                                    type="button"
                                                                    title="Disminuir en 1"
                                                                    onClick={() =>
                                                                        this.props.RestarCantidadDet(det)
                                                                    }
                                                                />
                                                            </a>
                                                            <a className="dropdown-item">
                                                                <BotonAddPequeño
                                                                    disabled={this.props.load}
                                                                    className="ml-2"
                                                                    type="button"
                                                                    title="Aumentar en 1"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        this.props.AgregarCantidadDet(det)
                                                                    }}
                                                                />
                                                            </a>
                                                            <a className="dropdown-item">
                                                                <BotonRemovePequeño
                                                                    disabled={this.props.load}
                                                                    className="ml-2"
                                                                    type="button"
                                                                    title="Quitar item"
                                                                    onClick={() =>
                                                                        this.props.RemoveProductoInList(det)
                                                                    }
                                                                />
                                                            </a>
                                                            <a className="dropdown-item">
                                                                <BotonDown
                                                                    disabled={this.props.load}
                                                                    className="ml-2"
                                                                    onClick={() => this.props.handleOpenModalDescuento(det)}
                                                                    title="Descuento"
                                                                />
                                                            </a>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-md-6 d-flex justify-content-center mt-3" style={{visibility: "hidden"}}>
                                                    <div>
                                                        <BotonMenosPequeño
                                                            disabled={this.props.load}
                                                            className="ml-2"
                                                            type="button"
                                                            title="Disminuir en 1"
                                                            onClick={() =>
                                                                this.props.RestarCantidadDet(det)
                                                            }
                                                        />
                                                        <BotonAddPequeño
                                                            disabled={this.props.load}
                                                            className="ml-2"
                                                            type="button"
                                                            title="Aumentar en 1"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                this.props.AgregarCantidadDet(det)
                                                            }}
                                                        />
                                                        <BotonRemovePequeño
                                                            disabled={this.props.load}
                                                            className="ml-2"
                                                            type="button"
                                                            title="Quitar item"
                                                            onClick={() =>
                                                                this.props.RemoveProductoInList(det)
                                                            }
                                                        />
                                                        <BotonDown
                                                            disabled={this.props.load}
                                                            className="ml-2"
                                                            onClick={() => this.props.handleOpenModalDescuento(det)}
                                                            title="Descuento"
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }

}
