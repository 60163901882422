import React from "react";
import ProductosSucursalesUI from "./ProductosSucursalesUI";
import PageError from "../componentes/PageError";
import PageLoading from "../componentes/PageLoading";
import ProductoService from "../services/ProductoService";
import {notificarError} from "../componentes/Almacenes/AlmacenNotify";


class ProductosSucursales extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            error: null,
            IdProducto: null,
            statusModal: false,
            respuestaDeleteProducto: null,
            table: {
                pageSize: 50,
                sorted: false,
                filtered: false,
                isLoading: true,
                pages: -1,
            },
            filterParams: {},
        };
        this.prodService = new ProductoService();
        this.onFilterProds = this.onFilterProds.bind(this);
        this.setTableState = this.setTableState.bind(this);
    }

    async onFilterProds(state, instance) {
        this.setTableState({isLoading: true});
        const params = {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }
        this.setState({data: [], filterParams: params});
        let prods = await this.prodService.filtrarSucursalesProds(params, { idSucursal: 3 })
        const prodsClean = prods.productos.filter(x => x)
        if (prodsClean) {
            this.setState({data: prodsClean})
            this.setTableState({pages: prods.pages})
        } else
            notificarError("No se ha podido filtrar los productos")
        this.setTableState({isLoading: false});
    }

    setTableState(newTableState) {
        this.setState((localState, props) => {
            return {
                table: {
                    ...localState.table,
                    ...newTableState,
                }
            }
        })
    }

    render() {
        const {isLoading, error} = this.state;
        if (isLoading) {
            return <PageLoading/>;
        }
        if (error) {
            return <PageError error={this.state.error}/>;
        }
        return (
            <div id="productosContainer">
                <ProductosSucursalesUI
                    onFilterProds={this.onFilterProds}
                    tableInfo={this.state.table}
                    data={this.state.data}
                    handleOnClickProducto={this.handleOnClickProducto}
                    IdProducto={this.state.IdProducto}
                    statusModal={this.state.statusModal}
                    history={this.props.history}
                />
            </div>
        );
    }
}

export default ProductosSucursales;
