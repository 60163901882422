import React from "react";
import "./styles/Productos.css";
import "jspdf-autotable";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactExport from "react-data-export";
import ProductoService from "../services/ProductoService";
import {ticketProductoPDF} from "../componentes/Caja/printDataCaja";
import { BotonExcel, } from "../componentes/Plantillas/Botones";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class ProductosSucursalesUI extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            dataProds: [],
            sucursales: []
        }
        this.prodService = new ProductoService();
        this.printProduct = this.printProduct.bind(this);
    }

    componentDidMount() {
        this.fetchSucursales();
    }

    async fetchSucursales() {
        const sucursalesReq= await fetch('/api/configFactElectronica/SucursalEmpresa');

        if (!sucursalesReq.ok) {
            return [];
        }

        const sucursales = await sucursalesReq.json();

        this.setState({ sucursales })
    }

    async printProduct(product) {
        //console.log({product})
        await ticketProductoPDF(product)
        return
    }

    getDataExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: `Código`,
                        width: {wpx: 150}
                    },
                    {
                        title: `Cod. de barras`,
                        width: {wpx: 150}
                    },
                    {
                        title: `Nombre `,
                        width: {wpx: 250}
                    },
                    ...this.state.sucursales.map((s) => {
                        return {
                            title: s.Nombre,
                            width: {wpx: 230}
                        }
                    })
                ],
                data: dataSet.map(row => {
                    return [
                        {
                            value: row.codigo,
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: row.CodigoBarra || "",
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: row.nombre,
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        ...this.state.sucursales.map((s) => {
                            return {
                                value: row[`Sucursal${s.IdSucursal}`] || 0,
                                style: {
                                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                                }
                            }
                        })
                    ];
                })
            }
        ];
    }

    render() {
        const props = this.props;
        const table = props.tableInfo;
        const dataExport = this.getDataExportExcel(this.props.data)

        return (
            <section className="ventas-fpay-section-vh pt-5">
                <div className="container">
                    <div className="ProcesarBoton mt-1 mb-3">
                        {/* <BotonCancelar
                            onClick={props.history.goBack}
                            className=""
                        /> */}
                        <div className="ProcesarBotones">
                            { props.data.length ? (
                                <ExcelFile
                                    element={
                                        <BotonExcel type="button" title="Guardar Excel"/>
                                    }
                                    filename="ReporteProductos"
                                >
                                    <ExcelSheet
                                        dataSet={dataExport}
                                        name="Productos"
                                    >
                                    </ExcelSheet>
                                </ExcelFile>
                            ) : null}
                        </div>

                    </div>
                    {
                        <ReactTable
                            id="table-productos-to-xls"
                            data={props.data}
                            pages={table.pages}
                            loading={table.isLoading}
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value
                            }
                            manual={true}
                            filterable={true}
                            sortable={true}
                            onFetchData={(state, instance) => props.onFilterProds(state, instance)}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Código",
                                            id: "codigo",
                                            width: 90,
                                            accessor: d => d.codigo,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["codigo"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "CodigoBarra",
                                            id: "CodigoBarra",
                                            width: 110,
                                            accessor: d => d.CodigoBarra,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["CodigoBarra"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Nombre",
                                            id: "nombre",
                                            width: 350,
                                            accessor: d => d.nombre,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["nombre"]}),
                                            filterAll: true
                                        },
                                        ...this.state.sucursales.map((s) => {
                                            return {
                                                Header: s.Nombre,
                                                id: s.IdSucursal,
                                                width: 350,
                                                accessor: d => d[`Sucursal${s.IdSucursal}`],
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["nombre"]}),
                                                filterAll: true
                                            }
                                        })
                                    ]
                                }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            previousText="Anterior"
                            nextText="Siguiente"
                            noDataText="No se encontraron registros"
                            loadingText="Cargando..."
                            ofText="de"
                            rowsText="filas"
                            pageText="Página"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                    }
                </div>
            </section>
        );
    }
}

export default ProductosSucursalesUI;
